export const timeOffSteps = [
    {
        target:'#timeOffCode',
        content:'You can create time off code by clicking here',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#timeOff',
        content:'Here you can create time off requests for your staff members',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'div > div > div > form',
        content:'Search you staff members with this powerful search',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.select-mini',
        content:'Filter time-off records based on status by selecting here. PENDING, REJECTED & APPROVED',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'table > tbody > tr',
        content:'You can review the details of your time off request here.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#time-off-request-action-buttons > div',
        content:'Approve or Reject your requests for time off.',
        placement:'auto',
        disableBeacon: true,
        spotlightPadding:5,
    },
]

