import React, { useState, useEffect } from "react";
import { CommonPageLoader, NormalButton, NormalModal } from "component/common";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSingleWork, updateWRStaffStatus } from "action/workRequest";
import { BreakTimePicker, ClockInPicker, ClockOutPicker } from "./DateTimePicker";
import classes from "./EditTimeSheet.module.scss";
import SelectDropdown from "./SelectDropdown";
import { getClassNameForBackground } from "service/utilities";
import { getClassNameForStaffBackground } from "service/utilities";
import { editTimeSheetBreak, editTimeSheetCheckIn, editTimeSheet } from "action/timeSheet";
import "./style.scss";
import marketIcon from '../../../assets/svg/VectorpencilBlue.svg';
import infoIcon from '../../../assets/svg/infoBlue.svg';
import notesIcon from '../../../assets/svg/notes-tick-1.svg'
import clockIn from '../../../assets/svg/map_cursors/clockIn.svg'
import clockOut from '../../../assets/svg/map_cursors/clockOut.svg'
import marketIconRed from 'assets/svg/VectorRed.svg'
import { NormalTextarea } from "component/common";
import DetailsItemWithImg from "./DetailsItemWithImg";
import EditableDropdown from "./EditableDropdown";
import { getCurrentLocation } from "utilities/getCurrentLocation";
import {Toast} from '../../../service/toast';
import flag from '../../../assets/svg/flag.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Status Mappings
const status = {
	0: "CANCELED",
	1: "NEW REQUEST",
	2: "PENDING STAFF ASSIGNMENT",
    3: "PENDING STAFF CLOCK-IN",
    4: "STAFF IS WORKING",
    5: "PENDING TIMESHEET APPROVAL BY CLIENT",
    6: "PLEASE REVIEW STAFF TIMESHEET",
    7: "TIMESHEET APPROVED. CREATE INVOICE NOW!",
    8: "INVOICE CREATED",
};

const staffStatusMap = {
	0 :"CANCELED",
	1 :"YET TO ASSIGN",
	2 :'YET TO APPROVE BY AGENCY',
	3 :'YET TO APPROVE BY CLIENT',
	4 :'IN PROGRESS',
	5 :'COMPLETED BY STAFF',
	6 :'Moved to phase 2',
	7 :'Moved to phase 2',
	8 :'Moved to phase 2',

};
const statusList = [
    {
        label: "Select Status",
        value:"Select" ,
        icon: "",
        boxColorClass: getClassNameForBackground(),
        click: "handleShow",
    },
    {
        label: "CANCELED",
        value: 0,
        boxColorClass: getClassNameForBackground(0),
        click: "handleShow",
    },
	// {
    //     label: "PENDING STAFF CLOCK-IN",
	// 	value: 3,
	// 	boxColorClass: getClassNameForBackground(3),
	// 	click: "handleShow",
	// },
	{
        label: "PENDING TIMESHEET APPROVAL BY CLIENT",
        value: 5,
        boxColorClass: getClassNameForBackground(5),
        click: "handleShow",
    },
    {
        label:"PLEASE REVIEW STAFF TIMESHEET",
        value: 6,
        boxColorClass: getClassNameForBackground(6),
        click: "handleShow",
    },
	{
        label: "STAFF IS WORKING",
        value: 4,
        boxColorClass: getClassNameForBackground(4),
        click: "handleShow",
    },
	{
        label: "TIMESHEET APPROVED. CREATE INVOICE NOW!",
        value: 7,
        boxColorClass: getClassNameForBackground(7),
        click: "handleShow",
    },
    // {
    //     label: "INVOICE GENERATED",
    //     value: 8,
    //     boxColorClass: getClassNameForBackground(8),
    //     click: "handleShow",
    // },
  
];

const staffStatusList = [
    {
      label: "Select Status",
      value: "",
      icon: "",
      click: "handleShow",
      boxColorClass: "",
    },
    {
      label: "COMPLETED BY STAFF",
      value: 5,
      boxColorClass: getClassNameForBackground(5),
    },
    
    {
      label: "IN PROGRESS",
      value: 4,   
      boxColorClass: getClassNameForBackground(3),
    },
];

// const pendingClockInStaffStatusList = [
//       {
// 		label: "PENDING CLOCKIN",
// 		value: 4,
// 		boxColorClass: getClassNameForBackground(3),
// 	  }
// ]

const inProgressStaffStatusList = [
    {
      label: "IN PROGRESS",
      value: 4,   
      boxColorClass: getClassNameForBackground(3),  
    },
];


const completedStaffStatusList = [
	{
		label: "COMPLETED BY STAFF",
		value: 5,
		boxColorClass: getClassNameForBackground(5),
	  }
];

const cancelStaffStatusList = [
	{
		label: "CANCELED",
		value: 0,   
	    boxColorClass: getClassNameForBackground(0),
	  },
];

// default values
const editTimeSheetDataDefaults = {
	clockIn: "",
	clockInStatus: false,
	clockOut: "",
	clockOutStatus: false,
	breakTime: [],
};


function EditTimeSheet({ show, activeStaffId, closeModal, workRequestId, getSingleWork, updateWRStaffStatus, editTimeSheetCheckIn, editTimeSheetBreak, editTimeSheet, afterLoadCalendar, Query }) {
	const [data, setData] = useState("");
	const [dataLoading, setDataLoading] = useState(true);
	// const [staffSelected, setStaffSelected] = useState("");
	const [editTimeSheetData, setEditTimeSheetData] = useState({ ...editTimeSheetDataDefaults });

	const [isEditStatus, setEditStatus] = useState(false);
	const [isEditStaffStatus, setEditStaffStatus] = useState(false);
	const [staffStatus, setStaffStatus] = useState();
	const [wrStatus, setWRStatus] = useState();
	const [loadingKey, setLoadingKey] = useState();
	// const [totalHours, setTotalHours] = useState("0hrs 0min");

	// -------------------
	// Close Modal Resets
	// -------------------

	const closeAndResetModal = () => {
		setData("");
		setDataLoading(true);
		// setStaffSelected("");
		setStaffStatus("");
		setWRStatus("");
		setEditStatus(false);
		setEditStaffStatus(false);
		setEditTimeSheetData({ ...editTimeSheetDataDefaults });
		closeModal();
	};
	const handleHazardPay = () =>{
		setEditTimeSheetData({...editTimeSheetData, claimHazardPay:!editTimeSheetData.claimHazardPay})
	  }
	// --------------------------------
	// Fetch Work Request Data by 'id'
	// --------------------------------

	const fetchWorkById = async (id) => {
		setDataLoading(true);
		const query = { id };
		await getSingleWork(query)
			.then((res) => {
				// console.log("data: ", res);
				setData(res);
				setWRStatus(res?.status);
				setDataLoading(false);
			})
			.catch((err) => {
				console.log("error ocuured : ", err);
				setDataLoading(false);
			});
	};

	const changeWRStatus = async(e) => {
		setDataLoading(true);
		setEditStatus(false);
		setEditStaffStatus(false);
		setWRStatus(e.target.value);
		const query = {
			"workId": workRequestId,
			"status": e.target.value,
			"clientId": data.clientId,
			"workStaffStatus": [
				{
				  "staffId": activeStaffId,
				  "status": staffStatus,
				}]
		  };
		  
		await updateWRStaffStatus(query)
			.then((res) => {
				afterLoadCalendar(Query);
				setDataLoading(false);
				setEditTimeSheetData([...editTimeSheetData])
			})
			.catch((err) => {
				setDataLoading(false);
			});

	}
	const changeStaffStatus = async(e) => {
		setStaffStatus(e.target.value);
		setEditStatus(false);
		setEditStaffStatus(false);
		setDataLoading(true);
		const query = {
			"workId": workRequestId,
			"status": wrStatus,
			"clientId": data.clientId,
			"workStaffStatus": [
			  {
				"staffId": activeStaffId,
				"status": e.target.value,
			  }
			]
		  };
		  
		await updateWRStaffStatus(query)
			.then((res) => {
				afterLoadCalendar(Query);
				setDataLoading(false);
			})
			.catch((err) => {
				console.log("error ocuured : ", err);
				setDataLoading(false);
			});
	}

	useEffect(() => {
		!!show && !!workRequestId && fetchWorkById(workRequestId);	
	}, [workRequestId, show]);
	
	// --------------------------------------------------------
	// Handling Time States - Clock-in, Clock-Out & Break-Time
	// --------------------------------------------------------

	const updateTimeSheet = ({ staffId, clockIn, clockOut, pauseResumeData = [], clockInLongLat=null,clockOutLongLat=null, claimHazardPay=false, hazardPayReason='', agencyEditTSNotes = "" ,staffNotes='' }) => {
		if (!staffId) return;

		let timeSheetObj = { ...editTimeSheetDataDefaults };

		if (!!clockIn) {
			timeSheetObj.clockIn = moment(clockIn);
			timeSheetObj.clockInLongLat = clockInLongLat;
			timeSheetObj.clockInStatus = true;
		}
		
		if (!!clockOut) {
			timeSheetObj.clockOut = moment(clockOut);
			timeSheetObj.clockOutLongLat = clockOutLongLat;
			timeSheetObj.clockOutStatus = true;
		}

		if (!!pauseResumeData?.length) {
			let breakTimeTemp = [];
			for (let i = 0; i < pauseResumeData.length; i += 2) {
				let newBreakTime = {
					pauseAt: !!pauseResumeData?.[i]?.time ? moment(pauseResumeData?.[i]?.time) : "",
					pauseLongLat: pauseResumeData?.[i]?.longLat ,
					resumeAt: !!pauseResumeData?.[i + 1]?.time ? moment(pauseResumeData?.[i + 1]?.time) : "",
					resumeLongLat: pauseResumeData?.[i + 1]?.longLat,
				};
				breakTimeTemp.push({ ...newBreakTime });
			}
			timeSheetObj.breakTime = [...breakTimeTemp];
		}
		if(claimHazardPay){
			timeSheetObj.claimHazardPay = true;
			timeSheetObj.hazardPayReason = hazardPayReason
		}else{
			timeSheetObj.claimHazardPay = false;
			timeSheetObj.hazardPayReason =''
		}

		if(!!staffNotes) {
			timeSheetObj.staffNotes = staffNotes
		}

		if(!!agencyEditTSNotes) {
			timeSheetObj.agencyEditTSNotes = agencyEditTSNotes
		}

		setEditTimeSheetData({ ...timeSheetObj });
	};

	useEffect(() => {
		
		if (!!activeStaffId && !!data) {
			let staffData = data?.workStaffAssignment?.staffWorkData?.find(({ staffId }) => staffId?.toString() === activeStaffId?.toString());
			setStaffStatus(staffData['status']);
			updateTimeSheet({ staffId: activeStaffId, clockIn: staffData?.clockIn, clockOut: staffData?.clockOut, pauseResumeData: staffData?.pauseResumeData, clockInLongLat:staffData?.clockInLongLat, clockOutLongLat:staffData?.clockOutLongLat, claimHazardPay: staffData?.claimHazardPay, hazardPayReason:staffData?. hazardPayReason, agencyEditTSNotes: staffData?.agencyEditTSNotes, staffNotes: staffData?.staffNotes });
		}
	
		// else setEditTimeSheetData({ ...editTimeSheetDataDefaults });
	}, [activeStaffId,data]);

	
	const addTime = (action) => {
		if (action == "clock-in") setEditTimeSheetData((prev) => ({ ...prev, clockIn: "", clockInStatus: true, clockInLongLat:null }));
		else if (action == "clock-out") setEditTimeSheetData((prev) => ({ ...prev, clockOut: "", clockOutStatus: true, clockOutLongLat: null }));
		else {
			let newBreakTime = {
				pauseAt: "",
				pauseLongLat: null,
				resumeAt: "",
				resumeLongLat: null,
			};
			setEditTimeSheetData((prev) => ({ ...prev, breakTime: [...prev?.breakTime, newBreakTime] }));
		}
	};

	// const totalHourCalculator = () => {
	// // Need to do more validations and corrections before making visible
	// 	let { clockIn, clockOut, breakTime } = editTimeSheetData;

	// 	if (!clockIn || !breakTime?.length) {
	// 		setTotalHours("0hrs 0min");
	// 		return;
	// 	}

	// 	let end = !!clockOut ? clockOut : breakTime?.[breakTime?.length - 1]?.pauseAt;
	// 	let time_between_in_out = new Date(end).getTime() - new Date(clockIn).getTime();

	// 	console.log("end : ", end);
	// 	console.log("time_between_in_out : ", time_between_in_out);

	// 	let total_break_time = 0;
	// 	let break_time_end_index = !!clockOut ? breakTime?.length - 1 : breakTime?.length - 2;
	// 	for (let i = 0; i < break_time_end_index; i++) {
	// 		const { pauseAt, resumeAt } = breakTime[i];
	// 		total_break_time += new Date(resumeAt).getTime() - new Date(pauseAt).getTime();
	// 	}

	// 	console.log("total_break_time : ", total_break_time);

	// 	let total_time = (time_between_in_out - total_break_time) / (1000 * 60); // in mins

	// 	setTotalHours(`${parseInt(total_time / 60 || 0)}hrs ${parseInt(total_time % 60 || 0)}min`);
	// };

	// useEffect(() => {
	// 	totalHourCalculator();
	// }, [editTimeSheetData]);

	const dateFormatter = (date) => (!!date ? moment(new Date(date)).format("YYYY-MM-DD[T]HH:mm:ss.SSSS") : null);

	const getRecentTimeForClockOut = (data) => {
		let breakTimeLength = data?.breakTime.length;
		if(breakTimeLength < 1)
			return data?.clockIn;
		else if(breakTimeLength -1 >= 0)
			return data?.breakTime?.[breakTimeLength - 1].resumeAt;
	}

	const isBreakTimeValid = (data) => {
		let isValid = true;
		data.breakTime.forEach((item, i) => 
		{
			if(i==0)
			{
				if(item.pauseAt < data?.clockIn ) isValid = false;
			}
			else
			{
				if(item.pauseAt < data?.breakTime[i-1].resumeAt ) isValid = false;
			}
			if(item.resumeAt < item.pauseAt ) isValid = false;
		})
		return isValid;
	}
	const isClockOutValid = (data) => {
		let isValid = false;
		if(data.clockOut > getRecentTimeForClockOut(data)) 
			isValid = true;
		return isValid;
	}
	
	// ----------------------------------
	// Save / Submit Edit Timesheet Data
	// ----------------------------------

	const saveTimesheet = async () => {
		if (!workRequestId || !activeStaffId || !editTimeSheetData.clockIn) return;

		if(!isBreakTimeValid(editTimeSheetData)) {
			Toast({ type: "error", message: "Please enter valid Pause and Resume Date and Time" });
			return ;
		}
		if(!isClockOutValid(editTimeSheetData)) {
			Toast({ type: "error", message: "Please enter valid ClockOut Time" });
			return ;
		}
			
		let currentLocation = await getCurrentLocation();

		let editTimeSheetBody = {
			staffId: activeStaffId,
			workId: workRequestId,
			clientId: data.clientId,
			clockInTime: dateFormatter(editTimeSheetData.clockIn),
			clockInLongLat: (!!currentLocation.status)?[currentLocation.longitude, currentLocation.latitude]:editTimeSheetData.clockInLongLat,
			clockOutTime: dateFormatter(editTimeSheetData.clockOut),
			clockOutLongLat: (!!currentLocation.status)?[currentLocation.longitude, currentLocation.latitude]:editTimeSheetData.clockOutLongLat,
			clientSiteTimeZone: data.clientSiteTimeZone,
			claimHazardPay:editTimeSheetData.claimHazardPay,
			hazardPayReason:editTimeSheetData.claimHazardPay ? editTimeSheetData.hazardPayReason :'',
			agencyEditTSNotes: editTimeSheetData.agencyEditTSNotes
		};
		
		if (!!editTimeSheetData.breakTime?.length) {
			let pauseResumeDatas = [];
			for (let i = 0; i < editTimeSheetData.breakTime.length; i++) {
				const item = editTimeSheetData.breakTime[i];
				pauseResumeDatas.push({ key: 'pause', time: dateFormatter(item?.pauseAt), longLat: (!!currentLocation.status)?[currentLocation.longitude ,currentLocation.latitude]:(!!item?.pauseLongLat) ? item?.pauseLongLat : null, clientSiteTimeZone: data.clientSiteTimeZone });
				pauseResumeDatas.push({ key: 'resume', time: dateFormatter(item?.resumeAt), longLat:(!!currentLocation.status)?[currentLocation.longitude, currentLocation.latitude]:(!!item?.resumeLongLat )? item?.resumeLongLat : null, clientSiteTimeZone: data.clientSiteTimeZone });
			}
			editTimeSheetBody.pauseResumeData = [...pauseResumeDatas];
		}
        setLoadingKey('save-time-sheet')
		await editTimeSheet(editTimeSheetBody)
			.then((res) => {
				afterLoadCalendar(Query);
				setLoadingKey('')
				closeAndResetModal();
			})
			.catch((err) => {
				console.log("error", err);
				setLoadingKey('')
			});
		
		
		// let checkInOutRequestBody = {
		// 	staffId: activeStaffId,
		// 	workId: workRequestId,
		// 	clockInTime: dateFormatter(editTimeSheetData.clockIn),
		// 	clockInLatLong: (!!currentLocation.status)?[currentLocation.latitude, currentLocation.longitude]:editTimeSheetData.clockInLatLong,
		// 	clockOutTime: dateFormatter(editTimeSheetData.clockOut),
		// 	clockOutLatLong: (!!currentLocation.status)?[currentLocation.latitude, currentLocation.longitude]:editTimeSheetData.clockOutLatLong,
		// };

		// await editTimeSheetCheckIn(checkInOutRequestBody)
		// 	.then((res) => {
		// 		console.log("check-in-out response : ", res);
		// 	})
		// 	.catch((err) => {
		// 		console.log("error", err);
		// 	});

		// if (!!editTimeSheetData.breakTime?.length) {
		// 	let pauseResumeDatas = [];

		// 	for (let i = 0; i < editTimeSheetData.breakTime.length; i++) {
		// 		const item = editTimeSheetData.breakTime[i];
		// 		pauseResumeDatas.push({ key: 'pause', time: dateFormatter(item?.pauseAt), pauseResumeLatLong: (!!currentLocation.status)?[currentLocation.latitude ,currentLocation.longitude]:(!!item?.pauseLatLong) ? item?.pauseLatLong : null });
		// 		pauseResumeDatas.push({ key: 'resume', time: dateFormatter(item?.resumeAt), pauseResumeLatLong:(!!currentLocation.status)?[currentLocation.latitude, currentLocation.longitude]:(!!item?.resumeLatLong )? item?.resumeLatLong : null });
		// 	}

		// 	let pauseResumeRequestBody = {
		// 		staffId: activeStaffId,
		// 		workId: workRequestId,
		// 		pauseResumeData: [...pauseResumeDatas],
		// 	};

		// 	await editTimeSheetBreak(pauseResumeRequestBody)
		// 		.then((res) => {
		// 			console.log("pause-resume response : ", res);
		// 		})
		// 		.catch((err) => {
		// 			console.log("error", err);
		// 		});
		// }
	};
	 const handleFlag = () =>{
		return  parseFloat(data?.workStaffAssignment?.staffWorkData?.find(({ staffId }) => staffId?.toString() === activeStaffId?.toString())?.staffWorkedHrs) > (((moment(data?.endDate)- moment(data?.startDate)) / (60*1000*60) ))
	 }
	return (
		<div>
			<NormalModal
				className="modal-fill"
				isOpen={workRequestId}
				size="lg"
				onRefresh={()=>{
					!!show && !!workRequestId && fetchWorkById(workRequestId);
				}}
				renderHeader={(e) => (
					<div className=" d-flex justify-content-between mb-0">
						<h6 className="font-weight-bold ">WR Id - {`${!!workRequestId && workRequestId.substr(workRequestId.length - 6)}`}</h6>
					</div>
				)}
				bodyClass="add-staff-box"
				headerClass="border-0 pt-4 px-4"
				toggle={closeAndResetModal}
			>
				{!dataLoading ? (
					<div>
						{/* Basic Details */}
						<>
						  <div className="header-timesheet">
							<DetailsItem label=  "Client Name" 	
							value={data?.clientName || "" } 
							// hoverValue={ <div className="pl-3 pt-1"> {data?.location || ""} </div>} 
							/>
								
							{/* <DetailsItem label=  "Client Name"							
							value={data?.clientName || ""}
							  />  */}
							{/* <DetailsItem label="Client Site" value={data?.location || ""} /> */}
							<DetailsItemWithImg
						        image={notesIcon}
								label="Client Notes"
							    value={
									!data?.notes?
								<>
								<span>{!!data?.notes ? data?.notes : "No notes found"}</span>
					        	</>:""
							    }
								hoverValue={<div className={!!data?.notes?"":"text-center"}>{!!data?.notes?data?.notes: "No notes found"}</div>}
							/>
							</div>
							<DetailsItem label="Job Function" value={data?.jobCategory +" - "+ data?.jobName || ""} />
							<DetailsItem label="Shift Date/Time" value= {
							<><div>
							<img className="mapImage" src = {clockIn} /> 
							
							<span className="pl-1">{!!data?.startDate ? moment(data?.startDate).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`): "--"}
							</span>
							<span className="pl-2">
							<img className=" mapImage" src ={clockOut} /></span>
							<span className="pl-1">{!!data?.endDate ? moment(data?.endDate).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) : "--"}
							</span></div>
							</>
							}
							/>
							
								{/* Notes */}
							<EditableDetails
								label="Reason to Edit"
								editableValue ={true}
								data={data}
								value={
									<NormalTextarea
										placeholder="Reason to edit Timesheet"
                                    	rows={2}
										value={editTimeSheetData.agencyEditTSNotes}
          								onChange={(e)=> setEditTimeSheetData({...editTimeSheetData, agencyEditTSNotes:e.target.value})}
									/>}
							/> 	
							
							<DetailsItemStaff
								label="Staff Name"
								align="align-items-center"
								value={
									<SelectDropdown
										options={
											!!data?.workStaffAssignment?.staffWorkData?.length
												? data?.workStaffAssignment?.staffWorkData?.map((staff) => ({ label: staff?.staffName, value: staff?.staffId }))
												: []
										}
										// onChange={setStaffSelected}
										onChange={()=>{}}
										value={activeStaffId}
										placeholder="Select staff"
										disabled={true}
									/>
								}
								hasimage={handleFlag()} 
								hoverFlag= {<div style={{fontFamily:"Poppins, Josefin Sans, Lora, sans-serif"}}>Staff has worked more than expected time</div>}
								img ={infoIcon}
                                hoverItem=
							{<div className="pl-3 pt-1"> Tips : 
							ClockIn/ Pause /Resume and Clockout should be in a sequential order .
Please delete clockout to add the Break Time and update the clockout again .
Deleting the clock In leads to the complete deletion of shift Entries .
Click Cancel to Exit and  retrive the previous entries .  
								</div>}
								
							/>
							
						</>
						
						{/* Clock-in, break-time and Clock-out manager */}
						{!!activeStaffId ? (
							<div>
								{/* Total Hours here */}
								{/* <div className={classes.total_hours}>
							<p>Total Hours</p>
							<p>:</p>
							<p>{totalHours}</p>
						</div> */}
<hr/>

								<div className="pt-3 pb-2">
									{!!editTimeSheetData?.clockInStatus && <ClockInPicker data={editTimeSheetData} setData={setEditTimeSheetData} clientLatLng={data?.longLat} clientSiteTimeZone={data.clientSiteTimeZone}/>}
									{!!editTimeSheetData?.breakTime?.length &&
										editTimeSheetData?.breakTime?.map((_, index) => (
											<BreakTimePicker key={index} index={index} data={editTimeSheetData} setData={setEditTimeSheetData} clientLatLng={data?.longLat} clientSiteTimeZone={data.clientSiteTimeZone} />
										))}
									{!!editTimeSheetData?.clockOutStatus && <ClockOutPicker data={editTimeSheetData} setData={setEditTimeSheetData} clientLatLng={data?.longLat} clientSiteTimeZone={data.clientSiteTimeZone} />}
								</div>

								{!editTimeSheetData?.clockOutStatus && (
									<div className={classes.action_container}>
										<button className={classes.action_btn} disabled={!!editTimeSheetData?.clockInStatus} onClick={() => addTime("clock-in")}>
											Clock-In
										</button>
										<button
											className={classes.action_btn}
											disabled={!!editTimeSheetData?.clockOutStatus || !editTimeSheetData?.clockInStatus}
											onClick={() => addTime("break-time")}	
										>
											Add Break
										</button>
										<button
											className={classes.action_btn}
											disabled={!!editTimeSheetData?.clockOutStatus || !editTimeSheetData?.clockInStatus}
											onClick={() => addTime("clock-out")}
										>
											Clock-Out
										</button>
									</div>
								)}

      <div className="d-flex align-items-center justify-content-between w-100 claimhazardpay">
        
         <div className="d-flex flex-row align-items-center " style={{gap:'10px'}}>
          <div className=" pb-3 ">
           <input className="hazardpay_switch" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={editTimeSheetData.claimHazardPay} onChange={handleHazardPay}/>
           <label className="hazardpay_label" for="flexSwitchCheckDefault"></label>
          </div>
           <span className="sub-text fs-13 s2-r" for="flexSwitchCheckDefault">Claim Hazard Pay ?</span>
          </div>
       
       
          {editTimeSheetData.claimHazardPay ? (
            <input
             style={{border:'1px solid black', height:'40px', width:'300px'}}
             className="rounded pl-1 mr-5 text-black fs-15 claim-hazard-pay"
             type="text"
             value={editTimeSheetData.hazardPayReason}
             onChange={(e)=> setEditTimeSheetData({...editTimeSheetData, hazardPayReason:e.target.value})}
             placeholder="Enter Reason (Max 50 characters)"
             maxLength={50}
           />
         ): null}	 
     </div>

	 <div className="d-flex w-100">
		{ editTimeSheetData.staffNotes?
         <div className="pt-2">
			<span className="sub-text fs-13">Staff Notes</span>
		 </div> :""
        }
		 <div className="pb-3">
		 {editTimeSheetData.staffNotes ? (
		   <div class="frame-2400" id="staffnotes">
                            <div class="at-emergency-ward-got-hurt-due-to-human-error">
                             {editTimeSheetData.staffNotes}
                            </div>
                            </div>
         ): null}
		 </div>
	   </div>

						{/* Action Buttons */}

						<EditableDropdown
							label="Do you want to change the Work Status ?"
							iseditStatus = {isEditStatus}
							setEditStatus = {setEditStatus}
							options={statusList}
							onClick = {(e) => changeWRStatus(e)}
							value={
								<>
									<span className={"color-box " + ((wrStatus == 2 && data?.workStaffAssignment && data?.workStaffAssignment.staffWorkData.length !== 0) ? getClassNameForBackground(2.1) : getClassNameForBackground(wrStatus))}></span>
									<span className="ml-3 s1-r">{  (wrStatus == 2 && data?.workStaffAssignment && data?.workStaffAssignment.staffWorkData.length !== 0) ? "VIEW STAFF RESPONSE. PLEASE CONFIRM" : !!status[wrStatus] ? status[wrStatus] : ""}</span>
								</>
							}
						/>
					   <EditableDropdown
							label="Do you want to change the Staff Status ?"
							iseditStatus = {isEditStaffStatus}
							setEditStatus = {setEditStaffStatus}
							options = {wrStatus == 0 ? cancelStaffStatusList : (wrStatus == 6 || wrStatus == 7) ? completedStaffStatusList : wrStatus == 4 ? inProgressStaffStatusList :  wrStatus == 5? completedStaffStatusList :staffStatusList }
							onClick = {(e) =>changeStaffStatus(e)}
							value={
								<>
									<span className={"color-box " + getClassNameForStaffBackground(staffStatus)}></span>
									<span className="ml-3 s1-r">{ wrStatus == 3 && staffStatus == 4 ? "PENDING CLOCKIN":
									!!staffStatusMap[staffStatus] ? staffStatusMap[staffStatus]  : ""}</span>
								</>
							}
						/>
						<div className={classes.final_action_container}>
							<button className={classes.final_action_btn_secondary} onClick={closeAndResetModal}>
								Cancel
							</button>
							{/* <button className={classes.final_action_btn_primary} disabled={!editTimeSheetData.clockIn && wrStatus == 0} onClick={saveTimesheet}>
								Submit
							</button> */}
							<NormalButton
							label='Submit'
							className={classes.final_action_btn_primary}
							disabled={!editTimeSheetData.clockIn && wrStatus == 0}
							onClick={saveTimesheet}
							loader={loadingKey === 'save-time-sheet'}
							/>
						</div>
						</div>
						
						) 
						
						: (
							<p className="text-muted text-center my-5">Select any Staff to add/update timesheet</p>
						)}
						

						
					</div>
				) : (
					<CommonPageLoader />
				)}
			</NormalModal>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ getSingleWork, updateWRStaffStatus,editTimeSheetCheckIn, editTimeSheetBreak, editTimeSheet }, dispatch);
};

export default connect(mapDispatchToProps, mapDispatchToProps)(withRouter(EditTimeSheet));

function DetailsItem({ label, value, align = "align-items-start" ,image,hoverItem}) {
	return (
		<div className={`row ${align}`} id="label">
			<p className="col-3 text-muted m-0 mb-3 h3-m">{label}</p>
			<p className="col-1 m-0 mb-3">{!!label ? ":" : ""}</p>
			<div className="col-7 pl-2 mb-2 s2-r">{value}</div>
		</div>
	);
}
function DetailsItemStaff({ label, value, align = "align-items-start",img,hoverItem,hasimage=false,hoverFlag}) {
	return (
		<div className={`row ${align}`}>
			<p className="col-3 text-muted m-0 mb-3 h3-m">{label}</p>
			<p className="col-1 m-0 mb-3">{!!label ? ":" : ""}</p>
			<div className="col-5 pl-2 mb-2 font-weight-bold text-dark">{value}</div>
			<OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            Tips : Clock In/ Pause /Resume and Clockout should be in a sequential order . Please delete clockout to add the Break Time and update the clockout again. Deleting the clock In leads to the complete deletion of shift Entries. Click Cancel to Exit and  retrive the previous entries .
                        </Tooltip>
                    }
                    >
                    <img src={img} alt="info icon" />
                </OverlayTrigger>
			<span className="flag-Icon">
				{hasimage && <img className="mt-3" src={flag} />}
			<span className="extra-Flag"> {hasimage && <img src={flag}/> ?hoverFlag:""}
			</span>
			</span>
			{/* <span className="info-icon">
			<img className=" pl-1" src={img} />
			<span className="extra-info"> {hoverItem}
			</span>
			</span> */}

		</div>
	);
}


function EditableDetails({data, editableValue,label, value, align = "align-items-start" }) {
	const [isEditNote, setEditNote] = useState(editableValue ? true : false);
	const editTimesheetNotes = () => {
		setEditNote(!isEditNote);
	}

	return (
		<div className={`row ${align}`}>

			<p className="col-3 text-muted m-0 mb-3 h3-m">{label}
				<img className="pl-3" src={marketIcon} onClick={editTimesheetNotes} /></p>
			<p className="col-1 m-0 mb-3">{!!label ? ":" : ""}</p>
			{!isEditNote ? (
				<div className="col-7 pl-2 m-0 mb-3">{value}</div>
			) : (
				<div className="col-7 pl-2 mb-2">{!!data?.workStaffAssignment?.staffWorkData[0]?.agencyEditTSNotes ? data?.workStaffAssignment?.staffWorkData[0]?.agencyEditTSNotes : ""}
				</div>
			)}
		</div>				
	);
}

				
/*
	TODO:
	=>	Add pause resume location things
	=>	Handle Location at Saving
	=>	Handle Multiple Staff States
	=>	update API change for only one staff
	=>	Handle Min. date criteria
*/
