import React,{useState,useEffect} from 'react';
import { withRouter, useLocation } from "react-router-dom";
import "./style.scss";
import Moment from "moment";
import {approveStaff,rejectStaff} from "action/workRequest";
import { getFileFromMDB } from 'action/fileHandler';
import {TimeSheetMarkNew } from 'component/workShedule/timeSheetMarkNew/index';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import leftArrowIcon from 'assets/svg/left_arrow.svg';
import rightArrowIcon from 'assets/svg/right_arrow.svg';
import deleteIcon from 'assets/svg/delete.svg';
import profile from '../../../assets/svg/Ellipse.png';
import expandfeedback from "assets/svg/expand-feedback.svg";
import useMapDataParser from 'component/common/interactiveMap/useMapDataParser';
import InteractiveMap from 'component/common/interactiveMap';
import ReactStars from "react-rating-stars-component";
import staffBackIcon from 'assets/svg/staff-back-icon.svg'
import staffNextIcon from 'assets/svg/staff-next-icon.svg'
import staffNameBg from 'assets/svg/staff-name-bg.svg'
import downArrowBlackIcon from 'assets/svg/down-arrow-black.svg'
import upArrowBlackIcon from 'assets/svg/up-arrow-black.svg'

function StaffDetailsNew({setActiveStaff,workID,fetchWorkById, startDate, endDate, approveStaff,rejectStaff,data,sitePicture, clientLocLatLng, wrStatus, getFileFromMDB, clientId}) {

    const [shiftDetails, setShiftDetails] = useState(true);
    const [active, setActive] = useState(0);
    const [isFormLoder, setFormLoder] = useState(false);
    const { timeData: staffTimeData, staffName, staffLatLong, showMap, toggleMap } = useMapDataParser({ data: data?.staffWorkData?.[active] });
    const [clientSitePicture, setClientSitePicture] = useState(null)
    const location = useLocation();

    const [activePointsFromStaffDetails, setActivePointsFromStaffDetails] = useState('');

    useEffect(() => {
        if (!!setActiveStaff) {
            setActiveStaff(staffWorkData[active])
        }
    }, []);
    const handleStaffDetais=(val)=>{
        // console.log(active+val)
        setActive(active+val);
        if(!!setActiveStaff){
            setActiveStaff(staffWorkData[active+val])
        }
    }
    // // handle single staff approve by agency
    // const handleApproveStaff = async (id) => {
    //     let body = {
    //         workId: workID,
    //         staffId: id,
    //     }
    //     setFormLoder(true);
    //     await approveStaff(body).then((data) => {
    //         setFormLoder(false);
    //         fetchWorkById(workID);
    //         // setDefault();
    //         // handleReload();
    //     }).catch(() => {
    //         setFormLoder(false);
    //     });
    // }

    // // handle single staff reject by agency
    // const handleRejectStaff = async (id) => {
    //     let body = {
    //         workId: workID,
    //         staffId: id,
    //         remove:true
    //     }
    //     setFormLoder(true);
    //     await rejectStaff(body).then((data) => {
    //         setFormLoder(false);
    //         fetchWorkById(workID);
    //         // setDefault();
    //         // handleReload();
    //     }).catch(() => {
    //         setFormLoder(false);
    //     });
    // }
    let{staffWorkData,workId}=data;

    const getClientLoc = (longLat) => {
        let clientLoc = {lat: '', lng:''};
        if(!!longLat)
        {
          clientLoc.lat = longLat?.[1];
          clientLoc.lng = longLat?.[0]
        }
        return clientLoc;
      }

    // Client site picture for InteractiveMap
    const handleImageDownload = async (sitePicture) => {
        let imageData;
        let fileId = sitePicture.split('/')[1]
        await getFileFromMDB({entity: 'client', clientId: clientId ? clientId : ''}, fileId)
          .then((response)=> {
            imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
            setClientSitePicture(imageData.toString())
            return imageData
          })
          .catch((error) => {
            setClientSitePicture(null);
            console.log('Error fetching image:', error);
          });
      };

      useEffect(()=>{
        if(sitePicture) {
          handleImageDownload(sitePicture)
        } else {
          setClientSitePicture(null)
        }
      },[sitePicture])
    
    // console.log(staffWorkData)
    return (
        <>
            {/* Map */}
            <InteractiveMap 
                timeData={staffTimeData} 
                staffName={staffName} 
                activePointsFromStaffDetails={activePointsFromStaffDetails} 
                staffLatLong={staffLatLong}
                open={showMap} 
                toggle={toggleMap} 
                sitePicture={clientSitePicture} 
                clientLatLong = {getClientLoc(clientLocLatLng)} 
            />
            
            {/* <div className=""> */}


           {/* staff shift details opener */}
          {!shiftDetails && 
           <div class="frame-2325" >
               <div class="staff-shift-details h2-sb">Staff Time Sheet Shift Details</div>

               <div class="frame-2324">
                 <div class="line-76"></div>

                  <div class="ellipse-202"></div>
                   <img src={downArrowBlackIcon} className='iconsax-linear-arrowdown-2' onClick={()=> setShiftDetails(true)}/>
                 </div>
            </div> }


                
            {/* staff shift Detaiils */}
             {shiftDetails && <>  
            <div class="frame-2364">
             <div class="frame-2205">
                <div class={`frame-2202  ${active === 0 ? 'no-drop':'cursor-pointer'}`} onClick={()=> active === 0 ? {}:handleStaffDetais(-1)}>
      
                       <img  src={staffBackIcon} className='material-symbols-arrow-back-ios-new'/>
 
                     <div class="frame-2253">
                     <div class="show-previous cta-3-m">Show Previous</div>
                            </div>
                         </div>

                          <div class="frame-2204">
                                    <div class="staff-shift-details h2-sb">Staff Time Sheet Shift Details</div>
                                </div>

    <div class={` frame-2203 ${(active+1) === staffWorkData.length ? 'no-drop':'cursor-pointer'} `}   onClick={()=>(active+1) === staffWorkData.length ? { }:handleStaffDetais(1)}>
      <div class="frame-2254">
        <div class="show-next cta-3-m">Show Next</div>
      </div>

     <img src={staffNextIcon} className='material-symbols-arrow-back-ios-new2' />


    </div>
  </div>
  { staffWorkData[active] &&
  <div class="frame-2433">
    <div class="frame-2434">
      <div class="connor-james">{staffWorkData[active].staffName}</div>
    </div>
    
   
    
  </div> }
  <img src={staffNameBg} className='polygon-15' />
          </div>


            {
                staffWorkData[active] ?
                (staffWorkData[active].status >= 3 || (staffWorkData[active].status === 0 && staffWorkData[active].clockIn))?
                    <TimeSheetMarkNew data={staffWorkData[active]} toggleMap={toggleMap} startDate={startDate} endDate={endDate} setActivePointsFromStaffDetails={setActivePointsFromStaffDetails} />
                :<>
                   <p style={{marginTop:"1rem"}}>No TimeSheet Details..</p> 
                </>
                : ""
            }
            
            {/* staff details close */}

            {/* Individual Staff Rating*/}
            {wrStatus > 5 &&
              <div style={{position: "relative", bottom: "20px", marginTop: staffWorkData[active]?.hazardPayReason !== null && staffWorkData[active]?.staffNotes !== null && "30px"}} class="frame-2247">
              <div class="frame-2245">
                <div class="frame-2244">
                  <div class="overall-wr overall-staff-name s1-r">{staffWorkData[active]?.staffName}</div>
                </div>
                {staffWorkData[active]?.staffReviews?.rating > 0 ?
                  <div style={{position: "relative", top: "5px"}} class="_4-3-5">
                    <span>
                      <span class="_4-3-5-span">{staffWorkData[active]?.staffReviews?.rating}</span>
                      <span class="_4-3-5-span2">/ 5</span>
                    </span>
                  </div>
                    :<span style={{position: "relative", top: "3px"}} class="_4-3-5-span s3-r">Not Rated</span> }

                <div class="frame-2018">
                  {/* Star Rating goes here*/}
                  <ReactStars
                    key={staffWorkData[active]?.staffId} 
                    size={25}
                    count={5}
                    color={"#E3E3E3"}
                    activeColor={"#FABB05"}
                    value= {staffWorkData[active]?.staffReviews?.rating}
                    edit={false}
                    isHalf={true}
                  />
                </div>
              </div>

              <div class="frame-2246">
                <div class="view-feedback s3-r">
                  {staffWorkData[active]?.staffReviews?.feedback 
                  ? staffWorkData[active]?.staffReviews?.feedback.length > 115
                  ? `${staffWorkData[active]?.staffReviews?.feedback.slice(0, 115)}...` 
                  : staffWorkData[active]?.staffReviews?.feedback
                  : "No Feedback"}
                </div>
                {staffWorkData[active]?.staffReviews?.feedback
                  ? staffWorkData[active]?.staffReviews?.feedback.length > 115 ?
                  <>
                  <img src={expandfeedback} style={{marginTop:"6px"}}/>
                  <div class="hover-feedback s3-r">
                    {staffWorkData[active]?.staffReviews?.feedback? staffWorkData[active]?.staffReviews?.feedback :  "No feedback"}
                  </div>
                  </>
                  :"":""}
              </div>
            </div>
            }

            <div class="shift-details-opener">
             <div class="line-76"></div>   
               <div class="ellipse-202"></div>
                  <img src={upArrowBlackIcon} className='iconsax-linear-arrowdown-2' onClick={()=> setShiftDetails(false)}/>
            </div>

            </>}
            
        </>

    )
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            approveStaff,rejectStaff,getFileFromMDB
        },
        dispatch
    );
};

export default connect(
    mapDispatchToProps,
    mapDispatchToProps
)(withRouter(StaffDetailsNew));


