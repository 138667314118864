import React, { useState, useEffect } from 'react'
import classes from './interactive_map.module.scss'
import play from "../../../assets/svg/play icon.svg";
import pause from "../../../assets/svg/pause icon.svg";
import TimelineMap from './timelineMap'
import Map from './map'


export default function InteractiveMap({open, toggle, staffName='', activePointsFromStaffDetails, staffLatLong = { lat: '', lng: '' }, clientLatLong ,timeData = [], sitePicture }) {
    const [activePoint, setActivePoint] = useState('');
    const [showLines, setShowLines] = useState(true);
    const [warningModal, setWarningModal] = useState({
        show: false,
        msg:'! No Location Found'
    });
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
      if(activePointsFromStaffDetails || activePointsFromStaffDetails===0){
        setActivePoint(activePointsFromStaffDetails);
        setShowLines(false);
      }
    }, [activePointsFromStaffDetails])

    const handleTogglePlayback = () => {
        setIsPlaying(!isPlaying); 
    };

    useEffect(()=>{
        if(open == false){
          setActivePoint('');
        }
    },[open])
    

    return !!open && (
        <div className={classes.interactive_map}>
            <div className={classes.container}>
                <div className={classes.close_button}>
                    <h2>{ !!staffName ? `Map View - ${staffName}`:'Map View' }</h2>
                    <button onClick={toggle}>×</button>
                </div>
                <div className={classes.map_action_wrapper}>
                    <div className={classes.map_wrapper}>
                        <Map
                            mapData={timeData}
                            staffLatLong={staffLatLong}
                            clientLocLatLong={clientLatLong}
                            activePoint={activePoint}
                            showLines={showLines}
                            setActivePoint={setActivePoint}
                            warningModal={warningModal}
                            sitePicture={sitePicture}
                            setWarningModal={setWarningModal}
                        />
                    </div>
                    <div className={classes.action_wrapper}>
                        <div> 
                            <p className={classes.shift_title}>My Shift Entry Details</p>
                            <label className={classes.show_lines}>
                                <input
                                    type="checkbox"
                                    checked={showLines}
                                    onChange={(e)=>setShowLines(e.target.checked)}
                                />
                                Show Lines
                            </label>
                            { timeData.length !==0 ? 
                            <p className={classes.button_container}  style={{marginLeft:"8rem", marginTop:"-1.5rem", fontSize:"11px", width:"5rem"}}
                            onClick={() => {
                                handleTogglePlayback();
                                
                            }}
                            >Play
                            { isPlaying?
                            <img style={{marginLeft:"0.5rem",marginTop:"-0.1rem"}} src={pause}/>
                            :
                             <img style={{marginLeft:"0.5rem",marginTop:"-0.1rem"}} src={play}/>
                             }
                            </p>
                            :""
                             }
                            <hr/>
                            <div>
                                <TimelineMap
                                    timelineArray={timeData}
                                    activePoint={activePoint}
                                    setActivePoint={setActivePoint}
                                    isPlay={isPlaying}
                                    setActiveisPlaying = {setIsPlaying}
                                    mapPointHandler={(lat, lng) => {
                                        if (!lng || !lat) setWarningModal(prev => ({ ...prev, show: true }));
                                        // else console.log(`latitude : ${lat} , longitude : ${lng}`);
                                    }}
                                />
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

