import { documentDownloadFromMDB, documentUpload, documentUploadOnMDB } from '../service/apiVariables';

// import { getServiceUrl } from '../service/api';
// import axios from 'axios';

 export const fileUpload = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...documentUpload.create, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

 export const fileUploadOnMDB = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...documentUploadOnMDB.create, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

// export const fileDownloadFromMDB = (body) => (dispatch, getState, { api, Toast }) => {
//   return new Promise(async (resolve, reject) => {
//     await axios
//     .get(`${getServiceUrl(documentDownloadFromMDB.create.baseURL)}${documentDownloadFromMDB.create.api}${body.id}/`, {
//         responseType: "blob",
//         headers : {
//             Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
//         }
//       })
//       .then(({ data, message }) => {
//         resolve(data);
//         Toast({ type: "success", message });
//       })
//       .catch(({ message }) => {
//         reject(Toast({ type: "error", message }));
//       });
//   });
// };