import React, { Component } from 'react'
// import { Pagination } from "../index"
import { scrollTop, appendQuery, convertStringToObject } from "service/helperFunctions"
import { history } from "service/helpers"
import './table.scss';
import sortIcon from "assets/svg/sort.svg";
import PropTypes from 'prop-types';
import Pagination from "react-js-pagination";

export class TableWrapper extends Component {
    state = {
        currentSortKeyDetails: null,
        paginationactive: 1,
        options:[
            {value:10,label:'10'},
            {value:50,label:'50'},
            {value:100,label:'100'},
            {value:500,label:'500'},
            {value:1000,label:'1000'},
        ],
        keyName : 'label',
        valueName : "value",
        placeholder : "Select",
    };

    componentDidUpdate(){
        if(this.state.paginationactive!==this.props.page){
            this.setState({ paginationactive: this.props.page });
        }
    }
    
    // componentWillMount() {
    //     let { field = '', orderBy = '' } = this.props;
    //     if (field && orderBy) {
    //         this.setState({
    //             currentSortKeyDetails: { sortKey: field, orderBy },
    //         });
    //     }
    // }

    getPlaceHolder = () => {
        //   let { placeHolder = 'No data found' } = this.props;
        //   if (typeof placeHolder === 'function') {
        //     return placeHolder();
        //   }

        //   return <p className="text-center">{placeHolder}</p>;
    };

    // handlePagination = page => {
    //     let { queryHandler, scrollProps = '' } = this.props;
    //     queryHandler({ page })
    //         .then(() => {
    //             scrollTop(...scrollProps);
    //         })
    //         .catch(err => {
    //             console.log('Error:::' + err);
    //         });
    // };
    

    handlePageChange(pageNumber) {
        this.setState({ paginationactive: pageNumber });
        this.props.paginationChange(pageNumber);
    }

    render() {

        let { headerDetails, paginationConfig, paginationLength, children, pageMeta, handleSort, isEmpty = false, id ='', className = '', overFlow = true, pageNumber,pageSize,handlePageSize,paginationShow=true,ClientOnboardingpagination } = this.props;
        let { paginationactive,options,keyName,valueName,placeholder } = this.state
        let { sortKey: currentSortKey, orderBy = '' } = this.state.currentSortKeyDetails || {};
        
        return (
            <div className="maintable table-container setpagination">
                {
                    paginationShow &&

                    <div className="div-pagination  mr-2  ">

                        {  ClientOnboardingpagination === "true" || ClientOnboardingpagination === undefined ?

                        <Pagination
                            activePage={paginationactive}
                            itemsCountPerPage={!!paginationConfig ? paginationConfig.pageCount : 0}
                            totalItemsCount={!!paginationConfig ? paginationConfig.TotalCount : 0}
                            pageRangeDisplayed={!!paginationConfig ? paginationConfig.btnDisplay : 0}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass=" custom-pagination pagination"
                            nextPageText="Next"
                            prevPageText="Previous"
                            hideFirstLastPages={true}
                            linkClassPrev="custom-prev"
                            linkClassNext="custom-next"
                            disabledClass="custom-disable"
                            hideDisabled={true}
                            itemClassNext={paginationConfig?.TotalCount<=paginationactive?"custom-next-disable custom-next":"custom-next"}
                            onChange={this.handlePageChange.bind(this)}
                        />
                        :""
                        }
                        {paginationConfig.TotalCount ? <div className="total-entries mt-2 ml-2">
                            <span className='s2-r'>Show</span>
                            <select
                                className="custom-hide-option mx-2"
                                style={{ position:"relative" ,zIndex:"2"}}
                                value={!!paginationConfig?paginationConfig.pageCount:0}
                                // disabled={disabled}
                                onChange={e => {
                                    handlePageSize(e.target.value);
                                    this.setState({ paginationactive: 1 });
                                    this.props.paginationChange(1);
                                }}
                            >
                                <option value='' disabled defaultValue>{placeholder}</option>
                                {options.length !== 0 && paginationConfig ?
                                    options.map((option, index) =>
                                        // <option value={option[valueName]} key={index}>{option[keyName]}</option>
                                        <option value={option[valueName]} disabled={option.value > paginationConfig.TotalCount ? true:false} key={index}>{option[keyName]}</option>
                                    ) : <option value='' disabled>No Options</option>}


                            </select>
                            <span className='s2-r'>Entries</span>
                        </div> : null}
                    </div>
                }
                <div className={`maintable-content ${overFlow ? 'table-responsive' : ''} ${className}`} id={id? id:""}>
                    <table className="table">
                        <thead>
                            <tr>
                                {headerDetails?.map(({ label, className, divClass = '', sortKey = '', element, isSort, isRequired, ascKey, isOptional }, index) => {
                                    return (
                                        <th className={className} key={index}>
                                            <div
                                                className={`d-flex align-items-center justify-content-start text-center setlistview ${sortKey &&
                                                    'cursor-pointer'} ${divClass}`}
                                            >
                                                {label}
                                                {isOptional &&
                                                   <span className="ml-1 fs-12 text-secondary">&nbsp;Optional</span>
                                               }
                                                {element && element()}
                                                {isRequired &&
                                                    <sup>*</sup>
                                                }
                                                
                                                {isSort ? (
                                                    <div
                                                        className={`d-flex table-filter align-items-center cursor-pointer ml-3
                                                        'active-filter'}`}
                                                    >
                                                        <img src={sortIcon} onClick={()=>handleSort(ascKey)} width={'11'} height={'11'} alt='sort-icon' />
                                                    </div>
                                                ) : (
                                                        ''
                                                    )}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>{children}</tbody>
                    </table>
                    {isEmpty ? this.getPlaceHolder() : ''}
                </div>
                {/* {pageMeta.buyerCount != 0 && */}
                
            </div>
        )
    }
}



TableWrapper.propTypes = {
    placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    isEmpty: PropTypes.bool,
    headerDetails: PropTypes.array.isRequired,
    pageMeta: PropTypes.object,
};
