import React, { useEffect, useState } from 'react';
import "./style.scss";
import { getDashboardHeader } from "../../../action/TeamAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

function DashboardTabs({ getDashboardHeader, setTotalUsers, data, setData }) {
    const [, setLoading] = useState(false);
    const [agencyId, setid] = useState({});

    const isMdScreen = useMediaQuery('(max-width:1140px)');
    const isSmScreen = useMediaQuery('(max-width:800px)');

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData'));
        let id = userData.agencyId ? userData.agencyId: userData.id;
        setid(id);
        fetchUserData(id);
        return ;
    }, []);

    const fetchUserData = async (id) => {
        setLoading(true);
        const query = {
            agencyId: id
        };
        await getDashboardHeader(query).then((data) => {
            setData(data);
             let  count =  (data.noOfActiveCoAdmin + data.noOfActiveManager + data.noOfActiveWorker)
             setTotalUsers(count)
        })
            .catch((e) => console.log(e));
        setLoading(false);
    }
    let { noOfActiveCoAdmin, noOfDeletedCoAdmin, noOfActiveManager, noOfDeletedManager, noOfActiveWorker, noOfDeletedWorker } = data;
    return (
        <div className="dashboard">
            <Grid spacing={2} container className="mt-4">
                <Grid item xs={12} sm={isSmScreen ? 12 : 6} md={isMdScreen ? 6 : 4}>
                    <div className={` dashboard-small-card bg-light-blue`}>
                        <div className="d-flex align-items-start" style={{width:"100%"}}>
                            <div className="pl-3 right-class adjustdashboardsizes">
                                <p className="mb-1 text-c1 settabsheading">Number Of Agency Co-Admins</p>
                                <div className='setactiveinactive'>
                                  <div>
                                   <p className="mb-0  dashboard-number text-c1">{noOfActiveCoAdmin || "0"} <sup className='adjustactivesuper'>Active </sup></p> 
                                  </div>
                                  <div>
                                   <p className="mb-0 dashboard-number text-c1 mr-0">{noOfDeletedCoAdmin || "0"} <sup className="adjustinactivesuper"> Archived </sup></p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={isSmScreen ? 12 : 6} md={isMdScreen ? 6 : 4}>
                    <div className={` dashboard-small-card bg-light-green`}>
                        <div className="d-flex align-items-start" style={{width:"100%"}}>
                            <div className="pl-3 right-class adjustdashboardsizes">
                                <p className="mb-1 text-c1 settabsheading">Number Of Agency Managers</p>
                                <div className='setactiveinactive'>
                                  <div>
                                    <p className="mb-0 dashboard-number text-c1">{noOfActiveManager || "0"}  <sup className='adjustactivesuper'>Active </sup></p> 
                                  </div>
                                  <div>
                                    <p className="mb-0 dashboard-number text-c1 mr-0">{noOfDeletedManager || "0"} <sup className="adjustinactivesuper"> Archived </sup></p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={isSmScreen ? 12 : 6} md={isMdScreen ? 6 : 4}>
                    <div className={` dashboard-small-card bg-light-blue50`}>
                        <div className="d-flex align-items-start" style={{width:"100%"}}>
                            <div className="pl-3 right-class adjustdashboardsizes">
                                <p className="mb-1 text-c1 settabsheading">Number Of Agency Workers</p>
                                <div className='setactiveinactive'>
                                  <div>
                                   <p className="mb-0 dashboard-number text-c1">{noOfActiveWorker || "0"}  <sup className='adjustactivesuper'>Active </sup></p> 
                                  </div>
                                  <div>
                                   <p className="mb-0 dashboard-number text-c1 mr-0">{noOfDeletedWorker || "0"} <sup className="adjustinactivesuper"> Archived </sup></p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getDashboardHeader },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(DashboardTabs);
