import React, { useEffect, useState } from 'react'
import './style.scss'
import loadingIcon from "assets/images/loading.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dummyImage from 'assets/images/dummy-image.png'
import { getFileFromMDB } from 'action/fileHandler';

const DisplayStaffImage = React.memo(({getFileFromMDB, imageId, entity, clientId, onSuccess, rounded, navbar}) => {
  const [onlineImage, setOnlineImage] = useState('')
  const [loading, setLoading] = useState(false)

      const handleImageDownload = async (logo) => {
        setLoading(true)
        let imageData;
        let fileId = logo.split('/')[1]
        await getFileFromMDB({entity: entity ? entity:'', clientId: clientId ? clientId : ''},fileId)
          .then((response)=>{
            imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
            setLoading(false)
            setOnlineImage(imageData.toString())
            if(onSuccess){
              onSuccess()
            }
            return imageData
          })
          .catch((error) => {
            setLoading(false);
            setOnlineImage(null);
            console.log('Error fetching image:', error);
          });
      };

      useEffect(()=>{
        if(imageId){
          handleImageDownload(imageId)
        }else{
          setOnlineImage(null)
        }
      },[imageId])

  return (<>
    {onlineImage !== null ? (
      <img
        className={`${
          navbar && 'profile-nav rounded-circle mb-3'
        } ${rounded && 'profile-icon rounded-circle'} ${
          loading ? 'loading-animation' : 'image-view'
        }`}
        src={loading ? loadingIcon : onlineImage}
        alt="User Profile"
      />
    ) : !navbar ? (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <img src={dummyImage} alt="Dummy Profile" />
      </div>
    ) : null}
</>)
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getFileFromMDB
      },
      dispatch
    );
  };

  export default connect('', mapDispatchToProps)(DisplayStaffImage)

