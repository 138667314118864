import React, { useState, useRef,useEffect, Suspense } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import moment from "moment";
import downArrow from "../../../assets/svg/down-arrow1.svg";
import { NormalCheckbox, NormalSelect, TableWrapper, NormalSwitch, Title, CommonPageLoader, NormalButton } from "component/common"
import "./style.scss";
import { NormalDropdown } from 'component/common/NormalDropdown';
//import WorkCalender from 'component/workShedule/workCalender';
import AddWorkRequest from 'component/workShedule/addWorkRequest';
import AssignWorkSchedule from './assignWorkSchedule';
import { getClassNameForBackground, getWorkStatus } from "service/utilities";
import { getAllClient ,getAllStaff,getAllWorkByStaff,getAllWorkByClient,getAllJobCategoriesByClient,getAllJobFunctionsByStaff} from "action/workRequest";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
// import  WorkAssignment  from 'component/workShedule/workAssignment';
import  WorkAssignment  from 'component/workShedule/workAssignmentNew';
import  WorkAssignTimeSheet  from 'component/workShedule/workAssignTimeSheet';
import UserDetail from 'component/StaffManagement/userDetail';
import { pageNavigationByName, store } from "service/helpers"
import DashboardTabs from 'component/Dashboard/dashboardTabs'; 
import leftArrow from 'assets/svg/arrow_back.svg';
import rightArrow from 'assets/svg/arrow_forward.svg'
import option from '../../../assets/svg/option.svg'
import optionDark from '../../../assets/svg/optionsBlue.svg'
import CalendarSolid from '../../../assets/svg/calendar-icon.svg'
import Calendar from '../../../assets/svg/calender.svg'
import search from 'assets/svg/search.svg'
import Vector from 'assets/svg/Vector.svg'
import WorkScheduleList from './workScheduleList';
import customerSolid from 'assets/svg/customer solid.svg';

import viewIcon from 'assets/svg/view.svg';
import deleteIcon from 'assets/svg/delete.svg';
import editIcon from 'assets/svg/edit.svg';
import viewActivity from 'assets/svg/Viewactivity.svg';

import redBox from "assets/images/red-box.png";
import greenBox from "assets/images/green-box.png";
import newrequest from "assets/images/new-request.png";
import cancelBox from "assets/images/rejected-staff.png";
import { drop } from 'lodash';
import { SearchBox } from 'component/common/SearchBox';
import Moment from 'moment';
import refreshIcon from "../../../assets/svg/refreshBlue.svg";

import { MultiSelect } from "react-multi-select-component";
import ItemRenderer from "component/common/ItemRenderer/ItemRenderr.tsx";
import valueRenderer from "component/common/ItemRenderer/valueRenderer.tsx";
import { useSelector } from 'react-redux';
import { startTourGuide } from 'reducer/tourGuide';
import { useLayoutEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomArrowMultiSelect from "component/common/CustomArrowMultiSelect";

import showArchivesGreen from '../../../assets/svg/show-archives-green.svg'
import showArchivesGrey from '../../../assets/svg/show-archives-grey.svg'
import AcceptWorkAlertModal from 'component/workShedule/AcceptWorkAlertModal';

const WorkCalender = React.lazy(() => import('component/workShedule/workCalender'));

const OptionsList = [
    { label: 'View Profile', value: 'Renew Subscription', icon: viewIcon, click: 'handleShow' },
    { label: 'Edit Co-Admin', value: 'Edit Co-Admin', icon: deleteIcon, click: 'handleShow' },
    { label: 'Archive Co-admin', value: 'Archive Co-admin', icon: editIcon, click: 'handleShow' },
    { label: 'View Activity', value: 'View Activity', icon: viewActivity, click: 'handleShow' },
]
const actions = [
    { icon: viewIcon, label: "Edit", iconClass: "material-icons" },
    { icon: editIcon, label: "Copy", iconClass: "material-icons" },
    { icon: deleteIcon, label: "Delete", iconClass: "material-icons" },
]
const statusList = [
    {
        label: "ALL WORK STATUS",
        value:"ALL WORK STATUS" ,
        icon: "",
        boxColorClass: getClassNameForBackground(),
        click: "handleShow",
    },
    {
        label: "NEW REQUEST",
        value: 1,
        icon: newrequest,
        boxColorClass: getClassNameForBackground(1),
        click: "handleShow",
    },
    {
        label: "PENDING STAFF ASSIGNMENT",
        value: 2,
        icon: redBox,
        boxColorClass: getClassNameForBackground(2),
        click: "handleShow",
    },
    {
        label: "VIEW STAFF RESPONSE. PLEASE CONFIRM",
        value: "2.1",
        icon: redBox,
        boxColorClass: getClassNameForBackground(2.1),
        click: "handleShow",
    },
    {
        label: "PENDING STAFF CLOCK-IN",
        value: 3,
        icon: newrequest,
        boxColorClass: getClassNameForBackground(3),
        click: "handleShow",
    },
    {
        label: "STAFF IS WORKING",
        value: 4,
        icon: greenBox,
        boxColorClass: getClassNameForBackground(4),
        click: "handleShow",
    },
    {
        label: "PENDING TIMESHEET APPROVAL BY CLIENT",
        value: 5,
        icon: greenBox,
        boxColorClass: getClassNameForBackground(5),
        click: "handleShow",
    },
    {
        label: "PLEASE REVIEW STAFF TIMESHEET",
        value: 6,
        icon: greenBox,
        boxColorClass: getClassNameForBackground(6),
        click: "handleShow",
    },
    {
        label: "TIMESHEET APPROVED. CREATE INVOICE NOW!",
        value: 7,
        icon: greenBox,
        boxColorClass: getClassNameForBackground(7),
        click: "handleShow",
    },
    {
        label: "INVOICE CREATED",
        value: 8,
        icon: greenBox,
        boxColorClass: getClassNameForBackground(8),
        click: "handleShow",
    },
    {
        label: "INVOICE PAID",
        value: 9,
        icon: greenBox,
        boxColorClass: getClassNameForBackground(9),
        click: "handleShow",
    },
    {
        label: "NOT FILLED",
        value: 10,
        icon: greenBox,
        boxColorClass: getClassNameForBackground(10),
        click: "handleShow",
    },
    {
        label: "CANCELED",
        value: 0,
        icon: cancelBox,
        boxColorClass: getClassNameForBackground(0),
        click: "handleShow",
    },
    // {
    //     label: "CANCELED (NO FEES)",
    //     value: 9 ,
    //     icon: cancelBox,
    //     click: "handleShow",
    //   },
];
export const StaffStatusLabels = (statusCode, status, clockIn, pauseResumeData, clockOut, cancelByWork, rejectedByStaff = false, rejectedByClient = false) =>{
    if(statusCode === 1 && !rejectedByStaff) return 'Yet to Accept' 
    if(statusCode === 1 && rejectedByStaff) return 'Rejected by staff' 
    if(statusCode === 2 ) return 'Yet to approve by Agency'
    if(statusCode === 3 && !rejectedByClient) return 'Yet to approve by Client' 
    if(statusCode === 3 && rejectedByClient) return 'Rejected by Client' 
    if(statusCode === 4 && status === 3 ) return "Pending ClockIn"
    if(statusCode === 4 && status === 4 && clockIn != null && pauseResumeData && pauseResumeData[pauseResumeData.length - 1].key == "pause" ) return 'Paused'
    if(statusCode === 4 && status === 4 && clockIn != null) return 'In progress'
    if(statusCode === 4 && status === 4 && !clockIn ) return "Pending ClockIn"
    if(statusCode === 4 && status === 4 && clockOut != null) return "Completed By Staff"
    if(statusCode === 5 && status >=  3)  return 'Completed By Staff' 
    if(statusCode === 4 && cancelByWork) return "Pending ClockIn"
    if(statusCode === 4) return 'In Progress'  
  }

 export const getWorkStatusLabel = (status,item) =>{
    if(status ===0) return "CANCELED"
    if(status ===1) return'NEW REQUEST'
    if(status === 2){
        if(item.workStaffAssignment && item.workStaffAssignment.staffWorkData.length !== 0){
            return 'VIEW STAFF RESPONSE. PLEASE CONFIRM' 
        }
        return'PENDING STAFF ASSIGNMENT'
    } 
    // if(status ===2) return'PENDING STAFF ASSIGNMENT'
    if(status ===3) return'PENDING STAFF CLOCK-IN'
    if(status ===4) return'STAFF IS WORKING' 
    if(status ===5) return'PENDING TIMESHEET APPROVAL BY CLIENT'
    if(status ===6) return'PLEASE REVIEW STAFF TIMESHEET'
    if(status ===7) return'TIMESHEET APPROVED. CREATE INVOICE NOW!' 
    if(status=== 8) return'INVOICE CREATED'
    if(status === 9) return 'INVOICE PAID'
    if(status === 10) return 'NOT FILLED'
  }
function WorkSchedule({ getAllClient,getAllStaff,getAllWorkByStaff,getAllWorkByClient,getAllJobCategoriesByClient,getAllJobFunctionsByStaff }) {
    const [visible, setVisible] = useState({ value: false, id: null });
    const [calendarView, setCalendarView] = useState(1)
    const [stat, setStat] = useState()
    const [action, setAction] = useState(0)
    const [calendarGrid, setCalendarGrid] = useState(3)
    const [workAssign, setWorkAssign] = useState({value:false,id:null});
    const [isWorkDetail, setIsWorkDetail] = useState({value:false,id:null});
    const [assignTimeSheet, setAssignTimeSheet] = useState({value:false,id:null});
    const [jobFunctionCategoriesArray, setJobFunctionCategoriesArray] = useState([]);
    const [isFormLoder, setFormLoder] = useState(false);
    const [searchValue, setValue] = useState("");
    const [statusPick, setStatusPick] = useState("");
    const [count, setCount] = useState(0);
    const [showAllStatus, setShowAllStatus] = useState(false);

    const [showArchives, setShowArchives] = useState(false)

    const [showAlertModal, setShowAlertModal] = useState({show:false, id:''});

    const isSmScreen = useMediaQuery('(max-width:700px)');

    const history = useHistory()
 const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const wrId = queryParams.get('workId');
useEffect(()=>{
if(wrId){
  setWorkAssign({value:true, id:wrId})
}
},[wrId])
    useEffect(()=>{
        if(!activeView){
            if(window.location.pathname === '/admin/workSchedule'){
              history.replace('list')
            }
        }
        return;
      },[])
    // useEffect(()=>{
    //     afterLoadCalendar(query);
    //     setTimeout(() => {
    //         setCount(count+1);
    //     }, 120000);
    // },[count]);
    
    let workRequestData = useSelector((state)=> state.webSocketReducer.workRequest)

    useEffect(()=>{
        handleUpdateWorkRequest()
      },[workRequestData])

      const handleUpdateWorkRequest = () => {
        if(workRequestData){
            const isInRange =  workRequestData.startDate >= query.startDate && workRequestData.endDate <= query.endDate;
   
            if(isInRange){
                let workRequestId =  workRequestData?.id ? workRequestData?.id : workRequestData?._id
                workRequestData.id  = workRequestId;
                let targetIndex = workList.findIndex(obj => obj.id === workRequestId)
            
                if(activeView){
                    workRequestData["StartTime"] = moment.utc(workRequestData.startDate).format();
                    workRequestData["EndTime"] = moment.utc(workRequestData.endDate).format();
                    workRequestData["Subject"] = `${dateFormaterFromHandleEventRendered(workRequestData.startDate)}-${dateFormaterFromHandleEventRendered(workRequestData.endDate)}&nbsp;${workRequestData.priority.toUpperCase()==="NORMAL"?"("+workRequestData.priority+")":"<b>("+workRequestData.priority+")</b>"}&nbsp;${workRequestData.jobName}`;
                    workRequestData["Category"] = workRequestData.status;
                }

            let staffName="";
            workRequestData?.workStaffAssignment?.staffWorkData.map((staff) => {
            staffName = staffName !=="" ? (staffName + " , "+ staff.staffName) : staff.staffName;
                        })
           staffName=staffName==""? "No Staff Assigned" :staffName;
            let data ={
              ...workRequestData,
              staffName:staffName,
              "workAssignedTime":`${Moment(workRequestData.startDate).format('hh:mm:ss a')} - ${Moment(workRequestData.endDate).format('hh:mm:ss a')}`,
              "statusName":getWorkStatusLabel(workRequestData.status,workRequestData)
            }
            
                if(targetIndex === -1){
                    setWorkList([{...data},...workList])
                    return;
                }
           let existingWorkList = [...workList]
           existingWorkList[targetIndex] = data
           setWorkList(existingWorkList)
            }
    
         }
      }



    let jobLocation = [
        {
            label: 'Work category',
            label: 'Job function',
        }
    ]
    const [activeView, setActiveView] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fiilterLoading, setFiilterLoading] = useState(false);
    const [clientId,setClientId] =useState(null);
    const [clientBy, setClientBy] = useState("All Clients");
    const [clientNames, setClientNames] = useState([]);
    const [staffId,setStaffId] =useState('');
    const [workList, setWorkList] = useState([]);
    const [refresh, doRefresh] = useState(false);
    const [initialStartDate, setInitialStartDate] = useState("");
    const [initialEndDate, setInitialEndDate] = useState("");
    const [multiSelectOptionsList, setMultiSelectOptionsList] = useState([])
    const [selectedOption, setSelectedOption] = useState([])
    const[filteredData, setFilteredData] = useState([])
    const [paginationConfig, setPaginationConfig] = useState({
        TotalCount: 0,
        pageCount: 10,
        btnDisplay: 5,
        itemCount: 0,
    });

    let pageMeta = {}
  
    const [filters, setFilter] = useState({
        value: "All Work Status",
        label: "All Work Status",
        bgOfSelectedStatus: "all-work-status",
      });
    const [query, setQuery] = useState({
        startDate:new Date(),
        endDate:null,
        sortBy:'all',
        searchKey:'',
        // clientId: null,
        status:[],
        jobCategory:'all',
        jobName:'all',
        location:'',
        isPickRequest:0,
        page:1,
        size:10,
        
    });
    useLayoutEffect(()=>{
        let productTour = localStorage.getItem('productTour')
        let workScheduleTour = localStorage.getItem('workScheduleTour')
        if(productTour === 'true' && workScheduleTour !== 'true' && process.env.REACT_APP_HOSTMODE === "LIVE"){
                store.dispatch(startTourGuide({run:true}))
        }
      },[])

    const getFilteredData = () => {
        const updatedWorkList = workList.filter((item) => {
            if(showArchives) {
                return (item.clientStatus === 0 || item.clientStatus === 2 || item.clientStatus === 3 || item.clientStatus === 5 )
            }
            else {
                return (item.clientStatus === 1 || item.clientStatus === undefined || item.clientStatus === null)
            }
        })

        let countAdded = []
        statusList.forEach((x)=>{
          if(x.value != "ALL WORK STATUS"){
          let count = 0
          let key = x.value == 2.1 ? 21 : x.value
      
          updatedWorkList.forEach((a)=>{
            if(x.value == 2){
              if(a.status===2 && !!a.workStaffAssignment && a.workStaffAssignment?.staffWorkData.length === 0){
                count = count + 1
              }
              return;
            }
            if(x.value == 2.1){
               if(a.status===2 && a.workStaffAssignment?.staffWorkData.length !== 0){
                count = count + 1
               }
              return;
            }
            if(x.value === a.status){
              count = count + 1
            }
          })
          countAdded.push({...x, count:count, key : key})
           }
        })
        setTimeout(() => {
            setMultiSelectOptionsList(countAdded)
        }, 500);
       

        let tempData = []
        if(selectedOption.length === 0 || selectedOption.length === 12){
           setFilteredData(updatedWorkList)
           return;
        }
        selectedOption.forEach(x => {
            updatedWorkList.forEach(a => {
              if(x.value == 2){
                if(a.status===2 && !!a.workStaffAssignment && a.workStaffAssignment?.staffWorkData.length === 0){
                  tempData.push(a)
                }
                return;
              }
              if(x.value == 2.1){
                 if(a.status===2 && a.workStaffAssignment?.staffWorkData.length !== 0){
                  tempData.push(a)
                 }
                return;
              }
              if(x.value === a.status){
                tempData.push(a)
              }
            })
        })
        setFilteredData(tempData)
      }

      useEffect(() => {
        getFilteredData()
      }, [selectedOption, workList, showArchives])

      useEffect(() => {
          if (!initialRender) {
              if (showArchives) {
                  setClientNames([{ value: "All Clients", label: "All Clients" }]);
                  setClientBy('All Client');
                  setClientId(null)
                  setJobFunctionCategoriesArray([{
                    label:'All Job categories',
                    value:'all'
                }]);
              }
              else {
                  fetchAllClient()
              }
          }
      },[showArchives])

 const handleUpdatePage = (value) =>{
    setQuery((prev)=>{
        return{
            ...prev,
            page:value
        }
    })
 }
      

    useEffect(()=>{
 
        let countAdded = []
        statusList.forEach((x)=>{
          if(x.value != "ALL WORK STATUS"){
          let count = 0
          let key = x.value == 2.1 ? 21 : x.value
      
          workList.forEach((a)=>{
            if(x.value == 2){
              if(a.status===2 && !!a.workStaffAssignment && a.workStaffAssignment?.staffWorkData.length === 0){
                count = count + 1
              }
              return;
            }
            if(x.value == 2.1){
               if(a.status===2 && a.workStaffAssignment?.staffWorkData.length !== 0){
                count = count + 1
               }
              return;
            }
            if(x.value === a.status){
              count = count + 1
            }
          })
          countAdded.push({...x, count:count, key : key})
           }
        })
         setMultiSelectOptionsList(countAdded)
      },[workList])

      // For handling duplicate initaial call, when switching to calendar view.
      const previousActiveViewRef = useRef({view:activeView, initial:true});
      useEffect(()=>{
        if (previousActiveViewRef.current.view === false && activeView === true) {
            previousActiveViewRef.current = {view:activeView, initail:false};
           return;
        }
        if(previousActiveViewRef.current.initial === true){
            previousActiveViewRef.current = {view:activeView, initail:false};
            return;
        }
        fetchAllWork(query);
        previousActiveViewRef.current = {view:activeView, initail:false};
      },[activeView , paginationConfig.pageCount])

    useEffect(() => {
        fetchAllWork(query);
        // if (Number(calendarView) === 0 && activeView === false) {
        //     // setStaffId('')
        //     // if(staffId === '')
        //     //
        //         fetchAllStaffs();
        //         if(staffId)
        //         afterLoadCalendar(query);

        // }else if(Number(calendarView) === 1 && activeView === false){
        //     // setClientId('')
        //     // if(clientId === '')
        //         afterLoadCalendar(query);
        // }
    }, [query.searchKey, query.sortBy,calendarView,refresh,stat,staffId, query.jobName, query.jobCategory , query.page,]);

    const [initialRender, setInitialRender] = useState(true);
   useEffect(()=>{
    if (initialRender) {
        setInitialRender(false);
        fetchAllClient();
        return;
      }
      setQuery((prev)=> {
        return{
            ...prev,
            jobCategory:'all'
        }
      })
    afterLoadCalendar(query);
   },[clientId])
    //handle get all client for listing
    const fetchAllClient = async () => {
        setFiilterLoading(true);
        await getAllClient()
            .then((data) => {
                if (data.length > 0) {
                    // setClientList(data);
                    let clients = data.map((client) => {
                      let obj = {};
                      obj.value = client.clientName;
                      obj.label = client.clientName;
                      obj.id = client.clientId;
                      return obj;
                    });
                    clients.unshift({value: "All Clients", label: "All Clients"});
                    setClientNames(clients);
                  }
                // setLoading(false);
                setFilterList(data.length ? data:[])          
                setFiilterLoading(false);
            }).catch((e) => setFiilterLoading(false));
    }
    //handle get all staff for listing
    const fetchAllStaffs = async () => {
        setFiilterLoading(true);
        await getAllStaff()
            .then((data) => {
                setFilterList(data.length ? data:[])
                if(staffId === '')
                    setStaffId(data[0]._id)
                    
                setFiilterLoading(false);
                
            }).catch((e) => setFiilterLoading(false));
    }

    // fetch all job functions 
    const fetchAllJobFunction=async()=>{
        setFormLoder(true);
        let query={};
        let api;
        if(Number(calendarView) === 0){
            query["staffId"] =staffId;
            query["clientId"] ='';
            api=getAllJobFunctionsByStaff;
        }else{
            query["clientId"] = clientId;
            query["staffId"] ='';
            api=getAllJobCategoriesByClient;
        }
        let modifyData=[{
            label:'All Job categories',
            value:'all'
        }];
        if(query["clientId"]) {
        await api(query)
            .then((data) => {
                 data?.forEach((item)=>{
                        modifyData.push({
                            label:item.category,
                            value:item.category,
                        })
                })
                setFormLoder(false);
          })
          .catch((e) => setFormLoder(false));
        }
        setJobFunctionCategoriesArray(modifyData);
    }
    //function for load calendar data after calendar render
    const afterLoadCalendar=(query)=>{
        
        fetchAllWork(query);
        fetchAllJobFunction();
    }

    const dateFormaterFromHandleEventRendered = (dateStr) => {
        let dateFormat = 'h:mm';
        let amPmStr = 'a'
        if(parseFloat(moment(dateStr).format('m'))<=0){
            dateFormat = 'h';
        }
        if(moment(dateStr).format('a')==='pm'){
            amPmStr = 'p';
        }

        return `${moment(dateStr).format(dateFormat)}${amPmStr}`;
    }

    // fetch all work data method
    const fetchAllWork = async (query) => {  
        let querytemp = query;
        setLoading(true);
        let api;
        if (Number(calendarGrid) === 0) {
            querytemp.startDate = moment(querytemp.startDate).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSS');
            querytemp.endDate = moment(querytemp.startDate).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSSS');
        }
        if (Number(calendarGrid) === 1) {
            querytemp.startDate = moment(querytemp.startDate).startOf('week').format('YYYY-MM-DD[T]HH:mm:ss.SSSS');
            querytemp.endDate = moment(querytemp.startDate).endOf('week').format('YYYY-MM-DD[T]HH:mm:ss.SSSS');
        }
        if (Number(calendarGrid) === 3) {
            querytemp.startDate = moment(querytemp.startDate).startOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSSS');
            querytemp.endDate = moment(querytemp.startDate).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSSS');
        }
        if(Number(calendarView) === 0){
            querytemp["staffId"] = staffId;
            querytemp["clientId"] ='';
            querytemp["jobCategory"] ='';
            api=getAllWorkByStaff;
        }else{
            querytemp["clientId"] = clientId;
            querytemp["staffId"] ='';
            api=getAllWorkByClient;
        }
        let body={...querytemp}
           body.size = paginationConfig.pageCount;
           body.startDate = moment(body.startDate).utc().milliseconds(0).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
           body.endDate = moment(body.endDate).utc().milliseconds(0).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
        await api(body)
            .then(({data,page}) => {
            let modifyData=[]
            let count = page * paginationConfig.pageCount;
            setPaginationConfig({ ...paginationConfig, TotalCount: count, itemCount:data.length})
            if(activeView){

                data.forEach((item)=>{
                    // item["start"]= moment(item.startDate).format("DD MMM,hh:mm a");
                    if(Number(calendarGrid) === ''){
                        item["start"]=new Date(item.startDate);
                        item["end"]=item.endDate;
                        item["StartTime"] = item.startDate;
                        item["EndTime"] = item.endDate;
                        item["id"]=item.id;
                        item["eventId"]=item.id;
                        item["display"]='block';
                        item["editable"]=false;
                        item["textEscape"]=false;
                        item["html"]=true;
                    } /*else if(Number(calendarGrid) === 1){
                        /*item["start"]=new Date(item.startDate);
                         //item["end"]=item.endDate;
                        item["id"]=item.id;
                        item["eventId"]=item.id;
                        item["display"]='block';
                        item["editable"]=false;
                        item["textEscape"]=false;
                        item["html"]=true;
                        item["StartTime"] = moment.utc(item.startDate).format();
                        item["EndTime"] = moment.utc(item.endDate).format();
                        item["Subject"] = item.workRequest;
                        item["Category"] = item.status;
                    } */else{
                        item["StartTime"] = moment.utc(item.startDate).format();
                        item["EndTime"] = moment.utc(item.endDate).format();
                        item["Subject"] = `${dateFormaterFromHandleEventRendered(item.startDate)}-${dateFormaterFromHandleEventRendered(item.endDate)}&nbsp;${item.priority.toUpperCase()==="NORMAL"?"("+item.priority+")":"<b>("+item.priority+")</b>"}&nbsp;${item.jobName}`;
                        item["Category"] = item.status;
                    }
                    if(item.isArchived !== 1)
                        modifyData.push(item);
                })
                setWorkList(modifyData.length ? modifyData : []);
                // doRefresh(!refresh);
            }else{
                modifyData = data.map(item=>{
                    let staffName="";
                    let status = item.status
                    item.workStaffAssignment?.staffWorkData.map((staff) => {
                        staffName = staffName !=="" ? (staffName + " , "+ staff.staffName) : staff.staffName;
                    })
                    staffName=staffName==""? "No Staff Assigned" :staffName;
                    return {...item,
                        "staffName":staffName,
                        "workAssignedTime":`${Moment(item.startDate).format('hh:mm:ss a')} - ${Moment(item.endDate).format('hh:mm:ss a')}`,
                        "statusName" : getWorkStatusLabel(status,item)
                        // "statusName" :  status ===0 ?<span className="required">CANCELED</span>:
                        //                 status ===1 ?'NEW REQUEST':
                        //                 status === 2 && item.workStaffAssignment.staffWorkData.length !== 0 ? 'VIEW STAFF RESPONSE. PLEASE CONFIRM': 
                        //                 status ===2 ?'PENDING STAFF ASSIGNMENT':
                        //                 status ===3 ?'PENDING STAFF CLOCK-IN':
                        //                 status ===4 ?'STAFF IS WORKING': 
                        //                 status ===5 ?'PENDING TIMESHEET APPROVAL BY CLIENT':
                        //                 status ===6 ?'PLEASE REVIEW STAFF TIMESHEET':
                        //                 status ===7 ?'TIMESHEET APPROVED. CREATE INVOICE NOW!': 
                        //                 status=== 8 ?'INVOICE CREATED':
                        //                 status === 9 ? 'INVOICE PAID':
                        //                 status === 10 ? 'NOT FILLED':''
                    }
                })
                setWorkList(!!modifyData ? modifyData:[]);
            }
            setLoading(false);
          })
          .catch((e) => setLoading(false));
      };

    //handle reload calender events
    const handleReload=()=>{
        // debugger 
        // doRefresh(!refresh);
        fetchAllWork(query);
    }

    const handleAssignFromList = (id) => {
        setAction(1);
        setWorkAssign({ value: true, id: id });
    }
    const [filter] = useState('')
    
    const onShowArchivesClick = () => {
        setShowArchives(!showArchives)
     }

     const handleAction = (action) => {
        if (action.value) {
            setAction(0);
            setWorkAssign({ value: true, id: showAlertModal?.id });
            setShowAlertModal({ show:false, id:'' })
        }
        else {
            setShowAlertModal({ show:false, id:'' })
        }
    }
 
    return (
        <div>
            <div className=" d-flex justify-content-between mb-3 flex-wrap">
            <Container maxWidth="1400px" sx={{display:"flex", paddingRight:"0px !important"}} className='ml-0 mr-0'>
            <Grid container spacing={2} sx={{display:"flex", justifyContent:"space-between", marginTop:"0px !important"}} className="title2">
                <Grid item xs={12} sm={12} md={4} id='work-schedule-title' className='pl-0'>
                <Title label='Work Schedule' />
                </Grid>
                <Grid item xs={12} sm={12} md={8} sx={{ display: "flex", justifyContent: {sm:"flex-start", md:'flex-end'}, flexWrap:"wrap"}} className='pl-0'>
                {/* <div className='d-flex justify-content-end'> */}
            <Grid item md={isSmScreen ? 12 : 'auto'} className="d-flex justify-content-end align-items-center ml-0 mr-2 title1">
                            <SearchBox
                                searchFunction={(value)=>{setQuery({ ...query, searchKey: value })}}
                                placeholder="Search by WR Id"
                                classNames="fixSearchWidth"
                                formClassNames="searchBoxFormSelcetor"
                            />
                            </Grid>
                            <Grid item md={isSmScreen ? 12 : 'auto'} className={"d-flex align-items-center ml-2 mr-2 mb-3 " +(action === 1?"":"text-off")}>
                                <NormalSwitch
                                    label="Assign View"
                                    name="screenmode"
                                    checked={action === 1 ? true : false}
                                    className="custom-checkbox"
                                    onChange={(e) => setAction(e.target.value === true ? 1 : 0)}
                                />
                            </Grid>
                        

                    <Grid item md={isSmScreen ? 12 : 'auto'} className="mr-2">
                        <NormalButton
                           id={'add-work-request-btn'}
                            label="+ Work Request"
                            outline={false}
                            className="mb-2 fs-13 btn btn-dark-primary border btn-block text-uppercase"
                            onClick={() => {
                                setInitialStartDate("");
              setInitialEndDate("");
				                setVisible({ value: true });
				}}
                        />
                    </Grid>
                    {/* </div> */}
	    	</Grid>
            </Grid>
            </Container>
	    	 <AddWorkRequest
        		visible={visible}
        		setVisible={setVisible}
        		handleReload={handleReload}
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
                setInitialStartDate={setInitialStartDate}
                setInitialEndDate={setInitialEndDate}
                setWorkAssign={setWorkAssign} 
      		/>
        </div>
        {/* <div>
            <div className=" d-md-flex flex-row-reverse d-sm-flex justify-content-start">
                    {showAllStatus ? <>
                        <div className='cursor-pointer showstatus' onClick={()=>setShowAllStatus(false)}>
                      <img src={downArrow} className="filter-color setuparrow"/>
                     </div>

                        <ul className="work-status-list-expanded">
                            {statusList.slice(1,).map(option=>
                                <li>
                                    {option.label}
                                </li>
                            )}
                            <li className="cursor-pointer" onClick={()=>setShowAllStatus(false)}>
                                - SHOW LESS
                            </li>
                        </ul>
                    </>
                    :
                    <>
                         <div className="cursor-pointer showstatus" onClick={()=>setShowAllStatus(true)}>
                        <img src={downArrow} className="filter-color"/>
                       </div>

                        <ul className="work-status-list">
                            <li>
                                PENDING STAFF ASSIGNMENT
                            </li>
                            <li>
                                STAFF IS WORKING
                            </li>
                            <li>
                                INVOICE CREATED
                            </li>
                            <li>
                                CANCELED
                            </li>
                            <li className="cursor-pointer" onClick={()=>setShowAllStatus(true)}>
                                + SHOW ALL
                            </li>
                        </ul>
                    </>}
	        </div>
        </div> */}


        {/* <div className="row bg-white pt-2"> */}
               
                {/* <div className="col-12 pl-2 pr-1"> */}
                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                        
                        {/* <div className="d-flex justify-content-end align-items-center"> */}
                        <Container maxWidth="1400px" sx={{display:"flex", paddingRight:"0px !important"}} className='ml-0 mr-0'>
                            <Grid container spacing={2} sx={{display:"flex", justifyContent:"space-between", marginTop:"0px !important"}}>
                            <div style={{ display: 'flex', alignItems:"center" }}>
                            <Grid item xs={12} className="d-flex align-items-center align-items-center mr-2">
                                <p className="mb-0 mr-2">
                                    {/* View by */}
                                <img className='clienticon' src={customerSolid} alt='client-icon' />
                                </p>
                                </Grid>
                                <Grid item xs={12} id='work-schedule-client-filter' className="mr-2">
                                <NormalDropdown
                                    // placeholder="Select"
                                    label={clientBy && clientBy.length > 25 ? clientBy.substring(0, 25) + "..." : clientBy}
                                    value={clientBy}
                                    optionsList={clientNames ? clientNames : [{ label: "No Option" }]}
                                    className="form-control select-mini border text-capitalize fs-10 rounded min-150 d-flex justify-content-between"
                                    onClick={(e) => {
                                        //clientId = e.target.data.id;
                                        setClientBy(e.target.value);
                                        setClientId(e.target.data.id);
                                        // afterLoadCalendar(query);
                                        //setCalendarView(e.target.value);
                                    }}
                                    itemStyle={{ fontSize: 14 }}
                                />
                                </Grid>
                                <Grid item xs={12} className="mr-2">
                                    <button
                                        id={'show-archive-btn'}
                                        className={`show-archive-btn ${showArchives ? 'active':''}`}
                                        onClick={() => {
                                            onShowArchivesClick()
                                        }}
                                    >
                                        <img src={showArchives ? showArchivesGreen : showArchivesGrey } alt='show-archive' width={'18'} height={'18'} />
                                       <span className='ml-3 mt-1 show-archive-active-btn'>{showArchives ? 'Show Active' : 'Show Archives'}</span>
                                    </button> 
                                </Grid>
                            </div>
                            <div className='d-flex justify-content-end'>
                            <Grid item xs={12} sm={12} md={6} sx={{ display: "flex", justifyContent: "flex-end", flexDirection: { xs: "column", sm: "row" } }} className="d-flex mr-2">
                            <div className='setthirdrow mr-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {/* <NormalSelect
                                    placeholder="Select Job Function"
                                    name="username"
                                    value={query.jobCategory}
                                    options={jobFunctionCategoriesArray}
                                    className="form-control select-mini"
                                    handleChange={e => { 
                                        setQuery({...query,jobCategory:e.target.value})
                                    }}
                                /> */}
                                <Grid item xs={12} id='work-schedule-job-category-filter'>
                                <NormalDropdown
                                    label={query.jobCategory == "all" ? "All Job Categories" :  query.jobCategory }    
                                    value={query.jobCategory}
                                    optionsList={jobFunctionCategoriesArray}
                                    className="form-control select-mini border text-capitalize fs-10 rounded d-flex justify-content-between jobFunctionSelectionWidth"
                                    onClick={(e) =>{ setQuery({...query,jobCategory:e.target.value}) }}
                                    itemStyle={{ fontSize: 14 }}
                                
                                />
                                </Grid>
                            </div>
                            <Grid item xs={12} className="d-flex align-items-center mr-2 setmultiselectcss" style={{minWidth:'180px'}}>
                                {/* <p className="mb-0 mr-2">
                                    Show
                                </p> */}
                                {/* <NormalDropdown
                            
                                    onClick={(e) => {
                                        setFilter({
                                            ...filters,
                                            value: e.target.value,
                                            label: e.target.data.label,
                                            bgOfSelectedStatus : getClassNameForBackground(e.target.value),
                                        });
                                    setStatusPick(e.target.value);
                                    
                                    }}
                                    label={
                                        filters.label.length > 15
                                            ? filters.label.substring(0, 15) + "..."
                                            : filters.label
                                    }
                                    optionsList={statusList}
                                    className="form-control select-mini border text-capitalize fs-10 rounded d-flex justify-content-between statusSelectionWidth"
                                /> */}
                                <div id='work-schedule-status-filter'>
                                <MultiSelect 
                               options = {multiSelectOptionsList}
                               value={selectedOption}
                               onChange={setSelectedOption}
                               labelledBy="Select"
                               ItemRenderer={ItemRenderer}
                               valueRenderer={valueRenderer}
                               disableSearch="true"
                               className='dropdown-container'
                               ArrowRenderer={CustomArrowMultiSelect}
                                   />
                                   </div>
                            </Grid>
                            {/*<div className="d-flex align-items-center  mr-1">
                                <NormalSelect
                                    placeholder="Action"
                                    name="username"
                                    value={action}
                                    onChange={(e) => setPick(e.target.value === true ? 1 : 0)}
                                    options={[{ label: 'Add work Request', value: 0 }, { label: 'Staff assignment', value: 1 }]}
                                    className="form-control select-mini"
                                    handleChange={e => {setAction(e.target.value) }}
                                />
                            </div> */}
                            <Grid item xs={12} className="d-flex align-items-center mr-2">
                                {/* <NormalSelect
                                    placeholder="Select Period"
                                    name="username"
                                    value={calendarGrid}
                                    options={[{ label: 'Day', value: 0 }, { label: 'Week', value: 1 },{label:'Month',value:3}]}
                                    className="form-control select-mini"
                                    handleChange={e => { setCalendarGrid(e.target.value) }}
                                /> */}
                                <div id='work-schedule-period-filter'>
                                <NormalDropdown
                                    label={ calendarGrid == 3 ? "Month" : calendarGrid ==1 ? "Week" : "Day"}
                                    value={calendarGrid}
                                    optionsList={[
                                    { label: "Day", value: 0 },
                                    { label: "Week", value: 1 },
                                    { label: "Month", value: 3 },
                                    ]}
                                    className="form-control select-mini border text-capitalize fs-10 rounded d-md-flex justify-content-between viewSelectWidth"
                                    onClick={(e) => setCalendarGrid(e.target.value)}
                                    itemStyle={{ fontSize: 14 }}
                                    />
                                    </div>
                            </Grid>
                            <Grid sx={{display:"flex", flexDirection: { xs: "row" }}}>
                                <Grid item xs={4} className='d-flex align-items-center' id='work-schedule-view-filter'>
                                <div onClick={() =>{ 
                                    setActiveView(false)
                                    setPaginationConfig(prevState => ({
                                        ...prevState,
                                        pageCount: 10,
                                    }));
                                    handleUpdatePage(1)
                                    history.replace('list')
                                }} 
                                className="d-flex align-items-center  mr-0 cursor-pointer information-icon">
                                    {/* <span className='extra-Information mt-2'> List View </span> */}
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                List View
                                            </Tooltip>
                                        }
                                        >
                                        <img className=" ml-4 list_img" src={`${activeView ?  option : optionDark}`} width={'23'} height={'15'} alt='list-icon' />
                                    </OverlayTrigger>
                                </div>
                                </Grid>
                                <Grid item xs={4} className="d-flex align-items-center">
                                <div onClick={() =>{ 
                                    setActiveView(true)
                                    setPaginationConfig(prevState => ({
                                        ...prevState,
                                        pageCount: 50,
                                    }));
                                    handleUpdatePage(1)
                                    history.replace('calendar')
                                }} 
                                className="d-flex align-items-center  mr-0 cursor-pointer information-icon justify-content-end">
                                    {/* <span className='extra-Information mt-2'> Calendar View </span> */}
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                Calendar View
                                            </Tooltip>
                                        }
                                        >
                                        <img className=" ml-3 calender_img" src={`${activeView ? CalendarSolid : Calendar}`} alt='calendar-icon' />
                                    </OverlayTrigger>
                                </div>
                                </Grid>
                                <Grid item xs={4} className="d-flex align-items-center">
                                <div onClick={() => afterLoadCalendar(query)} className="d-flex align-items-center  mr-0 cursor-pointer information-icon justify-content-end">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>
                                                Refresh
                                            </Tooltip>
                                        }
                                        >
                                        <img className='refreshIcon ml-1' src={refreshIcon} alt='refresh-icon'/>
                                    </OverlayTrigger>
                                </div>
                                </Grid>
                            </Grid>
                            </Grid>
                            </div>
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}
            {/* </div> */}
                </Grid>
            </Container>
            {activeView ?
                <div className="row pt-0 bg-white">
                    {/* <div className="col-3 left-list ">
                        <div className="bg-ash">
                            <div class="input-group  search-input-group mb-3">
                                <input type="text"
                                    id='searchValue'
                                    name='searchValue'
                                    value={searchValue}
                                    onChange={e => { }}
                                    className="form-control border-right-0 searchInput"
                                    placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" />
                                <div class="input-group-append ">
                                    <span class="input-group-text bg-white border-left-0" id="basic-addon2">
                                        <img className='mailIcon' src={search} alt='Logo' />
                                    </span>
                                </div>
                            </div>
                            <ul className="user-ul">
                                {
                                    fiilterLoading ?
                                    <li className="text-center">
                                        ... loading
                                    </li>
                                    :
                                    <>
                                        {
                                        filterList.length ?
                                            <>
                                                {
                                                    filterList.map((item, index) => {
                                                        return (
                                                            <li className={`cursor-pointer ${Number(calendarView) === 0? item._id === staffId ? 'active':'' : item.clientId === clientId ? 'active':''}`}
                                                                onClick={()=>{
                                                                    if(Number(calendarView) === 0)
                                                                        setStaffId(item._id)
                                                                    else
                                                                        setClientId(item.clientId)
                                                                }}>
                                                                <div key={index} className="d-flex justify-content-between ">
                                                                    <div className="d-flex justify-content-center ">
                                                                        <span className="white-round">

                                                                        </span>
                                                                        <div className="d-inline-flex flex-column ">
                                                                            <h6 className="team-list-title font-weight-bold">
                                                                                {Number(calendarView) === 0?`${item.staffName}`:item.clientName}
                                                                            </h6>
                                                                            <p className="team-list-title">
                                                                                {Number(calendarView) === 0 ? !!item.jobFunctions? item.jobFunctions.map((ar,index)=>{return(item.jobFunctions.length === index+1 ?ar:`${ar}, `)}):'':
                                                                                    !!item.jobFunction ?!!item.jobFunction? item.jobFunction.map((ar,index)=>{return(item.jobFunction.length === index+1 ?ar:`${ar}, `)}):'':''}
                                                                            </p>
                                                                        </div>
                                                                    </div>
								    <div className="d-flex justify-content-center align-items-center ">
                                                                        <img className='mailIcon mr-3' src={Vector} alt='Logo' />

                                                                        <p className="time-stamp">{Number(calendarView) === 0 ?'0':item.hrs} </p>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <li className="text-center">
                                                No Data
                                            </li>
                                        }
                                    </>
                                }
                            </ul>
                        </div>
                    </div> */}
                    <div className='col-12 pl-2' style={{marginTop:"1.7rem"}} id='calendar-pagination'>
                    <TableWrapper
                        page={query.page}
                        pageMeta={pageMeta}
                        paginationChange={(page) => handleUpdatePage(page)}
                        paginationConfig={paginationConfig}
                        handlePageSize={(count) => setPaginationConfig({ ...paginationConfig, pageCount: Number(count) })}
                        >
                    </TableWrapper>
                    </div>
                    <div className="col-12 pl-2">
                    <Suspense fallback={<CommonPageLoader/>}>
                        <WorkCalender  
                            bgOfSelectedStatus = {filters.bgOfSelectedStatus} 
                            action = {action} 
                            setPage = {handleUpdatePage}
                            loading = {loading} 
                            refresh = {refresh} 
                            calendarGrid = {calendarGrid} 
                            setAssignTimeSheet = {setAssignTimeSheet} 
                            setWorkAssign = {setWorkAssign} 
                            workList = {filteredData} 
                            calendarView = {calendarView} 
                            afterLoadCalendar = {afterLoadCalendar} 
                            clientId = {clientId} 
                            staffId = {staffId} 
                            statusPick = {statusPick} 
                            setVisible = {setVisible} 
                            query = {query} 
                            setQuery = {setQuery}  
                            stat = {stat} 
                            setInitialStartDate = {setInitialStartDate} 
                            setInitialEndDate = {setInitialEndDate} 
                            StaffStatusLabels={StaffStatusLabels}
                            setShowAlertModal={setShowAlertModal}
                        />
                        </Suspense>
                    </div>
                </div>
                :
                <div className="row pt-0 bg-white mt-3">
                    {/* <div className="col-3 left-list ">
                        <div className="bg-ash">
                            <div class="input-group  search-input-group mb-3">
                                <input type="text"
                                    id='searchValue'
                                    name='searchValue'
                                    value={searchValue}
                                    onChange={e => { }}
                                    className="form-control border-right-0 searchInput"
                                    placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" />
                                <div class="input-group-append ">
                                    <span class="input-group-text bg-white border-left-0" id="basic-addon2">
                                        <img className='mailIcon' src={search} alt='Logo' />
                                    </span>
                                </div>
                            </div>
                            <ul className="user-ul">
                                {
                                    fiilterLoading ?
                                    <li className="text-center">
                                        ... loading
                                    </li>
                                    :
                                    <>
                                        {
                                        filterList.length ?
                                            <>
                                                {
                                                    filterList.map((item, index) => {
                                                        return (
                                                            <li className={`cursor-pointer ${Number(calendarView) === 0? item._id === staffId ? 'active':'' : item.clientId === clientId ? 'active':''}`}
                                                                onClick={()=>{
                                                                    if(Number(calendarView) === 0)
                                                                        setStaffId(item._id)
                                                                    else
                                                                        setClientId(item.clientId)
                                                                }}>
                                                                <div key={index} className="d-flex justify-content-between ">
                                                                    <div className="d-flex justify-content-center ">
                                                                        <span className="white-round">

                                                                        </span>
                                                                        <div className="d-inline-flex flex-column ">
                                                                            <h6 className="team-list-title font-weight-bold">
                                                                                {Number(calendarView) === 0?`${item.staffName}`:item.clientName}
                                                                            </h6>
                                                                            <p className="team-list-title">
                                                                                {Number(calendarView) === 0 ? !!item.jobFunctions? item.jobFunctions.map((ar,index)=>{return(item.jobFunctions.length === index+1 ?ar:`${ar}, `)}):'':
                                                                                    !!item.jobFunction ?!!item.jobFunction? item.jobFunction.map((ar,index)=>{return(item.jobFunction.length === index+1 ?ar:`${ar}, `)}):'':''}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-center align-items-center ">
                                                                        <img className='mailIcon mr-3' src={Vector} alt='Logo' />

                                                                        <p className="time-stamp">{Number(calendarView) === 0 ?'0':item.hrs} </p>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <li className="text-center">
                                                No Data
                                            </li>
                                        }
                                    </>
                                }
                            </ul>
                        </div>
                    </div> */}
                    <div className='col-12 pl-2' style={{marginTop:"0.7rem"}} id='list-pagination'>
                    <TableWrapper
                        page={query.page}
                        pageMeta={pageMeta}
                        paginationChange={(page) => handleUpdatePage(page)}
                        paginationConfig={paginationConfig}
                        handlePageSize={(count) => setPaginationConfig({ ...paginationConfig, pageCount: Number(count) })}
                        >
                    <div className="col-12 pl-2 setlistcomponent">
                        <WorkScheduleList  
                            bgOfSelectedStatus = {filters.bgOfSelectedStatus} 
                            action = {action} 
                            setPage = {handleUpdatePage}
                            searchValue = {searchValue} 
                            statusPick = {statusPick} 
                            calendarGrid = {calendarGrid} 
                            setAssignTimeSheet = {setAssignTimeSheet} 
                            handleAssignFromList = {handleAssignFromList} 
                            setWorkAssign = {setWorkAssign} 
                            handleReload = {fetchAllWork} 
                            setWorkList = {setWorkList} 
                            loading = {loading} 
                            workList = {filteredData}
                            calendarView = {calendarView} 
                            afterLoadCalendar = {afterLoadCalendar} 
                            clientId = {clientId} 
                            staffId = {staffId} 
                            query = {query} 
                            setQuery = {setQuery}
                            StaffStatusLabels={StaffStatusLabels}
                            showArchives={showArchives}
                            setShowAlertModal={setShowAlertModal}
                        />
                    </div>
                     </TableWrapper>
                     </div>
                </div>
            }
            {
                Number(action) === 0 ?
                <>
		    {/*<AddWorkRequest
                        visible={visible}
                        setVisible={setVisible}
                        handleReload={handleReload}
                    />*/}
                    <WorkAssignment 
                        workAssign={workAssign}
                        setAssignTimeSheet={setAssignTimeSheet}
                        setWorkAssign={setWorkAssign} 
                        handleReload={handleReload}
                        setVisible={setVisible}
                        showArchives={showArchives}
                        />
                </>
                :
                <AssignWorkSchedule 
                    workAssign={workAssign}
                    setWorkAssign={setWorkAssign} 
                    handleReload={handleReload}
                    setAction={setAction}
                />
            }


            <WorkAssignTimeSheet 
                assignTimeSheet={assignTimeSheet}
                setAssignTimeSheet={setAssignTimeSheet} 
                handleReload={handleReload}
            />
            {/* {clearConsole} */}

            {showAlertModal.show ? <AcceptWorkAlertModal
                toggle={() => setShowAlertModal({show: false, id:''})}
                isOpen={showAlertModal.show}
                handleAction={(e) => handleAction(e)}
                message='Staff assignment is allowed only after you accept the work request'
                type='assign-view'
            /> : null}
        </div>
        
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAllClient,getAllStaff,getAllWorkByStaff,getAllWorkByClient,getAllJobCategoriesByClient,getAllJobFunctionsByStaff
        },
        dispatch
    );
};

export default connect(
    mapDispatchToProps,
    mapDispatchToProps
)(withRouter(WorkSchedule));
