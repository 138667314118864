export const auditLogsSteps = [
    {
        target:'.export-csv',
        content:'Click here to export the audit logs report to CSV',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        target:'div > div > form',
        content:'Here you can search for audit log with the activity name',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        target:'#main-content > div > div.content > div > div:nth-child(3) > div > div.div-pagination > div > select',
        content:'Select the number of records you want to see per page',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        target:'#main-content > div > div.content > div > div:nth-child(3) > div > div.div-pagination > ul',
        content:'Select the page number',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        target:'.audit-log-detail',
        content:'Here you can find the details of the audit log',
        placement:'auto',
        disableBeacon: true,
        
    },
   
]