import React from 'react';
import "./NormalInput.scss";
import infoIcon from "assets/svg/info.svg";
import locationIcon from "assets/svg/Vector (16).svg";


export const NormalInput = ({
    className = "form-control",
    placeholder = "",
    maxlength = '',
    minlength = '',
    min=0,
    max=undefined,
    label = "",
    onChange,
    value = "",
    name,
    style,
    pattern,
    disabled = false,
    type = 'text',
    isLink = false,
    info=false,
    locationPin=false
}) => {

    const handleClick = (e) => {
        // To Prevent the click event from propagating to the parent
        e.stopPropagation();
    };

    const handleWheel = (e) => {
        e.currentTarget.blur();
    }

    const handleKeyDown = (e) => {
        if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && type === 'number') {
            e.preventDefault();
        }
    }

    return (
        <div className="normal-input">
            {label !== '' ?
                <div className="fs-16 text-black font-JosefinSans pb-2 mb-1 line-height-20 fs-11">
                    {label} {info?<img className="ml-4" src={infoIcon}/>:''}
                </div>
                : ''}
            <input
                oninvalid="setCustomValidity(' ')"
                className={`${isLink ? `${className} islink` : className} custom-font-style`}
                name={name}
                type={type}
                style={style}
                disabled={disabled}
                pattern={pattern?pattern:''}
                value={value}
                min={min}
                max={max}
                placeholder={placeholder}
                maxLength={maxlength?maxlength:''}
                minLength={minlength?minlength:''}
                onChange={e => {

                    let body = {}

                    body = {
                        target: {
                            name: e.target.name,
                            value: e.target.value
                        }
                    }

                    onChange(body)

                }}
                onClick={handleClick}
                onWheel={handleWheel}
                onKeyDown={handleKeyDown}
            />
            {locationPin?
            <img src={locationIcon} className='location-input-icon'/>:''
            }
            {isLink
                ?
                <span className="link-area">
                    <i className="fs-24 icon-link"></i>
                </span>
                : ''}
        </div>
    )
}