import React, { useState, useEffect } from 'react';
import { NormalInput, NormalButton, Title } from 'component/common';
import "./style.scss";
import { CommonPageLoader } from 'component/common';
// import WorkAssignment from 'component/workShedule/workAssignment';
import WorkAssignment from 'component/workShedule/workAssignmentNew';
import WorkAssignTimeSheet from 'component/workShedule/workAssignTimeSheet';
import AddWorkRequest from 'component/workShedule/addWorkRequest';
import { getNotifications } from "../../../action/notification";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import moment from "moment";
import refreshIcon from "../../../assets/svg/mdi_refresh_white.svg";
import closeIcon from "../../../assets/svg/close.svg";
import EditTimeSheet from 'component/timeSheet/EditTimeSheet';
import ClientInvitationBox from 'component/ClientInvites/ClientInvitationBox';
import NotificationMessages from './NotificationMessages';


function NotificationSettings(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [workAssign, setWorkAssign] = useState({value: false, id: null});
    const [visible, setVisible] = useState({ value: false, id: null });
    const [assignTimeSheet, setAssignTimeSheet] = useState({value: false, id: null});
    const [editTimeSheet, setEditTimeSheet] = useState({ id: '',staffId:'', show: false });
    const [clientInvite, setClientInvite] = useState({ id: '', show: false });
    const [redirect, setRedirect] = useState(false);
     
    const [refresh, setRefresh] = useState(false)
    let userType = JSON.parse(localStorage.getItem('userType'))

    let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)
    useEffect(() => {
        if(props.sideBarClickStatus)
        fetchNotifications()
      }, [props.sideBarClickStatus]);
      
      useEffect(() => {
        if(props.sideBarClickStatus && notificationCount > 0)
        fetchNotifications()
      }, [notificationCount]);

    const fetchNotifications = async() =>{
          setLoading(true)
        await props.getNotifications().then((d) => {
            setLoading(false)
            if(d.length)
            d.sort((a,b)=>{return moment(b.date) - moment(a.date);})
            if(d[0]?.notifications.length !== data[0]?.notifications.length){
                setData(d);
            }
        }).catch((err)=>{
            setLoading(false)
        })
    }

 

    const fetchAll = async () => {
        
        setLoading(true);
        const query = {
        };
        await props.getNotifications(query).then((data) => {
            if(data.length){
                data.sort((a,b)=>{return moment(b.date) - moment(a.date);})
            setData(data);
            }
            
            setLoading(false);

        }).catch((e) => {
            setLoading(false);
        });
    };

    const closeShowEditTimeSheet = () => setEditTimeSheet({ id: '',staffId:'', show: false });

    if (redirect) return <Redirect to={redirect}/>

    return (
        <>
        {/* <div className='sticky-top border-0 pt-4 px-2 modal-header text-success'>
            <h5 className='text-white'> Notification </h5>
            </div> */}
         {/* <Title label='Notification' /> */}
            <div className='d-flex modal-headerNotification'>
                <div className='col-10 '>
                    <h5 className="position-absolute mt-2 h1-sb">Notification</h5>
                </div>
                <div className='col-2 d-flex justify-content-end'>
                    <img className='refreshIconInModel  mt-2' src={refreshIcon} onClick={fetchAll}/>
                    <img className='refreshIconInModel ml-2  mt-2'  src={closeIcon} onClick={props.handleSideBar} />
                </div>
            </div>
            {!loading ? (
                <>
                <NotificationMessages 
                data={data} 
                setWorkAssign={setWorkAssign}
                setAssignTimeSheet={setAssignTimeSheet}
                setEditTimeSheet={setEditTimeSheet}
                handleSideBar={props.handleSideBar}
                setClientInvite={setClientInvite}
                userType={userType}
                />
                {/* <div className="down">
                    {
                        data.length ?
                            data.sort((a,b)=>{return moment(b.date) - moment(a.date);}).map(({ date, notifications }, index) => {
                                console.log('Original notifications re-rendered')
                                return (
                                    <>
                                        <div className="ml-3 mr-3 mt-3" key={index}>
                                            <h6 className="font-weight-bold">{moment(date).format(`${localStorage.getItem('dateFormat')}`)}</h6>
                                            {notifications.map((item, itemIndex) =>
                                                <div key={itemIndex} className="container notification-bar cursor-pointer"
                                                    onClick={() => {
                                                        if(item.path.split('/')[2]==="workSchedule"){
                                                            if(!!item.path.split('/')[3]){
                                                                setAssignTimeSheet({value: true, id: item.path.split('/')[3]});
                                                            }
                                                            // else{
                                                            //     setRedirect(item.path.split('/')[2])
                                                            // }
                                                        }else if(item.path.split('/')[1]==="timeSheet"){
                                                            if(!!item.path.split('/')[2]){
                                                                setAssignTimeSheet({value: true, id: item.path.split('/')[2]});
                                                            }
                                                        }else if(item.path.split('/')[1]==="editTimeSheet"){
                                                            if(!!item.path.split('/')[3]){
                                                                setEditTimeSheet({ id : item.path.split('/')[2] , staffId : item.path.split('/')[3], show: true });
                                                            }
                                                        }else if(item.path.split('/')[1]==="invoice" && item.message.search("deleted")==-1){
                                                            if(!!item.path.split('/')[2]){
                                                                // Need to handle deleted invoice...
                                                                props.handleSideBar();
                                                                window.location.assign(`/Admin/invoice/viewInvoice/${item.path.split('/')[2]}/`);
                                                            }
                                                        }else if(item.path.split('/')[1]==="timeOff"){
                                                            if(!!item.path.split('/')[2]){
                                                                props.handleSideBar();
                                                                window.location.assign(`/admin/timeOff/`);
                                                            }
                                                        }else if(item.path.split('/')[1]==="invite"){
                                                            if(!!item.path.split('/')[3]){
                                                               setClientInvite({clientId:item.path.split('/')[3], show:true, clientName: item.message.split(':')[1], clientMessage:'',status:0, createdAt:item.createdAt });
                                                            }
                                                            
                                                        }
                                                        else{
                                                          console.log(item.path)
                                                        }
                                                    }}
                                                >

                                                    <div className="row align-items-start p-3 align-items-center">
                                                        <div className="col-10 pl-0 d-flex justify-content-start align-items-center">
                                                            <div className="checkBox col-1"></div>
                                                            <div className="col-11">
                                                                <p className=" pl-3 fs-14 font-weight-bold mb-1 text-grey-42">{item.status}</p>
                                                                <p className="fs-12 pl-3 wordwrap fixed-height mb-0 text-grey-6c">
                                                                    {item.message}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 text-right pr-4 ">
                                                            <p className="view-link">
                                                                View
                                                        </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>


                                    </>
                                );
                            })
                            :
                            <h8 className="text-center fs-13">No results Found</h8>
                    }
                </div> */}
                </>
            ) : <>
            {props.sideBarClickStatus &&
                <div colSpan="6" className="border-0 reports-loading-icon">
                    <CommonPageLoader />
                    </div>
                     } 
                    </>
            }
         <WorkAssignment
          workAssign={workAssign}
          setAssignTimeSheet={setAssignTimeSheet}
          setWorkAssign={setWorkAssign}
          setVisible={setVisible}
          handleReload={() => console.log('reload')}
        />
        <AddWorkRequest
        		visible={visible}
        		setVisible={setVisible}
                setWorkAssign={setWorkAssign} 
        		handleReload={()=> console.log('reload')}
      		/>
        <WorkAssignTimeSheet 
            assignTimeSheet={assignTimeSheet}
            setAssignTimeSheet={setAssignTimeSheet} 
            handleReload={() => console.log('reload')}
        />

        <EditTimeSheet 
            show={editTimeSheet.show} 
            activeStaffId={editTimeSheet.staffId} 
            closeModal={closeShowEditTimeSheet} 
            workRequestId={editTimeSheet.id} 
        />
        {clientInvite.show ? 
           <div className='position-fixed w-100 h-100 d-flex justify-content-center align-items-center ' style={{top:'0px',left:'0px',right:'0px' }}>
               <div style={{width:'500px', maxHeight:'500px'}}>
               <ClientInvitationBox clientInviteData={clientInvite} handleClose={()=> setClientInvite({show:false})} viewType={0} view='notification'/>
               </div>
           </div>
           : null}
           {/* <ClientInvites/> */}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getNotifications
        },
        dispatch
    );
};

export default connect(mapDispatchToProps,mapDispatchToProps)(NotificationSettings);