import React, { memo } from 'react'
import "./style.scss";
import moment from "moment";
import atom_white from 'assets/svg/atom_white.svg';
import notifi_clock from 'assets/svg/noti_clock.svg';
import flightTransparent from 'assets/svg/flight transparent.svg';
import customerTransparent from 'assets/svg/customer transparent.svg';
import invoiceTransparent from 'assets/svg/stack_white.svg';
import clockTransparent from 'assets/svg/clock transparent.svg';
import settingsTransparent from 'assets/svg/settings transparent.svg';
import feedbackTransparent from    'assets/svg/feedback transparent.svg'
import subscriptionTransparent from 'assets/svg/Subscription-white.svg'
import periodic_table_white from 'assets/svg/periodic_table_white.svg';
import { useHistory } from 'react-router-dom';
import userData from 'reducer/userData';
import { startTourGuide } from 'reducer/tourGuide';
import { store } from "service/helpers"
import no_results from "assets/svg/no_results.svg"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const NotificationMessages = (props) => {
    let {data, setWorkAssign, setAssignTimeSheet,
    setEditTimeSheet,
    setClientInvite} = props
    let notificationData = [...data]

    let history = useHistory()

    const loginCount = localStorage.getItem("loginCount")
    const userData = JSON.parse(localStorage.getItem("userData"))
    const accountExpired = userData?.subscriptionValidity ? moment(userData.subscriptionValidity)<moment(new Date()) : false
  return (
    <div className="down">
                    {
                        notificationData.length ?
                            notificationData.map(({ date, notifications }, index) => {
                                return (
                                    <>
                                        <div className="ml-3 mr-3 mt-3" key={index}>
                                            <h6 className="num-sb">{moment(date).format(`${localStorage.getItem('dateFormat')}`)}</h6>
                                            {notifications.map((item, itemIndex) =>
                                                <div key={itemIndex} className="container notification-bar cursor-pointer"
                                                    onClick={() => {
                                                        if(accountExpired) return;
                                                        // console.log(item)
                                                        if(item.path.split('/')[2]==="workSchedule"){
                                                            if(!!item.path.split('/')[3]){
                                                                setWorkAssign({value: true, id: item.path.split('/')[3]});
                                                            }
                                                            // else{
                                                            //     setRedirect(item.path.split('/')[2])
                                                            // }
                                                        }else if(item.path.split('/')[1]==="workSchedule"){
                                                            if(!!item.path.split('/')[2]){
                                                                setWorkAssign({value: true, id: item.path.split('/')[2]});
                                                            }
                                                        }else if(item.path.split('/')[1]==="timeSheet"){
                                                            if(!!item.path.split('/')[2]){
                                                                setWorkAssign({value: true, id: item.path.split('/')[2]});
                                                            }
                                                        }else if(item.path.split('/')[1]==="editTimeSheet"){
                                                            if(!!item.path.split('/')[3]){
                                                                setEditTimeSheet({ id : item.path.split('/')[2] , staffId : item.path.split('/')[3], show: true });
                                                            }
                                                        }else if(item.path.split('/')[1]==="invoice" && item.message.search("deleted")==-1){
                                                            if(!!item.path.split('/')[2]){
                                                                // Need to handle deleted invoice...
                                                                props.handleSideBar();
                                                                window.location.assign(`/Admin/invoice/viewInvoice/${item.path.split('/')[2]}/`);
                                                            }
                                                        }else if(item.path.split('/')[1]==="timeOff"){
                                                            if(!!item.path.split('/')[2]){
                                                                props.handleSideBar();
                                                                window.location.assign(`/admin/timeOff/`);
                                                            }
                                                        }else if(item.path.split('/')[1]==="invite"){
                                                            if(!!item.path.split('/')[3]){
                                                               setClientInvite({clientId:item.path.split('/')[3], show:true, clientName: item.message.split(':')[1], clientMessage:'',status:0, createdAt:item.createdAt });
                                                            }
                                                            
                                                        }
                                                        else if(item.path.split('/')[2] === "staffManagement") {
                                                            history.push(item.path);
                                                        }
                                                        else if(item.path.split('/')[1]=== "jobMaster"){
                                                           if(props?.userType === 3)
                                                             history.push('/admin/settings?jobManagement=true')
                                                            
                                                        }
                                                        else if(item.title === 'Client profile Update'){
                                                              history.push(item.path)
                                                         }
                                                        else if(item.path.split('/')[2] === "settings"){
                                                            if(props?.userType === 3){
                                                                history.push(item.path)
                                                            }
                                                            else if(props?.userType === 4 || props?.userType === 5){
                                                                history.push('/admin/invoice')
                                                            }
                                                        }
                                                        else if(item.path.split('/')[2] === "invoice"){
                                                            if(props?.userType === 4 || props?.userType === 5){
                                                                history.push(item.path)
                                                            }
                                                        }
                                                        else if(item.path === "/Admin/tax/taxSettings/"){
                                                            history.push('/admin/clientOnboarding')
                                                        }
                                                        else if(item.path === "/Admin/tax/taxSettings/"){
                                                            history.push('/admin/clientOnboarding')
                                                        }else if(item.path.split('/')[2] === "clientOnboarding"){
                                                            history.push('/admin/clientOnboarding')
                                                        }
                                                        else{
                                                        //   console.log(item.path)
                                                        }
                                                    }}
                                                >

                                                    <div className="row align-items-start p-3 align-items-stretch">
                                                        <div className="col-9 pl-0 d-flex justify-content-start align-items-center" style={{paddingRight:"0"}}>
                                                            <div style={{backgroundColor: 
                                                            item.status === 0 && item.message.includes("canceled")?"#B7B7B7":
                                                            item.status === 1 && item.message.includes("canceled") ?"#B7B7B7":
                                                            item.status === 2 && item.message.includes("rejected") ? "red" : 
                                                            item.status === 1 ? "#4F81BD" : 
                                                            item.status === 2 ? "#FB6873" : 
                                                            item.status === 3 ? "#F3993E" : 
                                                            item.status === 4 ? "#6DFAFD" : 
                                                            item.status === 5 ? "#FDF851" :  
                                                            item.status === 6 ? "#EA58F9" : 
                                                            item.status === 7 ? "#9C4EF7" :  
                                                            "" }} className="checkBox col-1">
                                                            </div>
                                                            
                                                            <img className='notif-icon' style={{width: "20px"}} src={
                                                                item.path.split('/')[1] === "admin" && item.path.split('/')[2] === "workSchedule" ? atom_white : 
                                                                item.path.split('/')[1] === "workSchedule" ? atom_white :
                                                                item.path.split('/')[1] === "timeOff" ? flightTransparent : 
                                                                item.path.split('/')[1] === "client" ? customerTransparent : 
                                                                item.path.split('/')[1] === "invoice" ? invoiceTransparent : 
                                                                item.path.split('/')[1] === ("editTimeSheet" || "timeSheet") ?clockTransparent: 
                                                                item.message.split(" ")[2] === "disagreed" && item.path.split('/')[1] === "timeSheet" ? atom_white:
                                                                item.path.split('/')[2] === "client" ? customerTransparent : 
                                                                item.path.split('/')[1] === "jobMaster" ? settingsTransparent : 
                                                                item.path.split('/')[1] === "feedback" ? feedbackTransparent : 
                                                                item.path.split('/')[2] === "staffManagement" ? periodic_table_white:
                                                                item.path.split('/')[2] === "settings" ? settingsTransparent :
                                                                item.path.split('/')[1] === "settings" ? settingsTransparent :
                                                                item.path.split('/')[2] === "invoice" ? settingsTransparent : 
                                                                item.path === '/Admin/tax/taxSettings/' ? customerTransparent :
                                                                item.path.split('/')[2] === "clientOnboarding" ? customerTransparent :
                                                                item.path === '/Admin/tax/taxRate/' && item.userType == 3 ? settingsTransparent :
                                                                item.path === '/Admin/tax/serviceItem/' && item.userType == 3 ? settingsTransparent:
                                                                item.path === '/Admin/tax/taxRate/' && item.userType !== 3 ? invoiceTransparent :
                                                                item.path === '/Admin/tax/serviceItem/' && item.userType !== 3 ? invoiceTransparent :
                                                                item.title === 'Client profile Update' ? customerTransparent :
                                                                item.title === 'Client Feedback' ? customerTransparent :
                                                                item.title === 'Agency Update'?(item.message.includes("subscription")&&subscriptionTransparent || settingsTransparent):
                                                                item.path.split('/')[1] === 'getAgencyJobs' ? settingsTransparent:
                                                                ""
                                                            } alt="" />
                                                            <div className="col-10">
                                                                {/* <p className=" pl-3 fs-14 font-weight-bold mb-1 text-grey-42">{item.status}</p> */}
                                                                <p className="h3-m wordwrap fixed-height mb-0 text-grey-6c">
                                                                    {item.message}
                                                                </p>
                                                            </div>
                                                        </div>
                                                      
                                                        {item.path.split('/')[1] === "jobMaster" && (props.userType === 4 || props.userType === 5) ?  '' : 
                                                        <div className="col-2 text-right pr-4 d-flex flex-column justify-content-between text-center">  
                                                           <div>             
                                                            {!accountExpired ?
                                                            <p className="view-link cta-2-m">
                                                                View
                                                          </p>
                                                          :
                                                          <OverlayTrigger
                                                          placement="left"
                                                          overlay={
                                                              <Tooltip>
                                                                  Your subscription is expired. View notification is blocked
                                                              </Tooltip>
                                                          }
                                                          >
                                                          <p className="no-drop cta-2-m">
                                                                View
                                                          </p>
                                                          </OverlayTrigger>
                                                          }
                                                          </div>
                                                          <div class="frame-4430">
                                                            <img class="notifi_clock" src={notifi_clock} />
                                                            <div class="_notifi-time">{moment(item.createdAt).format('hh:mm a')}</div>
                                                            </div>
                                                        </div> }
                                                        
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>


                                    </>
                                );
                            })
                            :
                                <div className='d-flex justify-content-center align-items-center' style={{width:"100%", flexDirection:"column"}}>
                                    <div>
                                    <img className='no-results-staff-notif' src={no_results} alt="no-results" />
                                    </div>
                                    <div>
                                    {
                                        loginCount === "1" ? (
                                            <h6 className="text-center no-results-text-staff-notif">
                                            Not sure where to start?&nbsp;
                                            <span
                                                className="cursor-pointer"
                                                onClick={() => {
                                                store.dispatch(startTourGuide({ run: true }));
                                                }}
                                            >
                                                Click Product Tour
                                            </span>{" "}
                                            to know more
                                            </h6>
                                        ) : null
                                    }
                                    </div>
                                </div>
                    }
                </div>
  )
}

export default memo(NotificationMessages)