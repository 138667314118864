import React from 'react';
import { NormalButton, NormalModal } from 'component/common';

export default function ConfirmModel({handleAction,isOpen,toggle, loadingKey = '', loadingCheckKey = '', message = '',type}) {
    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            headerClass="border-0 pt-1 px-1"
            toggle={toggle}
        >
             <>
                  <div className=" text-center mb-5 px-5">
                     {/* <p className="fs-20 mb-0">
                        Your work is <b>In-progress. </b>
                     </p> */}
                     <p className="h3-m mb-0">
                        {message ? message : 
                        <p style={{fontSize:"18px"}} >This action cannot be reversed. Are you sure to cancel?</p>}
                     </p>
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                     <NormalButton
                        onClick={()=>{
                            type?handleAction({type:type,value:false}):handleAction(false)
                        }}
                        id="cancel"
                        label="No"
                        outline={false}
                        disabled ={loadingKey === loadingCheckKey}
                        // loader={isFormLoder}
                        className="pl-4 pr-4 mb-2 btn fs-13 btn btn-dark-primary  btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                            type?handleAction({type:type,value:true}):handleAction(true)
                        }}
                        id="confirm"
                        label="Confirm"
                        loader={loadingKey === loadingCheckKey}
                        disabled={loadingKey === loadingCheckKey}
                        outline={false}
                        className="mb-2 ml-2 btn fs-13 btn border-primary  btn-block "
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}


