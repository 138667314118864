import React from 'react';
import { NormalButton, NormalSelect, NormalDate } from "component/common";
import Moment from "moment";
import moment from 'moment';
import BreakHours from 'component/common/BreakHours';

import "./style.scss"
import editIcon from 'assets/svg/edit.svg';
import clockIcon from 'assets/svg/mdi_access_time.svg';
import disableMap from 'assets/svg/Vector.svg';
import markerIconCin from 'assets/svg/map_cursors/clockIn.svg';
import marketIconCout from 'assets/svg/map_cursors/clockOut.svg';
import marketIconPause from 'assets/svg/map_cursors/pause.svg';
import marketIconResume from 'assets/svg/map_cursors/resume.svg';
import profile from '../../../assets/svg/Ellipse.png';
import flag from 'assets/svg/flag.svg'

export function TimeSheetMark(props) {

    const {setActivePointsFromStaffDetails, startDate, endDate, toggleMap} = props;

    let isVisible = false;
    const calculateHours = (date1, date2) => {
        let diff = (new Date(date1).getTime() - new Date(date2).getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    }

    const calculateBreakHours = (date1, date2,data) => {
        let diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
        diff /= (60 * 60);
        // console.log(diff)
        let diffBreak = (new Date(data[data.length - 1].time).getTime() - new Date(data[0].time).getTime()) / 1000;
        // console.log(diffBreak)
        diffBreak /= (60 * 60);
        let final = diff - diffBreak;
        // let hours= Math.abs(Math.round(diff));
        
        return Math.abs(Math.round(final));
    }
    let { pauseResumeData, clockIn, clockOut,status,staffWorkedHrs,pausedHours,overTimeHours} = props.data;
    // console.log("object", clockIn )
    return (
        <>
            {/* <div className="d-flex align-items-start mb-3"> */}
        {
            (status >= 4 || status===0) && clockIn !== null?
            <>
            <div className=" d-flex justify-content-between mb-3">
                <h6 className="font-weight-bold ">Time Sheet</h6>
            </div>
            <div class="d-flex flex-column align-items-start mb-3">
                <div class="step completed greenCir">
                    <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                    </div>

                    <div class="content-stepper">
                        <div className="row">
                            <div className="col-5">
                                <span class="fs-16 ml-0">{!!clockIn ?Moment(new Date(clockIn)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) :'Pending'}</span>
                            </div>
                            <div className="col-2">
                                <span class="fs-12">Clock-in</span>
                            </div>
                            <div className="col-2">
                                {clockIn?(
                                <img src={markerIconCin} style={{cursor:'pointer'}} onClick={()=>{
                                                           setActivePointsFromStaffDetails(0); 
                                                           toggleMap();
                                                        }}/> ) :
                                                    <img src = {disableMap} style= {{ cursor :'not-allowed'}}
                                                    />
                                                    }
                            </div>
                            <div className="col-3">
                                {moment(clockIn)<moment(startDate) && !!clockIn? (
                                    <small className="text-success no-wrap">Clocked-In Early</small>
                                ) : (
                                    ""
                                )}
                                {moment(clockIn)>moment(startDate) && !!clockIn? (
                                    <small className="text-danger no-wrap">Clocked-In Late (Tardy)</small>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                    </div>
                </div>
                {
                    !!pauseResumeData && pauseResumeData.length ?

                        pauseResumeData.map((item, index) => {
                            return (
                                <div class={`step ${item.key === 'pause' ? 'active' : 'completed'}`}>
                                    <div class="v-stepper">
                                        <div class="circle"></div>
                                        <div class="line"></div>
                                    </div>

                                    <div class="content-stepper">
                                        <div className="row">
                                            <div className="col-5">
                                                <span class="fs-16 ml-0">{Moment(item.time).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)}</span>
                                            </div>
                                            <div className="col-2">
                                                <span class="fs-12">{item.key === 'pause'? 'Pause':'Resume' }</span>
                                            </div>
                                            <div className="col-5">
                                                <img src={item.key ==='pause'?  marketIconPause : marketIconResume                                                  
                                                    
                                                   } style={{cursor:'pointer'}} onClick={()=>{
                                                           setActivePointsFromStaffDetails(index+1); 
                                                           toggleMap();
                                                        }}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })

                        : ''
                }
                <div class="step completed redCir">
                    <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                    </div>

                    <div class="content-stepper">
                        <div className="row">
                            <div className="col-5">
                                <span class="fs-16 ml-0">{
                                    !!clockOut? Moment(new Date(clockOut)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) : 
                                    !!clockIn?'In Progress':
                                    'Pending'
                                    }</span>
                            </div>
                            <div className="col-2">
                                <span class="fs-12">Clock-out</span>
                            </div>
                            <div className="col-2">
                                {clockOut? (
                                <img src={marketIconCout} style={{cursor:'pointer'}} onClick={()=>{
                                                            if(!!clockOut){
                                                                setActivePointsFromStaffDetails(pauseResumeData?pauseResumeData.length+1:1); 
                                                            }
                                                           toggleMap();
                                                        }}/>)
                                                     : (
                                                    <img src = {disableMap} style= {{ cursor :'not-allowed'}} />
                                                     )
                                                    }

                            </div>
                            <div className="col-3">
                            {moment(endDate) < moment(clockOut) && !!clockOut? (
                                <small className="text-danger no-wrap">Clocked-Out Late</small>
                                ) : (
                                    ""
                                )}
                                {moment(endDate) > moment(clockOut) && !!clockOut? (
                                    <small className="text-danger no-wrap">Clocked-Out Early</small>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className=" d-flex flex-column justify-content-between mb-3">
                <div className="row container-fluid mb-3">
                    <div className="col-md-4 d-flex ">
                        <img src={clockIcon} height="25px" width="16px"/>
                        <span class="fs-16 ml-3">Work Hours</span>
                    </div>
                    <div className="col-md-5">
                        <span class="fs-16">{!!clockIn ?Moment(clockIn).format('hh:mm:ss a'):'Pending'} - {!!clockOut? Moment(clockOut).format('hh:mm:ss a'):'Pending'}</span>
                    </div>
                    <div className="col-md-3 row no-padding">
                        {/* <span className="sub-text fs-14">{!!clockOut ?calculateHours(clockIn, clockOut):'Pending'} hours </span> */}
                        <span className="sub-text fs-14 col-md-9 no-padding">{staffWorkedHrs != undefined ?staffWorkedHrs.toFixed(4) :'Pending'} hours </span>
                        <div className="flag-icon col-md-3">
                                                        { parseFloat(staffWorkedHrs).toFixed(4) > ((moment(endDate)- moment(startDate)) /(60*1000*60)) ? 
                                                       <>
                                                        <img className=" ml-2 cursor-pointer mt-1 " src={flag}/>
                                                        <span className='flagicon-msg mb-1'> Staff has worked more than expected time </span>
                                                        </> : ""}
                                                        </div>
                    </div>
                </div>
                <BreakHours staffAvailable={true} data={{"pauseResumeData":!!pauseResumeData?pauseResumeData:[]}} />
                <div className="row container-fluid mb-3">
                    <div className="col-md-4 d-flex ">
                        <img src={clockIcon} />
                        <span class="fs-16 ml-3">Break Hours</span>
                    </div>
                    <div className="col-md-5">
                        {/* {
                            !!pauseResumeData ?
                            <span class="fs-16">{Moment(pauseResumeData[0].time).format('hh:mm:ss a')}  - {Moment(pauseResumeData[pauseResumeData.length -1].time).format('hh:mm:ss a')}</span>
                            :
                            ''
                        } */}
                        
                    </div>
                    <div className="col-md-3">
                        {
                            pausedHours != undefined ?
                            // <span className="sub-text fs-14">{calculateBreakHours(clockIn, clockOut,pauseResumeData)} hours</span>
                            <span className="sub-text fs-14">{pausedHours?.toFixed(4)} hours</span>
                            : <span className="sub-text fs-14"> 0.0000 hours </span>
                        }
                    </div>
                </div>
                <div className="row container-fluid mb-3">
                    <div className="col-md-5 d-flex ">
                        <img src={clockIcon} />
                        <span class="fs-16 ml-3">Over Time Work Hours</span>
                    </div>
                    <div className="col-md-4">
                        {/* <span class="fs-16">10:45am-02:30pm</span> */}
                    </div>
                    <div className="col-md-3">
                        <span className="sub-text fs-14">{!!overTimeHours ? overTimeHours.toFixed(4): '0.0000'} hours</span>
                    </div>
                </div>
                
                {/* Edit TimeSheet Reason */}
                {props.data.agencyEditTSNotes ? 
                    <div className="container d-flex" >
                        <div className=" text-left" style={{width:'100%'}}>
                            <p className="sub-text fs-13  text-black px-1 rounded " style={{width:'max-content', background:'rgb(192, 220, 247)'}}>Edit Timesheet Reason</p> 
                        </div>
                        <div className="" style={{width:'100%'}}>
                            <p className="text-black fs-16">{props.data.agencyEditTSNotes}</p> 
                        </div>
                    </div>
                : null}

                {/* Hazard Pay Reason */}
                {props.data.claimHazardPay ? 
                      <div className="container d-flex" >
                            <div className=" text-left" style={{width:'100%'}}>
                                 <p className="sub-text fs-13  text-black px-1 rounded " style={{width:'max-content', background:'rgb(192, 220, 247)'}}>Hazard Pay Reason</p> 
                            </div>
                            
                            <div className="" style={{width:'100%'}}>
                            <p className="text-black fs-16">{props.data.hazardPayReason}</p> 
                            </div>
                        </div>
                        : null}
                 {props.data.staffNotes ? 
                      <div className="container d-flex" >
                            <div className=" text-left" style={{width:'100%'}}>
                                 <p className="sub-text fs-13  text-black px-1 rounded " style={{width:'max-content', background:'rgb(192, 220, 247)'}}>Staff Notes</p> 
                            </div>
                            
                            <div className="" style={{width:'100%'}}>
                            <p className="text-black fs-16">{props.data.staffNotes}</p> 
                            </div>
                        </div>
                        : null}

            </div>
            </>
            :
            <div className=" d-flex justify-content-center mb-3">
                {
                     status > 4 ?
                     <h6 className="font-weight-bold ">Staff Need to Start Work</h6>
                     :
                     <h6 className="font-weight-bold ">Need to Approve</h6>
                }
            </div>
        }
            {/* </div> */}
        </>

    )
}


