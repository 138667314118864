import React, { useState } from 'react'
import search  from 'assets/svg/search.svg'
import { NormalSelect} from "component/common/index"
import "./style.scss"
import { SearchBox } from '../SearchBox'

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

export const TableFilter = ({ filterData, setFilter, filter, setPage, searchTable,searchState }) => {

    const [searchValue,updateSearchInput]  = useState('')
    const [filterdata,updateFilter]  = useState('')

    const isSmScreen = useMediaQuery('(max-width:760px)');

    const handleInput  = ({ target:{ value } }) =>{
        updateSearchInput(value)
        if(searchValue.length>2){
            // searchTable()
        }
    }

    return (
        <Grid item className=" align-items-start mt-4 d-flex justify-content-between">
            <div>
            <Box className="input-group  search-input-group setsearchbox" sx={{marginBottom: isSmScreen ? '0.5rem !important' : '1rem !important'}}>
                <SearchBox
                    searchFunction={(value)=>{setFilter({ ...filter, search: value }); setPage(1)}}
                    placeholder="Search by activity"
                />
            </Box>
            </div>
            
            <div className="d-flex align-items-center">
                {/* <p className="mb-0 mr-2">
                    Sort by
                </p>
                <NormalSelect
                    placeholder="Search"
                    name="username"
                    value={filter}
                    options={filterData}
                    className="form-control select-mini"
                    handleChange={(e) => {
                        if (["active", "inactive", "all"].includes(e.target.value)) {
                          setFilter(e.target.value);
                        }
                      }}
                /> */}
            </div>
        </Grid>
    )
}
