import React,{ useState,useEffect,useRef }  from 'react';
import { withRouter } from "react-router-dom";
import Tooltip from 'react-bootstrap/Tooltip'; 

import { NormalModal, NormalInput,NormalSelect,NormalDate,NormalCheckbox,NormalTextarea,DragFileUpload,CommonPageLoader } from "component/common"
import "./style.scss";
import LocationSearchInput from 'component/common/locationSearchAutocomplete';
import Moment from "moment";
import moment from "moment";
import SimpleReactValidator from 'simple-react-validator';
import { NormalButton } from 'component/common';
import { RepeatModel } from '../repeatModel';
import {addWork,deleteWork,getSingleWork,getAllClient,getAllJobNamensByClient,updateWork,getAllLocationsByClient, getAllJobCategoriesByClient} from "action/workRequest";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useCustomReducer } from "service/helpers/hooks";
import { fileUploadOnMDB } from "action/fileUpload";
import { deleteFileFromMDB } from 'action/fileHandler';
import deleteFileIcon from "assets/svg/delete-icon.svg";
import editIcon from 'assets/svg/edit.svg';
import deleteIcon from 'assets/svg/delete.svg';
import fileUploadIcon from 'assets/svg/fileUpload.svg';
import { logout } from 'service/utilities';
import { Toast } from 'service/toast';
import DisplayFile from './DisplayFile';
import { DeleteFileConfirmationModal } from "../../common/DeleteFileConfirmationModal/index.js"
import RecurringModel from "../RecurringModel";
import recurrenceIcon from 'assets/svg/recurrenceIcon.svg'
import infoIcon from "assets/svg/info.svg"
import { OverlayTrigger } from 'react-bootstrap';
import ConfirmModel from '../ConfirmModel';

let initState = {
    "clientName":"",
    "location":"",
    "longLat":[13.11212,80.121212],
    "zipCode":"",
    "workRequest":"",
    "startDate":"",
    "endDate": "",
    "isRepeat":false,
    "priority":"",
    "wrType":"",
    "holidays": [],
    "staffNeeded":'',
    "jobName":"" ,
    "notes":"",
    "documents":[],
    "clientId":"",
    "agencyId":"",
    "clientSiteTimeZone":"",
    "repeat":false,
    'repeatData':{},
    "rateFrequency":null
};

const payrateTypeOptions = [
    { 
        label: "Per Hour",
        value: 1
    }, 
     {  label: "Per Day",
        value: 2
    },
      { label: "Per Week",
        value: 3
    },
      { label: "Per Month",
        value: 4
    }
]

function AddWorkRequest({visible, setVisible,addWork,deleteWork,getSingleWork,handleReload, fileUploadOnMDB, deleteFileFromMDB, getAllJobCategoriesByClient,
    getAllClient,updateWork,getAllJobNamensByClient,getAllLocationsByClient,initialStartDate,initialEndDate,setInitialStartDate,setInitialEndDate, setWorkAssign}) {
    const [edit, setEdit] = useState(initState);
    const [enableWR, setEnableWR] = useState([]);
    const [agencyId, setAgencyId] = useState('');
    const [clientId, setClientId] = useState('');
    const [updeteID, setUpdeteID] = useState('');
    const [isUpload, setIsUpload] = useState(false);
    const [isFormLoder, setFormLoder] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isValidateupdate, setIsValidateupdate] = useState(false);
    const [file, setFile] = useState('');
    const [document, setDocument] = useState([]);
    const [jobFunctionCategoriesArray, setJobFunctionCategoriesArrays] = useState([]);
    const [jobFunctions, setJobFunctions] = useState([]);
    const [locations, setlocations] = useState([]);
    const [allLocations, setallLocations] = useState([]);
    const [clients, setClients] = useState([]);
    const [startTime, setstartTime] = useState('');
    const [endTime, setendTime] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [recurring, setRecurring] = useState({status:false});
    const [autoSplitWR, setAutoSplitWR] = useState(false)
    const [showAutoSplitWRConfirmation, setShowAutoSplitWRConfirmation] = useState(false)
    const [startDateWRType,setStartDateWRType] = useState(null)
    const [endDateWRType,setEndDateWRType] = useState(null)
    const [loadingKey, setLoadingKey] = useState('')

    // set Validator
    const validator = useRef(new SimpleReactValidator({
        validators: {
            element: (message) => (
                <span className='error-message font-md'>{message}</span>
            )
        }
    }));
    useEffect(()=>{
        if (initialStartDate){
          setEdit({ ...edit,startDate : initialStartDate});
        };
        if(initialEndDate){
          setEdit({ ...edit, endDate : initialEndDate});
        };
        return(()=>{
          setInitialStartDate("");
          setInitialEndDate("");
        })
      },[initialStartDate, initialEndDate])

    const validatorUpdate = () => {
        setIsValidateupdate(!isValidateupdate)
    }

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData'));
        let id = userData?.agencyId ? userData?.agencyId : userData?.id?userData?.id:"";
        if (!!id) {
            setAgencyId(id);
            // setEdit({ ...edit, clientName: userData.clientName })
        }else{
            logout();
        }
        if(clients.length === 0 && visible.value){
            fetchAllClient();
        }
        if (!!visible.id) {
            setUpdeteID(visible.id)
            fetchWorkById(visible.id);
        }

      setClientId("");  

    }, [visible]);


    useEffect(()=> {
        if(jobFunctionCategoriesArray?.length == 1){
            fetchAllJobFunction(jobFunctionCategoriesArray[0].value)
            setEdit({...edit, jobCategory: jobFunctionCategoriesArray[0].value})
        }
        if(jobFunctionCategoriesArray.length > 1  && edit.jobCategory!== "" && enableWR.length == 0){
            setEdit({...edit, jobCategory: '', jobName: '', location: ''})
        }
    },[jobFunctionCategoriesArray])

    useEffect(()=>{
        if(clients?.length == 1){
            let name= "clientName"
            let value = clients[0].value;
            handleInput({target: {name, value}})
        }
    },[clients])

    useEffect(()=> {
        const temp = {...edit}
        
        if(locations?.length == 1 && clientId !== ""){
            let value = locations[0].value;
            let dataObj = locations[0];
            let obj = allLocations[allLocations.findIndex((arr) => arr?.addressId === value)];
            temp.location = dataObj?.location
            temp.zipCode= obj?.zipCode
            temp.longLat= obj?.longLat
            temp.clientSiteTimeZone = obj?.timeZone
            temp.holidays= obj?.holidays
            temp.siteCode=dataObj?.siteCode
            temp.addressId=value

        }
        if(jobFunctions?.length == 1 && edit?.jobCategory !== "" &&  edit?.jobName == "" ){
            temp.jobName = jobFunctions[0].value
        }
        if(jobFunctions?.length == 1 && edit.jobCategory == "" && edit?.jobName !==""){
            temp.jobName= ''
        }
        setEdit(temp)
    },[locations,allLocations,jobFunctions])


    //handle get all client for listing
    const fetchAllClient = async () => {
        setLoading(true);
        await getAllClient()
            .then((data) => {
                let modifyData=[];
                data?.forEach((item) => {
                    modifyData.push(
                        {
                            label:item.clientName,
                            value:item.clientId
                        }
                    );
                })
                setClients(modifyData.length ? modifyData : []);
                setLoading(false)
            }).catch((e) => setLoading(false));
    }

    const fetchWorkById = async (id) => {
        setLoading(true);

        const query = { 'id': id };
        await
            getSingleWork(query)
                .then((data) => {

                    setEdit(data)
                    setEnableWR(data);
                    setClientId(data.clientId);
                    fetchAllLocations(data.clientId);
                    fetchAllJobFunctionCategoriesByClientId(data.clientId);
                    fetchAllJobFunction(data.jobCategory,data.clientId);
                    // console.log('sdgsdgdsg',modifyData);
                    // setWorkList(modifyData.length ? modifyData : []);
                    setLoading(false);
                })
                .catch((e) => setLoading(false));
    };
    // fetch all job functions 
    const fetchAllJobFunction=async(jobCategory,clientid)=>{
        setFormLoder(true);
        let query={
            jobCategory: jobCategory,
            clientId: edit.clientId !== ""? edit.clientId : clientid,
        }
        await getAllJobNamensByClient(query)
            .then((data) => {
            let uniqueJobNames = [...new Set(data)];
            let modifyData=[];
                uniqueJobNames?.forEach((item)=>{
                    modifyData.push(
                        {
                            label:item,
                            value:item
                        }
                    );
                })
                setJobFunctions(modifyData.length ? modifyData : []);
                setFormLoder(false);
          })
          .catch((e) => setFormLoder(false));
    }

    const fetchAllJobFunctionCategoriesByClientId = async(id)=>{
        let query={
            clientId:id
        };
        await getAllJobCategoriesByClient(query)
            .then((data) => {
                let modifyData=[];
                // let finalData = data[0].jobCategory;
                // finalData.forEach((item)=>{
                    data?.forEach(subItem=>{
                        if(modifyData.filter(tempData=>tempData.value===subItem?.category).length===0){
                            modifyData.push(
                                {
                                    label:subItem.category,
                                    value:subItem.category
                                }
                            );
                        }
                    })
                // })
                setJobFunctionCategoriesArrays(modifyData.length>0 ? modifyData : []);
          })
          
    }

    // fetch all client locations
    const fetchAllLocations=async(id)=>{
        setFormLoder(true);
        let query={
            clientId:id
        }
        await getAllLocationsByClient(query)
            .then((data) => {
            let modifyData=[];
                data?.clientAddress?.forEach((item)=>{
                    modifyData.push(
                        {
                            label:`${item.location.length >= 64 ? `${item.location.slice(0, 64)}...` : item.location} 
                                   ${item?.address ? ` (${item.address.length >= 17 ? `${item.address.slice(0, 17)}...` : item.address})` : ''}`,
                            value: item?.addressId ? item.addressId :'',
                            addressId:item?.addressId ? item.addressId : null,
                            siteCode:item?.address ? item.address : null,
                            location:item.location
                        }
                    );
                })
                setlocations(modifyData.length ? modifyData : []);
                setallLocations(data.clientAddress);
           
                setFormLoder(false);
          })
          .catch((e) => setFormLoder(false));
    }

    // set Default values
    const setDefault = () => {
        validator.current.hideMessages();
        setEdit(initState);
        setClients([]);
        setUpdeteID('')
        if(visible?.id) {
            setWorkAssign({ value: true, id: visible?.id });
        }
        setVisible(false);
        setAutoSplitWR(false)
        setStartDateWRType(null)
        setEndDateWRType(null)
    }
    //handle input change
    // Temporary backup code in case the useEffect method doesn't work as expected
    // The WR type doesn't change if the client location is added/changed after the start/end date is selected in this version of the code
    // const handleInput = ({ target: { name, value } }) => {
    //     value = name === 'staffNeeded' ? value : value;
    //     // value = name === 'startDate' ? moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSSS'):value;
    //     // value = name === 'endDate' ? moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSSS'):value;
    //     if(name === 'clientName'){
    //       fetchAllJobFunctionCategoriesByClientId(value);
    //       fetchAllLocations(value);
    //       let label = clients[clients.findIndex((ar=>ar.value === value))].label;
    //       console.log(name,label)
    //       setEdit({ ...edit, clientId: value,[name]: label, jobCategory: "", 'jobName' : "" })
    //       setClientId(value)
    //     }else if(name === 'jobCategory'){
    //       fetchAllJobFunction(value);
    //       setEdit({ ...edit, jobCategory: value, 'jobName' : ''  });
    //     }else if(name === 'engagementType'){
    //       let newvalue= parseInt(value);
    //       setEdit({ ...edit, [name]: newvalue})
    //     } else if (name === "endDate") {
    //       setEdit({ ...edit, [name]: value });
      
    //       if (edit.startDate) {
    //         const start = new Date(edit.startDate);
    //         const end = new Date(value);
      
    //         // Check if the start and end dates fall on weekends (Saturday or Sunday)
    //         const isStartWeekend = start.getDay() === 6 || start.getDay() === 0; // 6 is Saturday, 0 is Sunday
    //         const isEndWeekend = end.getDay() === 6 || end.getDay() === 0;
      
    //         // Set wrType based on weekday (1) or weekend (2)
    //         const wrType = isStartWeekend || isEndWeekend ? 2 : 1;
    //         setEdit((prevEdit) => ({ ...prevEdit, wrType }));
    //       }
    //     }
    //     else{
    //       setEdit({ ...edit, [name]: value })
    //     }

    // }

        const handleInput = ({ target: { name, value } }) => {
        value = name === 'staffNeeded' ? value : value;
        // value = name === 'startDate' ? moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSSS'):value;
        // value = name === 'endDate' ? moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSSS'):value;
        if(name === 'clientName'){
          fetchAllJobFunctionCategoriesByClientId(value);
          fetchAllLocations(value);
          let label = clients[clients.findIndex((ar=>ar.value === value))].label;
        //   console.log(name,label)
          setEdit({ ...edit, clientId: value,[name]: label, jobCategory: "", 'jobName' : "", holidays: [] })
          setClientId(value)
          setEdit(prevEdit => ({ ...prevEdit, startDate: '', endDate: '', wrType: '', location: '', addressId:'' }));
        }else if(name === 'jobCategory'){
          fetchAllJobFunction(value);
          setEdit({ ...edit, jobCategory: value, 'jobName' : ''  });
        }else if(name === 'engagementType'){
          let newvalue= parseInt(value);
          setEdit({ ...edit, [name]: newvalue})
        } 
        else{
            setEdit({ ...edit, [name]: value })
            if(name === 'startDate' && value === null) {
                setStartDateWRType(null)
            }
            if(name === 'endDate' && value === null) {
                setEndDateWRType(null)
            }
        }
      };

      useEffect(() => {
        if (edit.startDate && edit.endDate) {
          const start = new Date(edit.startDate);
          const end = new Date(edit.endDate);
      
          // 6 is Saturday, 0 is Sunday
          const isStartWeekend = start.getDay() === 6 || start.getDay() === 0; 
          const isEndWeekend = end.getDay() === 6 || end.getDay() === 0; 
      
          
          const selectedLocation = allLocations.find((location) => location.location === edit.location);

         // returns a boolean value   
          const isStartHoliday = selectedLocation && selectedLocation.holidays && checkHoliday(start);
          const isEndHoliday = selectedLocation && selectedLocation.holidays && checkHoliday(end);
      
          let wrType;
          if (isStartHoliday || isEndHoliday && edit.wrType === "3" || edit.wrType === 3) {
            wrType = "3"; // Holiday
          } else if (isStartWeekend || isEndWeekend && edit.wrType === "2" || edit.wrType === 2) {
            wrType = "2"; // Weekend
          } else {
            wrType = "1"; // Weekday
          }
          
          const startDateWrType = isStartHoliday ? "3" : isStartWeekend ? "2" : "1"
          const endDateWrType = isEndHoliday ? "3" : isEndWeekend ? "2" : "1"

          setStartDateWRType(startDateWrType)
          setEndDateWRType(endDateWrType)

        if(edit.startDate && edit.endDate && !moment(edit.startDate).isSame(edit.endDate) && startDateWrType !== endDateWrType) {
            setAutoSplitWR(true)
            setEdit(prevEdit => ({ ...prevEdit, repeat:false, repeatData:{} }));
          }
          else {
            setAutoSplitWR(false)
          }
          setEdit(prevEdit => ({ ...prevEdit, wrType }));
        }
      }, [edit.startDate, edit.endDate, edit.location]);
      
      const checkHoliday = (date) => {
        const selectedLocation = allLocations.find((location) => location.location === edit.location);
      
        // Check if the given date matches any of the holiday dates (The API received date is converted to string for comparison with the picked date)
        if (selectedLocation && selectedLocation.holidays) {
          const holidays = selectedLocation.holidays.map((holiday) => new Date(holiday));
      
          return holidays.some((holiday) => date.toDateString() === holiday.toDateString());
        }
    
        return false;
      };

    // submit form method
    const handleSubmit = async (shouldEmptyDocuments = false) => {
        const formValid = validator.current.allValid();
        
        if (formValid) {
            const body = edit;

            body.documents=document.length > 0 ? document : body.documents;

            if (shouldEmptyDocuments) {
                body.documents = [];
            }

            body['agencyId'] = agencyId;
            
            body.startDate=moment(edit.startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
            body.endDate=moment(edit.endDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
            body.repeatData.endsOn = edit.repeatData.endsOn ? moment(edit.repeatData.endsOn).format("YYYY-MM-DD[T]HH:mm:ss.SSSS"):"";

            if(moment(endDate).diff(moment(startDate), 'hours') >= 24){
                let message = "Shift duration should be less than 24 Hours."
                Toast({ type: "error", message, time: 5000 });
                return;
            }
            if (updeteID) {
                body['id'] = updeteID;
            }

            if(!autoSplitWR) {
            var api = visible.id ? updateWork : addWork;

            setFormLoder(true);
            await api(body)
                .then((data) => {
                    setFormLoder(false);
                    setDefault();
                    // handleReload();
                    // fetchAllData();
                    setstartTime("");
                    setendTime("");
                })
                .catch((e) => setFormLoder(false));
            }
            else {
                setShowAutoSplitWRConfirmation(true)
            }
        } else {
            validator.current.showMessages();
            validatorUpdate();   
            setFormLoder(false);            
                
        }
        setFormLoder(false);

    };

    const handleFileUpload = async (e) => {
        setLoading(true);
        if (e) {
            setFormLoder(true);
            setFile(e)
            setIsUpload(true);
            let formData = new FormData()
            formData.append('file', e[0]);
            await fileUploadOnMDB(formData)
                .then((res) => {
                    setIsUpload(false);
                    let copy = [...document];
                    // copy[0] = `${res.title}|data:${res.contentType};base64,${res.image}`;
                    copy[0] = `${res.fileId}|${res.title}|${res.contentType}`;
                    setDocument(copy)
                    setEdit({ ...edit, documents: copy })
                    // setDefault();
                    // fetchAllData();
                })
                .catch(() => setIsUpload(false)
                );
                setFormLoder(false);
        }
        // console.log(e);
        setLoading(false);
    }

      const deleteFile = async () => {
        setShowDeleteConfirmation(true);
     };

     const handleDeleteConfirmation = async (confirmed) => {
        setShowDeleteConfirmation(false);
        
        // let copy = [...document];

        if (confirmed) {
            const fileId = edit.documents[0]?.split('|')[0];
                try {
                    await deleteFileFromMDB({ fileId });
                    setDocument([])
                    setEdit({ ...edit, documents: [] })
                    if(visible.id) {
                        handleSubmit(true)
                    }
                } catch (error) {
                console.log(error)
            }
            }
        };

    const handleDelete = async () => {
        let body = {
            workId: visible.id,
            reason: "Date changed"
        }
        setFormLoder(true);
        await deleteWork(body).then((data) => {
            setFormLoder(false);
            setDefault();
            handleReload();
        }).catch(() => {
            setFormLoder(false);
        });
    };

    // handle place changes
    const handleSelectAddress=(data)=>{
        
        let value=data.target.value;
        let dataObj = data.target.data;
        let  obj = allLocations[allLocations.findIndex((ar)=>ar?.addressId === value)];
        setEdit({ ...edit, location: dataObj.location,zipCode:obj?.zipCode,longLat:obj?.longLat, clientSiteTimeZone: obj?.timeZone, holidays: obj?.holidays, siteCode:dataObj?.siteCode, addressId:value });
    }
    // set lat long value
    const handleLatLng=(data)=>{
        let newData =[];
        Object.keys(data).forEach((item)=>newData.push(data[item])) ;
        // console.log(newData)
        setEdit({ ...edit, longLat: newData })
    }

    // set pin code value
    const handleAddress = (data) => {
        let { pincode } = data;
        setEdit({ ...edit, zipCode: pincode })
    }

    const handleCloseRecurring = () => {
        setRecurring((prev)=>{
         return{
           ...prev,
            status:false
         }
        })
     }
   
     const handleUpdateRecurring = (data) =>{
        let modifyData = data;

        if(modifyData?.repeat && modifyData.repeatData?.endsOn && edit?.startDate !== ""){

           const originalDate = new Date(edit?.startDate);

            const hours = originalDate.getHours();
            const minutes = originalDate.getMinutes();
            const seconds = originalDate.getSeconds();
            

            const targetDate = new Date(modifyData.repeatData.endsOn);

            targetDate.setHours(hours);
            targetDate.setMinutes(minutes);
            targetDate.setSeconds(seconds)

            modifyData.repeatData.endsOn = targetDate

        }
      setEdit((prev)=>({
       ...prev,
       ...modifyData
      }))
      handleCloseRecurring()
     }

     const getWrPromises = (data) => {
        return data.map((item, index) => {
            const body = item;

            body.documents=document.length > 0 ? document : body.documents;

            body['agencyId'] = agencyId;
            
            body.startDate=moment(item.startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
            body.endDate=moment(item.endDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')

             if (index === 0 && updeteID) {
                body['id'] = updeteID;
                var api = updateWork;

                return api(body)
            }
            else {
                var api = addWork;

                return api(body)
            }

           
        })
     }

    const autoSplitWorkRequest = () => {

        if (moment(edit.startDate).isBefore(moment(edit.endDate))) {
            setLoadingKey('auto-split')
            const firstWrEndDate = moment(edit.startDate).set({ 'hour': 23, 'minute': 59, 'second': 0 });
            const secondWrStartDate = moment(edit.endDate).set({ 'hour': 0, 'minute': 0, 'second': 0 });

            let autoSplitWrArr = []
            const firstWr = { ...edit, wrType: startDateWRType, endDate: firstWrEndDate }
            const secondWr = { ...edit, wrType: endDateWRType, startDate: secondWrStartDate }
            autoSplitWrArr = [firstWr, secondWr]

            const wrPromises = getWrPromises(autoSplitWrArr)

            Promise.all(wrPromises)
                .then((promise) => {
                    setLoadingKey('')
                    setDefault()
                    setShowAutoSplitWRConfirmation(false)
                })
                .catch((error) => {
                    setLoadingKey('')
                    setDefault()
                    setShowAutoSplitWRConfirmation(false)
                })
        } else {
            Toast({ type: 'error', message: 'End date must be greater than start date' })
        }


    }

     const createOrUpdateWorkRequest = async(shouldEmptyDocuments = false) => {
            
            const body = edit;

            body.documents=document.length > 0 ? document : body.documents;

            if (shouldEmptyDocuments) {
                body.documents = [];
            }

            body['agencyId'] = agencyId;
            
            body.startDate=moment(edit.startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')
            body.endDate=moment(edit.endDate).format('YYYY-MM-DD[T]HH:mm:ss.SSSS')

            if (updeteID) {
                body['id'] = updeteID;
            }

            var api = visible.id ? updateWork : addWork;

            await api(body)
                .then((data) => {
                    setShowAutoSplitWRConfirmation(false);
                    setDefault();
                    setstartTime("");
                    setendTime("");
                })
                .catch((e) => {});
     }

     const handleAction = (action) => {
        action.value ? autoSplitWorkRequest() : createOrUpdateWorkRequest()
    }

    let { clientName, location, addressId, longLat, zipCode, workRequest,
        startDate,
        endDate,
        isRepeat,
        engagementType,
        priority,
        wrType,
        staffNeeded,
        jobName,
        jobCategory,
        notes,
        clientSiteTimeZone,
        documents,
        repeat,
        repeatData,
        rateFrequency
     } = edit;

        let tempStartDate = new Date(startDate);
        let tempEndDate = new Date(endDate);
    return (
        <NormalModal
        className={'modal-fill'}
        isOpen={visible.value}
        size="lg"
        toggle={setDefault}
        renderHeader={e => 
            <div className=" d-flex justify-content-between mb-3">
                <h6 className="font-weight-bold ">Work Request</h6>

                {/* <div className="d-flex ">
                    <img className=" ml-3 " src={editIcon}/>
                    <img className=" ml-3 " src={deleteIcon}/>
                 </div> */}
            </div>
        }
        bodyClass='add-staff-box'
        headerClass="border-0 pt-4 px-4"
    >
    {
        !isLoading ?
        <>
            <div className="row align-items-center mb-3">
                <div className="col-12">
                    <div className="form-group" id='work-schedule-add-work-request'>
                        <label className='add-wr-label'>Client Name <sup>*</sup></label>
                        <NormalSelect
                            id='work-schedule-add-work-request'
                            placeholder="Select client name"
                            name="clientName"
                            value={clientId}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            handleChange={handleInput}
                            options={clients}
                            disabled={!!visible.id}
                        />
                        {validator.current.message("clientName", clientName, "required")}
                    </div>    
                </div>
                {/* <div className="col-4 pl-1">
                    <div className="form-group">
                        <NormalInput
                            placeholder="Auto-populated..."
                            name="username"
                            value={clientId}
                            className="form-control rounded-0 bg-ash bg-ash"
                            onChange={()=>{ }}
                            disabled={true}
                            label={"Client Code *"}
                            type="text"
                        />
                    </div>    
                </div> */}
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-8">
                    <div className="form-group">
                    <label className='add-wr-label'>Site Location <sup>*</sup></label>
                        <NormalSelect
                            placeholder="Select client site location"
                            name="location"
                            id='wr-location'
                            value={addressId}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            handleChange={handleSelectAddress}
                            options={locations}
                            disabled={!!visible.id && (enableWR.hasOwnProperty('status') && enableWR.status !== 1)}
                        />
                        {/* <label>Location</label>
                        <div className="w-100">
                            <LocationSearchInput address={location} handleAddress={handleAddress}handleSelect={(address) => handleSelectAddress(address)} handleLatLng={handleLatLng}/>
                        </div> */}
                         {validator.current.message("location ", location, "required")}
                         {/* {
                            zipCode === '' && location !== '' ?
                                <div class="srv-validation-message">Select correct Address for PinCode</div>
                                :
                                ''
                        } */}
                    </div>    
                </div>
                <div className="col-4 pl-1">
                    <div className="form-group">
                    <label className='add-wr-label'>Zip Code <sup>*</sup></label>
                        <NormalInput
                            placeholder="Zip Code"
                            name="zipCode"
                            type="number"
                            value={zipCode}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            onChange={handleInput}
                          
                        />
                        {validator.current.message("Zip Code", zipCode, "required")}
                    </div>    
                </div>
            </div>
            <p className='work-req-details'>Work Request Details</p>
            <hr />
            <div className="row align-items-center mb-3">
                <div className="col-6 pr-1">
                    <div className="form-group">
                        <label className='add-wr-label'>Job Category <sup>*</sup></label>
                        <NormalSelect
                            placeholder="Select job category"
                            name="jobCategory"
                            value={jobCategory}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            handleChange={handleInput}
                            options={jobFunctionCategoriesArray}
                            disabled={!!visible.id}
                        />
                        {validator.current.message("Job Category", jobCategory, "required")}    
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                    <div className='d-flex justify-content-between'>
                    <label className='add-wr-label'>Job Name <sup>*</sup></label>
                    <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => ( 
                      <Tooltip {...props}> 
                       Job name selection is to relatively match with the experience or skills of your staff. 
                      </Tooltip> 
                    )} 
                    placement="top">
                        <img src={infoIcon} alt='info-icon'></img>
                    </OverlayTrigger>
                    </div>
                        <NormalSelect
                            placeholder="Select job name"
                            name="jobName"
                            value={jobName}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            handleChange={handleInput}
                            options={jobFunctions}
                            disabled={!!visible.id &&  (enableWR.hasOwnProperty('jobName') && enableWR.jobName !== null) && (enableWR.hasOwnProperty('status') && enableWR.status !== 1)}
                        />
                        {validator.current.message("Job Function", jobName, "required")}    
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-4 pr-1">
                    <div className="form-group">  
                    <label className='add-wr-label'>Start Date <sup>*</sup></label>  
                        <NormalDate
                            timeInput={true}
                            mindate={true}
                            placeholder={`${localStorage.getItem('dateFormat')}`}
                            name="startDate"
                            value={startDate ? new Date(startDate) : initialStartDate}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            onChange={handleInput}
                          
                        />
                        {validator.current.message("Start Date", startDate, "required")}
                    </div>    
                </div>
                <div className="col-4 pr-1">
                    <div className="form-group"> 
                    <label className='add-wr-label'>Start Time <sup>*</sup></label>     
                    <NormalDate
                                // disabled={startDate ? isNaN(new Date(startDate).getTime()) :true}
                                disabled={startDate ? isNaN(new Date(startDate).getTime()) :<>{initialStartDate?false:true}</>}
                                showTimeSelect={true}
                                timeIntervals={15}
                                timeInput={true}
                                mindate={true}
                                placeholder="HH:MM"
                                name="startTime"
                                value={startDate ? new Date(startDate.toString()) : initialStartDate}
                                className="form-control rounded-0 setmultibox fs-13"
                                onChange={(e)=>{
                                    let {
                                        target: { value },
                                    } = e;
                                    if (value != undefined) {
                                        setstartTime(value);
                                        let time = moment(value).format("HH:mm:ss");
                                        time = time.split(':');
                                        tempStartDate.setHours(time[0]);
                                        tempStartDate.setMinutes(time[1]);
                                        tempStartDate.setSeconds(0);
                                        setEdit({ ...edit, startDate: new Date(tempStartDate) })
                                    }
                                }}
                             
                            />   
                </div>
                {/*<div className="col-4 pl-3 pt-4">
                    <div className="form-group mt-3 position-relative">
                        <NormalCheckbox
                           name="isKeepMe"
                           checked={false}
                           label="Recurring Shifts"
                           id="isKeepMe"
                           onChange={()=>{}}
                        />
                        <RepeatModel isVisible={true} />  
                    </div>  
                </div>*/}
            </div>
            <div className="col-4">
                <div className="form-group">
                <label className='add-wr-label'>Client Site Timezone <sup>*</sup></label>   
                    <NormalSelect
                    // arrow={true}
                    placeholder="Time Zone"
                    disabled={true}
                    name="clientSiteTimeZone"
                    value={clientSiteTimeZone}
                    options={[{label:clientSiteTimeZone, value:clientSiteTimeZone}]}
                    className="form-control rounded-0 setmultibox fs-13"
                    />
                </div>
            </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-4 pr-1">
                    <div className="form-group">  
                    <label className='add-wr-label'>End Date <sup>*</sup></label>    
                        <NormalDate
                            timeInput={true}
                            mindate={true}
                            placeholder={`${localStorage.getItem('dateFormat')}`}
                            name="endDate"
                            value={endDate ? new Date(endDate) : initialEndDate}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            onChange={handleInput}
                           
                        />
                        {validator.current.message("End Date", endDate, "required")}
                
                       </div>
                </div>
                <div className="col-4 pr-1">
                    <div className="form-group">
                    <label className='add-wr-label'>End Time <sup>*</sup></label>  
                            <NormalDate
                            // disabled={isNaN(startDate.getTime())}
                                // disabled={!Moment(new Date(startDate)).isValid}
                                disabled={endDate ? !Moment(new Date(endDate)).isValid :<>{initialEndDate?false:true}</>}
                                showTimeSelect={true}
                                timeIntervals={15}
                                timeInput={true}
                                placeholder="HH:MM"
                                name="endTime"
                                value={endDate ? new Date(endDate.toString()) : initialEndDate}
                                className="form-control rounded-0 setmultibox fs-13"
                                onChange={(e)=>{
                                    let {target:{value}}=e;
                                    if (value != undefined) {
                                    setendTime(value);
                                    let time = moment(value).format("HH:mm:ss");
                                    time = time.split(':');
                                    tempEndDate.setHours(time[0]);
                                    tempEndDate.setMinutes(time[1]);
                                    tempEndDate.setSeconds(0);
                                    setEdit({ ...edit, endDate: new Date(tempEndDate) })
                                    }
                                }}
                               
                            />
                        </div>
                        </div>
                <div className="col-4">
                <div className="form-group">
                <label className='add-wr-label'>Client Site Timezone <sup>*</sup></label>
                    <NormalSelect
                    // arrow={true}
                    placeholder="Time Zone"
                    disabled={true}
                    name="clientSiteTimeZone"
                    value={clientSiteTimeZone}
                    options={[{label:clientSiteTimeZone, value:clientSiteTimeZone}]}
                    className="form-control rounded-0 setmultibox fs-13"
                    />
                </div>
            </div>
                     
            </div>

            <div className='row align-items-center mb-3'>
                <div className="col-4 pr-2">
                    <div className="form-group">
                        <label className='add-wr-label'>Engagement Type <sup>*</sup> </label>
                          <NormalSelect
                              placeholder="Select engage type"
                              name="engagementType"
                              value={engagementType}
                              className='form-control rounded-0 bg-ash setmultibox fs-13'
                              handleChange={handleInput}
                              options={[{label: "Contract", value:1}, {label: "Regular", value:2}]}
                              disabled={edit.status >= 2}
                          />
                        {validator.current.message("Engagement Type", engagementType, "required")}
                    </div>    
                </div>
                <div className="col-4 ">
                    <div className="form-group">
                        <label className='add-wr-label'>Work Request Type <sup>*</sup> </label>
                            <NormalSelect
                                placeholder="Select WR type"
                                name="wrType"
                                value={wrType}
                                className="form-control rounded-0 bg-ash setmultibox fs-13"
                                handleChange={handleInput}
                                options={[{ label: "Weekday", value: 1 }, { label: "Weekend", value: 2 },
                                { label: "Holiday", value: 3 }]}
                                disabled={edit.status >= 2}
                            />
                            {validator.current.message("Work Request Type", wrType, "required")}
                    </div>    
                </div>
               
                <div className="col-4 d-flex pt-3 ">
              
              {!autoSplitWR && <div class="frame-1647" id={`${repeat == false ? "" : "recurrence-container"}`}
               onClick={()=> setRecurring((prev)=>{
                                                      return{
                                                            ...prev,
                                                        status:true,
                                                             }
                                                     })}>
                                    <img src={recurrenceIcon} className='flip-image' alt='Recurrence' width={40}/>
                                    <div className={`add-recurrence`}>Recurrence</div>
            </div>}
            {repeat === true && !autoSplitWR &&
            <div className="cursor-pointer d-flex align-items-center recurrence-delete-button" onClick={()=> setEdit((prev)=>({...prev, repeat:false, repeatData:{}}))}>
            <img src={deleteFileIcon} alt="delete-recurrence"/>
            </div> }
              
            </div> 
                    
            </div>
            
            <div className="row align-items-center mb-3">
                <div className="col-4 pr-2">
                    <div className="form-group">
                        <label className='add-wr-label'>Priority <sup>*</sup></label>
                            <NormalSelect
                                placeholder="Select work priority"
                                name="priority"
                                value={priority}
                                className="form-control rounded-0 bg-ash setmultibox fs-13"
                                handleChange={handleInput}
                                options={[{ label: "Normal", value: "normal" }, { label: "Urgent", value: "urgent" },
                                { label: "Critical", value: "critical" }]}
                                
                            />
                            {validator.current.message("Priority", priority, "required")}
                    </div>    
                </div>
                <div className="col-4 pr-2">
                    <div className="form-group">
                        <label className='add-wr-label'>Billing Type <sup>*</sup></label>
                            <NormalSelect
                                placeholder="Select billing type"
                                name="rateFrequency"
                                value={rateFrequency ? rateFrequency : ''}
                                className="form-control rounded-0 bg-ash setmultibox fs-13"
                                handleChange={handleInput}
                                options={payrateTypeOptions}
                                disabled={edit.status >= 2}
                            />
                            {validator.current.message("Billing type", rateFrequency, "required")}
                    </div>    
                </div>
                <div className="col-4 pr-2">
                    <div className="form-group">
                    <label className='add-wr-label'>Staff Needed <sup>*</sup></label>
                        <NormalInput
                            placeholder="Enter staff count required"
                            maxlength="3"
                            minlength="1"
                            type={"number"}
                            name="staffNeeded"
                            value={staffNeeded}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            onChange={handleInput}
                          
                        />
                        {validator.current.message("Staff Needed", staffNeeded, "required")}
                    </div>    
                </div>
            </div>
            
            
            <div className="row align-items-start mb-3 height-add">
                <div className="col-8 pr-1">
                <div className="form-group">
                        <label className='add-wr-label'>Work Request Title </label>
                        <NormalInput
                            placeholder="Enter work request title"
                            maxlength = "200"
                            name="workRequest"
                            value={workRequest}
                            className="form-control rounded-0 bg-ash setmultibox fs-13"
                            onChange={handleInput}
                            
                        />
                        {/* {validator.current.message("work Request", workRequest, "required")} */}
                    </div> 
                    <div className="form-group">
                    <label className='add-wr-label'>Special Notes </label>
                        <NormalTextarea
                            placeholder="Enter special instructions (if any)..."
                            maxlength = "500"
                            name="notes"
                            rows={2}
                            value={notes}
                            className="form-control rounded-0 bg-ash bg-ash"
                            onChange={handleInput}
                        />
                        {/*validator.current.message("Note", notes, "required")*/}
                    </div>    
                </div>
                <div className="col-4 pr-1">
                    <div className="form-group">
                        <div className="file-upload">
                            
                            <DragFileUpload 
                                className=" rounded-0 bg-ash file-uploader drag-drop-container mr-2"
                                handleFileUpload={handleFileUpload}
	    			label={"Attach Document (if any)"}
                                >
                                <div className="p-3 icon">
                                
                                    {
                                        !!documents && documents.length > 0 ?
                                        <>
                                            {
                                                // documents.map((item) =>  { return (<img className="p-2 doc-upload-img" src={item.split("|").length>1 && item.split("|")[1]} alt={item.split("|").length>1 && item.split("|")[0]} />) })
                                                <DisplayFile file={documents} agencyId={agencyId}/>
                                            }
                                        </>
                                        :
                                        <>
                                        <img className="pt-4" src={fileUploadIcon}    />
                                        <p className="p-2">Drag & Drop (or) Click</p>
                                      </>
                                    }
                                </div>    
                            </DragFileUpload>
                            {documents && documents.length > 0 &&
                                <img 
                                    className='cursor-pointer'
                                    style={{position: "relative", bottom: "45px"}} 
                                    src={deleteFileIcon}
                                    onClick={deleteFile}
                                />
                            }
                        </div>
                        {/*validator.current.message("Document", file, "required")*/}
                    </div>
                </div>
                
            </div>
            
            <div className="d-flex align-items-end mt-3 justify-content-end ">
                     <NormalButton
                        onClick={() => handleSubmit(false)}
                        id="Confirm"
                        label={`${!!visible.id ? 'UPDATE' : 'CONFIRM'}`}
                        outline={false}
                        loader={isFormLoder || isUpload}
                        className="mb-2 btn fs-14 btn-violet-primary btn-block form-btn"
                    />
            </div>
          
            <RecurringModel
      data={edit}
      show={recurring.status}
      handleClose={handleCloseRecurring}
      repeat={edit?.repeat}
      repeatData={edit?.repeatData}
      handleUpdateRecurring={handleUpdateRecurring}
      /> 
        </>
        :
        <CommonPageLoader />
    }

            {showDeleteConfirmation && (
               <DeleteFileConfirmationModal
                  isOpen={true}
                  handleAction={handleDeleteConfirmation}
                  toggle={() => setShowDeleteConfirmation(false)}
               />
            )}

        { showAutoSplitWRConfirmation ? <ConfirmModel
          toggle={() => setShowAutoSplitWRConfirmation(!showAutoSplitWRConfirmation)}
          isOpen={showAutoSplitWRConfirmation}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = {`auto-split`}
          loadingKey={loadingKey}
          message='Work request type is different for start date and end date. Do you want to split work request?'
          type='auto-split'
        /> : null }

    </NormalModal>

    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        addWork,
        deleteWork,
        getSingleWork,
        getAllJobNamensByClient,
        getAllClient,
        updateWork,
        getAllLocationsByClient,
        fileUploadOnMDB,
        deleteFileFromMDB,
        getAllJobCategoriesByClient,
      },
      dispatch
    );
  };
  
  export default connect(
    mapDispatchToProps,
    mapDispatchToProps
  )(withRouter(AddWorkRequest));
