let userType = JSON.parse(localStorage.getItem('userType'));


export const invoiceSteps =  (!!userType&&Number(userType) === 4) || (!!userType&&Number(userType) === 5) ? [
    {
        target:'.search-invoice',
        content:'Search Invoices with client name',
        placement:'auto',
        disableBeacon: true,
        // disableScrolling: true,
    },
    {
        target: '#agency-coad-tax-rates',
        content: 'Click Here to Add Tax Rates.',
        placement: 'auto',
        disableBeacon: true
    },
    {
        target: '#agency-invoice-service-item',
        content: 'Here you can add & explore our range of service Items, each accompanied by its respective GST rates, ensuring transparency and clarity in your selection process.',
        placement: 'auto',
        disableBeacon: true,
    },
    {
        target:'.create-invoice',
        content:'Click Here to Create Invoice',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-due',
        content:'Filter Invoices with particular period based on Due Date.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-invoice',
        content:'Filter Invoices with Invoice Date.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-billing',
        content:'You can filter Invoices with Billing period.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'div.invoiceStatus > div',
        content:'You can filter Invoices with Status. Draft, Published & Paid.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.invoice-row',
        content:'You can check the invoice details here.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.invoice-action-buttons > div',
        content:'You can control invoice with quick action buttons. View, Edit & Delete',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
        spotlightPadding:10,
    },
 ]:
 [
    {
        target:'.search-invoice',
        content:'Search Invoices with client name',
        placement:'auto',
        disableBeacon: true,
        // disableScrolling: true,
    },
    {
        target:'.create-invoice',
        content:'Click Here to Create Invoice',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-due',
        content:'Filter Invoices with particular period based on Due Date.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-invoice',
        content:'Filter Invoices with Invoice Date.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.tour-billing',
        content:'You can filter Invoices with Billing period.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'div.invoiceStatus > div',
        content:'You can filter Invoices with Status. Draft, Published & Paid.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.invoice-row',
        content:'You can check the invoice details here.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.invoice-action-buttons > div',
        content:'You can control invoice with quick action buttons. View, Edit & Delete',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
        spotlightPadding:10,
    },
]


export const invoiceCreationSteps =[
    {
        target:'.select-client-name',
        content:'Select the client you want to create invoice',
        placement:'auto',
        disableBeacon: true,
        // disableScrolling: true,
    },
    {
        target:'.select-invoice-currency',
        content:'Select Currency to create invoice',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.client-site-location',
        content:'Select the client site location',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: false,
    },
    {
        target:'.invoice-start-date',
        content:'Select the start date',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.invoice-end-date',
        content:'Select the End date',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.client-email-id',
        content:'Enter the client Email ID',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.invoice-number',
        content:'Enter the invoice number',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.select-invoice-date',
        content:'Select Invoice Date to create invoice',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.select-invoice-terms',
        content:'Select Terms for Invoice',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.select-invoice-due-date',
        content:'Select Due date for the Invoice',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#tourguide-invoice',
        content:'Add custom message to Invoice',
        placement:'top',
        disableBeacon: true,
    },
    {
        target:'.file-attachment-readonly',
        content:'You can attach file for invoice',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.total-border',
        content:'Sub total and Grand total amount of invoice will be shown here. You can round off the amount, if required',
        placement:'auto',
        disableBeacon: true,
    },
    ...(process.env.REACT_APP_BASE_SERVER === '1' ? [{
        target:'.overall-tax-invoice',
        content:'Enter the Tax percentage, if applicable, here',
        placement:'auto',
        disableBeacon: true,
    }]
    :
    []),
    {
        target:'.generate-invoice',
        content:'Click here to generate invoice with the selected details',
        placement:'auto',
        disableBeacon: true,
    },
]

export const invoiceEditSteps =[

    {
        target:'.invoice-number',
        content:'Enter the invoice number',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        target:'.select-invoice-date',
        content:'Select the Invoice Date to change ',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target:'.select-invoice-terms',
        content:'Select Terms for Invoice',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target:'.select-invoice-due-date',
        content:'Select Due date for the Invoice',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.invoice-message',
        content:'You can edit the message of this Invoice',
        placement:'top',
        disableBeacon: true,
        // scrollOffSet:200,
    },
    {
        target:'.file-attachment-readonly',
        content:'You can update your file attachment',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.total-border',
        content:'Sub total and Grand total amount of invoice will be shown here. You can round off the amount, if required',
        placement:'auto',
        disableBeacon: true,
    },
    ...(process.env.REACT_APP_BASE_SERVER === '1' ? [{
        target:'.overall-tax-invoice',
        content:'Update the Tax percentage, if applicable, here',
        placement:'auto',
        disableBeacon: true,
    }]
    :
    []),
    {
        target:'#updateInvoice',
        content:'Click here to update invoice',
        placement:'auto',
        disableBeacon: true,
    },
]
export const invoiceViewSteps = [
    {
        target:'.select-invoice-status',
        content:'Select the invoice status. DRAFT, PUBLISH & PAID',
        placement:'auto',
        disableBeacon: true,
        // disableScrolling: true,
    },
    {
        target:'.print-invoice',
        content:'Click here to print the invoice',
        placement:'auto',
        disableBeacon: true,
        // disableScrolling: true,
    },
]