import React, {useState} from 'react'
import './style.scss'
import warningIcon from 'assets/svg/warning.svg';

const WarningIcon = ({right, top, open}) => {
    const [show, setShow] = useState(false)

  return (
    <>
    <div className='warning-icon-main' onMouseEnter={()=> open && setShow(true)} onMouseLeave={()=> setShow(false)}>
        <img className='ml-2' src={warningIcon} width={25}/>
        {show && (
        <div className="warning-icon-message" style={{right:right, top:top}}>Important fields are missing</div>
      )}
    </div>
    
    </>
  )
}

export default WarningIcon