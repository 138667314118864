export const reportsSteps = [
    {
        target:'.total-staff',
        content:'You can check the count of your total Staff',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.total-shifts',
        content:'You can check the total number of shifts',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.completed-shifts',
        content:'You can check the total shifts, which is completed till now',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.pending-invoices',
        content:'You can check the pending invoices count here',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.total-shift-hours',
        content:'You can monitor the total shift hours over the period of time ',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.invoice-summary',
        content:'You can check the total invoice summary over the period of time ',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.billable-hours',
        content:'You can check & download the Billable hours reports with each client ',
        placement:'auto',
        disableBeacon: true,
        placementBeacon:'top'
    },
    {
        target:'.payroll-report',
        content:'You can check & download payroll details by each staff',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.agency-user-report',
        content:'You can monitor the number of Agency users in various levels.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.rejected-staff-report',
        content:'You can check the rejected staff reports by filtering by start and end date.',
        placement:'auto',
        disableBeacon: true,
    },
]