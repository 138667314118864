import moment from 'moment';
import React from 'react'
import { useState, useEffect } from 'react';
import classes from './interactive_map.module.scss'
import clockInCursor from '../../../assets/svg/map_cursors/clockIn.svg'
import clockOutCursor from '../../../assets/svg/map_cursors/clockOut.svg'
import pauseCursor from '../../../assets/svg/map_cursors/pause.svg'
import resumeCursor from '../../../assets/svg/map_cursors/resume.svg'
import { entryType } from './constants'
import laptopIcon from '../../../assets/svg/laptop.svg'
import mobileIcon from 'assets/svg/mobile.svg'
import tabletIcon from 'assets/svg/tablet.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./interactive_map.module.scss"

function TimeLineMapPoint({ label, date, pointType, pointClickHandler, active, deviceDetails }) {

    let line = '';
    switch (pointType) {
        case entryType.PAUSED:
            line += classes.dashed;
            break;
        case entryType.CLOCK_OUT:
            line += classes.no_line;
            break;
        case entryType.CLOCK_IN:
        case entryType.RESUMED:
        default: 
            line += classes.solid;
            break;
    }

    return (
        <div className={classes.timeline_point} onClick={pointClickHandler}>
            <div className={classes.line_cursor_wrapper}>
                <img
                    className={classes.cursor}
                    src={
                        label === entryType.CLOCK_IN ? clockInCursor :
                            label === entryType.CLOCK_OUT ? clockOutCursor :
                                label === entryType.PAUSED ? pauseCursor : resumeCursor
                    }
                    alt="cursor"
                />
                <div className={`${classes.line} ${line}`} />
            </div>
            <div className={classes.content_wrapper}>
                <p className={classes.label}>
                    {label}
                    <OverlayTrigger placement="top" style={{zIndex:'101'}}
                        overlay={
                            <Tooltip id='tooltip'>
                                Device Type: {deviceDetails?.deviceType}<br />
                                Brand: {deviceDetails?.brand}<br />
                                Model: {deviceDetails?.model}<br />
                                OS Version: {deviceDetails?.osVersion}<br />
                                Browser Version: {deviceDetails?.browserVersion}<br />
                                Date/Time : {deviceDetails?.timeStamp ? moment(new Date(deviceDetails?.timeStamp)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) : 'Not Available'}
                            </Tooltip>}>
                        <img src={deviceDetails?.deviceType === 'desktop' ? laptopIcon : deviceDetails?.deviceType === 'laptop' ? laptopIcon : deviceDetails?.deviceType === 'tablet' ? tabletIcon : (deviceDetails?.deviceType === 'mobile' || deviceDetails?.deviceType === 'Mobile') ? mobileIcon : laptopIcon} alt="info icon" style={{ width: "15px", marginLeft: "0.75rem" }} />
                    </OverlayTrigger>
                </p>
                <p className={classes.time}>{`${moment(date).format(`${localStorage.getItem('dateFormat')}`)},  ${moment(date).format('LT')}`}</p>
            </div>
        </div>
    )
}

export default function TimelineMap({ timelineArray = [], mapPointHandler = () => { }, activePoint, setActivePoint, isPlay, setActiveisPlaying }) {

    const [isPlaying, setIsPlaying] = useState(isPlay);
    let index = 0;
    let timeoutId;

    const onClickHandler = (lat, lng, index) => {
        mapPointHandler(lat, lng);
        if (!!lng && !!lat) setActivePoint(index);
    }
    
    const handlePlayVideo = (timelineArray) => {
        const executeNext = () => {

             if(index== timelineArray.length-1){
                setActiveisPlaying(!isPlaying)
             }
            if (index < timelineArray.length && isPlaying) {
                const item = timelineArray[index];

                if (item) {
                    onClickHandler(item.lat, item.lng, index);

                    timeoutId = setTimeout(() => {
                        index++;
                        executeNext();
                    }, 2000);
                } else {
                    timeoutId = setTimeout(() => {
                        index++;
                        executeNext();
                    }, 2000);
                }
            }
        };

        executeNext();
    };

    useEffect(() => {
        if (isPlaying) {
            handlePlayVideo(timelineArray); 
        } else {
            index=0;
            clearTimeout(timeoutId); 
        }

        return () => clearTimeout(timeoutId);
    }, [isPlaying]);

    useEffect(() => {
        setIsPlaying(isPlay);
      }, [isPlay]);


    return (
        <div className={classes.timeline_wrapper}>
            {
                !!timelineArray.length && timelineArray.map((item, index) => (
                    <TimeLineMapPoint
                        key={index}
                        active={activePoint === index}
                        label={item?.label}
                        date={item?.date}
                        pointType={item?.label}
                        pointClickHandler={() => onClickHandler(item?.lat, item?.lng, index)}
                        deviceDetails={item?.deviceDetails}
                    />
                ))
            }

        </div>
    )
}
