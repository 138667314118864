import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import locationIcon from 'assets/svg/Vector (16).svg'
import {extractAddressFields} from '../../../service/helperFunctions'
import "./style.scss"

export default class LocationSearchInput extends React.Component {
  

  constructor(props) {
    super(props);
    let {address}=this.props
    this.state = { address: address?address:'' };
  }

  static getDerivedStateFromProps(props, state) {
    let {address}=props;
    return {address:  address?address:''  };
  }
 
  handleChange = address => {
    this.setState({ address });
    this.props.handleSelect(address)
  };
 
  handleSelect = (address) => {
    this.setState({ address });
    this.props.handleSelect(address)
    geocodeByAddress(address)
      .then(results => {

         let data=extractAddressFields(results[0].address_components)
         if(this.props.handleAddress){
            this.props.handleAddress(data)
         }
         getLatLng(results[0]).then (latDetails=>
         this.props.handleLatLng(latDetails)
        )
        
      })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
    
  };
  
  

  render() {
    let{placeholder,address, disabled}=this.props;
    return (
      <PlacesAutocomplete
        value={!!address ? address : this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="normal-input">
            
            <input
              {...getInputProps({
                placeholder: `${!!placeholder ?placeholder:'Search Places ...'}`,
                className: 'location-search-input',
                disabled
              })}
              // value={address}
              className="normal-form-input pl-3 pr-5 form-control rounded-0 bg-ash location-input-font"
            />
            {/* <img src={locationIcon} className='locationIcon' alt='location' /> */}
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span className='location-input-dropdown-font'>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}