export const staffManagementSteps = [
   
    {
        target:'#onboard-staff',
        content:'You can onboard new staff by clicking here',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#searchStaff',
        content:'You can search by staff name',
        placement:'auto',
    },
    {
        target:'#staff-role-filter',
        content:'You can filter by staff role like Co-admin, Manager or Worker',
        placement:'auto',
        disableScrolling: true,
    },
    {
        target:'#staff-status-filter',
        content:'Filter with status Active or Inactive',
        placement:'auto',
        disableScrolling: true,
    },
    {
        target:'.staff_list_img',
        content:'Switch to List view',
        placement:'auto',
        disableScrolling: true,
    },
    {
        target:'.column_img',
        content:'Switch to Card View',
        placement:'auto',
        disableScrolling: true,
    },
    {
        target:'table > tbody > tr',
        content:'You can review the staff details here.',
        placement:'auto',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'#staff-action-buttons > div > div',
        content:'You can control the staff profile with these quick-action buttons.',
        placement:'auto',
        disableScrolling: true,
    },
]

export const staffManagementCardSteps = [
    {
        target:'.setbodydiv',
        content:'Check your Staff Co-Admins, Managers & Workers in one place',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.subscribe-dots',
        content:'Click here to filter with Active & Archived staff',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.periodic-table',
        content:'Click here to View the staff profile',
        placement:'auto',
        disableBeacon: true,
    },

];

export const staffManagementAddStaff = [
    
    {
        target:'.nav-tabs > a ',
        content:'You can create a staff account by entering basic information in this tab.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(2) ',
        content:'The permissions tab is where you can manage the staffs permissions',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(3) ',
        content:'In this tab, you can define staff payrate.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(4) ',
        content:'Upload staff-related documents here.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(5)',
        content:'Customize the staff preferences here.',
        placement:'auto',
        disableBeacon: true,
    },
    
]

export const staffManagementViewStaff = [
    
    {
        target:'.nav-tabs > a ',
        content:'Check the General Details of the staff',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(2) ',
        content:'Check the permissions that staff allowed to do',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(3) ',
        content:'View the payrates of the staff',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(4) ',
        content:'View the documents of staff.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(5)',
        content:'View the staff preferences here.',
        placement:'auto',
        disableBeacon: true,
    },
    
]
export const staffManagementTeamDetails = [
    
    {
        target:'#main-content > div > div.content > div.d-flex.justify-content-between.my-4 > div > div:nth-child(1)',
        content:'Check your Total Team members count',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#main-content > div > div.content > div.d-flex.justify-content-between.my-4 > div > div:nth-child(2) ',
        content:'Total Team members hired',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#main-content > div > div.content > div.d-flex.justify-content-between.my-4 > div > div:nth-child(3) ',
        content:'Total Team members pending to hire',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.search-input-group ',
        content:'Search you team member.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#main-content > div > div.content > div.row.justify-content-space-between.team-details-list > div:nth-child(1) > div',
        content:'Click here to Add Staff.',
        placement:'auto',
        disableBeacon: true,
    },
    
]