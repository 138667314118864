import React from "react";
import "./style.scss";
import {
  ButtonDropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export class NormalDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownId: Math.floor(100000 + Math.random() * 900000),
      isOpen: false,
    };
  }
  handleDropdown = () => {
    // console.log("this is:", mennuId);
    // let submenuElem = document.getElementById(mennuId);
    // submenuElem.classList.toggle("show");
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      className = "",
      label = "",
      labelIcon = "",
      onClick,
      disabled = false,
      caret = true,
      alinement = "",
      outline = false,
      color = "",
      optionsList = [
        { icon: "edit", label: "Edit", iconClass: "material-icons" },
        { icon: "content_copy", label: "Copy", iconClass: "material-icons" },
        { icon: "delete", label: "Delete", iconClass: "material-icons" },
      ],
      id = "",
    } = this.props;
    let { isOpen } = this.state;

    return (
      <ButtonDropdown
        disabled={disabled}
        isOpen={isOpen}
        toggle={this.handleDropdown}
      >
        {/* <Button id="caret" >{label ? label : ''}  {isOpen ? <i className="fas fa-chevron-up ml-2"></i> : <i className="fas fa-chevron-down ml-2"></i>}</Button> */}
        <DropdownToggle
          disabled={disabled}
          outline={outline}
          caret={caret}
          color={color}
          className={`${className} custom-dropdown-toggle`}
        >
          {label ? label : <i className={labelIcon}></i>}{" "}
          <i className="fas fa-chevron-down ml-2"></i>
        </DropdownToggle>

        <DropdownMenu  style={{transform: alinement === 'left' ? 'translate3d(-93px, 0px, 0px)' : 'translate3d(0px, 0px, 0px)'}}>
          {optionsList.map((data, index) => (
            <DropdownItem
              key={index}
              className="custom-dropdown-item" // Add this line
              onClick={(e) => {
                let body = {};
                body = {
                  target: {
                    value: data.value,
                    name: e.target.name ?e.target.name :e.target.label  ,
                    data: data,
                  },
                };
                // this.handleDropdown(id)
                onClick(body);
              }}
            >
              {/* <img className="mx-2" src={data.icon} /> <span>{data.label}</span> */}
              {/* {data.icon && <img className="mx-2" src={data.icon} />} <span>{data.label}</span> */}
              {data.boxColorClass && <span className={"custom-color-box "+data.boxColorClass}></span>}{(!data.boxColorClass && 
              data.icon) && <img className="mx-2" src={data.icon} />}{' '}
                <span>{data.label}</span>
							{data.checkbox ? <input type="checkbox" className="float-right" /> : null}
            </DropdownItem>
          ))}
          {/* <DropdownItem onClick={this.signOut}>Sign Out</DropdownItem> */}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
