export const authType = {
    getUserLoginDetails: 'GET_USER_LOGIN_DETAILS',
}

export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const UPDATE_NEW_WORK_REQUEST = 'UPDATE_NEW_WORK_REQUEST';
export const UPDATE_CLIENT_INVITATIONS = 'UPDATE_CLIENT_INVITATIONS';
export const UPDATE_CONNECTED_CLIENT = 'UPDATE_CONNECTED_CLIENT';
export const UPDATE_IMPORT_CLIENT = 'UPDATE_IMPORT_CLIENT';
export const UPDATE_IMPORT_CLIENT_BILLRATE = 'UPDATE_IMPORT_CLIENT_BILLRATE'
export const UPDATE_CONNECTED_STAFF = 'UPDATE_CONNECTED_STAFF';
export const UPDATE_CONNECTED_STAFF_PAYRATE = 'UPDATE_CONNECTED_STAFF_PAYRATE';
export const UPDATE_INVOICE_DATA = 'UPDATE_INVOICE_DATA';
export const DELETE_INVOICE_DATA = 'DELETE_INVOICE_DATA';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';

export const START_TOUR_GUIDE = 'START_TOUR_GUIDE';