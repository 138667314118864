
export const workScheduleListSteps = [
    {
        target:'#add-work-request-btn',
        content:'Click here to create work request',
        placement:'auto',
        disableBeacon: true,
        // spotlightClicks: true,
    },
    {
        target:'#work-schedule-client-filter',
        content:'Click here to filter work records by client',
        disableScrolling: true,
        // spotlightClicks: true,
    },
    {
        target:'#work-schedule-job-category-filter',
        content:'Here you can filter work records by job category',
        disableScrolling: true,
        // spotlightClicks: true,
    },
    {
        target:'#work-schedule-status-filter',
        content:'Filter work records by current status',
        disableScrolling: true,
    },
    {
        target:'#work-schedule-period-filter',
        content:'Filter work records by period. like DAY, WEEK & MONTH', 
        disableScrolling: true,
    },
    {
        target:'.list_img',
        content:'Here you can switch between List & Calendar view.', 
        disableScrolling: true,
        spotlightPadding:5, 
    },
    {
        target:'.calender_img',
        content:'Switch to Calendar view by clicking here.', 
        disableScrolling: true,
        spotlightPadding:5,
    },
    {
        target:'.refreshIcon',
        content:'Click here to reload all work requests.', 
        disableScrolling: true,
    },
    {
        target:'#work-list-date-slider',
        content:'View work records based on selected period', 
        disableScrolling: true,
        spotlightPadding:5,
    },
    {
        target:'.table > tbody > tr:nth-child(1)',
        content:'When a work request is created, it will be presented here with some basic information.', 
        disableScrolling: true,
    },
    {
        target:'#work-request-actions',
        content:'Quick action buttons to control the work request', 
        disableScrolling: true,
        spotlightPadding:10,
    },
]

export const workScheduleCalendarSteps = [
    {
        target:'.time-period',
        content:'Click here to view work request details  ',
        disableBeacon: true,
        disableScrolling: true,
    },
]




export const addWorkRequestSteps = [
    {
        target:'#work-schedule-add-work-request',
        content:'Select your client to create a new work request',
        disableBeacon: true,
        placement: 'top'
    },
]