import React, { useState, useEffect } from 'react'
import search  from 'assets/svg/search.svg'
import "./style.scss"

const emptyFunction = (val) => {

}

export const SearchBox = ({ searchFunction=emptyFunction, placeholder, id, classNames, formClassNames, onChange=emptyFunction, instantSearch }) => {
    
    const [forSearch, setForSearch] = useState("");

    // Only triggered if there's instantSearch is true (used only for frontend filtering)
    useEffect(() => {
        if(instantSearch) {
            searchFunction(forSearch);
        }
      }, [forSearch]);

    return (
        <div className=" align-items-start d-flex justify-content-between mb-3" id={id? id: ""}>
            <div className={formClassNames ? formClassNames : ""}>
            <form className={formClassNames?"input-group search-input-group " +formClassNames:"input-group search-input-group " }
                    onSubmit={(e) => {
                    e.preventDefault();
                    searchFunction(forSearch);
                    }}
                    >
                    <input
                        type="search"
                        id="searchValue"
                        name="searchValue"
                        value={forSearch}
                        onChange={e=>{setForSearch(e.target.value); onChange(e.target.value)}}
                        className={classNames?"form-control border-right-0 searchInput "+classNames:"form-control border-right-0 searchInput"}
                        placeholder={placeholder?placeholder:"Search"}
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append " id='searchdiv'>
                        <span
                        className="input-group-text bg-white border-left-0"
                        id="basic-addon2"
                        >
                        <button
                            type="submit"
                            className="hidden-button searchicon"
                        >
                            <img className="mailIcon" src={search} alt="Logo" />
                        </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}
