import React from "react";
import "./style.scss";

export default function DetailsItemWithImg({ label, value,image,hoverValue, align = "align-items-start" }) {
	return (
		<div className={`row ${align}`} id="imageLabel">
			<p className="col-6 text-muted m-0 mb-2 h3-m">{label}
			<span className="location">
            <img className=" pl-4 hovernotesicon"src={image} />
			<div className={hoverValue?"location-info pl-2 pr-2 fs-13":""}>
						{hoverValue?hoverValue:""}			
			</div>
			</span>
			</p>
		</div>
	);
}