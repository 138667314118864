import axios from "axios"

import { history, store } from "../service/helpers"
import { getUserLoginCount, logOut } from "action/AuthAct";

//var feURL = 'http://staffiqdev.qutrix.io';
//var feURL = 'http://staffatom.com';
var feURL = process.env.REACT_APP_FEURL? process.env.REACT_APP_FEURL : 'http://staffiqdev.qutrix.io';
var hostMode = process.env.REACT_APP_HOSTMODE? process.env.REACT_APP_HOSTMODE : 'LOCAL';

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
  }
});
export const getClassNameForBackground = (
  status
)=>{

  var tempBgOfSelectedStatus = "";
  if(status=== 0) tempBgOfSelectedStatus = "wr-cancel";
  else if(status=== 1) tempBgOfSelectedStatus = "wr-newRequest";
  else if(status=== 2) tempBgOfSelectedStatus = "wr-yetAssign";
  else if(status=== 2.1) tempBgOfSelectedStatus = "wr-viewStaffResponse"
  else if(status=== 3) tempBgOfSelectedStatus = "wr-yetStart";
  else if(status=== 4) tempBgOfSelectedStatus = "wr-inProgress";
  else if(status=== 5) tempBgOfSelectedStatus = "wr-completedByStaff";
  else if(status=== 6) tempBgOfSelectedStatus = "wr-completedByClient";
  else if(status=== 7) tempBgOfSelectedStatus = "wr-completedByAgency";
  else if(status=== 8) tempBgOfSelectedStatus = "wr-invoiceGenerated";
  else if(status=== 9) tempBgOfSelectedStatus = "wr-invoicePaid";
  else if(status=== 10) tempBgOfSelectedStatus = "wr-notFilled";

  return tempBgOfSelectedStatus;
}

export const getClassNameForStaffBackground = (
  staffStatus
)=>{

  var tempBgOfSelectedStaffStatus = "";
  if(staffStatus === 0) tempBgOfSelectedStaffStatus = "st-cancel";
  else if(staffStatus === 1) tempBgOfSelectedStaffStatus = "st-yetAccept";
  else if(staffStatus=== 2) tempBgOfSelectedStaffStatus = "st-pendingAgencyApproval";
  else if(staffStatus=== 2.1) tempBgOfSelectedStaffStatus = "st-pendingAgencyApproval2";
  else if(staffStatus=== 3) tempBgOfSelectedStaffStatus = "st-pendingClientApproval";
  else if(staffStatus=== 4) tempBgOfSelectedStaffStatus = "st-pendingClockIn";
  else if(staffStatus=== 5) tempBgOfSelectedStaffStatus = "st-pendingClientCompletion";
  else if(staffStatus=== 6) tempBgOfSelectedStaffStatus = "st-phase2";
  else if(staffStatus=== 7) tempBgOfSelectedStaffStatus = "st-phase2";
  else if(staffStatus=== 8) tempBgOfSelectedStaffStatus = "st-phase2";

  return tempBgOfSelectedStaffStatus;
}

export const getClassNameForLeftBorder = (
  status
)=>{
  var tempBgOfSelectedStatus = "";
  if(status=== 0) tempBgOfSelectedStatus = "wr-cancel-left-border";
  else if(status=== 1) tempBgOfSelectedStatus = "wr-newRequest-left-border";
  else if(status=== 2) tempBgOfSelectedStatus = "wr-yetAssign-left-border";
  else if(status=== 2.1) tempBgOfSelectedStatus = "wr-viewStaffResponse-left-border";
  else if(status=== 3) tempBgOfSelectedStatus = "wr-yetStart-left-border";
  else if(status=== 4) tempBgOfSelectedStatus = "wr-inProgress-left-border";
  else if(status=== 5) tempBgOfSelectedStatus = "wr-completedByStaff-left-border";
  else if(status=== 6)tempBgOfSelectedStatus = "wr-completedByClient-left-border";
  else if(status=== 7) tempBgOfSelectedStatus = "wr-completedByAgency-left-border";
  else if(status=== 8) tempBgOfSelectedStatus = "wr-invoiceGenerated-left-border";
  else if(status=== 9) tempBgOfSelectedStatus = "wr-invoicePaid-left-border";
  else if(status=== 10) tempBgOfSelectedStatus = "wr-notFilled-left-border";
  
  return tempBgOfSelectedStatus;
}

export const logout = async(value) => {
  if(value){
    let auditId = localStorage.getItem('auditId')
    await store.dispatch(logOut({auditId:auditId ? auditId : null}))
    .then(()=>{
      localStorage.clear()
      let redirectURL = '';
      if ( hostMode == 'LIVE' ) {
        redirectURL = (feURL) + '/auth/login';
      } else {
        redirectURL = (feURL) + ':2238/auth/login';
      }
      window.location.assign(redirectURL);
    })
    .catch((err)=>{
      logout()
    })
    return;
  }
  localStorage.clear()
  let redirectURL = '';
  if ( hostMode == 'LIVE' ) {
    redirectURL = (feURL) + '/auth/login';
  } else {
    redirectURL = (feURL) + ':2238/auth/login';
  }
  window.location.assign(redirectURL);
}
export const coordinates = () => {
  const LONGITUDE = 13.11212;
  const LATITUDE = 80.121212;
  return {LATITUDE, LONGITUDE};
};




export const planFeatures = [
  {
    title : 'Auto-Smart Invoice',
    value : 'Invoicing',
    message:'Streamline your billing process with professional invoices. Generate, manage, and deliver invoices effortlessly. Ensure accuracy, get paid faster, and project a professional image to clients. Sign up now and simplify your invoicing experience.'
  },
  {
    title : 'Timesheet Management',
    value : 'Timesheet Management',
    message:'Effortlessly track, manage, and analyze your teams time. Streamline project progress, enhance productivity, and make informed decisions. Sign up now for efficient time management'
  },
  {
    title : 'Client Management',
    value : 'Client portal ',
    message:'Simplify client interactions. Access documents, communicate securely, and track project progress in one central hub. Enhance client satisfaction and streamline collaboration. Sign up now for a seamless client experience.'
  },
  {
    title : 'Staff App',
    value : 'Staff App',
    message:'Empower your team with a user-friendly app. Streamline communication, simplify task management, and boost productivity. Experience seamless collaboration and efficiency. Sign up now and transform your workforce.'
  },
]


export const maskEmailAddress=(emailAddress) =>{
  function mask(str) {
      var strLen = str.length;
      if (strLen > 4) {
          return str.substr(0, 1) + str.substr(1, strLen - 1).replace(/\w/g, '*') + str.substr(-1,1);
      } 
      return str.replace(/\w/g, '*');
  }
  return emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, function (m, p1, p2, p3) {      
      return mask(p1) + '@' + mask(p2) + p3;
  });
}


export const checkUserLoginCount = async() => {
    let userMail = JSON.parse(localStorage.getItem('userData')).email
    if(userMail)
    await store.dispatch(getUserLoginCount({userMail:encodeURIComponent(userMail)}))
    .then((data)=>{})
    .catch(err =>{})
}

export const freeTrialPlan ={
  "id": "#1234567890123",
  "planCategory": "Free Trial",
  "planCode": "ewre",
  "planName": "Free Trial Plan",
  "planType": 0,
  "features": [
      {
          "value": "Invoicing",
          "status": true
      },
      {
        "value": "Timesheet Management",
        "status": true
     },
     {
      "value": "Editing profile details",
      "status": true
     },
     {
      "value": "Client portal",
      "status": true
     },
     {
      "value": "Staff App",
      "status": true
     },
  ],
  "billingType": 1,
  "status": 1,
  "subscriberType": 3,
  "baseUserLimit": 5,
  "baseAmount": 0,
  "currency": null,
  "symbol": null,
  "pricingModel": 1,
}

export const planFeatureLabels = {
  "Invoicing":"Auto-Smart Invoice",
  "Timesheet Management":"Timesheet Management",
  "Client portal ": "Client Management",
  "Staff App":"Staff App",
  "Staff":"Staff Management",
  "Reports":"Insightful Dashboard",
  "Audit Logs":"Audit Logs",
  "Time Off":"Time Off"
}

export const standardPlanFeatures = [
  'Work Shift Management',
  'Geo-Enabled Assignment',
  'Overtime Regulations',
  'Agency Skills Listing',
  'Recurring Shift Handling',
]

export const standaradPlanFeaturesDescription = [
  {
    title : 'Work Shift Management',
    value : 'Work Shift Management',
    message:'Efficiently organize employee schedules to optimize workforce productivity and ensure smooth operational coverage'
  },
  {
    title : 'Geo-Enabled Assignment',
    value : 'Geo-Enabled Assignment',
    message:'Effortless Staff Pairing by Location. Seamlessly Schedule the Nearest Staff to Meet Client Needs.'
  },
  {
    title : 'Overtime Regulations',
    value : 'Overtime Regulations',
    message:'Our built in overtime regulation feature helps you to precisely track, manage and calculate overtime hours for accurate & fair compensation.'
  },
  {
    title : 'Agency Skills Listing',
    value : 'Agency Skills Listing',
    message:'Categorization of diverse job roles within an agency to facilitate organizational structure and efficient workflow.'
  },
  {
    title : 'Recurring Shift Handling',
    value : 'Recurring Shift Handling',
    message:'Systematic management of recurring work schedules to ensure consistency and efficiency in operational staffing'
  },
]

export function validateGSTIN(GSTIN) {
	const GSTIN_REGEX = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z][1-9A-Za-z]Z[0-9a-zA-Z]$/;
	const CHECKSUM_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  
	GSTIN = GSTIN.trim();
  
	if (!GSTIN) {
	  return "GSTIN is empty";
	}
  
	if (!GSTIN.match(GSTIN_REGEX)) {
	  return "GSTIN doesn't match the pattern";
	}
  
	const checkSum = generateCheckSum(GSTIN.slice(0, -1), CHECKSUM_CHARS);
	const isValid = GSTIN[GSTIN.length - 1] === checkSum;
  
	if (isValid) {
	  return `GSTIN is valid`;
	} else {
	  return `GSTIN is not valid`;
	}
  }

  function generateCheckSum(GSTIN, CHECKSUM_CHARS) {
    if (!GSTIN) {
      throw new Error("GSTIN supplied for checkdigit calculation is null");
    }
    
    const Mod_ = CHECKSUM_CHARS.length;
    const cpChars = CHECKSUM_CHARS.split('');
    
    let sum = 0;
    let factor = 2;
    
    for (let i = GSTIN.length - 1; i >= 0; i--) {
      const codePoint = cpChars.indexOf(GSTIN[i]);
      const digit = factor * codePoint;
      factor = factor === 2 ? 1 : 2;
      sum += Math.floor(digit / Mod_) + (digit % Mod_);
    }
    
    const checkCodePoint = (Mod_ - (sum % Mod_)) % Mod_;
    return cpChars[checkCodePoint];
    }