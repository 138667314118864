import React, { useEffect, useState } from 'react'
import './style.scss'
import loadingIcon from "assets/images/loading.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFileFromMDB } from 'action/fileHandler';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import dummyImage from "assets/images/dummy-image.png"

const DisplayImage = React.memo(({getFileFromMDB,  imageId, entity, clientId, onSuccess, rounded, navbar, showDummyImage}) => {
  const [onlineImage, setOnlineImage] = useState('')
  const [loading, setLoading] = useState(false)

      const handleImageDownload = async (logo) => {
        setLoading(true)
        let imageData;
        let fileId = logo.split('/')[1]
        await getFileFromMDB({entity: entity ? entity:'', clientId: clientId ? clientId : ''},fileId)
          .then((response)=>{
            imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
            setLoading(false)
            setOnlineImage(imageData.toString())
            if(onSuccess){
              onSuccess()
            }
            return imageData
          })
          .catch((error) => {
            setLoading(false);
            setOnlineImage(null);
            console.log('Error fetching image:', error);
          });
      };

      useEffect(()=>{
        if(imageId){
          handleImageDownload(imageId)
        }else{
          setOnlineImage(null)
        }
      },[imageId])

  return (<>
    {onlineImage !== null ?
      <img className={`${navbar && 'profile-nav rounded-circle mb-3'} ${rounded && 'profile-icon rounded-circle'} ${loading ? 'loading-animation': 'image-view'}`}
         src={loading ? loadingIcon : onlineImage} /> 
           :
       <div className="w-100 h-100 d-flex justify-content-center align-items-center">
           {!showDummyImage ?
            <label className='font-weight-bold mb-0'>N/A</label> 
            : 
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Image not found</Tooltip>}
            >
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                style={{
                  height: entity === "client" && "40px",
                  width: entity === "client" && "55px",
                }}
                src={dummyImage} alt="Dummy Profile" />
              </div>
            </OverlayTrigger>
          } 
        </div> }
</>)
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getFileFromMDB
      },
      dispatch
    );
  };

  export default connect('', mapDispatchToProps)(DisplayImage)

