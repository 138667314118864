import React from 'react';
import { NormalModal } from "component/common"
import "./style.scss"
import { NormalButton } from 'component/common';
import profile from '../../../assets/svg/Ellipse.png';

export default function UserDetail() {
    return (
        <NormalModal
        isOpen={false}
        size="lg"
        renderHeader={e => <h6 className="font-weight-bold ">Co-Admin details</h6>}
        bodyClass='user-detail-box'
        headerClass="border-0 pt-4 px-4"
    >
        <>
            <div className="d-flex align-items-start">
                <div className="d-flex align-items-center">
                    <img className="rounded-circle" src={profile} alt="profile-img" />
                    <div className="ml-4">
                        <p className="fs-24 mb-0 font-weight-bold">William Shatner</p>
                        <p className="fs-16 mt-1">Mumbai, India</p>
                    </div>
                </div>
                <NormalButton
                    onClick={e=>{}}
                    id="edit"
                    label="Edit"
                    outline={false}
                    // loader={isFormLoder}
                    className="ml-4 btn px-4 fs-14 bg-ash-gray detail-edit"
                />
            </div>
            <div className="my-5">
                <div className=" row align-items-center">
                    <div className="col-4">
                        <p className="font-weight-bold">Email</p>
                    </div>
                    <div className="col">
                        <p>contact@apple.com</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-4">
                        <p className="font-weight-bold">Phone</p>
                    </div>
                    <div className="col">
                        <p>+32 7654 1243</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-4">
                        <p className="font-weight-bold">Current Address</p>
                    </div>
                    <div className="col">
                        <p>I Apple park way in cuppertino</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-4">
                        <p className="font-weight-bold">Remarks</p>
                    </div>
                    <div className="col">
                        <p>Lorem Ipsum is simply dummy text</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-4">
                        <p className="font-weight-bold">Permission</p>
                    </div>
                    <div className="col">
                        <p>Subscription, Audit Log</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-4">
                        <p className="font-weight-bold">Status</p>
                    </div>
                    <div className="col">
                        <p>A-Active</p>
                    </div>
                </div>
            </div>
        </>

    </NormalModal>

    )
}


