import React, { Component, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './sidebar.scss';
import logo from "../../../assets/images/logo.png"
import atom_blue from 'assets/svg/atom_blue.svg';
import atom_white from 'assets/svg/atom_white.svg';
import clockSolid from 'assets/svg/clock solid.svg';
import clockTransparent from 'assets/svg/clock transparent.svg';
import customerSolid from 'assets/svg/customer solid.svg';
import customerTransparent from 'assets/svg/customer transparent.svg';
import invoiceSolid from 'assets/svg/stack_blue.svg';
import invoiceTransparent from 'assets/svg/stack_white.svg';
import periodic_table_blue from 'assets/svg/periodic_table_blue.svg';
import periodic_table_white from 'assets/svg/periodic_table_white.svg';
import flightSolid from 'assets/svg/flight solid.svg';
import flightSolidWarning from 'assets/svg/timeOffSolidWarning.svg';
import flightTransparent from 'assets/svg/flight transparent.svg';
import flightTransparentWarning from 'assets/svg/timeOffTransparentWarning.svg';
import statSolid from 'assets/svg/stat solid.svg';
import statTransparent from 'assets/svg/stat transparent.svg';
import microscope_blue from 'assets/svg/microscope_blue.svg';
import microscope_white from 'assets/svg/microscope_white.svg';
import settingsSolid from 'assets/svg/settings solid.svg';
import settingsTransparent from 'assets/svg/settings transparent.svg';
import {history, store} from '../../../service/helpers';
import settingsWithWarning from 'assets/images/settingsWithWarning.png';
import settingsWithWarningWhite from 'assets/images/settingsWithWarningWhite.png';
import subscriptionWhiteIcon from 'assets/svg/Subscription-white.svg'
import subscriptionSolidIcon from 'assets/svg/Subscription-solid.svg'
import subscriptionSolidWarningIcon from 'assets/svg/subscription-solid-warning.svg'
import subscriptionWhiteWarningIcon from 'assets/svg/subscription-white-warning.svg'
import { startTourGuide } from 'reducer/tourGuide';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const initialNavLinks = [
  {
    to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    classNames:"",
  },
  {
    to: '/admin/workSchedule',
    label: 'Work Schedule',
    iconName: atom_blue,
    iconTransparent: atom_white,
    classNames:"WSSizePlus",
  },
  {
    to: '/admin/timeSheet',
    label: 'Time Sheet Review',
    iconName: clockSolid,
    iconTransparent: clockTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/staffManagement',
    label: 'Staff',
    iconName:periodic_table_blue,
    iconTransparent:periodic_table_white,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/clientOnboarding',
    label: 'Clients',
    iconName: customerSolid,
    iconTransparent: customerTransparent,
    classNames:"sideBarIcon",
  },

  {
    to: '/admin/invoice',
    label: 'Invoices',
    iconName: invoiceSolid,
    iconTransparent: invoiceTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/timeOff',
    label: 'Time Off',
    iconName:flightSolid,
    iconTransparent:flightTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/report',
    label: 'Reports',
    iconName:statSolid,
    iconTransparent:statTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/auditLogs',
    label: 'Audit Logs',
    iconName: microscope_blue,
    iconTransparent: microscope_white,
    classNames: "ALsizePlus",
  },
  {
    to: '/admin/subscription',
    label: 'Subscription',
    iconName: subscriptionSolidIcon ,
    iconTransparent: subscriptionWhiteIcon,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/settings',
    label: 'Settings',
    iconName: settingsSolid ,
    iconTransparent: settingsTransparent,
    classNames:"sideBarIcon",
  },
  
  {
    to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    classNames:"",
  },
];


const Sidebar = (props) => {
  let {permissionList} = props;
  const [navLinks, setNavLinks] = useState(initialNavLinks);
  const [prev, setPrev] = useState('');
  const [next, setNext] = useState('');
  const [active, setActive] = useState('');
  
  let dispatch = useDispatch()
  const activeTab = (index) => {
    let{location}=history;
    let matchData=location.pathname.split('/');
    if(matchData[matchData.length+1]!='notification'){
      setNext(index+1);
      setPrev(index-1);
      setActive(index);
    }
  }
let hasSettingsWarning = useSelector((state)=> state.navbar.hasSettingsWarning);
let hasTimeOffWarning = useSelector((state)=> state.navbar.hasTimeOffWarning);
let hasSubscriptionWarning = useSelector((state)=> state.navbar.hasSubscriptionWarning);

  useEffect(()=>{
    let{location}=history;
    // let {permissionList} = props;
    let updatedNavLinks = navLinks.filter(linkObj=>{
      let isIndex = permissionList?.findIndex((ar)=>ar.label === linkObj.label);
      if(isIndex!==-1 || linkObj.label=="") return true;
      else return false;
    })
    setNavLinks(updatedNavLinks);
    var haveActivePagePermission = false;
    var temp = '';
    updatedNavLinks.forEach((item,index)=>{
      if( (location.pathname.toLocaleLowerCase().search( item.to.toLocaleLowerCase())!=-1) || (item.to  === location.pathname) ){
        activeTab(index);
        haveActivePagePermission = true;
        temp = location.pathname;
        return;
      }
    })
    // if(!haveActivePagePermission && !!location.pathname && updatedNavLinks.length>2){
    //    window.location.href = updatedNavLinks[1].to;
    // }
  },[history.location, hasSettingsWarning, permissionList]);


  let userStatus = JSON.parse(localStorage.getItem('userData')).status
  useEffect(()=>{
    if(userStatus ===3)
    setNavLinks(prevLinks=>prevLinks.filter((item)=>item.to !== '/admin/settings'))
  },[userStatus])

  useEffect(()=>{
    setNavLinks(prevLinks=>prevLinks.map(link=>{
      if(link.label==="Settings"){ 
        return {
          ...link, 
          iconName: store.getState().navbar.hasSettingsWarning? settingsWithWarning : settingsSolid ,
          iconTransparent: store.getState().navbar.hasSettingsWarning? settingsWithWarningWhite : settingsTransparent,
        };
      }else if(link.label==="Time Off"){
        return {
          ...link, 
          iconName: !hasTimeOffWarning? flightSolid : flightSolidWarning,
          iconTransparent: !hasTimeOffWarning? flightTransparent : flightTransparentWarning,
        };
        
      }else if(link.label==="Subscription"){
        return {
          ...link, 
          iconName: hasSubscriptionWarning? subscriptionSolidWarningIcon : subscriptionSolidIcon,
          iconTransparent: hasSubscriptionWarning? subscriptionWhiteWarningIcon : subscriptionWhiteIcon,
        };
        
      }else return link;
    }));
  },[store.getState().navbar.hasSettingsWarning, hasTimeOffWarning, hasSubscriptionWarning]);

const handleTour = () =>{
  dispatch(startTourGuide({run:false, stepIndex:0}))
}
  let { menuOpen, handleSidenav } = props;
  let {location} = history
  return (
    <>
      <div className={`left-menu bg-back-green  ${menuOpen ? 'open' : 'd-print-none'}`}>
        <div className="sidebar-logo d-print-none">
          {/* <img src={logo} alt="logo" /> */}
        </div>
        <PerfectScrollbar>
          <ul>
            {
                navLinks.map(({ to, label, iconName,iconTransparent, classNames }, index) => (
                <li key={index} onClick={handleTour}>
                  {/*<NavLink onClick={(to!='/something'?()=>this.activeTab(index):'')} to={to} className={`nav-link  ${to=='/something'?'disabled':''} ${prev==index?'prev-li':''} ${next==index?'next-li':''}`}>*/}
                  <NavLink onClick={(to!='/something'?()=>activeTab(index):'')} to={to} tabIndex={to=='/something'?'-1':''} className={`nav-link  ${to=='/something'?'disabled':''} ${prev==index&&location.pathname!=='/admin/notification'?'prev-li':''} ${next==index&&location.pathname!=='/admin/notification'?'next-li':''}`}>
                    <div className="sidebar-menu">
                      <div className="menu-icon text-left">
                        {/*active==index?<img src={iconName} className=''/>:<img src={iconTransparent} className=''/>*/}
                        {
                          !menuOpen ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip id={`tooltip-${index}`}>{label}</Tooltip>}
                            >
                              <img
                                src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                                className={classNames}
                              />
                            </OverlayTrigger>
                          ) : (
                            <img
                              src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                              className={classNames}
                            />
                          )
                        }
                        {/* {active==index&&location.pathname!=='/admin/notification'?<img src={iconName} className={classNames}/>:<img src={iconTransparent} className={classNames}/>} */}
                      </div>
                      {menuOpen && <span className="sidebar-menu-desc fs-16">{label}</span>}
                      {/* {menuOpen?<span className="sidebar-menu-desc fs-16">{label}</span>:<div className='hoverTitle'><span>{label}</span></div>} */}
                    </div>
                  </NavLink>
                </li>
                // loadTab(to, label, iconName,iconTransparent,index)
              ))
              }
          </ul>
        </PerfectScrollbar>
      </div>
      <div className={`sidemenu-overlay ${menuOpen ? 'open' : ''}`} onClick={() => handleSidenav()}></div>
    </>
  );

}

export default Sidebar;


// export default class Sidebar extends Component {
  
//   constructor(props) {
//     super(props);
//     this.activeTab = this.activeTab.bind(this);
    
//     this.state = {
//       navLinks: [
//         {
//           to: '/something',
//           label: '',
//           iconName: '',
//           iconTransparent: '',
//           classNames:"",
//         },
//         {
//           to: '/admin/workSchedule',
//           label: 'Work Schedule',
//           iconName: atom_blue,
//           iconTransparent: atom_white,
//           classNames:"WSSizePlus",
//         },
//         {
//           to: '/admin/timeSheet',
//           label: 'Time Sheet Review',
//           iconName: clockSolid,
//           iconTransparent: clockTransparent,
//           classNames:"sideBarIcon",
//         },
//         {
//           to: '/admin/staffManagement',
//           label: 'Staff',
//           iconName:periodic_table_blue,
//           iconTransparent:periodic_table_white,
//           classNames:"sideBarIcon",
//         },
//         {
//           to: '/admin/clientOnboarding',
//           label: 'Clients',
//           iconName: customerSolid,
//           iconTransparent: customerTransparent,
//           classNames:"sideBarIcon",
//         },
      
//         {
//           to: '/admin/invoice',
//           label: 'Invoices',
//           iconName: invoiceSolid,
//           iconTransparent: invoiceTransparent,
//           classNames:"sideBarIcon",
//         },
//         {
//           to: '/admin/timeOff',
//           label: 'Time Off',
//           iconName:flightSolid,
//           iconTransparent:flightTransparent,
//           classNames:"sideBarIcon",
//         },
//         {
//           to: '/admin/report',
//           label: 'Reports',
//           iconName:statSolid,
//           iconTransparent:statTransparent,
//           classNames:"sideBarIcon",
//         },
//         {
//           to: '/admin/auditLogs',
//           label: 'Audit Logs',
//           iconName: microscope_blue,
//           iconTransparent: microscope_white,
//           classNames: "ALsizePlus",
//         },
//         {
//           to: '/admin/settings',
//           label: 'Settings',
//           iconName: settingsSolid ,
//           iconTransparent: settingsTransparent,
//           classNames:"sideBarIcon",
//         },
        
//         {
//           to: '/something',
//           label: '',
//           iconName: '',
//           iconTransparent: '',
//           classNames:"",
//         },
      
      
//       ],
//       prev: '',
//       next: '',
//       active: ''
//     };
//   }

//   activeTab = (index) => {   
//     console.log(index,"INDEX")
//     let{location}=history;
  
//     // console.log("histroy",location.pathname.split('/'))
//     let matchData=location.pathname.split('/')
//     if(matchData[matchData.length+1]!='notification'){
//       this.setState({
//         next: index+1,
//         prev:index-1,
//         active:index
//       },()=>{
//         console.log(this.state.prev,this.state.next,"PREV NEXT")
//       })
//     }
//   }

 

//   componentDidMount(){
//     let{location}=history;
//     let {navLinks}=this.state;
//     let {permissionList} = this.props;
//     this.setState({...this.state, navLinks: navLinks.filter(linkObj=>{
//       let isIndex = permissionList.findIndex((ar)=>ar.label === linkObj.label);
//       if(isIndex!==-1 || linkObj.label=="") return true;
//       else return false;
//     })})
//     navLinks.forEach((item,index)=>{
//       if( (location.pathname.search( item.to)!=-1) || (item.to  === location.pathname) ){
//         this.setState({
//           next: index+1,
//           prev:index-1,
//           active:index
//          });
//       }
//     })
//   }

//   render() {
//     let { navLinks,prev,next,active } = this.state;
//     let { menuOpen, handleSidenav } = this.props;
//     let {location}=history
//     return (
//       <>
//         <div className={`left-menu bg-back-green  ${menuOpen ? 'open' : ''}`}>
//           <div className="sidebar-logo">
//             {/* <img src={logo} alt="logo" /> */}
//           </div>
//           <PerfectScrollbar>
//             <ul>
//               {
//                  navLinks.map(({ to, label, iconName,iconTransparent, classNames }, index) => (
//                   <li key={index}>
//                     {/*<NavLink onClick={(to!='/something'?()=>this.activeTab(index):'')} to={to} className={`nav-link  ${to=='/something'?'disabled':''} ${prev==index?'prev-li':''} ${next==index?'next-li':''}`}>*/}
//                     <NavLink onClick={(to!='/something'?()=>this.activeTab(index):'')} to={to} tabIndex={to=='/something'?'-1':''} className={`nav-link  ${to=='/something'?'disabled':''} ${prev==index&&location.pathname!=='/admin/notification'?'prev-li':''} ${next==index&&location.pathname!=='/admin/notification'?'next-li':''}`}>
//                       <div className="sidebar-menu">
//                         <div className="menu-icon text-left">
//                           {/*active==index?<img src={iconName} className=''/>:<img src={iconTransparent} className=''/>*/}
//                           {active==index&&location.pathname!=='/admin/notification'?<img src={iconName} className={classNames}/>:<img src={iconTransparent} className={classNames}/>}
//                         </div>
//                         {menuOpen?<span className="sidebar-menu-desc fs-16">{label}</span>:<div className='hoverTitle'><span>{label}</span></div>}
//                       </div>
//                     </NavLink>
//                   </li>
//                   // loadTab(to, label, iconName,iconTransparent,index)
//                 ))
//                }
//             </ul>
//           </PerfectScrollbar>
//         </div>
//         <div className={`sidemenu-overlay ${menuOpen ? 'open' : ''}`} onClick={() => handleSidenav()}></div>
//       </>
//     );
//   }
// }

// const initialNavLinks = [
//   {
//     to: '/something',
//     label: '',
//     iconName: '',
//     iconTransparent: ''
//   },
//   {
//     to: '/admin/workSchedule',
//     label: 'Work Schedule',
//     iconName: atom_blue,
//     iconTransparent: atom_white
//   },
//   {
//     to: '/admin/timeSheet',
//     label: 'Time Sheet Review',
//     iconName: clockSolid,
//     iconTransparent: clockTransparent
//   },
//   {
//     to: '/admin/staffManagement',
//     label: 'Staff',
//     iconName:periodic_table_blue,
//     iconTransparent:periodic_table_white
//   },
//   {
//     to: '/admin/clientOnboarding',
//     label: 'Clients',
//     iconName: customerSolid,
//     iconTransparent: customerTransparent
//   },

//   {
//     to: '/admin/invoice',
//     label: 'Invoices',
//     iconName: invoiceSolid,
//     iconTransparent: invoiceTransparent
//   },
//   {
//     to: '/admin/timeOff',
//     label: 'Time Off',
//     iconName:flightSolid,
//     iconTransparent:flightTransparent
//   },
//   {
//     to: '/admin/report',
//     label: 'Reports',
//     iconName:statSolid,
//     iconTransparent:statTransparent
//   },
//   {
//     to: '/admin/auditLogs',
//     label: 'Audit Logs',
//     iconName: microscope_blue,
//     iconTransparent: microscope_white
//   },
//   {
//     to: '/admin/settings',
//     label: 'Settings',
//     iconName: settingsSolid ,
//     iconTransparent: settingsTransparent,
//   },
  
//   {
//     to: '/something',
//     label: '',
//     iconName: '',
//     iconTransparent: ''
//   },
// ];

// export default function Sidebar(props){
//   const [navLinks, setNavLinks] = useState(initialNavLinks);

//   const [prev, setPrev] = useState('');
//   const [next, setNext] = useState('');
//   const [active, setActive] = useState('');
  
//   const activeTab=(index)=>{
//     setActive(index);
//     setPrev(index-1);
//     setNext(index+1);
//   }

//   // useEffect(()=>{
//   //   setNavLinks(prevLinks=>prevLinks.map(link=>{
//   //     if(link.label==="Settings"){ 
//   //       return {
//   //         ...link, 
//   //         iconName: store.getState().navbar.hasSettingsWarning? settingsWithWarning : settingsSolid ,
//   //         iconTransparent: store.getState().navbar.hasSettingsWarning? settingsWithWarningWhite : settingsTransparent,
//   //       };
//   //     }else return link;
//   //   }));
//   // },[store.getState().navbar.hasSettingsWarning])

//   useEffect(()=>{

//     let {location} = history;
//     let tempNavLinks = navLinks;
//     let {permissionList} = props;
//     let navArray = [];
//     navArray.push(tempNavLinks[0]);
//     tempNavLinks.forEach((item,index)=>{
//       let isIndex = permissionList.findIndex((ar)=>ar.label === item.label)
//       if(isIndex !== -1){
  
//         // if( item.to  === location.pathname ){
//         //   acitveTab(index);
//         // }
//         navArray.push(item);
//       }
//     });
    
//     navArray.push(navLinks[navLinks.length-1]);
  
//     navArray.forEach((item,index)=>{
//         if( item.to  === location.pathname || (location.pathname.toUpperCase().search( item.to.toUpperCase())!=-1)){
//           activeTab(index);
//         }
//     });
  
//     setNavLinks(navArray);
//   },[]);

//   let { menuOpen, handleSidenav } = props;
//   let { location } = history;
//   return (
//     <>
//       <div className={`left-menu bg-site-primary  ${menuOpen ? 'open' : ''}`}>
//         <div className="sidebar-logo">
//           {/* <img src={logo} alt="logo" /> */}
//         </div>
//         <PerfectScrollbar>
//         <ul>
//               {
//                 navLinks.map(({ to, label, iconName,iconTransparent }, index) => (
//                 <li key={index} >
//                   <NavLink onClick={(to!='/something'?()=>activeTab(index):'')} to={to} tabIndex={to=='/something'?'-1':''} className={`nav-link  ${to=='/something'?'disabled':''} ${prev==index&&location.pathname!=='/admin/notification'?'prev-li':''} ${next==index&&location.pathname!=='/admin/notification'?'next-li':''}`}>
//                     <div className="sidebar-menu">
//                       <div className="menu-icon text-left">
//                         {active==index&&location.pathname!=='/admin/notification'?<img src={store.getState().navbar.hasSettingsWarning && to==='/admin/settings'? settingsWithWarning : iconName} className='max-height-26px'/>:<img src={store.getState().navbar.hasSettingsWarning && to==='/admin/settings'? settingsWithWarningWhite : iconTransparent} className='max-height-26px'/>}
//                       </div>
//                       {menuOpen?<span className="sidebar-menu-desc fs-16">{label}</span>:<div className='hoverTitle'><span>{label}</span></div>}
                      
//                     </div>
//                   </NavLink>
//                 </li>
//               ))
//               }
//           </ul>
//           {/* <ul>
//             {navLinks.map(({ to, label, iconName,iconTransparent }, index) => (
//               <li key={index} onClick={(to!='/something'?()=>activeTab(index):'')}>
//                   <NavLink to={to} className={`nav-link  ${to=='/something'?'disabled':''} ${prev == index ? 'prev-li' : ''} ${next == index ? 'next-li' : ''}`}>
//                     <div className="sidebar-menu">
//                       <div className="menu-icon text-left">
//                         {active==index?<img src={iconName} className=''/>:<img src={iconTransparent} className=''/>}
//                       </div>
//                       {menuOpen&&<span className="sidebar-menu-desc fs-16">{label}</span>}
//                     </div>
//                 </NavLink>
//               </li>
//             ))}
//           </ul> */}
//         </PerfectScrollbar>
//       </div>
//       <div className={`sidemenu-overlay ${menuOpen ? 'open' : ''}`} onClick={() => handleSidenav()}></div>
//     </>
//   );
// }
