
const isCompanyNameUpdated = 'isCompanyNameUpdated'
const hasSettingsWarning = 'hasSettingsWarning'
const hasTimeOffWarning = 'hasTimeOffWarning'
const hasSubscriptionWarning = 'hasSubscriptionWarning'

const initialState= {
  isCompanyNameUpdated: false,
  hasSettingsWarning: localStorage.getItem("hasSettingsWarning") ? localStorage.getItem("hasSettingsWarning")==="true" : false,
  hasTimeOffWarning:localStorage.getItem("hasTimeOffWarning") ? localStorage.getItem("hasTimeOffWarning")==="true" : false,
  hasSubscriptionWarning:false,
}

export function changeIsCompanyNameUpdated(){
    return {
        type:isCompanyNameUpdated,
        info:'Change when company name will be updated.'
    }
}
export function changeHasSettingsWarning(isWarning = false){
    return {
        type: hasSettingsWarning,
        payload: isWarning,
        info:'Set the settings warning variable.'
    }
}

export default (state = initialState, action)=>{
    switch(action.type){
        case isCompanyNameUpdated:
            return {
                ...state,
                isCompanyNameUpdated : !state.isCompanyNameUpdated
            }
        case hasSettingsWarning:
            return {
                ...state,
                hasSettingsWarning : action.payload
            }
        case hasTimeOffWarning:
            return {
                ...state,
                hasTimeOffWarning : action.payload
            }
        case hasSubscriptionWarning:
            return {
                ...state,
                hasSubscriptionWarning : action.payload
            }
        default : return state
    }
}