import React,{useEffect, useState} from "react";

import "./RejectAccordianclient.scss"


const RejectAccordian = (props)=>{

    const [show, setShow] = useState(false);
    const [data,setData] = useState(props.data);
    const [open,setOpeninput] = useState(false);
    
    const data1 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Dislikes his/her past history"){
            return true
        }
    })
    

    const data2 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unreliable/Attendance Concerns"){
            return true
        }
    })
    
    const data3 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Communication Problems"){
            return true
        }
    })

    const data4 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "His/Her hiring price is too expensive"){
            return true
        }
    })

    const data0 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Others"){
            return true
        }
    })
   
    useEffect(()=>{
        if(data0?.length>0){
            setOpeninput(true)
           }
           else if(data0?.length==0){
            setOpeninput(false)
           }
    },[data0])
   
    return (
        <>
        <div className={show==true?"outerdiv":""}>
           <div>
            <p className={show==true?"Reject":"notReject"} id={data?"":"notRejected"} onClick={()=> setShow(!show)}>{data?"Rejected by Client  📩":"Yet to approve by Client"}</p>
           </div>
           {show && 

            <div className="setrejectfields">
                <form onSubmit={""}>
                    
                <div className="setinputfields">
                <div className="setclientheadingborder"> <h3 className="clientheader" >Reason(s) selected by Client for rejecting staff:</h3></div>
                 <div className="clientinnderdiv1">
                  <div className="clientinnerdiv">

                    <input className="setinput2" type="checkbox" checked={data1?.length>0?true:false} name="Dislikes his/her past history" value="1" /> <label >Dislikes his/her past Work history </label> 
                    <br></br>
                    <input   className="setinput2" type="checkbox"  checked={data2?.length>0?true:false} name="Unreliable/Attendance Concerns" value="2" /> <label> Unreliable/Attendance Concerns</label> 
                    <br></br>
                    <input  className="setinput2" type="checkbox"  checked={data3?.length>0?true:false} name="Communication Problems" value="3" /> <label> Communication Problems </label> 
                    <br></br>
                    <input   className="setinput2" type="checkbox"  checked={data4?.length>0?true:false} name="His/Her hiring price is too expensive" value="4" /> <label> His/Her hiring price is too expensive </label> 
                    <br></br>
                    <div className="setother">
                    <input  className="setinput" type="checkbox"  checked={data0?.length>0?true:false} name="Others" value="0" />  <label> Others </label>
                    </div>
                { open &&
                <input id="textarea" disabled={data?true:false} className="settextarea1" rows="2" cols="40" placeholder={data?.additionalComments}></input>}
                <div className="tocontact"><input id="contact" className="setcontact" type="checkbox" checked={data?.contactEnabled == true?true:false} /> <label className="setcontactlabel">Please Contact Client to Discuss</label> <br></br></div>
                </div>
                </div>
                <div className="setclienthr"></div>
                </div>
                </form>
            </div>
           
           }
           </div>
           <br></br>
        </>
    )
}

export default RejectAccordian;