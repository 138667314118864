import React, { Component } from "react";
import  Navbar  from "component/common/navbar";
import Sidebar from "component/common/Sidebar";
import {CommonPageLoader, NormalButton } from "component/common"
import {getPermissionMenu,getAllSubscription} from '../action/AuthAct';
import {  getBillingPlans } from 'action/SubscriptionAct';
import NotificationSettings from 'pages/Admin/Notification'
import { logout } from '../service/utilities';
import "assets/scss/layouts/AdminLayout.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import TourGuide from "component/common/TourGuide";
import connectToWebSocket from "service/webSocket";
import { history, store } from "service/helpers";
import moment from "moment";
import { getSettingsById } from "action/SettingsAct";
import { connect } from "react-redux";
import _ from "lodash";
import {updateDefaultBillingRateFrequency} from '../action/workRequest';
import SidebarMobile from "component/common/SideBarMobile";

let paths = {
   'Work Schedule': '/admin/workSchedule',
   'Time Sheet Review': '/admin/timeSheet',
   'Staff': '/admin/staffManagement',
   'Clients': '/admin/clientOnboarding',
   'Invoices': '/admin/invoice',
   'Time Off': '/admin/timeOff',
   'Reports': '/admin/report',
   'Audit Logs': '/admin/auditLogs',
   'Subscription': '/admin/subscription',
   'Settings': '/admin/settings',
 } 


 let featuresList = {
   'Invoicing' : 'Invoices',
   'Timesheet Management' : 'Time Sheet Review',
   'Client portal ' : 'Clients',
   'Staff': 'Staff',
   'Time Off': 'Time Off',
   // 'Editing profile details':'Profile Edit',
   'Staff App':'Staff App',
   'Reports':'Reports',
   'Audit Logs':'Audit Logs'
 }

 class AdminLayoutComponent extends Component {
   state = {
      profilemenu: false,
      menuOpenClass: false,
      authToken: '',
      loading:true,
      permissionList:[],
      isSideBarClicked: false,
      permittedPaths:[], 
      features:[],
      isClosed:true
   };

   fetchAgencyData = async() => {
      let user = JSON.parse(localStorage.getItem('userData'))
      let userType = localStorage.getItem('userType')
      if(userType === '3') {
      await store.dispatch(getSettingsById({id:user.id}))
      .then((data)=>{
        localStorage.setItem('userData', JSON.stringify(data))
        localStorage.setItem('subscriptionPlanId',data.subscriptionPlanId)
        if(moment(data.subscriptionValidity)<moment(new Date())){
          store.dispatch({type:'hasSubscriptionWarning', payload:true});
        }else{
          store.dispatch({type:'hasSubscriptionWarning', payload:false});
        }
      })
      .catch((err)=>{})}
      else {
         await store.dispatch(getSettingsById({id:user.agencyId}))
         .then((res)=>{
         localStorage.setItem('subscriptionPlanId', res.subscriptionPlanId) 
         })
         .catch((err)=>{console.log(err)})
      }
    }

    updateBillingRateFrequency = () => {
      store.dispatch(updateDefaultBillingRateFrequency())
      .then((res)=>{
         // this.setState({loading: false,})
      }).catch((err)=>{
         console.log(err)
      })
    }

   componentDidMount = async () => {
      try{
      await this.fetchAgencyData()
      let authToken = localStorage.getItem('AuthToken')

      if (!authToken) {
         // logout();
      } else {
         this.setState({ authToken });
         this.fetchAllPermissions();
         await this.fetchAllFeatures();
         this.updateBillingRateFrequency();
         connectToWebSocket()
           window.addEventListener('online', ()=>{
          connectToWebSocket()
           })
      } }  
      catch(err){
         console.log(err)
      }


   }

   componentDidUpdate(prevProps,prevState) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
         this.hasPermission(window.location.pathname, this.state.permittedPaths)
      }
      if(!_.isEqual(prevProps.permissionsData, this.props.permissionsData) || (!_.isEqual(prevState.features,this.state.features))){
         this.updatePermissions(this.props.permissionsData)
      } 

    }

   componentWillUnmount() {
      const script = document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}"]`);
      if (script) {
         document.head.removeChild(script);
      }
   }
   hasPermission(route, permissionList=[]) {
      const parts = route.split("/");
      let pathName = `/${parts[1]}/${parts[2]}`
      let permit = true;
      permit = permissionList.includes(pathName.toLowerCase())
     if(!permit && permissionList.length){
      history.push('/admin/settings')
     }
    }

   updatePermissions = (res) =>{
      const subscriptionPlanId = localStorage.getItem('subscriptionPlanId')
      let data = res
      let subscriptionValidity = JSON.parse(localStorage.getItem('userData')).subscriptionValidity
      let userStatus = JSON.parse(localStorage.getItem('userData'))?.status
      let userType = localStorage.getItem('userType')
      if(userType !== '3'){
         if(moment(subscriptionValidity)<moment(new Date())){
          data = [{label: "Settings"}]
          }
      }
      let permissionPaths = [];
      let features = []; 
      
      this.state.features?.map((item)=>{
         if(Object.keys(featuresList).includes(item.value)){
            features.push(featuresList[item.value])
         }
      })

      data?.forEach(item => {
         if(Object.values(featuresList).includes(item.label)){
            if(features.length === 0  || features.includes(item.label)){
              !!paths[item.label] && permissionPaths.push(paths[item.label]?.toLowerCase()) 
            } 
         } 
         else { 
            permissionPaths.push(paths[item.label]?.toLowerCase())
         } 
      });  

      data = data?.filter( item =>{ 
            if(Object.values(featuresList).includes(item.label)) {
               if(features.length === 0  || features.includes(item.label)){
                  return true
               } 
            } 
            else {return true}
      })  

      if(userStatus === 3){
         history.push('/admin/subscription')
      }
      this.hasPermission(window.location.pathname, permissionPaths)
      let isLoading = subscriptionPlanId !== 'null'&& this.state.features?.length === 0;
      this.setState({loading:isLoading,permissionList:data,permissionPaths:permissionPaths})  
   }
   
   //get all permissions
  fetchAllPermissions =()=>{
   this.setState({ loading: true })
   store.dispatch(getPermissionMenu())
   .then((res)=>{
      // this.setState({loading: false,})
   }).catch(()=>{
      this.setState({ loading: true, permissionList:[{label: "Settings"}]})
   })
} 

 fetchAllFeatures = async() =>{
   this.setState({loading:true})
   const subscriptionPlanId = localStorage.getItem('subscriptionPlanId')
   
   if(subscriptionPlanId !== "null"){ 
      try{
      const allPlans = await store.dispatch(getAllSubscription())
      const [currentPlan] =  allPlans.filter((plan)=>plan.id === subscriptionPlanId) 
      this.setState({loading:true,features:currentPlan ? currentPlan.features : null})
      }
      catch(err){ 
         console.log(err)
         this.setState({loading:true})
      }

   } 
   else {
   this.setState({loading:true})}
 }



   handleProfileMenu = (event, active) => {
      event.stopPropagation();
      this.setState({
         profilemenu: active
      });
   };

   handleSidenav = () => {
      let { menuOpenClass } = this.state;

      this.setState({
         menuOpenClass: !menuOpenClass
      });
   };

   handleSideBar = () => {
      let { isSideBarClicked } = this.state;

      this.setState({
         isSideBarClicked: !isSideBarClicked
      }); 

      isSideBarClicked ? setTimeout(()=>this.setState({isClosed:isSideBarClicked}),500) : this.setState({isClosed:isSideBarClicked})
   };
   render() {
      let { children } = this.props;

      let { menuOpenClass,permissionList,loading } = this.state;

      return (
         <>
         <div id="main-content" onClick={e => this.handleProfileMenu(e, false)}>
            {
               !loading ?
               <div className="content-wrapper">
                   <div className="d-block d-sm-none">
                     <SidebarMobile
                        menuOpen={menuOpenClass}
                        handleSidenav={this.handleSidenav}
                        permissionList={permissionList}
                        pathname={history.location.pathname}
                     />
                  </div>
                  <div className="d-none d-sm-block">
                  <Sidebar
                     key={permissionList}
                     menuOpen={menuOpenClass}
                     permissionList={permissionList}
                     handleSidenav={this.handleSidenav}
                  />
                  </div>
                  <Navbar
                  handleNav={this.handleSidenav}
                  handleSideBar= {this.handleSideBar}
                  sideBarClickStatus={this.state.isSideBarClicked}
                  />
                  <div className="content">{children}</div>
               </div>
               :
               <div style={{position: "relative", top: "300px"}}>
                  <CommonPageLoader />
               </div>
            }
         </div>
            <div className={`sidebarNavigation open ${this.state.isSideBarClicked ? 'in' : 'out'}
            ${this.state.isClosed && 'hide'}`}
            >  <NotificationSettings handleSideBar={this.handleSideBar} sideBarClickStatus={this.state.isSideBarClicked} />
            </div>
            <TourGuide/>
      </>
      );
   }
}

const mapStateToProps = (state)=>{
   return {
      permissionsData:state.webSocketReducer.permissions
   }
}
export const AdminLayout = connect(mapStateToProps)(AdminLayoutComponent)
