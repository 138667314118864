import CustomComponent from "../CustomComponent";

export const settingsPageSteps = [
    // {
    //     target:'h4',
    //     content:'Settings Module',
    //     placement:'auto',
    //     disableBeacon: true,
    // },

    {
        content: 'Update your contact information Stay connected with your clients.',
        target:'#agency-general-settings',
        placement:'bottom',
        disableBeacon: true,
    },
    {
        content:' Here you can add and update your company details.',
        target:'#agency-company-details',
        placement:'bottom',
        disableBeacon: true,
    },

    {
        content:'Add job categories & functions from global job management.',
        target:'#agency-job-management-settings',
        placement:'auto'
    },
    
    {
        content:' Here you can add, update and delete your tax details.',
        target:'#agency-tax-details',
        placement:'bottom',
        disableBeacon: true,
    },
    
    ...(process.env.REACT_APP_BASE_SERVER === '2' ? [{
        content: 'Here you can add & explore our range of service Items, each accompanied by its respective GST rates, ensuring transparency and clarity in your selection process.',
        target: '#agency-invoice-service-item',
        placement: 'bottom',
        disableBeacon: true,
    }]
    :
    []),
   
    {
        content:'Control staff permissions at one place globally.',
        target:'#agency-user-permissions-settings',
        placement:'bottom'
    },
    {
        target:'#agency-manage-account-accordion',
        content:'Displays the details for managing your account. You can "delete", "recover" and "download data" of your account here.',
        placement:'auto',
    },
]

export const CompanyDetailsSteps = [
    {
        content:'Select Time Zone.',
        target:'#company-details-time-zone',
        placement:'auto',
        disableBeacon: true,
        disableBeacon: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: true,
          placement: 'bottom',
          // spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
    },
]

export const settingsPageStepsCoAdmin = [
    {
        content:'Click on Edit to change your profile details',
        target:'#coAdmin',
        placement:'auto',
        disableBeacon: true,
    },
    {
        content:'You can change your password by clicking here.',
        target:'#staff-change-password',
        placement:'auto',
        disableBeacon: true,
    },
]