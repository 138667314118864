import React, { useEffect, useState } from "react";
import { DatePicker, TimePicker } from "antd";
import classes from "./EditTimeSheet.module.scss";
import deleteIcon from "assets/svg/deleteRed.svg";
import { NormalSelect } from "component/common";
import dangerIcon from "assets/images/icon/danger.png";
import moment from "moment";

const types = {
	CLOCK_IN_OUT: "clock-in-and-clock-out",
	BREAK_TIME: "pause-resume-time",
};

// ----------------------
// Clock-In Picker
// ----------------------

export const ClockInPicker = ({ data, setData, clientLatLng, clientSiteTimeZone="" }) => {
	const handleDelete = () => setData({ clockIn: "", clockInStatus: false, clockOut: "", clockOutStatus: false, breakTime: [] });
	const handleChange = (val) => setData((prev) => ({ ...prev, clockIn: val, clockInStatus: true, clockInLongLat: clientLatLng }));
		
	return <DateTimePickerCategorizer type={types.CLOCK_IN_OUT} value1={data?.clockIn} onChange1={handleChange} onDelete={handleDelete} label1="Clock-In" clientSiteTimeZone={clientSiteTimeZone} />;
};

// ----------------------
// Clock-Out Picker
// ----------------------

export const ClockOutPicker = ({ data, setData, clientLatLng, clientSiteTimeZone="" }) => {
	const handleDelete = () => setData((prev) => ({ ...prev, clockOut: "", clockOutStatus: false }));
	const handleChange = (val) => setData((prev) => ({ ...prev, clockOut: val, clockOutStatus: true, clockOutLongLat: clientLatLng }));
	return <DateTimePickerCategorizer type={types.CLOCK_IN_OUT} value1={data?.clockOut} onChange1={handleChange} onDelete={handleDelete} label1="Clock-Out" clientSiteTimeZone={clientSiteTimeZone} />;
};

// ----------------------
// Break Time Picker
// ----------------------

export const BreakTimePicker = ({ data, setData, index, clientLatLng, clientSiteTimeZone="" }) => {
	const handleDelete = () => {
		let breakTime = [...data?.breakTime?.filter((_, indx) => indx?.toString() !== index?.toString())];
		setData((prev) => ({ ...prev, breakTime }));
	};
	const handleChange = (val, action) => {
		let breakTime = [...data?.breakTime];
		breakTime[index] = action == "pause" ? { ...breakTime[index], pauseAt: val, pauseLongLat: clientLatLng } : { ...breakTime[index], resumeAt: val, resumeLongLat: clientLatLng };
		setData((prev) => ({ ...prev, breakTime }));
	};

	return (
		<DateTimePickerCategorizer
			type={types.BREAK_TIME}
			clientSiteTimeZone={clientSiteTimeZone}
			value1={data?.breakTime?.[index]?.pauseAt}
			onChange1={(val) => handleChange(val, "pause")}
			label1="Paused At"
			value2={data?.breakTime?.[index]?.resumeAt}
			onChange2={(val) => handleChange(val, "resume")}
			label2="Resumed At"
			onDelete={handleDelete}
		/>
	);
};

// ----------------------
// Helper Components
// ----------------------

// Old code
// function DateTimePicker({ value, onChange, label }) {
// 	const [open, setOpen] = useState(false);
// 	return (
// 		<>
// 			<p className="col-3 m-0 my-2 h3-m">{label}</p>
// 			<div className="col-5 my-2">
// 				<DatePicker value={value} size="large" placeholder={`${localStorage.getItem('dateFormat')}`} onChange={onChange} format={`${localStorage.getItem('dateFormat')}`} className={classes.datepicker} />
// 			</div>
// 			<div className="col-4 my-2">
// 				<TimePicker value={value} onChange={onChange} onSelect={val=>{onChange(val); setOpen(false)}} onOpenChange={()=>setOpen(!open)} open={open} size="large" placeholder="HH:MM" use12Hours format="h:mm A" className={classes.datepicker} />
// 			</div>
// 		</>
// 	);
// }

// Fix for ok button not working properly
function DateTimePicker({ value, onChange, label }) {
	const [open, setOpen] = useState(false);
	const [tempDate, setTempDate] = useState(null);
	const [tempTime, setTempTime] = useState(null);
  
	const handleDateChange = (date) => {
	  setTempDate(date);
	  if (date !== tempDate) {
      setTempTime(null);
    }
	  onChange(mergeDateAndTime(date, tempTime));
	};
  
	const handleTimeChange = (time) => {

		setTempTime(time);

		if(value!==""){
		onChange(mergeDateAndTime(value, time));}
		else {
			onChange(mergeDateAndTime(tempDate,time));
		}
	  };

	useEffect(()=>{
		if (tempDate !== null && tempTime !== null) {
			mergeDateAndTime(tempDate, tempTime);
		  }
	},[tempDate,tempTime])
  
	const mergeDateAndTime = (date, time) => {
		if (!date) {
		  return null;
		}
		const mergedDateTime = moment(date)
		  .hours(time ? time.hours() : getMomentObject(date).hours())
		  .minutes(time ? time.minutes() : getMomentObject(date).minutes());
	  
		return mergedDateTime.toDate();
	  };
	
	const getMomentObject = (value) => {

    if (moment.isMoment(value)) {
        return value;
    }

    return moment(value);
  };

  const getTimePickerValue = () => {
  if (tempTime === null && tempDate == value) {
    return value ? getMomentObject(value) : value;
  } else if (tempDate !== value && tempTime !==null && moment.isMoment(value)) {
    setTempTime(null);
    return null;
  } else if (value !== null && value !== '' && !tempTime) {
    return getMomentObject(value);
  } else {
    return tempTime;
  }
};

  
	return (
	  <>
		<p className="col-3 m-0 my-2 h3-m">{label}</p>
		<div className="col-5 my-2">
		  <DatePicker
			value={tempDate ? getMomentObject(tempDate) : (value !== null && value !== '' ? getMomentObject(value) : value )}
			size="large"
			placeholder={`${localStorage.getItem('dateFormat')}`}
			onChange={handleDateChange}
			format={`${localStorage.getItem('dateFormat')}`}
			className={classes.datepicker}
		  />
		</div>
		<div className="col-4 my-2">
		  <TimePicker
			value={getTimePickerValue()}
			onChange={handleTimeChange}
			onSelect={handleTimeChange}
			onOpenChange={(isOpen) => {
			  setOpen(isOpen);
			  if (!isOpen && tempDate && tempTime) {
				onChange(mergeDateAndTime(tempDate, tempTime));
			  }		  
			}}
			open={open}
			size="large"
			placeholder="HH:MM"
			use12Hours
			format="h:mm A"
			className={classes.datepicker}
		  />
		</div>
	  </>
	);
}

function DateTimePickerCategorizer({ type = types.CLOCK_IN_OUT, value1, onChange1, label1, value2, onChange2, label2, onDelete, clientSiteTimeZone="" }) { 
	return (
		<>
			<div className="row align-items-center">
				<div className="col-9 row">
				<DateTimePicker label={label1} value={value1} onChange={onChange1} />
					{type == types.BREAK_TIME && 
				<DateTimePicker label={label2} value={value2} onChange={onChange2}/>}
				</div>
				<div className="col-2">
					<div className={type !== types.BREAK_TIME?"form-group mt-2 mb-2 pt-2 timezone-tooltip-hover":"form-group mt-1 mb-0 pt-3 timezone-tooltip-hover"}>
						<p
						// arrow={true}
						// placeholder="Time Zone"
						// disabled={true}
						// name="clientSiteTimeZone"
						// value={clientSiteTimeZone}
						// options={[{label:clientSiteTimeZone, value:clientSiteTimeZone}]}
						className="customNormalSelectSize"
						>{clientSiteTimeZone}</p>
						{!!clientSiteTimeZone ? 
							<p className="timezone-tooltip">{clientSiteTimeZone}</p>
							:
							<p className="timezone-tooltip">
								<img className='dangerIcon' src={dangerIcon} alt='danger'/>
								Please select Client Site Timezone</p>
						}
					</div>
					{type == types.BREAK_TIME && 
					<div className="form-group mt-1 mb-4 pt-2 timezone-tooltip-hover">
						<p
						// arrow={true}
						// placeholder="Time Zone"
						// disabled={true}
						// name="clientSiteTimeZone"
						// value={clientSiteTimeZone}
						// options={[{label:clientSiteTimeZone, value:clientSiteTimeZone}]}
						className="customNormalSelectSize mb-2"
						>{clientSiteTimeZone}</p>
						{!!clientSiteTimeZone ? 
							<p className="timezone-tooltip timezone-tooltip-resumed">{clientSiteTimeZone}</p>
							:
							<p className="timezone-tooltip timezone-tooltip-resumed">
								<img className='dangerIcon' src={dangerIcon} alt='danger'/>
								Please select Client Site Timezone</p>
						}
					</div>
					}
				</div>
				<div className="col-1 align-self-center custom-justify-right">
					<button className={classes.delete_btn} onClick={onDelete}>
						<img src={deleteIcon} />
					</button>
				</div>
			</div>
			<hr />
		</>
	);
}
