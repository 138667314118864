import React from "react";
import { CommonPageLoader } from "component/common";
import logo from '../assets/images/Staff IQ.png'

export class PlainLayout extends React.Component {
  render() {
    return (
      <>
          <div className="row m-0">
            <div className="col-md-12 auth-layout d-flex align-items-center text-center">
              {this.props.children}
            </div>
        </div>
      </>
    );
  }
}