import { addQuery } from "service/helperFunctions";
import { jobCategoriesApi} from "../service/apiVariables";

export const getAllJobCategories = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, jobCategoriesApi.getAllJobCategories);
    api({ ...jobCategoriesApi.getAllJobCategories })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateJobCategory = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...jobCategoriesApi.updateJobCategory, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 3000 })
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getJobFunctions = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, jobCategoriesApi.getJobFunctions);
    api({ ...jobCategoriesApi.getJobFunctions })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const addJobCategory = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...jobCategoriesApi.addJobCategory, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAgencyJobs = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, jobCategoriesApi.getAgencyJobs);
    api({ ...jobCategoriesApi.getAgencyJobs })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
        // reject(Toast({ type: "error", message }));
      });
  });
};

export const addJobFunctionById = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, jobCategoriesApi.addJobFunctionById);
    api({ ...jobCategoriesApi.addJobFunctionById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const unsubscribejobFunctionsByIds = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    
    api({ ...jobCategoriesApi.unsubscribejobFunctionsById, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message })
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const jobFunctionNotifyUsers = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, jobCategoriesApi.jobFunctionNotifyUsers);
    api({ ...jobCategoriesApi.jobFunctionNotifyUsers })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};