import React,  { useEffect,useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.scss';
import {pageNavigationByName, store} from 'service/helpers';
import { logout } from "../../../service/utilities";
import { getNotificationsCout } from "../../../action/notification";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { getSettingsById } from 'action/SettingsAct';
import { getStaffById } from 'action/StaffAct';
import nav_bell from '../../../assets/svg/mdi_notifications .svg';
import nav_bell_grey from '../../../assets/svg/notificationGrey.svg';
import nav_user from '../../../assets/svg/user.svg';
import logoutIcon from '../../../assets/svg/logout.svg';
import menu from '../../../assets/svg/menu.svg';
import logo from '../../../assets/svg/agency_logo_base.png';
// import DisplayImage from '../DisplayImage';
import DisplayImage from "../../Settings/companyDetails/DisplayImage/"
import { NormalSwitch } from '../NormalSwitch';
import { startTourGuide } from 'reducer/tourGuide';
import { useSelector } from 'react-redux';
import { TourSwitch } from '../TourGuide/TourSwitch';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

function Navbar (props){
   const dispatch = useDispatch();
   const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [userInfo, setUserInfo] = useState({});
  const [userLogo, setUserLogo] = useState({});
  const [companyLogo, setCompanyLogo] = useState('');
  const [agencyName, setAgencyName] = useState('');

  const isSmScreen = useMediaQuery('(max-width:768px)');

  const [notificationCallCounter, setNotificationCallCounter] = useState(0);
  let userData = JSON.parse(localStorage.getItem('userData'))
  useEffect(()=>{
      setCompanyLogo(userData?.companyLogo)
      setAgencyName(userData?.agencyName)
 },[userData?.companyLogo, userData?.agencyName])
  
 useEffect(()=>{
  let id = userData?.agencyId ? userData?.agencyId : userData?.id;
  if(id === userData?.id){
     setUserLogo(userData)
  }
 },[userData?.profileLink , userData?.profileImage, userData?.adminName, userData?.firstname, userData?.middleName, userData?.lastName])
 let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)
  useEffect(()=>{
   setCount(notificationCount)
  },[notificationCount]);

  useEffect(()=>{
    getNotifyCount()
   },[]);

   useEffect(() => {
      // let timerFunc = setTimeout(() => {
      //   getNotifyCount();
      // }, 5000);
      // return () => clearTimeout(timerFunc);
      
      if ('userData' in localStorage) {
         let userData = JSON.parse(localStorage.getItem('userData'));
         let id = userData?.agencyId ? userData?.agencyId : userData.id;
         let query = {
           id,
         };
        //  setLoading(true);
        //  dispatch(getSettingsById(query))
        //    .then((res) => {
        //      if (res) {
        //        setUserInfo(res);
        //        setLoading(false);
        //      }
        //    })
        //    .catch(() => {
        //      setLoading(false);
        //    });

           setLoading(true);
           if(id != userData.id){
             id = userData.id ? userData.id : userData.userId;
             let query = {
               id
             }
            dispatch(getStaffById(query))
              .then((res) => {
                if (res) {
                  setUserLogo(res);
                  setLoading(false);
                }
              })
              .catch(() => {
                setLoading(false);
              });
           }
          //  else{
          //   dispatch(getSettingsById(query))
          //   .then((res) => {
          //     if (res) {
          //       setUserLogo(res);
          //       setLoading(false);
          //     }
          //   })
          //   .catch(() => {
          //     setLoading(false);
          //   });
          //  }
       }
     }, [store.getState().navbar.isCompanyNameUpdated]);

   const getNotifyCount= async()=>{
      setLoading(true);
      await props.getNotificationsCout({})
      .then((data) => {
         setCount(data.count);
         setLoading(false)
      })
      .catch((e) => setLoading(false));
   }
   let tourGuideData = useSelector((state) => state.tourGuideReducer.tourGuideData);
   const handleTour = ({target:{value}}) => {
    dispatch(startTourGuide({run:value}))
    if(props.sideBarClickStatus){
    props.handleSideBar()
    return;
    }
  }
  useEffect(()=>{
  if(tourGuideData.run && props.sideBarClickStatus)
    props.handleSideBar()
  },[tourGuideData.run])

  let isConnected = useSelector((state) => state.webSocketReducer.isConnected);

   let userPic = userLogo.agencyId ? userLogo.profileLink : userLogo.profileImage;
   return (
    <React.Fragment>
    <div className="navbar fixed-top p-3">
      <div className="d-flex justify-content-end w-100 align-items-center">
        <div className="mr-4 d-flex align-items-center pr-3 nav-logo">
          <img src={menu} onClick={props.handleNav} className="mb-3 mr-3 cursor-pointer" width={'25'} height={'17'} />
          <div className="mb-3 logo-pic">
          {companyLogo ? <DisplayImage navbar={true} imageId={companyLogo}/> : null}
          </div>
          {/* <img src={userInfo.companyLogo ? userInfo.companyLogo : ""} className="mb-3 logo-pic" /> */}
          {/* <p className="ml-3 text-violet">powered by Staff IQ</p> */}
        </div>
        {/* <p className="pr-5 font_size col-5 align-items-center mv-left">{userInfo ? userInfo.agencyId ? userInfo.firstname : userInfo.agencyName : "User Name"}</p> */}
        <Box sx={{display: isSmScreen ? 'none' : ''}} component="p" className="pr-5 font_size col-5 align-items-center mv-left agency-name-nav">{agencyName}</Box>

        <div className={`mr-5 mb-3  ${(userData.status === 2 || userData.status === 3) && `disable-click`}`}>
          <TourSwitch
           checked={tourGuideData.run}
           onChange={handleTour}
          />
        </div>

        <div className={`mr-4 pr-3 position-relative ${(userData.status === 2 || userData.status === 3) && 'disable-click'}`}>
          <img
            src={`${props.sideBarClickStatus?nav_bell:nav_bell_grey}`}

            className="mb-3 cursor-pointer"
            // onClick={() => {
            //   //pageNavigationByName({name: 'notification'});
            //   setCount(0);
            //   localStorage.setItem('notifications', 0);
            //   const newTab = window.open('notification', '_blank');
            //   if (newTab != null) {
            //     newTab.focus();
            //   }
            // }}
            onClick={() => {
              setCount(0);
              // localStorage.setItem('notifications', 0);
              props.handleSideBar();
}}
          width={'24'}
          height={'24'}
          />
          <span className="badge my-badge">{!!count ? count.toString().length>3?"999+":count : ''}</span>
        </div>
        <div className="mr-2 d-flex align-items-center pr-3 hover-icon position-relative" style={{left:'0px', top:'0px'}}>
        <OverlayTrigger placement="left" overlay={<Tooltip >{userData.status === 2? "Please restore your account to get instant updates": isConnected ? 'You are now connected to get updates instantly': 'You are now disconnected to get updates instantly, Please refresh'}</Tooltip>}>
       <div className='d-flex align-items-center position-absolute' style={{bottom:'15px', left:'-5px'}}>
        <div className={`${userData.status === 2 ? 'bg-danger' : isConnected ? 'connected': 'bg-danger'}`} style={{borderRadius:'50%', minWidth:'15px', minHeight:'15px', border:'2px solid white'}}></div>
        </div>
        </OverlayTrigger>
          {/* <img
            src={
              userLogo ? userLogo.userPic === 'http://localhost' ||
              userPic === '' ||
              userPic === null ? (
                nav_user
              ) : (
                userPic
              ) : (
                nav_user
              )
            }
            className="profile-nav rounded-circle mb-3"
          /> */}
          {userPic ? userPic.includes('data:image/')  ?
            <img
              src={
                userLogo ? userLogo.userPic === 'http://localhost' ||
                userPic === '' ||
                userPic === null ? (
                  nav_user
                ) : (
                  userPic
                ) : (
                  nav_user
                )
              }
              className="profile-nav rounded-circle mb-3"
            />
          :
            <DisplayImage navbar={true} adminContact={true} imageId={userPic}/>
          : 
          <img src={nav_user} className="profile-nav rounded-circle mb-3"/>}
        
         <span className="extra-hover mx-2">{userLogo ? userLogo.adminName ? userLogo.adminName : userLogo.firstname +" "+ userLogo.middleName +" "+ userLogo.lastName : "User Name"}</span>
        </div>
        <div className="d-flex align-items-start mr-4 cursor-pointer" onClick={() => logout(true)}>
          <div className="vert-line mr-3" />
          <img className="" src={logoutIcon} alt="user" width={'17'} height={'17'}/>
          <Box sx={{display: {xs: 'none', sm:'block'} }} component="p" className="ml-3 mb-1 text-light-violet logout-text">Logout</Box>
        </div>
      </div>
    </div>
  </React.Fragment>
   );
}
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
     {
       getNotificationsCout,
     },
     dispatch
   );
 };
 
 export default connect(mapDispatchToProps, mapDispatchToProps)(Navbar);

