import React, { useState, useEffect } from "react";
import marketIcon from '../../../assets/svg/VectorpencilBlue.svg';
import tickIcon from '../../../assets/svg/right.svg';
import { NormalDropdown } from "component/common/NormalDropdown";


export default function EditableDropdown({ label, value,iseditStatus,setEditStatus, onClick,options, align = "align-items-start" }) {


    const onEditClicked = () => {
        setEditStatus(!iseditStatus);
    }

	return (
		<div className={`row align-down ${align}`}>
			<p className="col-6 text-muted m-0 mb-2 h3-m">{label}</p>
			<div className="col-6 pl-2 mb-2 ">
            { !iseditStatus ?
            <div>
                {value}
                <span><img className="editIcon" src={marketIcon} onClick={onEditClicked}/></span>
            </div>
            : <div>
                
                <NormalDropdown
                value={iseditStatus}
                label="Select Status"
                optionsList={options}
                onClick={onClick}
                className="form-control select-mini border text-capitalize fs-14 rounded min-150 d-flex justify-content-between"

                 
                
                />
            <span><img className="editIcon" src={tickIcon} onClick={onEditClicked}/></span>
            </div>
             }
            </div>
            </div>
	);
}