import React, { useState } from 'react';
import { Title } from "component/common"
import "./style.scss"
import option from 'assets/svg/option.svg'
import Union from 'assets/svg/Union.svg'

export function WorkSheduleHeader() {

    let jobLocation =[
        {
            label: 'Work category',
            label: 'Job function',
        }
    ]
    let action =[
        {
            label: 'Add work Request',
            label: 'Staff assignment',
        }
    ]
    const [filter,searchValue]  = useState('')
    return (
        <div className=" d-flex justify-content-between mb-3 mt-3 pl-3 pr-3">
            <Title label='Work Schedule' />

            <div className="d-flex ">
                <ul className="work-status-list">
                    <li>
                        PENDING STAFF ASSIGNMENT
                    </li>
                    <li>
                        CANCELED
                    </li>
                    <li>
                        STAFF IS WORKING
                    </li>
                    <li>
                        COMPLETED
                    </li>
                </ul>
            </div>
        </div>

    )
}


