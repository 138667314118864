import React,{useEffect, useState} from "react";

import "./RejectAccordianstaff.scss"


const RejectAccordianStaff = (props)=>{

    const [show, setShow] = useState(false);
    const [data,setData] = useState(props.data);
    const [open,setOpeninput] = useState(false);
    
    const data1 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Lack of Assistance"){
            return true
        }
    })
    

    const data2 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Not meeting state guidelines*"){
            return true
        }
    })
    
    const data3 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unsafe work environment*"){
            return true
        }
    })

    const data4 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unavailable"){
            return true
        }
    })

    const data5 = data?.rejectReasons?.filter((elem)=>{
        if(elem=="Work loads are too large"){
            return true
        }
    })

    const data0 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Other"){
            return true
        }
    })

    useEffect(()=>{
        if(data0?.length>0){
            setOpeninput(true)
           }
           else if(data0?.length==0){
            setOpeninput(false)
           }
    },[data0])
    
    return (
        <>
        <div className={show==true?"outerdiv":""}>
        <div>
            <p className={show==true?"setReject":"setnotReject"} id={data?"":"notRejected"} onClick={()=> setShow(!show)}>{data?"Rejected by Staff  📩":"Yet to accept"}</p>
           </div>
        {show && 

<div className="setrejectfields">
    <form onSubmit={""}>
        
    <div className="setinputfields">
    <div className="setheadingborder"> <h3 className="header" >Reason(s) selected by staff for rejecting Work Request:</h3></div>
       <div className="innderdiv1">
         <div className="innerdiv">

        <input className="setinput2" type="checkbox" checked={data1?.length>0?true:false} name="Lack of Assistance" value="1" /> <label >Lack of Assistance </label> 
        <br></br>
        <input className="setinput2" type="checkbox"  checked={data2?.length>0?true:false} name="Not meeting state guidelines*" value="2" /> <label> Not meeting state guidelines*</label> 
        <br></br>
        <input className="setinput2" type="checkbox"  checked={data3?.length>0?true:false} name="Unsafe work environment*" value="3" /> <label> Unsafe work environment* </label> 
        <br></br>
        <input className="setinput2" type="checkbox"  checked={data4?.length>0?true:false} name="Unavailable" value="4" /> <label> Unavailable</label> 
        <br></br>
        <div className="setinput3">
        <input  type="checkbox"  checked={data5?.length>0?true:false} name="Work loads are too large" value="5" />  <label> Work loads are too large</label> 
        </div>
        <div className="setothers">
        <input className="setinput" type="checkbox"  checked={data0?.length>0?true:false} name="Others" value="0" />  <label> Other</label> 
        </div>
     { open &&
    <input id="textarea" disabled={data?true:false}  className="settextarea" rows="2" cols="30" placeholder={data?.additionalComments}></input>}
    <div className="contactto"><input id="contact" className="setcontact" type="checkbox" checked={data?.contactEnabled == true?true:false} /> <label className="setcontactlabel">Please Contact Staff to Discuss</label></div>
    </div>
    </div>
    <div className="sethr"></div>
    </div>
    </form>
</div>

}
           </div>
        </>
    )
}

export default RejectAccordianStaff;