import React from 'react';
import WarningIcon from 'component/StaffManagement/WarningIcon';
import "./style.scss"

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <div className='mb-2'>
            <WarningIcon />
        </div>
        <p className='h3-m'>{message}</p>
        <div className="confirmation-modal-actions">
          <button className='cta-1-m' onClick={onConfirm}>Yes</button>
          <button className='cta-1-m' onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
