import React from "react";
import './style.scss';
import { Option } from "react-multi-select-component";

interface IDefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick;
}

const ItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: IDefaultItemRendererProps) => (
  <div id="setmultiselectcss" className={`item-renderer ${disabled ? "disabled" : ""}`}  >
    <input
      className={`checkbox${option.key}`}
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <span className="setoption">{`${option.label}`} 
     {option.count === 0 ? <span > ({option.count})</span> : ''}
    {option.count !== 0 && option.count !== undefined  ? <span className="count"> ({option.count})</span> : ''  }
    
    </span>
  </div>
);

export default ItemRenderer;