import { combineReducers } from "redux";
import home from "./home"
import navbar from "./navbar"
import webSocketReducer from "./webSocketReducer"
import tourGuideReducer from "./tourGuide";
import userDataReducer from "./userData";

export const reducers = combineReducers({
    home,
    navbar,
    webSocketReducer,
    tourGuideReducer,
    userDataReducer
})