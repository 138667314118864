import React, { Component } from 'react'
import "./select.scss"
export class NormalSelect extends Component {
    render() {

        let {
            className = "form-control select-form-control w-100",
            options = [],
            keyName = 'label',
            valueName = "value",
            handleChange,
            value = '',
            name = '',
            placeholder = "Select",
            disabled = false,
            arrow = false,
            isReadOnly = false,
            label = '',
            id=''
        } = this.props
        return (
            <>
                {!isReadOnly ?

                    <div className={`${arrow ? 'select-section' : 'Select'}`} id={id ? id : ''}>
                        {label&&
                            <div className="fs-11 text-black font-JosefinSans pb-2 mb-1 line-height-20">
                                {label}
                            </div>}
                        <select
                            className={`${className ? className : ""} custom-font-style-normalselect`}
                            value={value}
                            disabled={disabled}
                            onChange={e => {
                                let body = {
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value,
                                        label: e.target.options[e.target.selectedIndex].label,
                                         // === "" ? e.target.value : Number(e.target.value)

                                         data:options[e.target.selectedIndex-1]
                                    }
                                }
                                handleChange(body)
                            }}
                            name={name}
                            style={value == "" ? {fontSize:"10px"} : {}}
                        >
                            <option className='sr-2 select-options' value='' disabled defaultValue>{placeholder}</option>
                            {options.length !== 0 ?
                                options.map((option, index) => (
                                    <option className='sr-2 select-options' value={option[valueName]} disabled={!!option.disabled?true:false}  key={index}>{option[keyName]}</option>
                                )) : <option className='sr-2 select-options' value='' disabled>No Options</option>}

                        </select>
                        {arrow &&
                            <span className="icon-down down-icon" id={id ? id : ''}></span>
                        }
                    </div>
                    :
                    <p>{value}</p>
                }
            </>
        )
    }
}

