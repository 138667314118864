import { addQuery } from '../service/helperFunctions';
import { authApi,agencyApi } from "../service/apiVariables";
import { api } from "../service/api"


// common login
export const login = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.adminLogin, body })
      .then(({ message, data }) => {
        
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const otplogin = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.otpLogin, body })
      .then(({ message, data }) => {
        localStorage.setItem("AuthToken", data.token);
        localStorage.removeItem('userData')
        localStorage.setItem("userData", JSON.stringify(data));

        resolve(data);

        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const forgotPassword = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.forgetPassword, body })
      .then(({ message, data }) => { 

        resolve(data);

        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
 

export const resetpassCode = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.resetPassword, body })
      .then(({ message, data }) => { 

        resolve(data);

        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getUserById = (id) => (dispatch, getState, { api, Toast }) => {
  agencyApi.getUserById.addQuery = { key: "id", payload: id };

  return new Promise((resolve, reject) => {
    api({ ...agencyApi.getUserById })
      .then(({ data, message }) => {
        resolve(data);
        // Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// export const getPermissionMenu = () => (dispatch, getState, { api, Toast }) => {

//   return new Promise((resolve, reject) => {
//     api({ ...agencyApi.getPermissionMenu })
//       .then(({ data, message }) => {
//         resolve(data);
//         // Toast({ type: "success", message });
//       })
//       .catch(({ message }) => {
//         reject(Toast({ type: "error", message }));
//       });
//   });
// };
export const getPermissionMenu = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
      api({  ...agencyApi.getPermissionMenu})
      .then((data) => {
          dispatch({type:'UPDATE_PERMISSIONS', payload:data.data})
          resolve(data)
      }).catch(({ message }) => {
          reject(Toast({ type: "error", message }));
          reject(message)
      })
  })
}

export const getAllRoll = (query) => (dispatch, getState, { api, Toast }) => {
  
  return new Promise((resolve, reject) => {

      addQuery(query, agencyApi.getRolls);

      api({ ...agencyApi.getRolls }).then(({ data }) => {
          resolve(data)
      }).catch(({ message }) => {
          reject(Toast({ type: "error", message }))
      })
  })
}
export const getTimeOffCode = (query) => (dispatch, getState, { api, Toast }) => {
  
  return new Promise((resolve, reject) => {

      addQuery(query, agencyApi.timeOffCode);

      api({ ...agencyApi.timeOffCode }).then(({ data }) => {
          resolve(data)
      }).catch(({ message }) => {
          reject(Toast({ type: "error", message }))
      })
  })
}

export const logOut = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, authApi.adminLogOut);
    api({ ...authApi.adminLogOut })
      .then(({ message, data }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch((data) => {
        reject(data);
      });
  });
};

export const getUserLoginCount = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
      addQuery(query, agencyApi.getUserLoginCount);
      api({ ...agencyApi.getUserLoginCount }).then(({ data }) => {
        localStorage.setItem('loginCount', data)
        localStorage.setItem('productTour', data < 2 ? true : false)
        resolve(data)
      }).catch(({ message }) => {
          reject(Toast({ type: "error", message }))
      })
  })
} 

export const getAllSubscription = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
      api({  ...agencyApi.getAllSubscription})
      .then(({data}) => {
          resolve(data)
      }).catch(({ message }) => {
          reject(Toast({ type: "error", message }));
          reject(message)
      })
  })
}

export const sendOtpToNewEmail =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, authApi.sendOtpToNewEmail);
      authApi.sendOtpToNewEmail.query.emailId = encodeURIComponent(authApi.sendOtpToNewEmail.query.emailId)
      authApi.sendOtpToNewEmail.query.newEmailId = encodeURIComponent(authApi.sendOtpToNewEmail.query.newEmailId)
      api({ ...authApi.sendOtpToNewEmail })
        .then(({ data, message }) => {
          Toast({ type: "success", message })
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const otpVerifyNewEmail =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, authApi.otpVerifyNewEmail);
      authApi.otpVerifyNewEmail.query.emailId = encodeURIComponent(authApi.otpVerifyNewEmail.query.emailId)
      authApi.otpVerifyNewEmail.query.newEmailId = encodeURIComponent(authApi.otpVerifyNewEmail.query.newEmailId)
      api({ ...authApi.otpVerifyNewEmail })
        .then(({ data, message }) => {
          Toast({ type: "success", message })
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };