import React from 'react';
import './customMarker.scss';

export const Marker = (props) => {
    const { color,key,infoWindowContent, name, id,colorMarker=false,children='',onClick='', isInfo= true ,infoWindowOpen='',index } = props;
 
    return (
        <div key={key} className="customMarker"
        title={name}
        onClick={isInfo && infoWindowOpen== index?"": onClick}
        >
            {
                colorMarker&&
                <div className="colorMarker"
                style={{ backgroundColor: color, cursor: 'pointer'}}
                title={name}
                >
                </div>
            }
            
            {
                !!children&&children
            }
            { isInfo &&(
                infoWindowOpen == index  && (
                    <div className="info-window" onClick={""}>
                        <div className="inner-class" onClick={""}>
                            
                            {infoWindowContent}
                            <span className="rectang"></span>
                        </div>
                    </div>
                )
            ) 
            }
            {/* <div className="info-window">
                dhfh
            </div> */}
        </div>
    );
  };

//   export default Marker;