import React from 'react';
import { NormalButton, NormalModal } from 'component/common';
 
const ConfirmModal = ({handleAction,isOpen,toggle, message, isLoading}) => {
    
    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            headerClass="border-0 pt-4 px-4"
            toggle={toggle}
        >
             <>
                  <div className=" text-center mb-5 px-5">
                     <p className="fs-16 mb-0">
                        {message}
                     </p>
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                     <NormalButton
                        onClick={()=>{
                            handleAction(false)
                        }}
                        id="cancel"
                        label="Cancel"
                        outline={false}
                        className="mb-2 btn fs-13 btn btn-dark-primary  btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                            handleAction(true)
                        }}
                        id="confirm"
                        label="Confirm"
                        outline={false}
                        loader={isLoading}
                        className="mb-2 ml-2 btn fs-13 btn-purple-outline   btn-block "
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}

export default ConfirmModal;