import React from 'react';
import { NormalButton, NormalModal } from 'component/common';

const AcceptWorkAlertModal = ({handleAction,isOpen,toggle, message = '',type}) => {
    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            headerClass="border-0 pt-1 px-1"
            toggle={toggle}
        >
             <>
                  <div className=" text-center mb-5 px-5">
                     <p className="h3-m mb-0">
                        {message}
                     </p>
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                     <NormalButton
                        onClick={()=>{
                            type?handleAction({type:type,value:false}):handleAction(false)
                        }}
                        id="cancel"
                        label="OK"
                        outline={false}
                        className="pl-4 pr-4 mb-2 btn fs-13 btn btn-dark-primary  btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                            type?handleAction({type:type,value:true}):handleAction(true)
                        }}
                        id="confirm"
                        label="Accept Work"
                        outline={false}
                        className="mb-2 ml-2 btn fs-13 btn border-primary  btn-block "
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}


export default AcceptWorkAlertModal;