import { useEffect, useState } from 'react';
import { entryType } from './constants';

export default function useMapDataParser({ data }) {

    const [timeData, setTimeData] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [staffName, setStaffName] = useState('');
    const [staffLatLong, setStaffLatLong] = useState({ lat: '', lng: '' });
    
    const toggleMap = () => setShowMap(prev => !prev);
    
    const formatData = (details = {}) => {
        if (!Object.keys(details).length) return;

        let temp = [];

        // clock-in
        !!details?.clockIn && temp.push(
            {
                label: entryType.CLOCK_IN,
                date: new Date(details?.clockIn),
                lat: details?.clockInLongLat?.[1],
                lng: details?.clockInLongLat?.[0],
                deviceDetails: details?.deviceDetails?.[0]
            }
        );

        // pause resume 
        if (!!details?.pauseResumeData?.length) {
            for (let i = 0; i < details?.pauseResumeData.length; i++) {
                const el = details?.pauseResumeData[i];
                if (el?.key === 'pause') {
                    temp.push({
                        label: entryType.PAUSED,
                        date: new Date(el?.time),
                        lat: el?.longLat?.[1],
                        lng: el?.longLat?.[0],
                        deviceDetails: details?.deviceDetails?.[i+1]
                    });
                } else if (el?.key === 'resume') {
                    temp.push({
                        label: entryType.RESUMED,
                        date: new Date(el?.time),
                        lat: el?.longLat?.[1],
                        lng: el?.longLat?.[0],
                        deviceDetails: details?.deviceDetails?.[i+1]
                    });
                }
            }
        }

        // clock-out
       !!details?.clockOut && temp.push({
            label: entryType.CLOCK_OUT,
            date: new Date(details?.clockOut),
            lat: details?.clockOutLongLat?.[1],
            lng: details?.clockOutLongLat?.[0],
            deviceDetails: details?.deviceDetails?.[details?.deviceDetails?.length - 1]
        });

        setStaffLatLong({
            lat: details?.longLat?.[1],
            lng: details?.longLat?.[0]
        });
        setTimeData([...temp]);
        setStaffName(details?.staffName || '');
     }
    
    useEffect(() => {
       if(!!data){ 
         formatData(data)
       }
       else if (data == undefined){
        setTimeData([]);
        setStaffName('');
        setStaffLatLong({ lat: '', lng: '' });
       }
    },[data])

    return { timeData, staffName, staffLatLong, showMap, toggleMap };
}
