



const initialState = {
    userData:{
    }
  };

  export function updateUserData(data){
    return {
        type: 'UPDATE_USER_DATA',
        payload: data,
    }
}

  export default  (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_USER_DATA':
        return {
          ...state,
          userData:{
          ...action.payload
         }
        };
      default:
        return state;
    }
  };
  