import React from 'react';
import Redirecting from 'assets/images/Loading_gif/staffatom-redirecting.gif'
import "./style.scss";

function CommonRedirectLoading(){
    return(
        <>
            <div className="loader">
                <img fetchpriority="high" src={Redirecting} style={{width:"110px", height:"100px", background:"none"}} alt='loading-icon' />
            </div>
        </>
    )
}

export default CommonRedirectLoading