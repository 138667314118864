import { staffApi } from '../service/apiVariables';
import { addQuery } from '../service/helperFunctions';



export const getStaffAll = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.getAllStaff);
        api({ ...staffApi.getAllStaff })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const uploadAndOnboardStaffs = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.uploadAndOnboardStaffs, body})
        .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
  };

export const notifyAgain = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.notifyAgain);
        staffApi.notifyAgain.query.emailId= encodeURIComponent(staffApi.notifyAgain.query.emailId)
        api({ ...staffApi.notifyAgain })
            .then(({ data, message }) => {
                resolve(Toast({ type: 'success', message }));
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getAllJobFunctionCategoriesByAgency = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, staffApi.getJobFunctionCategoriesAgency);
      api({ ...staffApi.getJobFunctionCategoriesAgency, query })
        .then(({ data,message }) => {
          resolve(data); 
        //   Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getAllJobFunction = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, staffApi.getJobFunction);
      api({ ...staffApi.getJobFunction, query })
        .then(({ data,message }) => {
          resolve(data); 
        //   Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };


export const getStaffById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.getStaffId);
        api({ ...staffApi.getStaffId })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};
export const getAllRegions = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.getRegions);
        api({ ...staffApi.getRegions })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const addStaff = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.postAddStaff, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};
export const updateStaff = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.updateStaff, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};

export const uploadStaffPayRates = (body,query) => (dispatch,getState, {api, Toast}) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.uploadStaffPayRates);
        api({ ...staffApi.uploadStaffPayRates, body, query })
            .then((response) => {
                resolve(response);
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
}

export const inviteStaff = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.postStaffInvite, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};
 

export const updateDeleteFlag = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.deleteStaffFlag);
        api({ ...staffApi.deleteStaffFlag })
            .then(({ data, message }) => {
                resolve(data);
                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const permanentDelete = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.permanentDelete);
        api({ ...staffApi.permanentDelete })
            .then(({ data, message }) => {
                resolve(data);
                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getPermissionByAgency = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.getPermissionAgency);
        api({ ...staffApi.getPermissionAgency })
            .then(({ data, message }) => {
                resolve(data);
                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};
 
export const updateGeneral = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.updateGeneral, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};
export const updatePreferences = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.updatePreferences, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};

export const updateStaffPermission = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.updateStaffPermission, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};

export const updateStaffPayRate = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.updateStaffPayRate, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};

export const updateStaffCredentials = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.updateStaffCredentials, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};


export const staffChangePassword = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, staffApi.staffChangePassword);
      staffApi.staffChangePassword.query.emailId=encodeURIComponent(staffApi.staffChangePassword.query.emailId)
      api({ ...staffApi.staffChangePassword })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const staffVerifyOTP = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, staffApi.staffOtpVerify);
      staffApi.staffOtpVerify.query.emailId=encodeURIComponent(staffApi.staffOtpVerify.query.emailId)
      api({ ...staffApi.staffOtpVerify })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const staffUpdatePassword = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...staffApi.staffUpdatePassword, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };


  export const deleteStaffPayRate = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.deleteStaffPayRate);
        api({ ...staffApi.deleteStaffPayRate })
            .then(({ data, message }) => {
                resolve(data);
                Toast({ type: "success", message });
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message, time:5000 }));
            });
    });
};