import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { store } from './helpers';

export const stompClients = {
}

 function connectToWebSocket(){
  const wsUrls = JSON.parse(process.env.REACT_APP_WEBSOCKET);
  function getPath (userType){
    return userType === '3' ? 'agency' : 'staff'
  }
  const token = localStorage.getItem('AuthToken');
  const userType = localStorage.getItem('userType')

  wsUrls.forEach((url) => {
        const ws = new SockJS(url);
    const stompClient = Stomp.over(ws);
    stompClient.debug = null
    stompClient.connect({ Authorization: "Bearer " + token }, function(frame) {
      const portString = url.match(/:(\d+)/)[1];
      const portNumber = parseInt(portString.slice(-2));
      
      if (portNumber) {
        stompClients[portNumber] = stompClient
        if(portNumber === 99){
          stompClient.subscribe("/users/topic/notify/count",function(data) {
            let NFCData = JSON.parse(data.body)
               store.dispatch({type:'UPDATE_NOTIFICATION_COUNT', payload:NFCData.message})
          });
        }else if(portNumber === 90 || portNumber === 95){
               stompClient.subscribe(`/users/topic/${getPath(userType)}/workrequest`,function(data) {
               let wrData = JSON.parse(data.body)
               store.dispatch({type:'UPDATE_NEW_WORK_REQUEST', payload:wrData.message})
            });
          stompClient.subscribe(`/users/topic/invoice`, function(data){
             let invoiceData = JSON.parse(data.body)
             store.dispatch({type: 'UPDATE_INVOICE_DATA', payload:invoiceData.message})
          })
          stompClient.subscribe(`/users/topic/deleteinvoice`, function(data){
            let deletedinvoiceData = JSON.parse(data.body);
            store.dispatch({type: 'DELETE_INVOICE_DATA', payload: deletedinvoiceData.message})
          })
        }else if(portNumber === 85 || portNumber === 87){
                stompClient.subscribe(`/users/topic/${getPath(userType)}/invite`,function(data) {
        let clientData = JSON.parse(data.body)
        if(Array.isArray(clientData.message)){
          store.dispatch({type:'UPDATE_CLIENT_INVITATIONS', payload:clientData.message})
        }else{
          store.dispatch({type:'UPDATE_CONNECTED_CLIENT', payload:clientData.message})
        }
      })
      stompClient.subscribe(`/users/topic/client/add`, function(data){
        let importClientData = JSON.parse(data.body);
        store.dispatch({type: 'UPDATE_IMPORT_CLIENT', payload: importClientData.message})
      })
      stompClient.subscribe(`/users/topic/client/billingrate`, function(data){
        let importClientBillrateData = JSON.parse(data.body);
        store.dispatch({type: 'UPDATE_IMPORT_CLIENT_BILLRATE', payload: importClientBillrateData.message})
      })
        }else if(portNumber === 91){
          stompClient.subscribe("/users/topic/permissionmenu",function(data) {
            let permissionData = JSON.parse(data.body)
            if(permissionData.message)
               store.dispatch({type:'UPDATE_PERMISSIONS', payload:permissionData.message})
          });
          stompClient.subscribe("/users/topic/staff/add", function(data){
            let staffData = JSON.parse(data.body)
            if(staffData.message)
              store.dispatch({type: 'UPDATE_CONNECTED_STAFF', payload: staffData.message})
          });
          stompClient.subscribe("/users/topic/staff/payrate", function(data){
            let payRateData = JSON.parse(data.body)
            if(payRateData.message)
              store.dispatch({type: 'UPDATE_CONNECTED_STAFF_PAYRATE', payload: payRateData.message})
          });
        }
      }
      store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:true})
    })
    ws.onclose = function() {
      store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:false})
  };
  window.addEventListener('offline', ()=>{
      ws.close()
    })
  })

}
  
export default connectToWebSocket