import React,{useEffect, useState} from "react";

import "./style.scss"

import whiteTriangle from 'assets/svg/white-triangle.svg'

const RejectAccordianStaffNew = (props)=>{

   
    const [data,setData] = useState(props.data);
    const [open,setOpeninput] = useState(false);
    
    const data1 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Lack of Assistance"){
            return true
        }
    })
    

    const data2 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Not meeting state guidelines*"){
            return true
        }
    })
    
    const data3 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unsafe work environment*"){
            return true
        }
    })

    const data4 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unavailable"){
            return true
        }
    })

    const data5 = data?.rejectReasons?.filter((elem)=>{
        if(elem=="Work loads are too large"){
            return true
        }
    })

    const data0 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Other"){
            return true
        }
    })

    useEffect(()=>{
        if(data0?.length>0){
            setOpeninput(true)
           }
           else if(data0?.length==0){
            setOpeninput(false)
           }
    },[data0])
    
    return (
        <>
       
     
           <div class="frame-2360">
            <img src={whiteTriangle} className="white-triangle-reject" />
                    <div class="frame-2355">
                          <div class="frame-2348">
                               <div class="select-reason-s-for-rejecting-work-request">
                               Reason(s) selected by staff for rejecting Work Request
                                </div>
                           </div>
                     </div>

                     <div className="rejected-staff-body-box"> 
                    
                           <div className="d-flex flex-column justify-content-start" style={{gap:'0.9rem'}}>
                             <div>
                            <input disabled className="input-check-box-rejected-reason mr-2" type="checkbox" checked={data1?.length>0?true:false} name="Lack of Assistance" value="1" /> <label className="rejected-reason-label" >Lack of Assistance </label> 
                            </div>
                            <div>
                             <input disabled className="input-check-box-rejected-reason mr-2" type="checkbox"  checked={data2?.length>0?true:false} name="Not meeting state guidelines*" value="2" /> <label className="rejected-reason-label"> Not meeting state guidelines*</label> 
                             </div>

                           <div>   <input disabled className="input-check-box-rejected-reason mr-2" type="checkbox"  checked={data3?.length>0?true:false} name="Unsafe work environment*" value="3" /> <label className="rejected-reason-label"> Unsafe work environment* </label>  </div> 
        
                            <div>  <input disabled className="input-check-box-rejected-reason mr-2" type="checkbox"  checked={data4?.length>0?true:false} name="Unavailable" value="4" /> <label className="rejected-reason-label"> Unavailable</label> </div>
                            
                            </div>


                            <div className="d-flex flex-column justify-content-between" style={{gap:'0.9rem'}}>
                            

                                <div>
                                          <div > <input disabled className="input-check-box-rejected-reason"   type="checkbox"  checked={data5?.length>0?true:false} name="Work loads are too large" value="5" />  <label className="rejected-reason-label"> Work loads are too large</label>  </div>
                                                  <div className="d-flex flex-column align-items-start"> <div> <input disabled className="input-check-box-rejected-reason" type="checkbox"  checked={data0?.length>0?true:false} name="Others" value="0" />  <label className="rejected-reason-label"> Other</label>  </div>

                                         { open &&
                                            <textarea className="input-check-box-rejected-reason other-message-box" rows="2" cols="30" value={data?.additionalComments}></textarea>}
                                       </div>
                                </div>



                                       <div className=""><input disabled  className="input-check-box-rejected-reason" type="checkbox" checked={data?.contactEnabled == true?true:false} /> <label className="rejected-reason-label">Please Contact Staff to Discuss</label></div>
                            </div>

                      </div>
            </div> 
      

        </>
    )
}

export default RejectAccordianStaffNew;