import React from 'react';
import "./style.scss";

export const NormalSwitch = ({
    className = "custom-checkbox",
    label = "",
    value = "",
    name = "",
    onChange,
    checked,
    disable = false,
}) => {

    return (
        // <label className={className}>
        //     <input
        //         type="checkbox"
        //         name={name}
        //         value={value}
        //         checked={checked}
        //         disabled={disable}
        //         onChange={({ target: { name, checked: Checked } }) => {
        //             onChange && onChange({ target: { name, value: Checked } })
        //         }}
        //     />
        //     <span className="checkbox-tick border-radius-circle">
        //         <i className="icon-tick-transparent border-radius-circle"></i>
        //     </span>
        //     {label ? <span className="label-txt fs-16 pl-2 ml-1">{label}</span> : ""}
        // </label>
        <div className="d-flex align-items-center">
            <label className="switch">
                <input type="checkbox"
                name={name}
                value={value}
                checked={checked}
                disabled={disable}
                onChange={({ target: { name, checked: Checked } }) => {
                    onChange && onChange({ target: { name, value: Checked } })
                }}
                />
                <span className="slider round"></span>
                
            </label>
            {label ? <span className="label-txt s2-m switch-label-text pl-2 ml-1">{label}</span> : ""}
        </div>
    )

}

