import React from 'react';
import { NormalButton, NormalSelect, NormalDate } from "component/common";
import Moment from "moment";
import moment from 'moment';
// import BreakHours from 'component/common/BreakHours';
import BreakHours from 'component/common/BreakHoursNew';

import "./style.scss"
import editIcon from 'assets/svg/edit.svg';
import clockIcon from 'assets/svg/mdi_access_time.svg';
import disableMap from 'assets/svg/Vector.svg';
import expandfeedback from "assets/svg/expand-feedback.svg";
import markerIconCin from 'assets/svg/map_cursors/clockIn.svg';
import marketIconCout from 'assets/svg/map_cursors/clockOut.svg';
import marketIconPause from 'assets/svg/map_cursors/pause.svg';
import marketIconResume from 'assets/svg/map_cursors/resume.svg';
import profile from '../../../assets/svg/Ellipse.png';
import flag from 'assets/svg/flag.svg'

import workHoursIcon from 'assets/svg/work-hours-time-icon.svg'
import overTimeIcon from 'assets/svg/over-time-work-hours-icon.svg'
import desktopIcon from 'assets/svg/desktop.svg'
import laptopIcon from 'assets/svg/laptop.svg'
import mobileIcon from 'assets/svg/mobile.svg'
import tabletIcon from 'assets/svg/tablet.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export function TimeSheetMarkNew(props) {

    const {setActivePointsFromStaffDetails, startDate, endDate, toggleMap} = props;

    let isVisible = false;
    const calculateHours = (date1, date2) => {
        let diff = (new Date(date1).getTime() - new Date(date2).getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    }

    const calculateBreakHours = (date1, date2,data) => {
        let diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
        diff /= (60 * 60);
        // console.log(diff)
        let diffBreak = (new Date(data[data.length - 1].time).getTime() - new Date(data[0].time).getTime()) / 1000;
        // console.log(diffBreak)
        diffBreak /= (60 * 60);
        let final = diff - diffBreak;
        // let hours= Math.abs(Math.round(diff));
        
        return Math.abs(Math.round(final));
    }
    let { pauseResumeData, clockIn, clockOut,status,staffWorkedHrs,pausedHours,overTimeHours,deviceDetails, disagreeTimeSheet} = props.data;
    // console.log("object", clockIn )
    // console.log(deviceDetails)
    return (
        <>
            {/* <div className="d-flex align-items-start mb-3"> */}
        {
            (status >= 4 || status===0) && clockIn !== null?
            <>
            <div className=" d-flex justify-content-between align-item-center mb-3 mt-2" style={{width:"75%",marginLeft:"2rem"}}>
                <div  className="d-flex align-items-center">
                <h6 className="font-weight-bold " style={{marginBottom:"0px"}}>Time Sheet</h6>
                </div>
                <div className= {disagreeTimeSheet == true? "cylindrical-label":""}>
                    {disagreeTimeSheet == true ? "Client Disagreed": ""}
                </div>
            </div>
            <div class="d-flex flex-column align-items-start" style={{width:'80%', marginLeft:'4rem'}}>
                <div class="step completed greenCir">
                    <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                    </div>

                    <div class="content-stepper">
                        <div className="row">
                            <div className="col-4">
                                <span class="ml-0 s2-r">{!!clockIn ?Moment(new Date(clockIn)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) :'Pending'}</span>
                            </div>
                            <div className="col-2 d-flex">
                            {!!clockIn && Object.keys(deviceDetails?.[0] || {}).length > 0 && <OverlayTrigger placement="top"
                             overlay={
                             <Tooltip>
                              Device Type: {deviceDetails?.[0]?.deviceType}<br />
                              Brand: {deviceDetails?.[0]?.brand}<br />
                              Model: {deviceDetails?.[0]?.model}<br />
                              OS Version: {deviceDetails?.[0]?.osVersion}<br />
                              Browser Version: {deviceDetails?.[0]?.browserVersion}<br />
                              Date/Time : {deviceDetails?.[0]?.timeStamp ? Moment(new Date(deviceDetails?.[0]?.timeStamp)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) : 'Not Available'}
                             </Tooltip>}>

                            <img src={deviceDetails?.[0]?.deviceType === 'desktop' ? laptopIcon : deviceDetails?.[0]?.deviceType === 'laptop' ? laptopIcon : deviceDetails?.[0]?.deviceType === 'tablet' ? tabletIcon : (deviceDetails?.[0]?.deviceType === 'mobile' || deviceDetails?.[0]?.deviceType === 'Mobile') ? mobileIcon : laptopIcon} alt="info icon" style={{width:"15px", marginLeft:"0.75rem"}} />
                            </OverlayTrigger>}
                            <span class="s1-r ml-2">Clock-in</span>
                            </div>
                            <div className="col-2">
                                {clockIn?(
                                <img src={markerIconCin} style={{cursor:'pointer'}} onClick={()=>{
                                                           setActivePointsFromStaffDetails(0); 
                                                           toggleMap();
                                                        }}/> ) :
                                                    <img src = {disableMap} style= {{ cursor :'not-allowed'}}
                                                    />
                                                    }
                            </div>
                            <div className="col-3">
                                {moment(clockIn)<moment(startDate) && !!clockIn? (
                                    <small className="text-success no-wrap s2-m">Clocked-In Early</small>
                                ) : (
                                    ""
                                )}
                                {moment(clockIn)>moment(startDate) && !!clockIn? (
                                    <small className="text-danger no-wrap s2-m">Clocked-In Late (Tardy)</small>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                    </div>
                </div>
                {
                    !!pauseResumeData && pauseResumeData.length ?

                        pauseResumeData.map((item, index) => {
                            return (
                                <div class={`step ${item.key === 'pause' ? 'active' : 'completed'}`}>
                                    <div class="v-stepper">
                                        <div class="circle"></div>
                                        <div class="line"></div>
                                    </div>

                                    <div class="content-stepper">
                                        <div className="row">
                                            <div className="col-4">
                                                <span class="fs-16 ml-0 s2-r">{Moment(item.time).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)}</span>
                                            </div>
                                            <div className="col-2 d-flex">
                                                { Object.keys(deviceDetails?.[index + 1] || {}).length > 0 && <OverlayTrigger placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            Device Type: {deviceDetails?.[index + 1]?.deviceType}<br />
                                                            Brand: {deviceDetails?.[index + 1]?.brand}<br />
                                                            Model: {deviceDetails?.[index + 1]?.model}<br />
                                                            OS Version: {deviceDetails?.[index + 1]?.osVersion}<br />
                                                            Browser Version: {deviceDetails?.[index + 1]?.browserVersion}<br />
                                                            Date/Time : {deviceDetails?.[index + 1]?.timeStamp ? Moment(new Date(deviceDetails?.[index + 1]?.timeStamp)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) : 'Not Available'}
                                                        </Tooltip>}>

                                                    <img src={deviceDetails?.[index + 1]?.deviceType === 'desktop' ? laptopIcon : deviceDetails?.[index + 1]?.deviceType === 'laptop' ? laptopIcon : deviceDetails?.[index + 1]?.deviceType === 'tablet' ? tabletIcon :  (deviceDetails?.[index + 1]?.deviceType === 'mobile' || deviceDetails?.[index + 1]?.deviceType === 'Mobile') ? mobileIcon : laptopIcon} alt="info icon" style={{width:"15px", marginLeft:"0.75rem"}} />
                                                </OverlayTrigger>}
                                                <span class="s1-r ml-2">{item.key === 'pause'? 'Pause':'Resume' }</span>
                                            </div>
                                            <div className="col-5">
                                                <img src={item.key ==='pause'?  marketIconPause : marketIconResume                                                  
                                                    
                                                   } style={{cursor:'pointer'}} onClick={()=>{
                                                           setActivePointsFromStaffDetails(index+1); 
                                                           toggleMap();
                                                        }}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })

                        : ''
                }
                <div class="step completed redCir">
                    <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                    </div>

                    <div class="content-stepper">
                        <div className="row">
                            <div className="col-4">
                                <span class="s2-r ml-0">{
                                    !!clockOut? Moment(new Date(clockOut)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) : 
                                    !!clockIn?'In Progress':
                                    'Pending'
                                    }</span>
                            </div>
                            <div className="col-2 d-flex">
                            {!!clockOut && Object.keys(deviceDetails?.[deviceDetails?.length - 1] || {}).length > 0 && <OverlayTrigger placement="top"
                             overlay={
                             <Tooltip>
                              Device Type: {deviceDetails?.[deviceDetails?.length - 1]?.deviceType}<br />
                              Brand: {deviceDetails?.[deviceDetails?.length - 1]?.brand}<br />
                              Model: {deviceDetails?.[deviceDetails?.length - 1]?.model}<br />
                              OS Version: {deviceDetails?.[deviceDetails?.length - 1]?.osVersion}<br />
                              Browser Version: {deviceDetails?.[deviceDetails?.length - 1]?.browserVersion}<br />
                              Date/Time : {deviceDetails?.[deviceDetails?.length - 1]?.timeStamp ? Moment(new Date(deviceDetails?.[deviceDetails?.length - 1]?.timeStamp)).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`) : 'Not Available'}
                             </Tooltip>}>

                            <img src={deviceDetails?.[deviceDetails?.length - 1]?.deviceType === 'desktop' ? laptopIcon : deviceDetails?.[deviceDetails?.length - 1]?.deviceType === 'laptop' ? laptopIcon : deviceDetails?.[deviceDetails?.length - 1]?.deviceType === 'tablet' ? tabletIcon : (deviceDetails?.[deviceDetails?.length - 1]?.deviceType === 'mobile' || deviceDetails?.[deviceDetails?.length - 1]?.deviceType === 'Mobile') ? mobileIcon : laptopIcon} alt="info icon" style={{width:"15px", marginLeft:"0.75rem"}} />
                            </OverlayTrigger>}
                                <span class="s1-r ml-2">Clock-out</span>
                            </div>
                            <div className="col-2">
                                {clockOut? (
                                <img src={marketIconCout} style={{cursor:'pointer'}} onClick={()=>{
                                                            if(!!clockOut){
                                                                setActivePointsFromStaffDetails(pauseResumeData?pauseResumeData.length+1:1); 
                                                            }
                                                           toggleMap();
                                                        }}/>)
                                                     : (
                                                    <img src = {disableMap} style= {{ cursor :'not-allowed'}} />
                                                     )
                                                    }

                            </div>
                            <div className="col-3">
                            {moment(endDate) < moment(clockOut) && !!clockOut? (
                                <small className="text-danger no-wrap s2-m">Clocked-Out Late</small>
                                ) : (
                                    ""
                                )}
                                {moment(endDate) > moment(clockOut) && !!clockOut? (
                                    <small className="text-danger no-wrap s2-m">Clocked-Out Early</small>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className=" d-flex flex-column justify-content-between  work-hours-beakup" >
                <div className=" d-flex justify-content-start work-hours-flex-gap" >
                    <div className=" d-flex  " id='work-hours-title'>
                        <img src={workHoursIcon} width={15} height={15}/>
                        <span class="s2-r ml-3">Work Hours</span>
                    </div>
                    <div className="" id='work-hours-time'>
                        <span class="fs-16">{!!clockIn ?Moment(clockIn).format('hh:mm:ss a'):'Pending'} - {!!clockOut? Moment(clockOut).format('hh:mm:ss a'):'Pending'}</span>
                    </div>
                    <div className=" " id='work-hours-total-time'>
                      
                       <span className='work-hours-text s2-m'>{staffWorkedHrs != undefined ?staffWorkedHrs.toFixed(4) :'Pending'} hours</span> 

                        <div className="flag-icon col-md-3">
                                                        { parseFloat(staffWorkedHrs).toFixed(4) > ((moment(endDate)- moment(startDate)) /(60*1000*60)) ? 
                                                       <>
                                                        <img className=" ml-2 cursor-pointer mt-1 " src={flag}/>
                                                        <span className='flagicon-msg mb-1'> Staff has worked more than expected time </span>
                                                        </> : ""}
                                                        </div> 
                    </div>
                </div>
                <BreakHours staffAvailable={true} data={{"pauseResumeData":!!pauseResumeData?pauseResumeData:[]}} />
                <div className="d-flex justify-content-start work-hours-flex-gap">
                    <div className=" " id='work-hours-title' >
                        <img src={clockIcon} width={15}/>
                        <span class="s2-r ml-3">Break Hours</span>
                    </div>
                    <div className="" id='work-hours-time'>
                        
                    </div>
                    <div className="" id='work-hours-total-time'>
                        {
                            pausedHours != undefined ?
                          
                            <span className="break-hours-text fs-14 s2-m">{pausedHours?.toFixed(4)} hours</span>
                            : <span className="break-hours-text fs-14 s2-m"> 0.0000 hours </span>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-start work-hours-flex-gap">
                    <div className=" " id='work-hours-title'>
                        <img src={overTimeIcon} width={15}/>
                        <span class="s2-r ml-3">Over Time Work Hours</span>
                    </div>
                    <div className="" id='work-hours-time'>
                      
                    </div>
                    <div className="" id='work-hours-total-time'>
                        <span className="over-time-work-hours s2-m">{!!overTimeHours ? overTimeHours.toFixed(4): '0.0000'} hours</span>
                    </div>
                </div>
                
                {/* Edit TimeSheet Reason */}
                {props.data.agencyEditTSNotes ? 
                    <div class="frame-2403 time-sheet-edit-reason mt-2">
                          <div class="frame-2399 time-sheet-reason-text">
                                <div class="hazard-reason">Edit Timesheet Reason</div>
                          </div>
                               <div class="frame-2400 time-sheet-reason-box">
                                      <div class="at-emergency-ward-got-hurt-due-to-human-error">
                                           {props.data.agencyEditTSNotes}
                                        </div>
                                </div>
                        </div> : null}

            </div>
            {/* Hazard Pay Reason */}
            <div class="frame-2406 ">
{props.data.claimHazardPay ? 
  <div class="frame-2403">
    <div class="frame-2399">
      <div class="hazard-reason s1-m">Hazard Reason</div>
    </div>

    <div class="frame-2400">
      <div class="at-emergency-ward-got-hurt-due-to-human-error s3-m">
      {props.data.hazardPayReason}
      </div>
    </div>
  </div> : null }
 
  {props.data.staffNotes ? 
  <div class="frame-2405">
    <div class="frame-2399">
      <div class="staff-notes s1-m">Staff Notes</div>
    </div>

    <div class="frame-2400">
      <div class="clocked-out-earlier-due-to-personal-emergency s3-m">
      {props.data.staffNotes.length> 140 ?
        `${props.data.staffNotes.slice(0, 140)}...`
         : props.data.staffNotes
       }
      </div>
      {
        props.data.staffNotes.length> 140 ? 
        <>
         <img src={expandfeedback}/>
         <div className='hover-staffnotes s3-r'>
            {props.data.staffNotes}
         </div>
        </>
        :""
      }
    </div>
  </div> : null }
</div>
            </>
            :
            <div className=" d-flex justify-content-center mb-3">
                {
                     status > 4 ?
                     <h6 className="font-weight-bold ">Staff Need to Start Work</h6>
                     :
                     <h6 className="font-weight-bold ">Need to Approve</h6>
                }
            </div>
        }
            {/* </div> */}
        </>

    )
}


