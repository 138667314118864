import React, {memo, useEffect, useState} from 'react'
import './style.scss'

import ReactJoyride , { STATUS, ACTIONS,EVENTS,LIFECYCLE,  } from 'react-joyride';
import { CompanyDetailsSteps, settingsPageSteps, settingsPageStepsCoAdmin} from './tourGuideSteps/settingsPageSteps';
import { useHistory } from 'react-router-dom';
import CustomComponent from './CustomComponent';
import { store } from 'service/helpers';
import { useSelector } from 'react-redux';
import { addWorkRequestSteps, workScheduleCalendarSteps, workScheduleListSteps } from './tourGuideSteps/workScheduleSteps';
import tourGuide, { startTourGuide } from 'reducer/tourGuide';
import { useDispatch } from 'react-redux';
import { clientManagementAddSteps, clientManagementSteps } from './tourGuideSteps/clientManagementSteps';
import { staffManagementAddStaff, staffManagementCardSteps, staffManagementSteps, staffManagementTeamDetails, staffManagementViewStaff } from './tourGuideSteps/staffManagementSteps';
import { timeSheetCalendarSteps, timeSheetReviewSteps } from './tourGuideSteps/timeSheetReviewSteps';
import { timeOffSteps } from './tourGuideSteps/timeOffSteps';
import { invoiceCreationSteps, invoiceEditSteps, invoiceSteps, invoiceViewSteps } from './tourGuideSteps/invoiceSteps';
import { reportsSteps } from './tourGuideSteps/reportsSteps';
import { auditLogsSteps } from './tourGuideSteps/auditLogsSteps';
import Modal from './Modal/Modal';
import {  changePlanSteps, managePLansSteps, paymentTransactionSteps, subscriptionDetails } from './tourGuideSteps/subscriptionManagement';

const TourGuide = (props) => {

let dispatch = useDispatch()

  const [tour, setTour] = useState({
    run:false,
    steps:[],
    stepIndex:0,
  })
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("")

  let tourGuideData = useSelector((state) => state.tourGuideReducer.tourGuideData);
  
  let pathName = window.location.pathname
  let history = useHistory()
  
let userType = localStorage.getItem('userType');

 const getTourSteps = (section) => {
  
   if(pathName === '/admin/settings'){
    if(section === 'company-details'){
      return  CompanyDetailsSteps
     }
     return userType === '3' ? settingsPageSteps :  settingsPageStepsCoAdmin
   }
   if(pathName === '/admin/workSchedule/list'){
    if(section === 'add-work-request'){
      return  addWorkRequestSteps
     }
    return workScheduleListSteps
  }
  if(pathName === '/admin/workSchedule/calendar'){
    return [ ...workScheduleListSteps.slice(0,8), ...workScheduleCalendarSteps]
  }
  if(pathName === '/admin/timeSheet/list'){
    return timeSheetReviewSteps
  }
  if(pathName === '/admin/timeSheet/calendar'){
    return [ ...timeSheetReviewSteps.slice(0,3),  ...timeSheetCalendarSteps]
  }
  if(pathName === '/admin/clientOnboarding'){
    return clientManagementSteps
  }
  if(pathName === '/admin/staffManagement/list'){
    return staffManagementSteps
  }
  if(pathName === '/admin/staffManagement/card'){
    return [...staffManagementSteps.slice(0,1), ...staffManagementCardSteps]
  }
  if(pathName.toLowerCase().includes('/admin/staffManagement/addStaff'.toLowerCase())){
    const targetElement = document. querySelector('.nav-tabs > a:nth-child(5)');
    if(targetElement){
      return staffManagementAddStaff
    }
    return staffManagementAddStaff.slice(0,4)
  }
  
  if( pathName.includes('/Admin/staffManagement/StaffDetail')){
    const targetElement = document. querySelector('.nav-tabs > a:nth-child(5)');
    if(targetElement){
      return staffManagementViewStaff
    }
    return staffManagementViewStaff.slice(0,4)
  }
  if(pathName.includes('/Admin/staffManagement/teamDetails')){
    return staffManagementTeamDetails
  }
  if(pathName.includes('/admin/clientOnboarding/addClient')){
    return clientManagementAddSteps
  }
  if(pathName === '/admin/timeOff'){
    return timeOffSteps
  }
  if(pathName === '/admin/invoice'){
    return invoiceSteps
  }
  if(pathName === '/Admin/invoice/createInvoice/add'){
    return invoiceCreationSteps
  }
  if(pathName.includes('admin/invoice/createInvoice')){
    return invoiceEditSteps
  }
  if(pathName.includes('/viewInvoice')){
    return invoiceViewSteps
  }
  if(pathName === '/admin/report'){
    return reportsSteps
  }
  if(pathName === '/admin/auditLogs'){
    return auditLogsSteps
  }
  if(pathName === '/admin/subscription/manageplans'){
    return [...subscriptionDetails, ...managePLansSteps]
  }
  if(pathName.includes('/admin/subscription/manageplans/changeplan')){
    return changePlanSteps
  }
  if (pathName === "/admin/subscription/paymenttransactions") {
    const transactionDetailElement = document.querySelector('.transaction-detail');
    if (transactionDetailElement) {
        return paymentTransactionSteps;
    }
     return paymentTransactionSteps.slice(0, 4);
  }
 
 }

 // Checking if the click event's target is not a part of the Joyride tooltip and turning the tour guide off if it is not
 useEffect(() => {
  const handleOutsideClick = (event) => {
    if (tour.run) {
      const tooltipElement = document.querySelector('.react-joyride__tooltip');
      if (tooltipElement && !tooltipElement.contains(event.target)) {
        dispatch(startTourGuide({ run: false, stepIndex: 0 }));
      }
    }
  };

  // click event listener for the document body
  document.body.addEventListener('click', handleOutsideClick);

  return () => {
    document.body.removeEventListener('click', handleOutsideClick);
  };
}, [tour.run, dispatch]);

useEffect(()=>{
  setTour({
            ...tour,
            run:tourGuideData.run,
            steps: getTourSteps(tourGuideData.section),
            stepIndex:tourGuideData?.stepIndex ? tourGuideData?.stepIndex : 0
           })
},[
  tourGuideData,
   pathName
  ])


  const handleJoyrideCallback = (data) => {
    const { status,index, action, lifecycle, type, size} = data;

    // subscription view plan
    if(size === 0 && pathName === '/admin/subscription/manageplans/viewplan/features' || pathName === '/admin/subscription/manageplans/viewplan/staff') {
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true)
      setModalMessage("To change your plan -> Go back to manage plans. Click on change plan and then select your desired plan")
      return;
    }

    //subscription change plan
    if(pathName.includes('/admin/subscription/manageplans/changeplan')  && index === 0 && action === 'start' && lifecycle === 'init'){
      history.replace('features')
    }
    if(pathName.includes('/admin/subscription/manageplans/changeplan')  && index === 0 && action === 'next' && lifecycle === 'complete'){
      dispatch(startTourGuide({run:true, stepIndex:1}))
    }

if(pathName === '/admin/subscription/manageplans/changeplan/features' && index === 3 && action === 'next'){
  history.replace('staff')
  dispatch(startTourGuide({run:true, stepIndex:3}))
  return 
}
if(pathName === '/admin/subscription/manageplans/changeplan/staff' && index === 8 && action === 'next'){
  history.replace('paymentdetails')
  dispatch(startTourGuide({run:true, stepIndex:8}))
  return 
}

if(pathName === '/admin/subscription/manageplans/changeplan/staff' && index === 3 && action === 'prev' && lifecycle === 'complete'){
  history.replace('features')
  dispatch(startTourGuide({run:true, stepIndex:2}))
  return 
}
if(pathName === '/admin/subscription/manageplans/changeplan/paymentdetails' && index === 8 && action === 'prev' && lifecycle === 'complete'){
  history.replace('staff')
  dispatch(startTourGuide({run:true, stepIndex:7}))
  return 
}
 
    if(!data.step){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      return;
    }
    if (action === ACTIONS.SKIP && pathName.includes('/admin/subscription/manageplans/changeplan')) {
      dispatch(startTourGuide({run:false, stepIndex:0}))
      return;
    }
    if (action === ACTIONS.SKIP) {
      dispatch(startTourGuide({run:false, stepIndex:index}))
      return;
    }
    if (action === ACTIONS.CLOSE) {
      dispatch(startTourGuide({run:false}))
      return;
    }

    if (action === ACTIONS.PREV && lifecycle ===  LIFECYCLE.COMPLETE) {
      setTour((prevTour)=>{
        return{
          ...prevTour,
          stepIndex: prevTour.stepIndex -1
        }
      })
      return;
    }

    if (action === ACTIONS.NEXT && lifecycle ===  LIFECYCLE.COMPLETE) {
      setTour((prevTour)=>{
        return{
          ...prevTour,
          stepIndex: prevTour.stepIndex +1
        }
      })
      return;
    }
    if (type === 'tour:end') {
      dispatch(startTourGuide({run:false, stepIndex:0}))
      if(pathName === '/admin/settings'){
        localStorage.setItem('settingsTour', 'true')
        return;
      }
      if(pathName === '/admin/workSchedule/list'){
        localStorage.setItem('workScheduleTour', 'true')
        return;
      }
      if(pathName === '/admin/timeSheet/list'){
        localStorage.setItem('timeSheetTour', 'true')
        return;
      }
      if(pathName === '/admin/staffManagement/list'){
        localStorage.setItem('staffTour', 'true')
        return;
      }
      if(pathName === '/admin/clientOnboarding'){
        localStorage.setItem('clientTour', 'true')
        return;
      }
      if(pathName === '/admin/invoice'){
        localStorage.setItem('invoiceTour', 'true')
        return;
      }
      if(pathName === '/admin/timeOff'){
        localStorage.setItem('timeOffTour', 'true')
        return;
      }
      if(pathName === '/admin/report'){
        localStorage.setItem('reportsTour', 'true')
        return;
      }
      if(pathName === '/admin/auditLogs'){
        localStorage.setItem('auditLogsTour', 'true')
        return;
      }
      if(pathName === '/admin/subscription/manageplans'){
        localStorage.setItem('subscriptionTour', 'true')
        return;
      }
      
      return;
    }

    if(type === 'error:target_not_found' && (pathName === "/admin/workSchedule/list" || pathName === "/admin/workSchedule/calendar")){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no work requests yet!")
      return;
    }
    if(type === 'error:target_not_found' && (pathName === "/admin/timeSheet/list" || pathName === "/admin/timeSheet/calendar")){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no time sheet records yet!")
      return;
    }
    if(type === 'error:target_not_found' && (pathName === "/admin/staffManagement/list" || pathName === "/admin/staffManagement/card")){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no staff yet!")
      return;
    }
    if(type === 'error:target_not_found' && pathName === "/admin/clientOnboarding"){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no clients yet!")
      return;
    }
    if(type === 'error:target_not_found' && pathName === "/admin/invoice"){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no invoices available.")
      return;
    }
    if(type === 'error:target_not_found' && pathName === "/admin/timeOff"){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no time off records.")
      return;
    }
    if(type === 'error:target_not_found' && pathName.toLowerCase().includes('/admin/staffManagement/addStaff'.toLowerCase()) ){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      return ;
    }
    if(type === 'error:target_not_found' && pathName.includes('/admin/subscription/manageplans/changeplan')){
      setTour((prev)=>{
       return {
         ...prev,
         stepIndex:index+1
       }
      })
         return;
      }
    if(type === 'error:target_not_found' && pathName.includes('/admin/subscription')){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there's no subscription plan.")
      return;
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGoBack = () => {
    setShowModal(false);
    history.push("/admin/subscription/manageplans")
  };

  useEffect(() => {
    handleCloseModal()
  }, [pathName])


  return (<>
  {showModal && (
      <Modal
        message={modalMessage}
        onClose={handleCloseModal}
        goBack={handleGoBack}
      />
    )}
   <ReactJoyride
   callback={handleJoyrideCallback}
   stepIndex={tour.stepIndex}
   run={tour.run}
   steps={tour.steps}
   continuous
   hideCloseButton
   scrollToFirstStep
   scrollOffset={100}
   scrollDuration={1}
   showProgress
   showSkipButton
   spotlightPadding={0}
   disableOverlayClose
   floaterProps={{
    disableAnimation:false,
   }}
   styles={{
    options: {
      arrowColor: 'white',
      backgroundColor: 'white',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#180065',
      textColor: 'black',
      width: 'max-content',
      zIndex: 99999,
    },
    overlay: { height: '100vh' },
    tooltip: {
      fontFamily: 'Poppins, Josefin Sans, Lora, sans-serif',
      fontSize: '15px',
      fontWeight: '500',
    },
    buttonSkip: {
      fontFamily: 'Montserrat, Josefin Sans, Poppins, Lora, sans-serif',
      fontSize: '14px',
      fontWeight: '500',
    },
    buttonBack: {
      fontFamily: 'Montserrat, Josefin Sans, Poppins, Lora, sans-serif',
      fontSize: '15px',
      fontWeight: '400',
    },
    buttonNext: {
      fontFamily: 'Montserrat, Josefin Sans, Poppins, Lora, sans-serif',
      fontSize: '15px',
      fontWeight: '500',
    },
  }}
   />
  </>)
}

export default memo(TourGuide)