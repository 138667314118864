
import { teamApi, staffApi } from '../service/apiVariables';
import { addQuery } from '../service/helperFunctions';


export const getAllUser = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, teamApi.getAllUser);
        api({ ...teamApi.getAllUser })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getAllUserList = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, teamApi.getAllUserList);
        api({ ...teamApi.getAllUserList })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const generateBillingOrPayRateTemplate = (query) => (dispatch, getState, {api, Toast}) => {
    return new Promise((resolve, reject) => {
        addQuery(query, teamApi.generateBillingOrPayRateTemplate);
        api({ ...teamApi.generateBillingOrPayRateTemplate })
            .then((data) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
}

export const postCreateTeam = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...teamApi.postAddTeam, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};


export const getTeamById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, teamApi.getTeamById);
        api({ ...teamApi.getTeamById })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getDashboardHeader = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, teamApi.getTeamHeader);
        api({ ...teamApi.getTeamHeader })
            .then(({data}) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const putTeamEdit = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...teamApi.putTeamEdit, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};

export const getAllManagers = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.getAllManagers, body })
            .then(({ message, data }) => {
                resolve(data);

                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};

export const getAllCountries = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.getAllRegions, body })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};

export const addTeam = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.addTeams, body })
            .then(({ message, data }) => {
                resolve(data);
                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};
export const updateTeam = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        api({ ...staffApi.updateTeams, body })
            .then(({ message, data }) => {
                resolve(data);
                Toast({ type: "success", message, time: 5000 });
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};