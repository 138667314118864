import React,{useState} from 'react';
import { NormalButton,NormalSelect,NormalDate } from "component/common"
import "./style.scss"
import editIcon from 'assets/svg/edit.svg';
import deleteIcon from 'assets/svg/delete.svg';
import Select from 'react-select';
import profile from '../../../assets/svg/Ellipse.png';

const weekDaysList=[
    {label:'Sunday',value:'sunday'},
    {label:'MonDay',value:'monday'},
    {label:'Tuesday',value:'tuesday'},
    {label:'Wednesday',value:'wednesday'},
    {label:'Thursday',value:'Thursday'},
    {label:'Friday',value:'friday'},
    {label:'Saturday',value:'saturday'},
   
 ]
export function RepeatModel() {

    let isVisible= false;
    const [workingDays, setworkingDays] = useState([]);
    return (
        <>
        {
            isVisible?
            <div id="repeat-position">
                <div className=" d-flex justify-content-between mb-3">
                    <h6 className="font-weight-bold ">Repeat</h6>

                    <div className="d-flex ">
                        <img className=" ml-3 " src={deleteIcon}/>
                    </div>
                </div>
                <div className="row align-items-center mb-3">
                    <div className="col-4">
                        <div className="form-group">
                            
                            <NormalSelect
                                placeholder="Weekly"
                                name="clentName"
                                value={"Clientname"}
                                className="form-control rounded-0"
                                onChange={()=>{}}
                                label={'Repeat'}
                            />
                        </div>    
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <NormalSelect
                                placeholder="1"
                                name="clentName"
                                value={"Clientname"}
                                className="form-control rounded-0"
                                onChange={()=>{}}
                                label={'Every'}
                            />
                            
                        </div>    
                    </div>
                    <div className="col-5">
                        <div className="form-group mb-0 mt-1">
                            <p className="mb-0">Week</p>
                            
                        </div>    
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 ">
                        <p className="fs-16 my-2">Repeat days</p>
                    </div>
                    <div className="col-8">
                        <div className="form-group">
                        <Select
                            isMulti
                            options={weekDaysList}
                            className="basic-multi-select form-control-multiselect"
                            value={workingDays}
                            name="working"
                            onChange={(e) => { 
                                if(!!e){
                                let copy = [...workingDays];
                                copy.push(e)
                                setworkingDays(e);
                                }else{
                                setworkingDays([]);
                                }
                            }}
                        />
                        </div>
                    </div>
                    <div className="col-4 ">
                        <p className="fs-16 my-2">Working days</p>
                    </div>
                    <div className="col-8 mb-2">
                        <div className="d-flex flex-row week-days">
                        {
                            weekDaysList.map((item,index)=>{
                                return(
                                <button key={index} className={`${!!workingDays&&workingDays.findIndex((ar)=>ar.value === item.value) !== -1? 'active':''}`}>{item.label.substr(0,3)}</button>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mb-3">
                    <div className="col-12">
                        <p>For these days</p>
                        <ul className="days">
                            <li>
                                Sun 
                            </li>
                            <li>
                            Mon 
                            </li>
                            <li>
                            Tue
                            </li>
                            <li>
                            Wed
                            </li>
                            <li>
                            Thur 
                            </li>
                            <li>
                            Fri 
                            </li>
                            <li>
                            Sat
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row align-items-center mb-3">
                    <div className="col-3 ">
                        <div className="form-group">
                            
                            <NormalSelect
                                placeholder="Date"
                                name="clentName"
                                value={"Clientname"}
                                className="form-control rounded-0"
                                onChange={()=>{}}
                                label={'End Repeat Date'}
                            />
                        </div>    
                    </div>
                    <div className="col-4 ">
                        <div className="form-group">
                            <NormalDate
                                placeholder="MM/DD/YYY"
                                name="username"
                                // value={emailOrMobNum}
                                className="form-control rounded-0"
                                // onChange={this.handleChange}
                                label={"End Repeat Date*"}
                            />
                            
                        </div>    
                    </div>
                </div>
                <div className="row align-items-center mb-3">
                    <div className="col-8 ">
                           <h6 className="font-weight-normal uppercase mb-3">Summary</h6>
                           <p className="timing-txt-summery">
                            Weekly on monday, tuesday,wednesday,thursday,friday 
                                until Oct 14,2020       
                           </p>
                    </div>
                    
                </div>
                <div className="d-flex align-items-end mt-5 justify-content-end ">
                     <NormalButton
                        onClick={()=>{}}
                        id="Confirm"
                        label="Okay"
                        outline={false}
                        // loader={isFormLoder}
                        className="mb-2 btn fs-14 btn-violet-primary btn-block form-btn"
                    />
            </div>
            </div>
            :
            ''
        }
    </>

    )
}


