import React from 'react'
import './style.scss'
import Warning from '../../../assets/svg/warning-sign.svg'
import CustomLoader from '../CustomLoader';
const WarningModal = (props) => {
    let {label, handleNo, handleYes,data, loading = false} = props;  
  return (
    <div className='global-box-warning'>
        <div className='main-box-warning'>
                <div className='warning-header'>
                    <img className='warning-sign' width={'40px'} src={Warning}/>
                    <span className='fs-16 h3-m'>{label}</span>
                </div>
                {props.children}
                <div className='warning-body d-flex justify-content-center align-items-center'>
                     <div id='no-button' className='no-button cta-1-m cursor-pointer' onClick={handleNo}>No</div>
                     <div id='yes-button' className={`yes-button cta-1-r  ${loading == true ? 'no-drop' : 'cursor-pointer'}`} onClick={()=>{
                      if(loading === false)
                      handleYes(data)
                    }}>Yes</div>
                     {loading === true ? <CustomLoader/> : null}
                </div>
        </div>
    </div>
  )
}

export default WarningModal