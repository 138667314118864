import React, { useState, useEffect } from 'react'
import './style.scss'
import moment from 'moment';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { acceptClientInvite, getClientInvitations, notifyUsers } from 'action/ClientInvites';
import { getClientById } from 'action/clientPortalAction';
import { NormalButton } from 'component/common';
import messageIcon from '../../../assets/svg/message.svg'
import { NormalToUSInput } from 'component/common/FormatContact';
import { maskEmailAddress } from 'service/utilities';
import DisplayImage from 'component/common/DisplayImage';
import dummyImage from "assets/images/dummy-image.png"

const getTimeElapsedString = (dateString) => {
const utcDate = dateString; 
const localDate = moment.utc(utcDate).local().format('YYYY-MM-DD HH:mm:ss');
  const date = new Date(localDate);
  const now = new Date();
  const elapsed = now - date;
  const elapsedSeconds = Math.floor(elapsed / 1000);
  if (elapsedSeconds < 60) {
    return `${elapsedSeconds} seconds ago`;
  } else if (elapsedSeconds < 3600) {
    const minutes = Math.floor(elapsedSeconds / 60);
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (elapsedSeconds < 86400) {
    const hours = Math.floor(elapsedSeconds / 3600);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else {
    const days = Math.floor(elapsedSeconds / 86400);
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  }
}


const ClientInvitationBox = (props) => {
let {acceptClientInvite, clientInviteData, handleClose, viewType,getClientById, loadClientInvitations,fetchAllUsers, view, getClientInvitations, notifyUsers}= props
    const [fullView, setFullView] = useState(false)
    const [,setLoading] = useState(false)
    const [loadingKey, setLoadingKey] = useState('')
    const [replyMessage, setreplyMessage] = useState({status:false, message:''})
    const [clientDetails, setClientDetails] = useState({})

     const {clientName,} = clientDetails
     const {clientContacts, clientAddress} = clientDetails
    
     const [clientData, setClientData] = useState({...clientInviteData})
     const [viewStatus, setViewStatus] = useState(viewType)

    const loadClientDetails = async(query)=>{
      setLoading(true)
      await getClientById(query)
      .then((data)=>{
           setClientDetails(data)
           setLoading(false)
      })
      .catch((err)=>{
        setLoading(false)
      })
    }
    useEffect(()=>{
       if(clientData.clientId){
        loadClientDetails({id:clientData.clientId})
       }
    },[clientData])

  const getUpdatedStatus = async () =>{
    await getClientInvitations()
    .then((data)=>{
     
      let index = data.findIndex(data => data.clientId === clientData.clientId)
      if(index !== -1){
        setClientData({...clientData, ...data[index]})
        setViewStatus(data[index].status)
        
      }
    })
  }
 
    useEffect(()=>{
   if(view === 'notification' && clientData.clientId)
   getUpdatedStatus()
    },[view])

    const notifyClient = async(query) =>{
      await notifyUsers(query)
      .then((res) => {
        
      })
      .catch((err) => console.log(err))
    }
    
    const acceptInviteAPI = async(query) =>{
      await acceptClientInvite(query)
      .then((data)=>{
        setLoadingKey('')
        const notifyQuery = { ...query, clientName: clientName }
        notifyClient(notifyQuery)
        if(clientData.show){
          handleClose()
          return;
        }
        // loadClientInvitations()
        // fetchAllUsers()
        
      })
      .catch((err)=>{
        setLoadingKey('')
        if(clientData.show){
          handleClose()
          return;
        }
        // loadClientInvitations()
      })
      
    }
    let userData = JSON.parse(localStorage.getItem('userData'))
    let email = clientDetails?.emailId
    const handleAcceptInvite = async() => {
      setLoadingKey('accept-invite')
      let query ={clientId:clientData.clientId, message:replyMessage.message, status:1, email:encodeURIComponent(email)}
      acceptInviteAPI(query)
    }
    // const handlePendingInvite = async() => {
    //   let query ={clientId:clientData.clientId, message:replyMessage.message, status:0, email:encodeURIComponent(email)}
    //   acceptInviteAPI(query)
    // }
    const handleDeclineInvite = async() => {
      setLoadingKey('decline-invite')
      let query ={clientId:clientData.clientId, message:replyMessage.message, status:2, email:encodeURIComponent(email)}
      acceptInviteAPI(query)
    }

    const maskNumber = (phoneNumber) =>{
      let maskedNumber = phoneNumber.split('')
      maskedNumber[2] = '*'
      maskedNumber[7] = '*'
      maskedNumber[11] = '*'
      maskedNumber[12] = '*'
      maskedNumber.join('')
      return maskedNumber
    }
    
  return (
    <div className='client-invitaion-global-box'>
        <div className='client-invitaion-main-box'>

          {clientData.show ? 
          <div className='d-flex justify-content-end align-items-start'>
            <div className='cursor-pointer font-weight-bold text-dark' onClick={handleClose}>X</div>
           </div> : null}

                  <div className='d-flex'>
                    <div className={`${clientAddress?.[0].sitePicture && 'client-logo'}`}>
                      {/* {clientAddress?.[0].sitePicture ? 
                    <img  style={{ height: "100%", width: "100%" }}
                        className="client-logo"
                        src={clientAddress?.[0].sitePicture && clientAddress?.[0].sitePicture.split("|").length>1 && clientAddress?.[0].sitePicture.split("|")[1]}
                        alt="sitePicture"  
                        /> : null} */}
                    {
                      clientAddress?.[0].sitePicture ? (
                        clientAddress?.[0].sitePicture.includes("data:image/") ? (
                          <img
                            style={{ height: "100%", width: "100%" }}
                            className="client-logo"
                            src={
                              clientAddress?.[0].sitePicture &&
                              clientAddress?.[0].sitePicture.split("|").length > 1 &&
                              clientAddress?.[0].sitePicture.split("|")[1]
                            }
                            alt="sitePicture"
                          />
                        ) : (
                          <DisplayImage
                            clientId={clientDetails.id}
                            entity={"client"}
                            imageId={clientAddress?.[0].sitePicture}
                          />
                        )
                      ) : <img src={dummyImage} alt="dummy-img"/>
                    }
                    </div>
                    <div className='w-100 d-flex justify-content-between align-items-start cursor-pointer ' onClick={()=> {
                      setFullView(!fullView)
                    }}>
                          <div className='client-title-box'>
                                   <div className='h2-sb'>{clientName}</div>
                                    <div className='client-view-button ele-m'>{fullView ? "Hide Details" : 'View Details'}</div> 
                          </div>
                          <div className='d-flex justify-content-center align-items-center'>
                            <label className='s3-m'>{getTimeElapsedString(clientData.createdAt)}</label>
                          </div>
                    </div>
                  </div>
                  { viewStatus !==1 && viewStatus !==2 &&
                  <div className='w-100 d-flex justify-content-end align-items-center'>
                            <div className='cursor-pointer' onClick={()=> setreplyMessage({status:!replyMessage.status})}><span className='s2-r text-black-50'>Reply</span> <img src={messageIcon} alt='reply'/></div>
                  </div>}

                  {!replyMessage.status ? 
                  <div className='w-100 position-relative'>
                    {clientData?.clientMessage ? <div className={`h3-r ${fullView ? 'client-message-box-full-view' : 'client-message-box'}`} readOnly={true} > {fullView ? clientData.clientMessage : clientData.clientMessage.slice(0,100) }  </div> : <p className='h3-r'>No Message from client.</p> }
                       {!fullView && !!clientData?.clientMessage ?  <div className='hide-message '></div>: null}
                  </div>: null}

                  {replyMessage.status ? 
                  <div className='w-100'>
                     <textarea  className='w-100 border-0' value={replyMessage.message} onChange={(e)=> setreplyMessage({...replyMessage, message:e.target.value})} placeholder='Enter Message to Client' disabled={viewStatus === 4}></textarea>
                  </div> : null}
                  
                           
                           {fullView ? <>
                        
                         <div className='d-flex justify-content-center align-items-center mt-3 ' style={{gap:'10px'}}>
                              <div className='view-client-profile flex-column'>
                                 <p className='mb-0 h3-m'>Client Name</p>
                                 <p className='mb-0 s1-r'>{clientContacts?.[0].contactPerson}</p>
                                </div>
                                <div className='view-client-profile flex-column'>
                                 <p className='mb-0 h3-m'>Company Name</p>
                                 <p className='mb-0 s1-r'>{clientName}</p>
                                </div>
                         </div> 

                                 <div className='view-client-profile '>
                                 <p className='mb-0 field-name  h3-m' >Contact Number</p>
                                 <p className='mb-0 s1-r'>{viewStatus === 1 ? NormalToUSInput(clientContacts?.[0].contactNumber) : maskNumber(NormalToUSInput(clientContacts?.[0].contactNumber))}</p>
                                </div>

                                <div className='view-client-profile '>
                                 <p className='mb-0 field-name h3-m'>Email</p>
                                 <p className='mb-0 s1-r'>{viewStatus === 1 ? clientContacts?.[0].contactEmail : maskEmailAddress(clientContacts?.[0].contactEmail)}</p>
                                </div> 
                             
                                </> : null}

                  { viewStatus === 0 ?
                  <div className='client-action-buttons'>
                            <NormalButton
                        onClick={handleDeclineInvite}
                        label="Decline"
                        loader={loadingKey === 'decline-invite'}
                        disabled={loadingKey === 'decline-invite'}
                        outline={false}
                        className="btn-light-primary"
                        />
                        <NormalButton
                        onClick={handleAcceptInvite}
                        label="Accept"
                        loader={loadingKey === 'accept-invite'}
                        disabled={loadingKey === 'accept-invite'}
                        outline={false}
                        className="btn-dark-primary"
                        />
                            
                  </div>: null}
                  {viewStatus === 1 ? 
                  <div className='d-flex justify-content-between align-items-end'>
                    <div> 
                      <p className='bottom-label-approved h3-r'>Approved</p>
                     <p className='bottom-label-approved num-sb'>{new Date(clientData.updatedAt).toLocaleDateString()}</p>
                     </div>
                   
                    <div className='approved-by s2-r'>by {clientData?.userMail ? clientData?.userMail:'-'}</div>
                  </div> : null}
                  {viewStatus === 2 && 
                  <div className='client-action-buttons'>
                            <div className='approve-button declined-button cursor-pointer '  ><label className='mb-0 cursor-pointer'>Declined</label></div>
                            
                  </div>}
        </div>
    </div>
  )
}



const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      { acceptClientInvite, getClientById, getClientInvitations, notifyUsers },
      dispatch
  );
};

export default connect(null, mapDispatchToProps)(ClientInvitationBox);