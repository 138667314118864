import React,{useEffect,useState} from 'react';
import { NormalModal,CommonPageLoader } from "component/common"
import "./style.scss";
import { withRouter } from "react-router-dom";
import { NormalButton } from 'component/common';
import StaffDetails from 'component/workShedule/staffDetails';
import { 
    getSingleWork,
    workCompletedByAgency, 
    approveStaff,
    rejectStaff,
} from "action/workRequest";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Moment from "moment";
import { getClassNameForBackground, getClassNameForLeftBorder } from "service/utilities";

import bagIcon from 'assets/svg/bag.svg';
import tickIcon from 'assets/svg/tick.svg';
import markerIcon from 'assets/svg/Vector.svg';
import approveIcon from 'assets/svg/approve.svg';
import axios from 'axios';
import { getServiceUrl } from 'service/api';
import { documentDownloadFromMDB } from 'service/apiVariables';
import { StaffStatusLabels } from 'pages/Admin/workSchedule';
import RejectAccordian from 'component/common/RejectAccordian/RejectAccordian';
import RejectAccordianStaff from 'component/common/RejectAccordianStaff/RejectAccordianStaff';

function WorkAssignTimeSheet({
    getSingleWork,
    assignTimeSheet,
    setAssignTimeSheet,
    handleReload,
    workCompletedByAgency,
    approveStaff,
    rejectStaff,
}) {
    const [data, setData] = useState({});
    // const [clientId, setClientId] = useState('');
    const [workID, setWorkID] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isFormLoder, setFormLoder] = useState(false);
    const [reject, setReject] = useState([])

    useEffect(() => {
        if (!!assignTimeSheet.id) {
            fetchWorkById(assignTimeSheet.id);
            setWorkID(assignTimeSheet.id)
        }
    }, [assignTimeSheet]);
    // get work by workId
    const fetchWorkById = async (id) => {
        setLoading(true);

        const query = { 'id': id };
        // console.log(query)
        await
            getSingleWork(query)
                .then((data) => {
                    // console.log(data)
                    setData(data)
                    // console.log('sdgsdgdsg',modifyData);
                    // setWorkList(modifyData.length ? modifyData : []);
                    setLoading(false);
                })
                .catch((e) => setLoading(false));
    };

    const fetchFileDownloadURLById = async (id, filename='none.png', type="image/png") => {
      setLoading(true);
      
      if(filename.split(".").length>1){
          filename = filename.split(".")[0];
      }

      const link = document.createElement("a");
      link.target = "_blank";
      link.download = filename


      // await
      // fileDownloadFromMDB({ 'id': id })
      await axios
          .get(`${getServiceUrl(documentDownloadFromMDB.create.baseURL)}${documentDownloadFromMDB.create.api}${id}/`, {
              responseType: "blob",
              headers : {
                  Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
              }
          })
          .then((res) => {

                  link.href = URL.createObjectURL(
                  new Blob([res.data], { type: type })
                  );
                  link.click();
              
              setLoading(false);
          })
          .catch((e) => setLoading(false));
  };
    
  // handle single staff approve by agency
  const handleApproveStaff = async (id) => {
    let body = {
      workId: workID,
      staffId: id,
    };
    // setFormLoder(true);
    await approveStaff(body)
      .then((data) => {
        // setFormLoder(true);
        // setDefault();
        // handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };

  // handle single staff reject by agency
  const handleRejectStaff = async (id) => {
    let body = {
      workId: workID,
      staffId: id,
    };
    // setFormLoder(true);
    await rejectStaff(body)
      .then((data) => {
        // setFormLoder(false);
        // setDefault();
        // handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };


    // handle single staff approve by agency
    const handleCompleted = async (id) => {
        let body = {
            workId: assignTimeSheet.id,
            clientId:data?.clientId,
        }
        setFormLoder(true);
        await workCompletedByAgency(body).then((data) => {
            setFormLoder(false);
            setDefault();
            // handleReload();
        }).catch(() => {
            setFormLoder(false);
        });
    }

    // set Default values
    const setDefault = () => {
        setData({});
        setAssignTimeSheet(false);
        handleReload();
    }
    let{createdAt,workRequest,status,jobCategory,jobName,startDate,endDate,engagementType,priority,wrType,workStaffAssignment,staffNeeded,staffAccepted,location, clientName,notes,documents,longLat}=data;

    useEffect(()=>{
        let rejectdata=[]
      for(let i=0;i<workStaffAssignment?.staffWorkData?.length;i++){
        let count=0;
        let rejectedreason = [];
        for(let a=0;a<workStaffAssignment?.rejectedStaff?.length;a++){
        if(workStaffAssignment?.staffWorkData[i].staffId===workStaffAssignment?.rejectedStaff[a].staffId){
          rejectedreason.push(workStaffAssignment.rejectedStaff[a])
          count++;
        }
        }
        if(count ==1){
          rejectdata.push({value:true, reject:rejectedreason[0]})
        }
        else {
          rejectdata.push({value:false,reject:""})
        }
      }
      setReject(rejectdata)
    },[data])

    return (
        <NormalModal
        className={'modal-fill'}
        toggle={setDefault}
        isOpen={assignTimeSheet.value}
        size="lg"
        onRefresh={()=>{
            fetchWorkById(assignTimeSheet.id);
            setWorkID(assignTimeSheet.id)
        }}
        renderHeader={e => 
            <div className=" d-flex justify-content-between">
                <h6 className="font-weight-bold ">WR Id - {`${!!assignTimeSheet.id&&assignTimeSheet.id.substr(assignTimeSheet.id.length - 6)}`}</h6>
            </div>
        }
        bodyClass='add-staff-box'
        headerClass="border-0 pt-4 px-4"
    >
        {
            !isLoading?
        <>
            <div className="row align-items-start mb-3">
                {/* <div className="col-12">
                    <h6>Work Request ID - {`WR ${!!assignTimeSheet.id&&assignTimeSheet.id.substr(assignTimeSheet.id.length - 6)}`} </h6> 
                </div>*/}
                <div className="col-1">
                    <div className="form-group">
                        <img className=" ml-3 " src={bagIcon}/>
                    </div>    
                </div>
                <div className="col-10">
                    <div className="form-group">
                        <p className="sub-heading fs-18">Job Function - {jobCategory} - {jobName} </p>
                        {/* <p className="sub-text fs-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p> */}
                        
                    </div>    
                    <div className="form-group">
                        <p className="sub-heading fs-18">Work Request Priority - {priority}</p>
                    </div>    
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-1">
                    <div className="form-group">
                        <img className=" ml-3 " src={markerIcon}/>
                    </div>    
                </div>
                <div className="col-10">
                    <div className="form-group">
                        <p className="sub-heading fs-16">{location}</p>                        
                    </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                   <div className="form-group">
                       <p className="sub-text fs-16" >Client Name</p>
                   </div>    
                </div>
                <div className="col-6">
                   <div className="form-group">
                       <p className="text-black fs-14 "> {clientName} </p>                        
                   </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Work Schedule Date/Time</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-14 ">  Starts    {Moment(startDate).format(localStorage.getItem('dateFormat'))}  {Moment(startDate).format('hh:mm:ss a')}
                        </p>                        
                        <p className="text-black fs-14 ">  Ends    {Moment(endDate).format(localStorage.getItem('dateFormat'))} {Moment(endDate).format('hh:mm:ss a')}
                        </p>                        
                        {/* <p className="text-black fs-12 ">  Weekly on monday, Tuesday,wednesday,thursday,friday until Oct 14,2020
                        </p>                         */}
                    </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Work Request Status</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                    <span className={"color-box "+(status !==2 ? getClassNameForBackground(status): status ==2 && workStaffAssignment.staffWorkData.length !== 0? getClassNameForBackground(2.1):getClassNameForBackground(2) )}></span>
                    <span className={`req-status text-black fs-16`}>
                        {
                            status === 0 ? 'CANCELED':
                            status === 1 ? 'NEW REQUEST': 
                            status === 2 && workStaffAssignment.staffWorkData.length !== 0 ? 'VIEW STAFF RESPONSE. PLEASE CONFIRM': 
                            status === 2 ? 'PENDING STAFF ASSIGNMENT':
                            status === 3 ? 'PENDING STAFF CLOCK-IN':
                            status === 4 ? 'STAFF IS WORKING': 
                            status === 5 ? 'PENDING TIMESHEET APPROVAL BY CLIENT':
                            status === 6 ? 'PLEASE REVIEW STAFF TIMESHEET':
                            status === 7 ? 'TIMESHEET APPROVED. CREATE INVOICE NOW!':
                            status === 8 ? 'INVOICE CREATED':
                            status === 9 ? 'INVOICE PAID':
                            status === 10 ? 'NOT FILLED':''
                        }
                        </span> 
                        {/* <span className="req-status text-black fs-16">New request</span>                         */}
                    </div>    
                </div>
            </div>
            
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Work Request Title </p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 ">  {workRequest?workRequest:"-"}</p>                        
                    </div>    
                </div>
            </div>
            {/* <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Work category</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 "> Maintanance</p>                        
                    </div>    
                </div>
            </div> */}
             <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Engagement Type</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 text-capitalize"> {
                                                 engagementType == 1 ? "Contract":
                                                 engagementType == 2 ? "Regular": "-"
                        }</p>                        
                    </div>    
                </div>
            </div>
            {/* <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" ></p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 text-capitalize"></p>                        
                    </div>    
                </div>
            </div> */}
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Work Request Type</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 "> {
                                                  wrType == 1 ? "Weekday" :
                                                  wrType == 2 ? "Weekend" :
                                                  wrType == 3 ? "Holiday" :
                                                  wrType == 4 ? "Contract" : "-"
                        }</p>                        
                    </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Number Of Staff</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 ">{`${staffAccepted ? staffAccepted : 0}-Allocated (Out Of ${staffNeeded} Requested)`}</p>                        
                    </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Work Requested Date/Time</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 ">
                            {Moment(createdAt).format(localStorage.getItem('dateFormat'))}
                            <span className="ml-3">
                            {Moment(createdAt).format('hh:mm:ss a')}
                            </span>
                         </p>                       
                    </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Client Notes</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-16 "> {notes ? notes : "-"}</p>                        
                    </div>    
                </div>
            </div>
            <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Client Documents</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        {documents?.length===0?"-":documents?.map((doc,i)=>
                          <a className="fs-16 document-links" 
                          onClick={()=>{
                                if(doc.split("|").length>1)
                                    fetchFileDownloadURLById(doc.split("|")[0], doc.split("|")[1], doc.split("|")[2]);
                                } 
                            }>
                               {doc.split("|").length>1 && ( 
                                  doc.split("|")[1].length>35 ? '...'+doc.split("|")[1].substring(doc.split("|")[1].length-35) : doc.split("|")[1] 
                                )} ({i+1})
                          </a> )}
                                               
                    </div>    
                </div>
            </div>
            <hr/>
            <>
              <h6 className="text-center font-weight-bold">Staff Assignment Details</h6>
              <table className="w-80 mb-3 col-12 center-table mt-4 pt-4">
                <thead>
                  <th>Name</th>
                  {/* <td>ID</td> */}
                  {/* <td>Location</td> */}
                  <th className="setstatusheader">Staff Status</th>
                  <th id='setAction'>Action</th>
                </thead>
                <tbody>
                  {!!workStaffAssignment &&
                  // !!workStaffAssignment.staffWorkData ? (
                  workStaffAssignment.staffWorkData.length > 0 ? (
                    <>
                      {workStaffAssignment.staffWorkData.map((item, index) => (
                        <tr>
                          <td className="fs-14 setstaffname" >
                            <p>{item.staffName.length > 20 ? item.staffName.substring(0, 20) + "..." : item.staffName}</p>
                            </td>
                          {/* <td className="fs-12">{item.staffId}</td> */}
                          {/* <td className="fs-12">{item.location}</td> */}
                          
                          <td className="fs-14">
                          
                            <p className="fs-14 mt-3 setparadata" id='timesheet'>
                            <div className={item.status ==1 && reject.length>0 && reject[index].reject !==""?"settimesheetstatus":item.status == 3 && reject.length>0 && reject[index].reject !=="" ?"setTimesheet": item.status === 4 && status === 3 ? "pending-clockIn" : item.status === 4 && status === 4 ? "in-progress" : item.status === 4 && status === 4 ? "paused" : item.status === 5 ? "completed-by-staff" : "normaltimesheetstatus"}>
                              {" "}
                              {item.status ==1 && reject.length>0 && reject[index].reject !==""? <RejectAccordianStaff key={item.id} data={reject.length>0 && reject[index].value==true ? reject[index].reject:""} /> :item.status == 3 && reject.length>0 && reject[index].reject !=="" ?<RejectAccordian key={item.id} data={reject.length>0 && reject[index].value==true ? reject[index].reject:""} />:StaffStatusLabels(item.status,item.status,item.clockIn,item.pauseResumeData,item.clockOut)}
                              </div>
                            </p>
                           
                          </td>
                         
                          {status === 0 ? (
                            <td className="fs-14">Work Canceled</td>
                          ) : (
                            <>
                              {item.status === 2 ? (
                                <>
                                  <td
                                    className="text-success text-decoration-underline fs-12 w-15 cursor-pointer setapprove"
                                    onClick={() =>
                                      handleApproveStaff(item.staffId)
                                    }
                                  >
                                    APPROVE{" "}
                                  </td>
                                </>
                              ) : (
                                <td className="text-muted fs-12 w-15 no-drop setapprove">
                                  {item.status > 3 ? "APPROVED" : "APPROVE"}
                                </td>
                              )}
                              {item.status < 4 && status !== 10 ? (
                                <td
                                  className="text-danger text-decoration-underline fs-12 w-15 cursor-pointer setreject"
                                  // className="text-danger fs-12"
                                  // onClick={() => handleRejectStaff(item.staffId)}
                                  onClick={() =>
                                    handleRejectStaff(item.staffId)
                                  }
                                >
                                  REJECT{" "}
                                </td>
                              ) : (
                                <td
                                  // className="text-danger fs-12 cursor-pointer"
                                  className="text-muted fs-12 w-15 no-drop setreject"
                                  // onClick={() =>
                                  //   handleRejectStaff(item.staffId)
                                  // }
                                >
                                  REJECT
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="5" className="border-0">
                        <p className="text-center fs-14">
                          {status === 0
                            ? "Work Canceled"
                            : status === 1
                            ? "Need to accept by agency"
                            : "No staff assign"}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
            {/* <div className="row align-items-center justify-content-end">
                <div className="col-5">
                    <div className="form-group">
                        <p className="sub-text fs-16" >Rate (optional)</p>
                    </div>    
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <p className="text-black fs-14 ">  30.00 USD
                        </p>                          
                    </div>    
                </div>
            </div> */}
            <hr />
            {
                !!workStaffAssignment &&
                <StaffDetails data={workStaffAssignment} workID={assignTimeSheet.id} fetchWorkById={fetchWorkById} startDate={startDate} endDate={endDate} sitePicture={data?.sitePicture} clientLocLatLng={data?.longLat}/>
            }
            
            {/* <TimeSheetMark /> */}
            <div className=" mt-3">
		{/*<p className="fs-12 italic btn-gray-clr footer-sec pb-3">Approving the pay is only for tracking the payment and does not trigger any payment. This is used to show the staff earnings in Staff screen only. Please ensure that you initiate the payment offline</p>*/}
                <div className="d-flex align-items-center mt-3 justify-content-between ">
                    <div className="d-flex flex-column">
                        {/*<p className="fs-16 btn-gray-clr mb-1"> {status === 6? <>'Client Approved *'<span className="violet-tick"><img src={approveIcon} /> </span></>:<>Client Approval *<span className="violet-tick"><img src={tickIcon} /> </span></> }</p>*/}
                        <p className="fs-16 btn-gray-clr mb-1"> Client Approval Status {status === 6?<span className="green-tick"><img src={approveIcon} /></span> : status > 6?<span className="green-tick"><img src={tickIcon} /></span>:<span className="violet-tick"><img src={tickIcon} /> </span> }</p>
		        {/*<p className="fs-8 btn-gray-clr">Allowed only with client user permission</p>*/}
                    </div>
                        
                        <div className="p-3"></div>
                        <NormalButton
                            onClick={()=>{handleCompleted()}}
                            id="Confirm"
                            label="Approve For Invoicing"
                            outline={false}
                            disabled={isFormLoder || status<=5 || status >=7}
                            //disabled={isFormLoder || status!==6}
                            className="mb-2 btn fs-14 btn-dark-primary btn-block form-btn "
                        />
                </div>
            </div>
        </>
        :
        <CommonPageLoader />
        }

    </NormalModal>

    )
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSingleWork,
            workCompletedByAgency,
            approveStaff,
            rejectStaff,
        },
        dispatch
    );
};

export default connect(
    mapDispatchToProps,
    mapDispatchToProps
)(withRouter(WorkAssignTimeSheet));
