import { clientApi } from '../service/apiVariables';

import { addQuery } from '../service/helperFunctions';




export const getUserClient = (query) => (dispatch, getState, { api, Toast }) => {
  
    return new Promise((resolve, reject) => {

        addQuery(query, clientApi.getAllClient);

        api({ ...clientApi.getAllClient }).then(({ data }) => {
            resolve(data)
        }).catch(({ message }) => {
            reject(Toast({ type: "error", message }))
        })
    })
}

export const getCSVTemplate = (query) => (dispatch, getState, { api, Toast }) => {
  
  return new Promise((resolve, reject) => {
    addQuery(query, clientApi.getCSV);
      api({ ...clientApi.getCSV }).then(({ data }) => {
          resolve(data)
      }).catch(({ message }) => {
          reject(Toast({ type: "error", "message":(!!message?message:"") }))
      })
  })
}

export const postimportClients = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
      api({ ...clientApi.postimportClients, body})
      .then((response) => {
              resolve(response);
          })
          .catch(( error) => {
              reject(error);
          });
  });
};

export const uploadClientBillingRates = (body,query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
      addQuery(query, clientApi.uploadClientBillingRates);
      api({ ...clientApi.uploadClientBillingRates, body, query})
      .then((response) => {
              resolve(response);
          })
          .catch(({ message }) => {
              reject(Toast({ type: 'error', message }));
          });
  });
};

export const getClientById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, clientApi.getClientById);
        api({ ...clientApi.getClientById })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getPayrateById = (query) => (dispatch, getState, {api, Toast}) => {
  return new Promise ((reslove,reject) => {
      addQuery(query, clientApi.getPayrateById);
      api({ ...clientApi.getPayrateById})
           .then(({data}) => {
               reslove(data);
           })
           .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
        });
  })
}

export const getAllCurrency = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...clientApi.getCurrency })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAllCurrencyByClient = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, clientApi.getAllCurrency);
    api({ ...clientApi.getAllCurrency, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};



export const getSearchByName = (query) => (dispatch, getState, { api, Toast }) => {
  
  return new Promise((resolve, reject) => {
      addQuery(query, clientApi.getClientByName);
      api({ ...clientApi.getClientByName })
          .then(({ data }) => {
              resolve(data);
          })
          .catch(({ message }) => {
              reject(Toast({ type: 'error', message }));
          });
  });
};

export const deleteClientById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, clientApi.putDeleteClient);
        api({ ...clientApi.putDeleteClient })
            .then(({ data, message }) => {
                resolve(data);
                Toast({ type: "success", message });
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const postCreateClient = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...clientApi.postAddClient, body })
        .then(({ message, data }) => {
          resolve(data);
  
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const postMultiClientCSV = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...clientApi.postMultiClient, body })
        .then(({ message, data }) => {
          resolve(data);
  
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const postInviteClient = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...clientApi.postInvite, body })
        .then(({ message, data }) => {
          resolve(data);
  
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };



  export const updateClient = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...clientApi.postUpdateClient, body })
        .then(({ message, data }) => {
          resolve(data);
  
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updatePayrate = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...clientApi.postUpdatePayrate, body })
        .then(({ message, data }) => {
          resolve(data);
  
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const deleteClientBillingRate = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, clientApi.deleteClientBillingRate);
        api({ ...clientApi.deleteClientBillingRate })
            .then(({ data, message }) => {
                resolve(data);
                Toast({ type: "success", message });
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message, time:5000 }));
            });
    });
};

export const getTaxSetting = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, clientApi.getTaxSetting);
    api({ ...clientApi.getTaxSetting })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateTaxSetting = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...clientApi.updateTaxSetting, body })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getTaxRegion = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, clientApi.getTaxRegion);
    api({ ...clientApi.getTaxRegion })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};