import moment from "moment"
import { history } from "service/helpers"
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return '';
  }

  const keys = [
    'page',
    'limit',
    'search',
    'sort',
    'startDate',
    'endDate',
    'id',
    'name',
    'searchKey',
    'status',
    'team',
    'region',
    'size',
    'staffOrClient',
    'paidDate',
    'timeZone',
    'roleType',
    'role',
    'flag', 
    'agencyId',
    'userId',
    'action',
    'staffId',
    'clientId',
    'type',
    'workId',
    'clientName',
    'staffName',
    'jobCategory',
     'message',
     'entity',
     'email',
     'emailId',
     'resetPassword',
     'currency',
     'planName',
     'quantity',
     'unitAmount',
     'purpose',
     'fileId',
     'userMail',
     'archiveFlag',
     'invoiceId',
     'clientId',
     'jobFunctionId',
     'engagementType',
     'wrType',
     'auditId',
     'proceed',
     'deleteFlag',
     'logType',
     'format',
     'module',
     'serviceItemId',
     'taxRateId',
     'paymentReq',
     'otp',
     "newEmailId",
     'category',
     'functionName',
     'jobMasterId'
  ];  


  keys.forEach((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != 'object') {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    }else if(Array.isArray(dataObject[key])){
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key]};
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).forEach((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = { key: keyName, payload: dataObject[key][keyName] };
          }
        });
    }
  });
};

export const scrollTop = (id = 'root', selector = 'getElementById') => {

  var contentPageEle = document[selector](id)

  if (selector != 'getElementById') {
    contentPageEle = contentPageEle[0]
  }


  contentPageEle && contentPageEle.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const generateQuery = (query) => {
  let url = '';
  let isQuestionMarkAdded = false;

  if (query.hasOwnProperty('url_id')) {
    url = `/${query.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] === '' ||
        query[key] === null ||
        key === 'url_id' ||
        (query[key] !== null && query[key].toString().trim() === '')
      ) { 
        return accumulator;
      } else {
        let accumulatorStr = accumulator + `${isQuestionMarkAdded ? '&' : '?'}${key}=${query[key]}`;
        isQuestionMarkAdded = true;
        return accumulatorStr;
      }
    }, '')
  );
};

export const getFormatDate = (date) => {

  return moment(date).format('DD MMM,hh:mm:ss a')

}
export const getFormatDateOnly = (date) => {

  let newDate = moment(date).format('DD MM YYYY')

  // console.log(moment.unix(date).format('DD MM YYYY'))

  if (newDate === "Invalid date") {

    return moment.unix(date).format('DD MM YYYY')

  } else {

    return newDate

  }

}

export const getSNo = ({ totalPages, page, chunk }, index) => {

  return parseInt((page - 1) * chunk) + index + 1

}

export const displayImg = (file) => {

  if (typeof file === "object") {

    return URL.createObjectURL(file)

  } else {

    return file

  }

}

export const getToken = () => {
  return localStorage.getItem('AuthToken') ? localStorage.getItem('AuthToken') : '';
};

export const removeFeatureDate = (current, paramDate, paramAction) => {
  return current.isBefore(moment(new Date()).subtract(paramDate, paramAction));
}

export const convertStringToObject = (searchQuery) => {

  try {

      if (!!searchQuery) {

          var search = (searchQuery).substring(1);

          let obj = JSON.parse('{"' + search.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')

          return {
              filter: '',
              search: '',
              page: 1,
              field: '',
              orderBy: '',
              ...obj
          }

      }

      return {
          search: '',
          page: 1,
          field: '',
          orderBy: '',
          filter: '',
      }


  } catch (error) {
      console.log(error, 'd4sds4d')
  }

}

export const appendQuery = (data) => {

  history.push({
      search: generateURLQuery(history.location.search, data)
  })

}
export const replaceQuery = (data) => {

  history.replace({
      search: generateURLQuery(history.location.search, data)
  })

}
const dataRequiredValidation = (value) => value !== '' && value !== null && value !== undefined

const updateQueryStringParameter = (uri, key, value) => {

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }

}

const removeQueryStringParameter = (url, parameter) => {
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
              pars.splice(i, 1);
          }
      }
      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;

}
export const amountFormat = (data) => {
  return data
    ? data
      .toString()
      .replace(/[^\d]/g, "")
      .replace(/^(\d+)(\.?\d\d)$/g, "$1.$2")
      // .replace(/^(\d+)(\d{2})?$/g, "$1.$2")
      .trim()
    : data;
};

export const numberFormat = (data) => {
  
  return data ? data.toString().replace(/[^\d]/g, "").trim() : data;
};
export const currencyFormat = (data) => {
  if(!data.includes(".")){
    return data
    ?
      parseInt(data).toFixed(2).replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",")
    : data;  
  }
  else{
  return data
  ?
  data.replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1.$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",")
  : data;
  }
};

export const generateURLQuery = (url, data) => {

  return data.reduce((acc, { label, value }) => {

      if (dataRequiredValidation(value)) {
          return updateQueryStringParameter(acc, label, value)
      } else {
          return removeQueryStringParameter(acc, label)
      }

  }, url)

}

export const extractAddressFields = (components) => {
  var formatted = {
    state: '',
    street: '',
    pincode: '',
    city: '',
    country:'',
    region:''
  };
  components.forEach((element) => {
    
    if (element.types.indexOf('administrative_area_level_1') !== -1) {
      formatted.state = element.long_name;
    }
    if (element.types.indexOf('country') !== -1) {
      formatted.country = element.short_name;
      formatted.country_fullname = element.long_name;
    }
    if (element.types.indexOf('country') !== -1) {
      formatted.region = element.long_name;
    }
    if (element.types.indexOf('locality') !== -1) {
      formatted.city = element.long_name;
    }
    if (element.types.indexOf('postal_code') !== -1) {
      formatted.pincode = element.long_name;
    }
    if (element.types.indexOf('street_number') !== -1) {
      formatted.street = element.long_name;
    }
    if (element.types.indexOf('route') !== -1) {
      formatted.street += `${formatted.street ? ', ' : ''}${element.long_name}`;
    }
    if (element.types.indexOf('sublocality') !== -1 && element.types.indexOf('sublocality_level_1') !== -1) {
      formatted.street += `${formatted.street ? ', ' : ''}${element.long_name}`;
    }
  });
  return formatted;
};

export const validatePassword = (password = '') =>{
  const validationsResult = {
    length: password.length >= 8 && password.length <= 15,
    specialCharacter: /[!^$&*%#@]/.test(password),
    number: /[0-9]/.test(password),
    uppercase: /[A-Z]/.test(password),
  };
  return validationsResult
}
export const getTaxLabel = process.env.REACT_APP_BASE_SERVER === '1' ? 'Tax EIN Number' : process.env.REACT_APP_BASE_SERVER === '2' ? 'GSTIN Number' : null

export const handleFormatTaxLabel = (value) => {
  if(value){
     return   value.length > 2 && process.env.REACT_APP_BASE_SERVER === '1' ? value.replace(/[^0-9-]/g, '').slice(0, 10).replace(/^(\d{2})(\d{0,7})$/, '$1-$2') : value
  }else{
      return '';
  }
};

export const handleDeFormatTaxLabel = (value) =>{
  return process.env.REACT_APP_BASE_SERVER === '1' ? value.replace(/-/g, '').slice(0,9) : value
}

const maskEmailAddress=(emailAddress) =>{
  function mask(str) {
      var strLen = str.length;
      if (strLen > 4) {
          return str.substr(0, 1) + str.substr(1, strLen - 1).replace(/\w/g, '*') + str.substr(-1,1);
      } 
      return str.replace(/\w/g, '*');
  }
  return emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, function (m, p1, p2, p3) {      
      return mask(p1) + '@' + mask(p2) + p3;
  });
} 

export const handleDownloadFile = ({data = null, filename='', type='application/octet-stream'}) => {
        
   if(data && filename){
     const blob = new Blob([data], { type });
     const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      
      URL.revokeObjectURL(fileURL);
    }
      
}