import React, { useState,useEffect } from 'react';
import "./style.scss"
import { NormalButton,TableWrapper,CommonPageLoader } from 'component/common';
import {FilterWorkList } from 'component/workShedule/FilterWorkList';
import {WorkSheduleHeader } from 'component/workShedule/workSheduleHeader';
import { approveWork,rejectWork} from "action/workRequest";
import { getClassNameForBackground, getClassNameForLeftBorder } from "service/utilities";
import ConfirmModel from 'component/workShedule/ConfirmModel';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FullCalendar from '@fullcalendar/react'
import moment from "moment";
import Moment from "moment";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import bagIcon from 'assets/svg/bag.svg';
import markerIcon from 'assets/svg/Vector.svg';
import viewBlue from 'assets/svg/viewBlue.svg';
import calendarIcon from "assets/svg/calendar-icon.svg";
import backIcon from "assets/svg/less-than.svg";
import frwdIcon from "assets/svg/greater-than.svg";
import warningIcon from "assets/svg/warning.svg";
import {ReactComponent as OneStaff} from "assets/svg/oneStaff.svg"
import {ReactComponent as TwoStaff} from "assets/svg/twoStaff.svg"
import { startTourGuide } from 'reducer/tourGuide';
import { store } from "service/helpers"
import CustomLoader from 'component/common/CustomLoader';
import no_results from "assets/svg/no_results.svg"
import RecurringIcon from "assets/svg/recurrenceIcon.svg"
import AssignStaff from 'assets/svg/Assign.svg'
import AssignStaffDisable from 'assets/svg/AssignDisabled.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

  const headerDetailsTemp=[
    {
        label: 'Client Name',
        isSort:true,
        ascKey:"clientName",
    },
    {
        label: 'Staff Name',
        isSort:true,
        ascKey:"staffName",
    },
    {
        label: 'WR Id',
        isSort:true,
        ascKey:"id",
    },
    {
        label: 'Job Name',
        isSort:true,
        ascKey:"jobName",
    },
    {
        label: 'Priority',
        isSort:true,
        ascKey:"priority",
    },
    {
        label: 'Work Assigned Time',
        isSort:true,
        ascKey:"workAssignedTime",
    },
    {
        label: 'Shift Start Date',
        isSort:true,
        ascKey:"startDate",
    },
    {
        label: 'WR Status',
        isSort:true,
        ascKey:"statusName",
    },
    {
        label: 'Action',
    }
]
var cardTitleTextLength = 14;
const calendarRef = React.createRef();
function WorkScheduleList({calendarGrid, action, setPage, setAssignTimeSheet,handleAssignFromList,setWorkList,loading,afterLoadCalendar,workList,query,setQuery,searchValue,statusPick,calendarView,staffId,clientId,setWorkAssign,approveWork,rejectWork,handleReload,bgOfSelectedStatus,StaffStatusLabels, showArchives, setShowAlertModal}) {
    const [calendarApi, setCalendarApi] = useState({});
    const [calendarTitel, setCalendarTitel] = useState('');
    const [open, setOpen ]= useState({status:false, id:''})
    const [headerDetails, setHeaderDetails] = useState(headerDetailsTemp);
    const [isFormLoder, setFormLoder] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [cancelId, setCancelId] = useState('');
    const [ascKey, setascKey] = useState("startDate");
    const [isAscending, setisAscending] = useState(false);
    const [onHover, setOnHover] = useState({status:false, id:''});
    const[clientid, setClientId] = useState('')
    const[loadingKey, setLoadingKey] = useState('')
    const[componentMounted, setcomponentMounted] = useState(false)
    const [openConfirmModal,setOpenConfirmModal] = useState({id:'',visible:false,index:''})
    const [workRequestData,setworkRequestData] = useState([]);
    const [status, setStatus] = useState("");
    // useEffect(() => {
    //     let api = calendarRef.current.getApi();
    //     query.startDate = moment.utc(api.currentDataManager.state.dateProfile.activeRange.start).format();
    //     query.endDate = moment.utc(api.currentDataManager.state.dateProfile.activeRange.end).format();
    //     setQuery(query)
    //     setCalendarTitel(api.currentDataManager.data.viewTitle)
    //     setCalendarApi(api)
    //     // if(Number(calendarView) === 0){
    //     //     if (!!staffId)
    //     //         afterLoadCalendar(query);
    //     // }else{

    //     //     if(!!clientId)
    //     //         afterLoadCalendar(query);
    //     // }
        
    // }, [staffId,calendarView,query.jobFunction]);
useEffect(()=>{
    let api = calendarRef.current.getApi();
    setCalendarTitel(api.currentDataManager.data.viewTitle);
    setCalendarApi(api);
},[])

    useEffect(() => {
        // Blo
        if(!componentMounted){
            setcomponentMounted(true)
            return;
        }
        let api = calendarRef.current.getApi();
        const calendarDate = moment(query.startDate).month() === moment().month() ? moment() : moment(query.startDate)
        if (Number(calendarGrid) === 0)  {
          query.startDate = calendarDate
            .startOf("day")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
          query.endDate = calendarDate
            .endOf("day")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
        } else if (Number(calendarGrid) === 1)  {
          query.startDate = calendarDate
            .startOf("week")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
          query.endDate = calendarDate
            .endOf("week")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
        } else if (Number(calendarGrid) === 3)  {
          query.startDate = calendarDate
            .startOf("month")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
          query.endDate = calendarDate
            .endOf("month")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
        }
        setQuery(query);
        setCalendarTitel(api.currentDataManager.data.viewTitle);
        setCalendarApi(api);
        afterLoadCalendar(query);
      }, [calendarGrid]);
    
    //date click open modal
    const handleDateClick = (arg) => { // bind with an arrow function
        let currentDate = new Date();
        let selectDate = arg.date;
        if (currentDate <= selectDate) {
            setWorkAssign({ value: true });
        }
        // this.setState({ eventmodule: true, openDate: arg.dateStr })
    }

    // const handleEventClick = (e) => {
    //     let { event } = e;
    //     let extendedProps = event._def.extendedProps;
    //     setEventId(extendedProps.eventId);
    //     console.log(eventId)
    //     setVisible({ value: true, id: extendedProps.eventId });
    // }

    //calender next click handle
    const calendarNext = () => {
        calendarApi.next();
        if(Number(calendarGrid) == 0){
            query.startDate = moment(query.startDate).add(1, "day").startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
            query.endDate = moment(query.startDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
        }
        else if(Number(calendarGrid) == 1){
            query.startDate = moment(query.startDate).add(1, "week").startOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
            query.endDate = moment(query.startDate).endOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    
        } else if (Number(calendarGrid) == 3){
            query.startDate = moment(query.startDate).add(1, "month").startOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
            query.endDate = moment(query.startDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");      
        }
        //query.startDate = moment.utc(calendarApi.currentDataManager.state.dateProfile.activeRange.start).format();
        //query.endDate = moment.utc(calendarApi.currentDataManager.state.dateProfile.activeRange.end).format();
        setQuery(query)
        setPage(1)
        setCalendarTitel(calendarApi.currentDataManager.data.viewTitle);
        afterLoadCalendar(query);

    }

    //calender prev click handle
    const calendarPrev = () => {
        calendarApi.prev();
        if(Number(calendarGrid) == 0){
            query.startDate = moment(query.startDate).subtract(1, "day").startOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
            query.endDate = moment(query.startDate).endOf("day").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
        }
        else if(Number(calendarGrid) == 1){
            query.startDate = moment(query.startDate).subtract(1, "week").startOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
            query.endDate = moment(query.startDate).endOf("week").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    
        } else if (Number(calendarGrid) == 3){
            query.startDate = moment(query.startDate).subtract(1, "month").startOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
            query.endDate = moment(query.startDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
        }
        //query.startDate = moment.utc(calendarApi.currentDataManager.state.dateProfile.activeRange.start).format();
        //query.endDate = moment.utc(calendarApi.currentDataManager.state.dateProfile.activeRange.end).format();
        setQuery(query)
        setPage(1)
        setCalendarTitel(calendarApi.currentDataManager.data.viewTitle);
        afterLoadCalendar(query);
    }

    //calender prev click handle
    const calendarToday = () => {
        calendarApi.today();
        query.startDate = moment(new Date()).format();
        query.endDate = moment(new Date()).format();
        setQuery(query)
        setCalendarTitel(calendarApi.currentDataManager.data.viewTitle);
        afterLoadCalendar(query);
    }
    // manual sorting
    const handleSort = (type, isAsc, key, index) => {
        let tempData = workList;
        if (type === 'string') {
        tempData.sort((a, b) => {
            if (a[key] < b[key]) {
            return isAsc === 1 ? -1 : 1;
            }
            if (a[key] > b[key]) {
            return isAsc === 1 ? 1 : -1;
            }
            return 0;
        });
        } else if(type === 'date'){
        if (isAsc === 1) {
            tempData.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
        } else {
            tempData.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
        }
        }
        let tempHeader = [...headerDetails];
        tempHeader[index].isAsc = headerDetails[index].isAsc === 1 ? 0 : 1;
        setHeaderDetails(tempHeader);
        setWorkList(tempData);
    }

    const checkStaffNameAvail = (arrOfStaffNameAvailResults) => {
        let isStaffAvail = false;
        if (arrOfStaffNameAvailResults.length > 0) {
            for (var i = 0; i < arrOfStaffNameAvailResults.length; i++) {
                isStaffAvail = isStaffAvail || arrOfStaffNameAvailResults[i];
            }
        }
        return isStaffAvail;
    }

    // handle  approve by agency
    const handleApproveWork= async(id,clientId, index)=>{
        if(loadingKey === '')
        setLoadingKey(`accept-work-${index?index:openConfirmModal.index}`)
        let body = {
            workId: id?id:openConfirmModal.id,
            clientId:clientId
        }
        setFormLoder(true);
        await approveWork(body).then((data) => {
            setFormLoder(false);
            // handleReload(query);
            setOpenConfirmModal({id:'',visible:false,index:''})
            setLoadingKey('')
        }).catch(() => {
            setFormLoder(false);
            setOpenConfirmModal({id:'',visible:false,index:''})
            setLoadingKey('')
        });
    }

    const handleAction = (action) => {
        if(action.type === 'accept-work'){
            action.value ? handleApproveWork() : setOpenConfirmModal({...openConfirmModal,visible:!openConfirmModal.visible})
        } 

        else if(action.type === 'cancel-work') {
            action.value? handleRejectWork() : setOpenModal(!openModal)}    
    }

    // handle  reject by agency
    const handleRejectWork= async(id)=>{
        setLoadingKey('cancel-work')
        let body = {
            workId: cancelId,
            reason:"Staff is not available",
            clientId:clientid,
        }
        setFormLoder(true);
        await rejectWork(body).then((data) => {
            setFormLoder(false);
            setOpenModal(false);
            setLoadingKey('')
            // handleReload(query);
        }).catch(() => {
            setFormLoder(false);
            setLoadingKey('')
        });
    }

    //Redirect to a new tab
    const Redirect= (x)=>{
        window.open(`/Admin/staffManagement/StaffDetail/${x}`, '_blank')
    }

    const loginCount = localStorage.getItem("loginCount");

    return (
        <>
        {/* <WorkSheduleHeader />
        <FilterWorkList /> */}
        <div className=" custom-header mt-3">
            <div className="row adjustheader">
                <div className="col-md-5">
                    <div className="nav-btns">
                        <button className="today-btn" onClick={calendarToday}>
                            Today
                        </button>
                    </div>
                </div>
                <div className="d-flex align-items-center" id={'work-list-date-slider'}>
                    <div className="">
                        <span className=" mr-4 cursor-pointer" onClick={calendarPrev}><img src={backIcon} width={'9'} height={'16'} /></span>
                        <span className="cal-icc">
                        <img src={calendarIcon} width={'20'} height={'22'} />
                        </span>
                        <label className="calend-title ml-4 mr-4 mb-0 fs-14">
                        {Number(calendarGrid) === 0 ? (
                          moment(query.startDate ? query.startDate : new Date()).startOf("day").format("MMM DD, YYYY")
                        ) : (
                          ""
                        )}
                        {Number(calendarGrid) === 1 ? (
                          moment(query.startDate ? query.startDate : new Date()).startOf("week").format("MMM DD") +
                          " - " +
                          moment(query.startDate ? query.startDate : new Date()).endOf("week").format("MMM DD,YYYY")
                        ) : (
                          ""
                        )}
                        {Number(calendarGrid) === 3 ? (
                          moment(query.startDate ? query.startDate : new Date()).startOf("month").format("MMMM YYYY")) : (
                          ""
                        )}
                            {/*calendarTitel*/}</label>
                        <span className="cursor-pointer" onClick={calendarNext}><img src={frwdIcon} width={'9'} height={'16'} /></span>
                    </div>
                </div>

            </div>
        </div>
        <div className="work-schedule-list">
            <FullCalendar
                headerToolbar={false}
                ref={calendarRef}
                plugins={[timeGridPlugin]}
                initialView=""
                allDaySlot={false}
                events={[]}
                eventClick={e => { }}
                dateClick={e => { }}
            />
        </div> 
        <div id="workList" className="">
            {/* <TableWrapper
                headerDetails={headerDetails}
                className="workScheduleList"
            >
                { workList.length > 0 ?  workList.map((item, index) => {
                    let
                        { 
                            Sno,
                            staffName,
                            location,
                            date,
                            speciality,
                            teamName,
                            status
                        } = item;

                    return (
                        <tr className="my-2" key={index}>
                            <td>
                                <div className='d-flex align-items-center h-100'>
                                    <p className='mb-0'>{staffName}</p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100'>
                                    {location}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100'>
                                    {speciality}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100'>
                                    {new Date().getTime()}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100'>
                                    {date}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100'>
                                    {status}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100'>
                                    <span className='action-span'>
                                        View 
                                    </span>
                                    <span className='action-span ml-3'>
                                    Accept 
                                    </span>
                                    <span className=' action-span ml-3'>
                                    Reject * 
                                    </span>
                                    <span className='action-span ml-3'>
                                    Assign 
                                    </span>
                                </div>
                            </td>

                        </tr>
                    );
                }) : ""}
            </TableWrapper> */}
            
            <TableWrapper
                paginationShow={false}
                headerDetails={headerDetails}
                className="workScheduleList"
                // pagination={false}
                handleSort={(ascKey)=>{
                    setascKey(ascKey);
                    setisAscending(!isAscending);
                }}
            >
                {/* Show and Search filter */}
                
                {!loading ? 
                <>
                 {workList.length > 0 ? (
                    workList
                    .filter(
                        (item) =>
                        (
                            statusPick === "2.1" ? item.status === 2 && item.workStaffAssignment.staffWorkData?.length !== 0 : 
                            typeof statusPick === 'string' ? item : 
                            statusPick === 2 ? (item.status === 2 && item.workStaffAssignment.staffWorkData?.length === 0): 
                            item.status === statusPick
                        )
                    )
                      .filter(
                          (item) =>
                              (searchValue != ""
                                  ? item.clientName && item.clientName
                                      .toLowerCase()
                                      .indexOf(searchValue.toLowerCase()) !== -1
                                  : item.clientName.toLowerCase().length > 0) ||

                              (searchValue != ""
                                  ? item.workRequest && item.workRequest
                                      .toLowerCase()
                                      .indexOf(searchValue.toLowerCase()) !== -1
                                  : item.workRequest.toLowerCase().length > 0) ||

                              (searchValue != "" && item.workStaffAssignment && 
                                    item.workStaffAssignment.staffWorkData && 
                                    checkStaffNameAvail(item.workStaffAssignment.staffWorkData.map((staff) => 
                                    (staff.staffName.toLowerCase()
                                  .indexOf(searchValue.toLowerCase()) !== -1))))
                            
                            //   (searchValue != ""
                            //       ? item.location && item.location
                            //           .toLowerCase()
                            //           .indexOf(searchValue.toLowerCase()) !== -1
                            //       : item.location.toLowerCase().length > 0)
                            //   (searchValue != ""
                            //       ? item.status && item.status
                            //           .toLowerCase()
                            //           .indexOf(searchValue.toLowerCase()) !== -1
                            //       : item.status.toLowerCase().length > 0)
                      )
                     
                      .sort((a, b) => {
                        let key1 = a[ascKey];
                        let key2 = b[ascKey];
                        if (typeof key1 === "string") {
                          key1 = key1.toLocaleLowerCase();
                        }
                        if (typeof key2 === "string") {
                          key2 = key2.toLocaleLowerCase();
                        }
                        if (isAscending) {
                          if (key1 > key2) {
                            return -1;
                          } else if (key2 > key1) {
                            return 1;
                          }
                        } else {
                          if (key1 < key2) {
                            return -1;
                          } else if (key2 < key1) {
                            return 1;
                          }
                        }
                        return 0;
                      })
                .map((item, index) => {
                    let
                        {
                            clientId,
                            clientName,
                            workStaffAssignment,
                            workAssignedTime,
                           // location,
                            priority,
                            status,
                            statusName,
                            jobName,
                            createdAt,
                            startDate,
                            endDate,
                            staffNeeded,
                            id,
                            repeat,
                            clientStatus
                        } = item;
                        // console.log(statusName)
                        let staffName="";
                        item.workStaffAssignment?.staffWorkData.map((staff) => {
                            staffName = staffName !=="" ?
                             (staffName + " , "+ staff.staffName)
                              : staff.staffName;
                        })
                        staffName=staffName==""? "No Staff Assigned" :item.workStaffAssignment?.staffWorkData[0].staffName;

                        let staffNames = []
                        let noOfWorkers = item.workStaffAssignment? item.workStaffAssignment.staffWorkData.length:0
                         staffNames = item.workStaffAssignment?.staffWorkData.map(x => {
                          let statusCode = x.status
                          let tempRejectedStaff = item.workStaffAssignment? item.workStaffAssignment?.rejectedStaff ?item.workStaffAssignment?.rejectedStaff: [] : []
                          let rejectedByStaff = tempRejectedStaff.some((rStaff)=> rStaff.staffId === x.staffId && rStaff.userType == 6)
                          let rejectedByClient = tempRejectedStaff.some((rStaff)=> rStaff.staffId === x.staffId && rStaff.userType == 7)
                          return {name:x.staffName,
                             status:{
                              name:StaffStatusLabels(statusCode, status, x.clockIn, x.pauseResumeData, x.clockOut, item?.cancelByWork,rejectedByStaff, rejectedByClient)
                              
                            },
                            staffid:x.staffId
                        }
                         })
                       
                    return (
                        <>
                        <tr className="my-2" key={index}>
                            
                             <td>
                                <div style={{position: "relative", right: "12px"}} className='d-flex align-items-center h-100 fs-13'>
                                    <p className='mb-0'>{clientName}</p>
                                </div>
                            </td>
                            <td
                            onMouseLeave={()=> open.status==true? setOpen({status:false, id:''}): setOnHover({status:false, id:''})}
                            >
                                <div className='d-flex align-items-center h-100 fs-13'>
                                   <div>
                                    <p style={{cursor:'pointer'}} className= {staffName!=="No Staff Assigned"?'mb-0 text-underline-hover staffName':"mb-0"} onClick={()=> setOpen({status:true, id:id})}>{staffName}</p>
                                   </div>
                                { open.status & open.id == id || onHover.status && onHover.id == id ?
                                <div
                                className=" work-schedule-list-popup bg-white rounded   position-absolute d-flex flex-column align-items-center "
                               >
                               {noOfWorkers > 0 ? <div className="table-responsive" style={{width:'max-content'}}>
                                         <div className="table-multi-staff">
                                               <div className="table-row table-header">
                                                  <div className="table-cell staff-label-name">Name</div>
                                                  <div className="table-cell staff-status-name">Staff Status</div>
                                                  <div className="table-cell">Action</div>
                                                </div>
                                               
                                                      {staffNames?.length ? staffNames.map(x =>  <div className="table-row"><div className="table-cell staff-label-name">{x.name.length>cardTitleTextLength?x.name.substring(0, cardTitleTextLength-2)+'...':x.name}</div> <div className="table-cell staff-status-name">{x.status.name}</div> <div className="table-cell"onClick={() => Redirect(x.staffid)} style={{cursor:'pointer'}}><img src={viewBlue}/></div></div> ) : null}
                                                 
                                          </div>
                                          </div> : <span className="pt-4 px-2 fs-11">NO STAFF ASSIGNED</span>}

                                </div> : null}
                                <div className='ml-2' style={{marginBottom:"0em"}} key={index}>
                                  <p onMouseEnter={()=> setOnHover({status:true, id:id})}>  
                            { noOfWorkers > 0 ? null : <OneStaff fill="white" stroke="white" strokeWidth="20"  style={{width:'25px', height:'25px', backgroundColor:'grey', borderRadius:"100%"}} /> }
                            { noOfWorkers === 1 ? <OneStaff fill="white" stroke="white" strokeWidth="20"  style={{width:'25px', height:'25px', backgroundColor:'#6666FF', borderRadius:"100%"}} /> : null}
                            { noOfWorkers > 1 ? <TwoStaff fill="blue"  style={{width:'30px', height:'30px',  borderRadius:"100%"}} /> : null} 
                                 </p>
                                </div> 
                            <div style={{marginBottom:"1em"}}>
                            {noOfWorkers > 0 ? 
                             <p  className='rounded-circle bg-warning fs-13' id={noOfWorkers.toString().length>2? "biggernumber":"smallernumber"}  style={{fontSize:'16px',textAlign:"center" }}><span className={noOfWorkers == 111 ? "customSpacing":""} style={{textAlign:"center",verticalAlign:"middle"}}>{` ${noOfWorkers}`}</span></p>
                             : <p  className= 'rounded-circle fs-13 no_staff_assigned'  style={{ backgroundColor:'grey', color:'white' ,paddingLeft:"0.35rem", paddingRight:"0.3rem",display: "flex", justifyContent: "center", alignItems: "center" }}><span>{` ${noOfWorkers}`}</span></p>
                             }
                             </div>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100 fs-13'>
                                   <p className='mb-0'>{`${!!id && id.substr(id.length - 6)}`}</p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100 fs-13 text-capitalize' id={!item.hasOwnProperty('jobName')? "list-job-name":""}>
                                {!item.hasOwnProperty('jobName')? <> Select job name  <img style={{width:"20px",marginLeft:"5px"}} src={warningIcon}/></> : jobName}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100 fs-13 text-capitalize'>
                                    {priority}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100 fs-13'>
                                {workAssignedTime}
                                    {/* {Moment(createdAt).format('DD/MM/YYYY')} */}
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center h-100 fs-13'>
                                    {Moment(startDate).format(localStorage.getItem('dateFormat'))}
                                </div>
                            </td>
                            {/* <td>
                                <div className='d-flex align-items-center h-100 fs-13'>
                                <div style={{width:'10px', height:'10px', position:'relative', right:'0.6rem'}} className={""+((status == 2) && item.workStaffAssignment.staffWorkData.length !== 0? getClassNameForLeftBorder(2.1): getClassNameForLeftBorder(status)) } ></div>
                            
                                <span>{statusName} </span>

                                
                                </div>
                            </td> */}

                            <td 
                            >
                              <div 
                              className="d-flex  align-items-center h-100 fs-13"
                              >
                                 <div style={{minWidth:'10px', height:'10px', position:'relative', right:'0.6rem'}} className={""+((status == 2) && item.workStaffAssignment?.staffWorkData?.length !== 0? getClassNameForLeftBorder(2.1): getClassNameForLeftBorder(status)) } ></div>
                               <div 
                                 className="d-flex gap-5 justify-content-between align-items-center">
                                <span  className={status===0?"required fs-11":"fs-11"}>{statusName}</span>
                               </div>
                               {repeat && <img src={RecurringIcon} title='Recurrence WR' style={{marginLeft:'6px'}} width={'26'} height={'20'}/>}
                              </div>
                            </td>


                            <td>
                                <div className={`d-flex align-items-center h-100 fs-13 ${(action === 1 && showArchives) ? 'cursor-disabled' : ''}`} id='work-request-actions'>
                                    <span className={`action-span cursor-pointer text-view btn ${(action === 1 && showArchives) ? 'pointer-events-none' : ''}`} onClick={()=>{
                                        if(status >= 3|| (status === 0 && workStaffAssignment?.staffWorkData[0]?.clockIn )){
                                            if(action===1){
                                                setWorkAssign({ value: true, id: id });
                                            }else{
                                                // setAssignTimeSheet({ value: true, id: id });
                                                setWorkAssign({ value: true, id: id });
                                            }
                                        }else{
                                            if(action === 1 && status === 1) {
                                                setShowAlertModal({show:true, id:id});
                                                return;
                                            }
                                            setWorkAssign({ value: true, id: id });
                                        }
                                        // setWorkAssign({ value: true,id:id });
                                        }}>
                                        <span className='btn-text' >View</span>
                                    </span>
                                    {
                                        status === 1 ? repeat? 
                                        <span className={`d-flex align-items-center action-span ml-1 cursor-pointer 
                                        text-white btn btn-success accept-btn ${showArchives ? 'visibility-hidden' : ''}`} 
                                        onClick={()=>{
                                            setOpenConfirmModal({id:id,visible:true,index:index});
                                            setClientId(clientId)
                                         }}>
                                        <span className='btn-text'>Accept</span> {loading}
                                        </span> :
                                        <span className={`d-flex align-items-center action-span ml-1 cursor-pointer  btn btn-success accept-btn ${loadingKey === `accept-work-${index}` ? 'accept-btn-loading' : 'text-white'} ${showArchives ? 'visibility-hidden':''}`} onClick={()=>{handleApproveWork(id,clientId,index)}}>
                                        <span className='btn-text'>Accept</span> {loadingKey === `accept-work-${index}` ? <CustomLoader/> : null}
                                        </span>
                                    :
                                        <span className={`action-span ml-1 no-drop btn btn-muted ${showArchives ? 'visibility-hidden':''}`}>
                                        <span className='btn-text'>Accept</span>
                                        </span>
                                }
                                    {
                                        status !==0 && status <4?

                                        <span className={` action-span ml-1 cursor-pointer no-wrap text-white btn btn-danger reject-btn  ${showArchives ? 'visibility-hidden':''}`} 
                                        onClick={()=>{
                                            setCancelId(id)
                                            setOpenModal(true);
                                            setClientId(clientId)
                                            setStatus(status)
                                            setworkRequestData(workStaffAssignment)
                                         }}>
                                        <span className='btn-text'>Cancel</span>
                                        </span>
                                        :
                                        <span className={` action-span ml-1 no-drop no-wrap btn btn-muted ${showArchives ? 'visibility-hidden':''}`}>
                                        <span className='btn-text'>Cancel</span>
                                        </span>
                                    }
                                    {/* {
                                        status === 1 ?
                                        <span className='action-span ml-3 cursor-pointer' onClick={()=>{handleApproveWork(id)}}>
                                        Accept 
                                        </span>
                                    :
                                        <span className='action-span ml-3 no-drop'>
                                        Accept 
                                        </span>
                                    } */}
                                    {
                                        status > 1 && status <5  ?
                                        <span className={`action-span ml-1 cursor-pointer  ${showArchives ? 'visibility-hidden':''}`} onClick={()=>handleAssignFromList(id)}>
                                            {/* <span className='btn-text'>(Un)Assign</span>  */}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Assign/Unassign Staff
                                                    </Tooltip>
                                                }
                                            >
                                                <span className='btn-text'><img src={AssignStaff} alt='assigned' height={34} width={45}></img></span>
                                            </OverlayTrigger>
                                        </span>
                                    :
                                        <span className={`action-span ml-1 no-drop  ${showArchives ? 'visibility-hidden':''}`}>
                                            {/* <span className='btn-text'>(Un)Assign</span>  */}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Cannot Assign/Unassign Staff
                                                    </Tooltip>
                                                }
                                            >
                                                <span className='btn-text'><img src={AssignStaffDisable} alt='assigned' height={34} width={45}></img></span>
                                            </OverlayTrigger>
                                        </span>
                                    }
                                    {/* {
                                        (status >= 5 || 
                                        status ===0 || 
                                        (status == 2 && workStaffAssignment.staffWorkData.length !== 0))  ?
                                        <span className='action-span ml-1 no-drop btn btn-muted mr-4'>
                                            Edit 
                                        </span>
                                    :
                                        <span className='action-span ml-1 cursor-pointer text-edit btn mr-4' 
                                        onClick={(e) => {
                                            if (status !== 0)
                                            setVisible({ value: true, id: workStaffAssignment.workId });
                                        }}
                                        >
                                            Edit 
                                        </span>
                                    } */}
                                    {/* <span className='action-span ml-3 cursor-pointer'onClick={()=>handleAssignFromList(id)}>
                                        Assign 
                                    </span> */}
                                </div>
                            </td>

                        </tr>
                        </>
                    );
                })
                )
                    : 
                <td colSpan="7" className="border-0 no-results-ws">
                    <img className='no-results-ws' src={no_results} alt="no-results" />
                    {
                    loginCount === "1" ? (
                        <h6 className="text-center no-results-text-ws">
                        Not sure where to start?&nbsp;
                        <span
                            className="cursor-pointer"
                            onClick={() => {
                            store.dispatch(startTourGuide({ run: true }));
                            }}
                        >
                            Click Product Tour
                        </span>{" "}
                        to know more
                        </h6>
                    ) : null
                    }
                </td>
                }
                </>
                :
                <td className="border-0 common-page-loader">
                    <CommonPageLoader />
                </td>
                }
            </TableWrapper>
        </div>
        {openModal?<ConfirmModel
          toggle={() => setOpenModal(!openModal)}
          isOpen={openModal}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey='cancel-work'
          loadingKey={loadingKey}
          message={(status === 1 || (status === 2  && workRequestData?.staffWorkData?.length==0)) ? "Are you sure to cancel?" : "This action cannot be reversed. Are you sure to cancel?"}
          type='cancel-work'
        /> :null }
        { openConfirmModal.visible? <ConfirmModel
          toggle={() => setOpenConfirmModal({...openConfirmModal,visible:!openConfirmModal.visible})}
          isOpen={openConfirmModal.visible}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = {`accept-work-${openConfirmModal.index}`}
          loadingKey={loadingKey}
          message='This is a recurring work request. Are you sure to accept?'
          type='accept-work'
        /> : null }
        </>

    )
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            approveWork,rejectWork
        },
        dispatch
    );
};
export default connect(mapDispatchToProps, mapDispatchToProps)(WorkScheduleList);
