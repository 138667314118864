import React, { useEffect, useState, useRef } from 'react';
import { NormalToUSInput, NormalToUSOutput } from 'component/common/FormatContact';
import SimpleReactValidator from 'simple-react-validator';
import "./style.scss";
import { NormalButton, NormalInput,NormalSwitch, NormalSelect, NormalRadio, NormalCheckbox, DragFileUpload, CommonPageLoader, NormalDate } from 'component/common';
import LocationSearchInput from 'component/common/locationSearchAutocomplete';
import deleteIcon from "assets/svg/delete-icon.svg";
import Select from 'react-select';
import { fileUploadOnMDB } from "../../../action/fileUpload";
import { deleteFileFromMDB } from 'action/fileHandler';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Toast } from "service/toast";
import { getStaffById ,getAllJobFunctionCategoriesByAgency,getAllRegions,getAllJobFunction} from "../../../action/StaffAct";
import { getAllRoll} from "../../../action/AuthAct";
import editIcon from 'assets/svg/edit-icon.svg';
import sample_logo from "assets/svg/circle.svg";
import { getTimeZoneList } from 'action/SettingsAct';
import infoIcon from "assets/svg/info.svg"
import { isArray, values } from 'lodash';
import { getAgencyJobs } from 'action/JobCategories';
import { DeleteFileConfirmationModal } from "../../common/DeleteFileConfirmationModal/index.js"
import loadingIcon from "assets/images/loading.png";
import profileIcon from 'assets/svg/settingsProfile.svg';
import geolocationIcon from 'assets/svg/location_pin_icon.svg';
import envelopeIcon from 'assets/svg/EnvelopeIcon.svg'
import DisplayStaffImage from '../DisplayStaffImage/index';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import GeoInterval from 'component/common/GeoInterval';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Grid from '@mui/material/Grid';
import ChangeEmailModal from 'component/common/ChangeEmailModal';
import dangerIcon from "assets/images/icon/danger.png";

let initState = {
   firstname: '',
   middleName: "",
   lastName: "",
   gender: "",
   mobileNumber: "",
   email: "",
   address: "",
   zipCode: "",
   postalAddress: "",
   postalZipCode: "",
   team: "",
   teamManager: "",
   hireDate:"",
   // jobFunctions: '',
   // skills: '',
   timeZone:"",
   role: "",
   enableGeoTracking:false,
   geoUpdateInterval:0,
   employmentType: '',
   bioAuth: '',
   geoTag: false,
   // approveWorkRequest: false,
   // approveTimeSheet: false,
   biometric: '',
   profileLink: '',
   region: [],
   geoLocation: '',
   employeeId: '',
   longLat: [],
   //latLong: [13.11212,80.121212],
   dateFormat: "",
   distanceUnit:"",
   jobCategory:'',
   jobName:'',
   payRate: {
      "jobRate": [
            {
               "jobName": "",
               "jobCategory": "",
               "payRate": "",
               "overTimeRate": ""
            }
         ]
      }
};

function EditProfile({ staffData, getAllRoll,action, handleSaveValues, fileUploadOnMDB, deleteFileFromMDB, getAllRegions, getTimeZoneList, handleTabsValue, getStaffById, match,getAllJobFunctionCategoriesByAgency,getAllJobFunction, handleProgress, getAgencyJobs, loadingKey, isAdd }) {  

   const [formFields, setFields] = useState(initState);
   const [isUpload, setIsUpload] = useState(false);
   const [updateNow, setUpdateNow] = useState(true);
   const [edit, setEdit] = useState({});
   const [loading, setLoading] = useState(false);
   const [jobFunctionCategoriesArray, setJobFunctionCategoriesArray] = useState([]);
   const [jobNameArray, setJobNameArray] = useState([]);
   const [regionsArray, setregionsArray] = useState([]);
   const [allRolls, setAllRolls] = useState([]);
   const [isFormLoder, setFormLoder] = useState(false);
   const [agencyId, setAgencyId] = useState('');
   const [timezones, setTimezones] = useState([
      {"label":"", "value":""},
      {"label":"America/New_York", "value":"America/New_York"},
  ]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
   // const [delinkedPayrate,setDelinkedPayrate] = useState([]);
   // const [originalDelinkedPayrate,setoriginalDelinkedRates] = useState([]);
   const [sameAsAbove, setSameAsAbove] = useState(false)
   const [singleUserData, setSingleUserData] = useState({})

   const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)

  let userType = JSON.parse(localStorage.getItem('userType'));
  let userData = JSON.parse(localStorage.getItem('userData'));

const updateProfileProgress = () => {
   let generalDetails = {
      "firstname": formFields.firstname,
      "middleName": formFields.middleName,
      "lastName": formFields.lastName,
      "gender": formFields.gender,
      "mobileNumber": formFields.mobileNumber,
      "email": formFields.email,
      "address": formFields.address,
      "zipCode": formFields.zipCode,
      "postalAddress": formFields.postalAddress,
      "postalZipCode": formFields.postalZipCode,
      "hireDate": formFields.hireDate,
      "timeZone": formFields.timeZone,
      "role": formFields.role,
      "employmentType": formFields.employmentType,
      "region": formFields.region!==null? formFields.region?.[0]:"",
      "employeeId": formFields.employeeId,
      "dateFormat": formFields.dateFormat,
  }

      
   let totalPercentage = 0;
   Object.values(generalDetails).forEach((field) => {
     if (field === "" || field === null  || field === undefined) {
       totalPercentage += 0;
     } else {
       totalPercentage += 1;
     }
   });
   const finalPercentage = (totalPercentage / Object.keys(generalDetails).length) * 100;
   
   handleProgress('general', finalPercentage)
}

useEffect(()=>{
   updateProfileProgress()
},[formFields])

  const addNewJobFunctionFields = () => {
      let payRate = formFields.payRate;

      if(jobNameArray.length<=payRate.jobRate.length){
         Toast({ type: "error", message: "No More Job Names Are Left." });
         return;
      }

      let payrateLastIindex = payRate.jobRate.length-1;
      if(payrateLastIindex>=0){ 
         if(!payRate.jobRate[payrateLastIindex].jobCategory){
            Toast({ type: "error", message: "First Fill All The Job Fields." });
            return;
         }
         if(!payRate.jobRate[payrateLastIindex].jobName){
            Toast({ type: "error", message: "First Fill All The Job Fields." });
            return;
         }
      }

      payRate = {
         "jobRate": [
            ...payRate?.jobRate,
            {
               "jobCategory": "",
               "jobName": "",
               "payRate": "",
               "overTimeRate": ""
            },
         ]
      };
      setFields({...formFields, "payRate" : payRate});
  }

  const removePayRateByIndex = (index) => {
      let payRate = formFields.payRate;
      payRate.jobRate = payRate.jobRate.filter((e,i)=>i!==index);
      setFields({...formFields, "payRate" : payRate});
  }

   const handleJobFunctionInputs = (event, index=0) => {
      let { target: { name, value }} = event;
      let payRate = formFields.payRate;
      if(name==="jobCategory"){
         payRate.jobRate[index].jobCategory = value;
         payRate.jobRate[index].jobName = "";
         // On changing jobCategory we are reseting the payRate and overTimeRate
         // payRate.jobRate[index].payRate = 0;
         // payRate.jobRate[index].overTimeRate = 0;
      }else if(name==="jobName"){
         payRate.jobRate[index].jobName = value;
         // On changing jobName we are reseting the payRate and overTimeRate
         // payRate.jobRate[index].payRate = 0;
         // payRate.jobRate[index].overTimeRate = 0;
      }
      setFields({...formFields, "payRate" : payRate});
   }   

   const handleInput = ({ target: { name, value } }) => {
      setFields({ ...formFields, [name]: value });
   }
   const handleContact = ({ target: { name, value } }) => {
      setFields({ ...formFields, [name]: value });
   }
   const updateFunc = () => {
      setUpdateNow(!updateNow)
   }
   const handlePostalAddress = ({target: {name, value}}) => {
      setFields({...formFields, [name]: value})
   }

   useEffect(()=> {

      if(action == "Add" && formFields.dateFormat == "" && formFields.distanceUnit == "" && (userData?.region?.toString().toLowerCase() == "usa" || userData?.region?.toString().toLowerCase() == "united states of america" || userData?.region?.toString().toLowerCase() == "united states")){
         setFields(prevFields => ({...prevFields, dateFormat: 'MM/dd/yyyy' , distanceUnit: '1'}));
      }
      else if( action == "Add" && formFields.dateFormat == "" && formFields.distanceUnit == "" && (userData?.region?.toString().toLowerCase() == "india")){
         setFields(prevFields => ({...prevFields, dateFormat: 'dd/MM/yyyy', distanceUnit: '2'}));
      }

   },[userData])
   useEffect(() => {
      if(!formFields.postalAddress?.trim()){
         setFields({...formFields,postalZipCode: ""})
      }
   },[formFields.postalAddress])

   const fetchTimeZones = async () => {
      await getTimeZoneList({}).then((data) => {
          setTimezones([{"label":"", "value":""}, ...data.map(tZone=>{
              return {
                  "label": tZone,
                  "value": tZone,
              }
          })]);
      }).catch((e) => console.log(e));
    }
  
    useEffect(()=>{
        fetchTimeZones();
        let country = JSON.parse(localStorage.getItem('userData')).region
        setFields((prev)=>{
         return{
            ...prev,
            region:[{lable:country,value:country}]
         }
        })
    },[])
   useEffect(() => {
      let userData = JSON.parse(localStorage.getItem('userData'));
      let id = userData.agencyId ? userData.agencyId : userData.id;
        
        if (!!id) {
         setAgencyId(id);
         // fetchAllJobFunction(id);
         // fetchAllJobName(id);
         fetchAllRegions();
         fetchAllRolls();
     }
      if (action !== "Add") {
         // fetchUserData(action);
         
      }else {
         let country = JSON.parse(localStorage.getItem('userData')).region.toString()
         setFields({...initState, region:[{label:country,value:country}]});
         // setFields({ "region": [] });
      }
       return () =>{
         initState = {
            firstname: '',
            middleName: "",
            lastName: "",
            gender: "",
            mobileNumber: "",
            email: "",
            address: "",
            zipCode: "",
            postalAddress: "",
            postalZipCode: "",
            team: "",
            teamManager: "",
            hireDate:"",
            timeZone:"",
            role: "",
            employmentType: '',
            bioAuth: '',
            geoTag: false,
            biometric: '',
            profileLink: '',
            region: [],
            geoLocation: '',
            employeeId: '',
            longLat: [],
            dateFormat: "",
            distanceUnit:"",
            jobCategory:'',
            jobName:'',
            payRate: {
               "jobRate": [
                     {
                        "jobName": "",
                        "jobCategory": "",
                        "payRate": "",
                        "overTimeRate": ""
                     }
                  ]
               }
         }
       }
   }, [action]);

    // fetch all job functions 
//     const fetchAllJobFunction=async(id)=>{
//       setFormLoder(true);
//       let query={
//          id:id
//       };
      
//       await getAgencyJobs(query)
//           .then((data) => {
//           let modifyData=[];
//           let finalData= data
//               finalData.forEach((item)=>{
//                   modifyData.push(
//                       {
//                           label:item.category,
//                           value:item.category,
//                       }
//                   );
//               })
//               setJobFunctionCategoriesArray(modifyData.length ? modifyData : []);
//               setFormLoder(false);
//         })
//         .catch((e) => setFormLoder(false));
//   }

    //fetch job names
//     const fetchAllJobName = async(id) => {
//       setFormLoder(true);
//       let query={
//          id:id
//       };
      
//       await getAgencyJobs(query)
//           .then((data) => {
//           let modifyData=[];
//           let finalData= data;
//               finalData.forEach((item)=>{
//                item.function.forEach((itemm)=>{
//                   modifyData.push(
//                       {
//                           label: itemm.name,
//                           value: itemm.name,
//                           category: item.category
//                       }
//                   );
//               })
//               })
//               setJobNameArray(modifyData.length ? modifyData : []);
//               setFormLoder(false);
//         })
//         .catch((e) => setFormLoder(false));
//   }

//   const DelinkedPayrate = ()=>{
//    let originaljobPayrate=[];
//    let jobpayrate=[];
//    let jobRate=[]
   
//    for(let i=0;i<delinkedPayrate?.payRate?.jobRate?.length;i++){
//       let count=0;
//       for(let a=0;a<delinkedPayrate.payRate.jobRate.length;a++){
//          if(delinkedPayrate.payRate.jobRate[i].jobCategory==delinkedPayrate.payRate.jobRate[a].jobCategory &&delinkedPayrate.payRate.jobRate[i].jobName==delinkedPayrate.payRate.jobRate[a].jobName ){
//             count++
//          }
//       }
//       if(count>=1){
//          let innercount=0;
//          for(let c=0;c<jobRate.length;c++){
//             if(jobRate[c].jobName== delinkedPayrate.payRate.jobRate[i].jobName && jobRate[c].jobCategory == delinkedPayrate.payRate.jobRate[i].jobCategory ){
//                innercount++;
//             }
//          }
//          if(innercount==0){
//             jobRate.push ({
//                "jobName": delinkedPayrate.payRate.jobRate[i].jobName,
//                "jobCategory": delinkedPayrate.payRate.jobRate[i].jobCategory,
//                "payRate": "",
//                "overTimeRate": ""
//             }) 
//          }
            
           
//       }

//    }
//    jobpayrate.push({"jobRate":jobRate});
//    originaljobPayrate.push({"payRate":jobpayrate[0]})
   
//    if(jobpayrate.length>0){
//    setoriginalDelinkedRates(originaljobPayrate[0]);}
//   }

//     useEffect(()=>{
     
//     DelinkedPayrate()

//     },[delinkedPayrate])

//     useEffect(()=>{
//       if(originalDelinkedPayrate?.payRate?.jobRate?.length>0){
//       formFields.payRate=originalDelinkedPayrate.payRate   }
//       else if (originalDelinkedPayrate?.payRate?.jobRate?.length==0){
//          formFields.payRate= { "jobRate": [
//             {
//                "jobName": "",
//                "jobCategory": "",
//                "payRate": "",
//                "overTimeRate": ""
//             }
//          ]}
//       }
//     },[originalDelinkedPayrate])

    // fetch all Regions
    const fetchAllRegions=async()=>{
      setFormLoder(true);
      let query={
      };
      
      // await getAllRegions(query)
      //     .then((data) => {
      //     let modifyData=[];
      //     let region= data[0].region;
      //      if(isArray(region)){
      //          region.forEach((item)=>{
      //          modifyData.push(
      //               {
      //                 label:item,
      //                 value:item
      //                }
      //             );
      //           })
          
      //      }
      //         setregionsArray(modifyData.length ? modifyData : []);
         
      //         setFormLoder(false);
      //   })
      //   .catch((e) => setFormLoder(false));
  }
    // fetch all Regions
    const fetchAllRolls=async()=>{
      setFormLoder(true);
      let query={
      };
      
      await getAllRoll(query)
          .then((data) => {
          let modifyData=[];
          let finalData= data;

              finalData.forEach((item)=>{
                 if (staffData.role == "1" || staffData.role == "2") {
   
                  if (item.type === 1 || item.type === 2) {
                      modifyData.push({
                          label: item.name,
                          value: item.type
                      });
                  }
              } else {
                    modifyData.push({
                        label: item.name,
                        value: item.type
                    });
                }

              })
              setAllRolls(modifyData.length ? modifyData : []);
         
              setFormLoder(false);
        })
        .catch((e) => setFormLoder(false));
  }
  useEffect(()=>{
   if (action !== "Add" && staffData) {
   Object.keys(staffData).length>0 && fetchUserData(staffData)
   }
  },[action, staffData])

  useEffect(() => {
   if(singleUserData.address === singleUserData.postalAddress && singleUserData?.address?.length > 0){
     setSameAsAbove(true)
   }
   else{
     setSameAsAbove(false)
   }
 },[singleUserData])

   const fetchUserData = async (data) => {

         // setDelinkedPayrate(data);
         let jobSplit = [];
         // let skillsSplit = [];
         let regionSplit = [];
         let bioSplit = [];
         // data.jobFunctions.forEach(val => {
         //    jobSplit.push({
         //       value: val,
         //       label: val
         //    })
         // })
         // data.skills.forEach(val => {
         //    skillsSplit.push({
         //       value: val,
         //       label: val
         //    })
         // }) 
         setSingleUserData(data)
         {
          data.region.forEach(val => {
            regionSplit.push({
               value: val,
               label: val
            })
         })
         // data.biometric.forEach(val => {
         //    bioSplit.push({
         //       value: val,
         //       label: val
         //    })
         // })
         let temp = {}
         temp.firstname = data.firstname;
         temp.middleName = data.middleName;
         temp.lastName = data.lastName;
         temp.gender = data.gender;
         temp.hireDate = data.hireDate;
         temp.mobileNumber = data.mobileNumber[0];
         temp.email = data.email;
         temp.address = data.address;
         temp.zipCode = data.zipCode;
         temp.postalAddress = data.postalAddress;
         temp.postalZipCode = data.postalZipCode;
         temp.team = data.team;
         temp.teamManagerName = data.teamManagerName;
         temp.employeeId = data.employeeId;
         // temp.jobFunctions = jobSplit;
         // temp.skills = skillsSplit;
         temp.role = data?.role;
         temp.enableGeoTracking = data?.enableGeoTracking
         temp.geoUpdateInterval = data?.geoUpdateInterval;
         temp.employmentType = data.employmentType;
         temp.bioAuth = data.bioAuth.toString();
         // temp.geoTag = data.geoTag;
         // temp.approveWorkRequest = data.approveWorkRequest;
         // temp.approveTimeSheet = data.approveTimeSheet;
         temp.longLat = data.longLat;
         // temp.biometric = bioSplit;
         temp.profileLink = data.profileLink;
         temp.region = regionSplit;
         // temp.geoLocation = data.geoLocation;
         temp.dateFormat = data.dateFormat;
         temp.distanceUnit = data.distanceUnit;
         temp.timeZone = data.timeZone;
         if(!!data.payRate)
         temp.payRate = data.payRate;
            
         else
            temp.payRate = {"jobRate": [{
                                       "jobName": "",
                                       "jobCategory": "",
                                       "payRate": "",
                                       "overTimeRate": ""
                                    }]};
         setFields(temp)
                                 }
         setEdit(data);
      
   };


   const validator = useRef(new SimpleReactValidator({
      validators: {
         element: (message) => (
            <span className='error-message font-md'>{message}</span>
         )
      }
   }));

const phoneUtil = PhoneNumberUtil.getInstance()
const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false;
    }
  };
   const handleMultiSelect = (value = "", name) => {
      setFields({ ...formFields, [name]: value });
   }
   const handleLogoUpload = async (e) => {
      
        if (formFields.profileLink) {
         await deleteFileFromMDB({fileId: formFields.profileLink.split("/")[1]});
     }

      if (e) {
         setIsUpload(true);
         let formData = new FormData()
         formData.append('file', e[0]);
         await fileUploadOnMDB(formData).then((res) => {
            setIsUpload(false);
            setFields({ ...formFields, profileLink: `${res.title}/${res.fileId}` })
            // setFields({ ...formFields, ['profileLink']: `${res.title}|data:${res.contentType};base64,${res.image}` });
         }).catch((e) => setIsUpload(false));
      }
   }

   const deleteImage = async () => {
      setShowDeleteConfirmation(true);
   };

   const handleDeleteConfirmation = async (confirmed) => {
      setShowDeleteConfirmation(false);
  
      if (confirmed) {
        if (formFields.profileLink) {
          const fileId = formFields.profileLink.split('/')[1];
          try {
            await deleteFileFromMDB({ fileId });
            setFields({ ...formFields, profileLink: '' });
            handleSubmit(false, true)
          } catch (error) {
              console.log(error)
          }
        }
      }
    };

   const handleSubmit = async (includeLogo = true, isDeleteAction = false) => {
      if(!isPhoneValid(mobileNumber)){
         Toast({type: 'error', message:'Please enter valid contact number', time: 3000})
         return;
     }
      if (validator.current.allValid()) {
         
         // formFields.region= formFields.region.map(val => {
         //    return val.value
         // })
         let formFieldsCopy = { ...formFields };
         if (!includeLogo) {
            formFieldsCopy.profileLink = '';
         }
         handleSaveValues(formFieldsCopy, null, isDeleteAction)
         handleTabsValue("1");
         // setFields(initState);
      }
      else {
         validator.current.showMessages();
         updateFunc();
         Toast({ type: "error", message: "Please Input All required Fields" });
      }
   };
   // set pin code value
   const handleAddress = (data) => {
      let userData = JSON.parse(localStorage.getItem('userData'))
      
      if (userData.hasOwnProperty('role')) {
         let country = JSON.parse(localStorage.getItem('userData')).region[0]
         if(country.toLowerCase() !== data.country_fullname.toLowerCase()){
            setFields({ ...formFields, zipCode:'', address:'', longLat:[]})
            Toast({type:'warning', message:'Address is not valid for this country'})
            return;
       }
      }
      else {
         let country = JSON.parse(localStorage.getItem('userData')).region
         if(country.toLowerCase() !== data.country_fullname.toLowerCase()){
            setFields({ ...formFields, zipCode:'', address:'', longLat:[]})
            Toast({type:'warning', message:'Address is not valid for this country'})
            return;
         }
      }
      let { pincode } = data;
      setFields({ ...formFields, zipCode: pincode })
  }
  // handle place changes
  const handleSelectAddress=(data)=>{
   setFields({ ...formFields, address: data })
  }

  // set lat long value
  const handleLatLng=(data)=>{
   let newData =[];
   Object.keys(data).forEach((item)=>newData.push(data[item])) ;
   newData.reverse()
   setFields({ ...formFields, longLat: newData });
   handleInput({target:{name: "longLat",value: newData}});
  }
   let {
      firstname,
      middleName,
      lastName,
      gender,
      mobileNumber,
      email,
      address,
      zipCode,
      postalAddress,
      postalZipCode,
      timeZone,
      team,
      region,
      teamManager,
      // jobFunctions,
      // skills,
      role,
      enableGeoTracking,
      geoUpdateInterval,
      employmentType,
      hireDate,
      bioAuth,
      geoTag,
      // approveWorkRequest,
      // approveTimeSheet,
      profileLink,
      geoLocation,
      biometric,
      employeeId,
      dateFormat,
      distanceUnit,
      jobCategory,
      jobName,
      payRate
   } = formFields;

   formFields.email = formFields.email.toLowerCase();

   const handleCheckBox = () => {
      if (sameAsAbove) {
          setSameAsAbove(false)
          setFields({...formFields, postalAddress: "", postalZipCode: ""})
      } else {
          setSameAsAbove(true)
          setFields({...formFields, postalAddress: formFields.address, postalZipCode: formFields.zipCode})
      }
  }

  const updateEmailField = (newEmail) => {
   setFields({ ...formFields, email: newEmail})
 }
   
   return (
      <>
         {!loading ?
            <>
               <div className="my-3 d-flex justify-content-between">
                  <div className="mt-2 profile-upload position-relative">
                     <DragFileUpload
                        className={` mainbg no-img w-100 d-flex justify-content-center outline-none`}
                        acceptFileFormat={["png", "jpeg", "svg", "jpg", "heif", "bmp", "gif", "webp", "tiff", "tif", "jfif"]}
                        handleFileUpload={(e) => handleLogoUpload(e)}
                     >
                        {/* <img
                           style={{ height: "90px", width: "90px" }}
                           src={profileLink ? profileLink.split("|").length>1 && profileLink.split("|")[1] : sample_logo}
                           className={"border-radius-5"}
                        /> */}
                        {isUpload ? 
                           <img
                              className={"agency-logo loading-animation"}
                              src={loadingIcon}
                           /> : null }
                           {!profileLink && !isUpload ? 
                           <img
                              className={"profile-icon rounded-circle"}
                              src={profileIcon}
                           /> : 
                           !isUpload && profileLink ?
                        <div style={{ height: "90px", width: "90px" }}>
                           {profileLink.includes('data:image/') ?
                              <img
                                 style={{ height: "90px", width: "90px" }}
                                 src={profileLink ? profileLink.split("|").length>1 && profileLink.split("|")[1] : sample_logo}
                                 className={"border-radius-5 rounded-circle"}
                              />
                           :
                              <DisplayStaffImage rounded={true} imageId={profileLink}/>
                           }
                        </div>
                         : null }
                        <div>
                           <span style={profileLink ? {top:'22px'} : {top:'44px'}}><img src={editIcon} className="edit-godz" alt='Edit Icon'></img></span>
                           {profileLink &&
                              <span style={profileLink ? {top:'22px'} : {top:'44px'}}>
                                 <img 
                                 alt='Delete Icon'
                                 src={deleteIcon}
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    deleteImage();
                                 }} 
                                 />
                              </span>
                           }
                        </div>
                     </DragFileUpload>
                  </div>  
                  
               <div className="d-flex justify-content-center align-items-start" style={{gap:'20px'}}>
                  {(role == 3 && userData?.enableGeoTracking) &&
                  <div className="d-flex flex-column justify-content-center align-items-start" style={{gap:'10px'}}>
                     <div className="d-flex justify-content-center align-items-center mt-1" style={{gap:'10px'}}>
                        <span className='s2-r'>Geo Location Tracking</span>
                      
                        <NormalSwitch
                        name='enableGeoTracking'
                        checked={enableGeoTracking}
                        onChange={handleInput}
                        />
                         </div>
                          <div className="d-flex flex-column justify-content-start align-items-start" style={{gap:'10px'}}>
                        {enableGeoTracking ?
                        <GeoInterval
                        value={geoUpdateInterval}
                        onChangeValue={(value)=>handleInput({target:{name:'geoUpdateInterval',value:value}})}
                        /> : ''}
                        </div>
                        </div>}
                  

                  <div className='d-flex align-items-center s2-r'> 
                     Agency Country 
                     <div className='agency-country '>
                        <img className="d-flex align-items-center" src={geolocationIcon} alt='geolocationIcon'  style={{height:"13px",width:"9px"}}/>
                          {region ? region[0]?.label : "No Selection"} 
                          </div>
                  </div>
               </div>
               </div> 
               

               <div className='staffdetailsmain'>
               <Grid container spacing={2} className="newrow" > 
               <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily'>Employee ID</label>
                        <NormalInput
                           placeholder="Enter employee id"
                           maxlength='50'
                           name="employeeId"
                           value={employeeId}
                           className="form-control rounded-0 setinputbox"
                           onChange={handleInput}
                           type={"text"}
                        />
                        {validator.current.message("Employee ID", employeeId, "required|min:1|max:10")}
                     </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily'>First Name </label>
                        <NormalInput
                           placeholder="Enter the first name"
                           maxlength='50'
                           minlength='2'
                           name="firstname"
                           value={firstname}
                           className="form-control rounded-0 setinputbox"
                           onChange={handleInput}
                        />
                        {validator.current.message("firstname", firstname, "required")}
                     </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily'>Middle Name <span className="optional-field-label">optional</span></label>
                        <NormalInput
                           placeholder="Enter the middle name"
                           maxlength='50'
                           minlength='2'
                           name="middleName"
                           value={middleName}
                           className="form-control rounded-0 setinputbox"
                           onChange={handleInput}
                        />
                        {/* {validator.current.message("middleName", middleName, "required")} */}
                     </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                        <label className='setheadingfontfamily'> Last Name </label>
                        <NormalInput
                           placeholder="Enter the last name"
                           maxlength='50'
                           minlength='2'
                           name="lastName"
                           value={lastName}
                           className="form-control rounded-0 setinputbox"
                           onChange={handleInput}
                        />
                        {validator.current.message("lastName", lastName, "required")}
                     </div>
                  </Grid>
               </Grid> 
               <Grid container spacing={2} className='newrow'>
               <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily d-flex justify-content-between flex-wrap'>Email
                     {(action !== "Add") && ((!!userType && Number(userType) === 3) || (!!userType && (Number(userType) === 4 || Number(userType) === 5)) && staffData.role == "3") &&
                     <div>
                     <a className="cursor-pointer random-href fs-12" onClick={() => setShowChangeEmailModal(true)}>
                                 <u>Change Email</u>
                              </a>
                              <OverlayTrigger placement="top" overlay={
                                 <Tooltip>
                                    Change email address of the staff
                                 </Tooltip>}>
                                 <span>
                                    <img src={dangerIcon} alt="warning-icon" className='ml-1' width={15} height={15} />
                                 </span>
                              </OverlayTrigger>
                              </div>}
                     </label>
                        <NormalInput
                           placeholder="Enter email address"
                           maxlength='100'
                           minlength='5'
                           name="email"
                           value={email}
                           disabled={action !== "Add"}
                           className="form-control rounded-0 setinputbox"
                           onChange={handleInput}
                        />
                        {validator.current.message("email", email, "required|email")}
                     </div>
                  </Grid>  
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily'>Contact Number </label>
                        {/* <NormalInput
                           placeholder="Enter contact number..."
                           name="mobileNumber"
                           value={mobileNumber}
                           className="form-control rounded-0 setinputbox"
                           onChange={handleContact}
                        /> */}
                          <PhoneInput
                             defaultCountry={(userData?.region?.toString().toLowerCase() === "usa" || userData?.region?.toString().toLowerCase() === "united states" || userData?.region?.toString().toLowerCase() === "united states of america") ? 'us' : (userData?.region?.toString().toLowerCase() === "india") ? 'in' : (process.env.REACT_APP_BASE_SERVER === '1') ? 'us' : (process.env.REACT_APP_BASE_SERVER === '2') ? 'in' : ''}
                              placeholder="Enter contact number"
                              value={mobileNumber ? mobileNumber : ''}
                               onChange={(phone) => {
                             let event = { target: { name:'mobileNumber', value:phone } }
                             handleContact(event)
                                 }}
                               />
                        {validator.current.message("Contact Number", mobileNumber, "required")}
                     </div>
                  </Grid> 
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label  className='setheadingfontfamily'>Gender</label>
                        {/* <NormalInput
                           placeholder="Type Name"
                           name="gender"
                           value={gender}
                           className="form-control rounded-0"
                           onChange={handleInput}
                           label={"Gender *"}
                        /> */}
                        <NormalSelect
                           placeholder="Select the gender"
                           options={[{ label: "Male", value: "Male" },{ label: "Female", value: "Female" },{ label: "Rather Not Say", value: "Rather Not Say" }]}
                           name="gender"
                           value={gender}
                           className="form-control rounded-0 setmultibox fs-12"
                           handleChange={handleInput}
                        />
                        {validator.current.message("Gender", gender, "required")}
                     </div>
                  </Grid> 
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                        <div className="form-group">
                        <label  className='setheadingfontfamily'>Hire Date <span className="optional-field-label">optional</span></label>
                           <NormalDate
                              isIcon={true}
                              placeholder={`${localStorage.getItem('dateFormat')}`}
                              name="hireDate"
                              value={hireDate ? new Date(hireDate) : ""}
                              className="form-control rounded-0 setmultibox fs-12"
                              onChange={handleInput}
                           />
                           {/* {validator.current.message("hireDate", hireDate, "required")} */}
                        </div>
                  </Grid> 
               </Grid> 

               <Grid container spacing={2} className='newrow'> 
               <Grid item xs={12} sm={6} md={6} className="setstaffinputboxes" id='staffaddress'>
                     <div className="form-group">
                        {/* <NormalInput
                           placeholder="Type Name"
                           name="address"
                           value={address}
                           className="form-control rounded-0"
                           onChange={handleInput}
                           label="Address *"
                        />  */}
                        <label className='setheadingfontfamily'>Staff Map Address
                           <span className="staff-addr-info-icon ml-2">
                              <img src={infoIcon} alt="info icon" />
                           </span>
                        </label>
                        <div className="w-100 position-relative">
                            <LocationSearchInput 
                              address={address} 
                              className="address-field-data"
                              placeholder="Search or enter staff's address"
                              handleAddress={handleAddress}
                              handleSelect={(address) => handleSelectAddress(address)} 
                              handleLatLng={handleLatLng}/> 
                              <img className='address-locationIcon'src={geolocationIcon} alt='geolocationIcon'/>
                        </div>
                        {
                            zipCode === '' && address !== '' ?
                                <div class="srv-validation-message">Select correct Address for PinCode</div>
                                :
                                ''
                        }
                        
                        {validator.current.message("address", address, "required")}
                     </div>
                  </Grid>
               <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily'>Zip Code </label>
                        <NormalInput
                           placeholder="Auto-populated"
                           name="zipCode"
                           value={zipCode}
                           className="form-control rounded-0 setinputbox"
                           onChange={handleInput}
                           type={"number"}
                           disabled={true}
                        />
                        {validator.current.message("zipCode", zipCode, "required")}
                     </div>
                  </Grid>
               <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                        <div className="fs-11 text-black font-JosefinSans pb-2 mb-1 line-height-20 setheadingfontfamily">
                           Staff Time Zone 
                        </div>
                        <NormalSelect
                           // arrow={true}
                           placeholder="Select the time zone"
                           name="timeZone"
                           value={timeZone}
                           options={timezones}
                           className="form-control rounded-0 setmultibox fs-12"
                           handleChange={handleInput}
                        />
                        {validator.current.message(
                           "Time Zone",
                           timeZone,
                           "required"
                        )}
                     </div>
                  </Grid>  
               </Grid> 

               <Grid container spacing={2} className='newrow'> 
               <Grid item xs={12} sm={6} md={6} className="setstaffinputboxes" id='staffaddress'>
                     <div className="form-group">
                        <label className='setheadingfontfamily'>Staff Postal Address <span className="optional-field-label">optional</span>
                           <OverlayTrigger placement="top" style={{zIndex:'101'}}
                              overlay={
                                 <Tooltip>
                                    This field can/should be used when the postal address of staff for communication differs from the Map Address.
                                 </Tooltip>
                              }
                           >
                              <img className='ml-2' src={infoIcon} alt="info icon" />
                           </OverlayTrigger>
                           <input className='ml-3 mb-1' type='checkbox' id='sameAsAbove' checked={sameAsAbove} onClick={handleCheckBox} disabled={address.length > 0 ? false : true}></input>
                           <label className='ml-1 font-Poppins cursor-pointer' for='sameAsAbove' style={{ marginBottom: '-2px' }}>Same as above</label>
                        </label>
                        <div className="w-100 position-relative">
                        <NormalInput 
                           placeholder="Enter staff's postal address"
                           name="postalAddress"
                           value={postalAddress ? postalAddress : ""}
                           maxlength='250'
                           className="form-control rounded-0 fs-11 edit-profile-form postal-address address-field-data"
                           onChange={handlePostalAddress}
                        />
                        <img className='envelope-Icon'src={envelopeIcon} alt='envelopeIcon'/>
                        </div>
                     </div>
                  </Grid>
               <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily'>Postal Zip Code </label>
                        <NormalInput
                           placeholder="Enter staff's zip code"
                           name="postalZipCode"
                           value={postalZipCode ? postalZipCode : ""}
                           maxlength='10'
                           className="form-control rounded-0 setinputbox"
                           onChange={handleInput}
                           type={"number"}
                           disabled={postalAddress?.length <= 0 || sameAsAbove ? true : false}
                        />
                     </div>
                  </Grid>
               <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label className='setheadingfontfamily'>Role</label>
                        <NormalSelect
                           placeholder="Select the role"
                           // options={[{ label: "Co-admin", value: "1" },
                           //          { label: "Manager", value: "2" },{ label: "Worker", value: "3" }]}
                           options={allRolls}
                           name="role"
                           value={role}
                           className="form-control rounded-0 setmultibox fs-12"
                           handleChange={handleInput}
                           disabled={(action !== "Add" && !!userType&&Number(userType) !== 3) || staffData.role == "3" }
                        />
                        {validator.current.message("Role", role, "required")}
                     </div>
                  </Grid>
                  </Grid>
                  <Grid container spacing={2} className='newrow lastRow' sx={{justifyContent:'normal'}}> 
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                     <label  className='setheadingfontfamily'>Employment Type </label>
                        <NormalSelect
                           placeholder="Select the employment type"
                           options={[{ label: "Full Time", value: '1' },
                           { label: "Part time", value: '2' },
                           { label: "Per-Diem", value: '3' },
                           { label: "Casual", value: '4' }]}
                           name="employmentType"
                           value={employmentType}
                           className="form-control rounded-0 setmultibox fs-12"
                           handleChange={handleInput}
                        />
                         {validator.current.message("Employment Type", employmentType, "required")}
                     </div>
                  </Grid>  
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                        <label className='setheadingfontfamily'>Date Format </label>
                        <NormalSelect
                              placeholder="Select your preferred date format"
                              options={[{ label: "MM/DD/YYYY", value: "MM/dd/yyyy" },{ label: "DD/MM/YYYY", value: "dd/MM/yyyy" }]}
                              name="dateFormat"
                              value={dateFormat}
                              className="form-control rounded-0 setmultibox fs-12"
                              handleChange={handleInput}
                        />
                           {validator.current.message("Date Format", dateFormat, "required")}
                        </div>
                  </Grid>
   
                  <Grid item xs={12} sm={6} md={3} className="setstaffinputboxes">
                     <div className="form-group">
                        <label className='setheadingfontfamily'>Distance Unit </label>
                        <NormalSelect
                              placeholder="Select the distance unit"
                              options={[{ label: "Miles", value: "1" },{ label: "KM", value: "2" }]}
                              name="distanceUnit"
                              value={distanceUnit!=0?distanceUnit:""}
                              className="form-control rounded-0 setmultibox fs-12"
                              handleChange={handleInput}
                        />
                           {validator.current.message("Distance Unit", distanceUnit, "required")}
                        </div>
                  </Grid>
               </Grid>  
               </div> 
                {/* <div className="col-6">
                     <div className="form-group mb-0 pt-3">
                        <label className="fs-17 text-black">Skills *</label>
                        <Select
                           isMulti
                           options={[{ value: 'java', label: 'java' }]}
                           className="basic-multi-select form-control-multiselect"
                           value={skills}
                           name="skills"
                           onChange={(value) => { handleMultiSelect(value, "skills") }}
                        />
                        {validator.current.message("skills", skills, "required")}
                     </div>
                  </div> */}

                  {/* <div className="col-6">
                     <div className="form-group">
                        <NormalSelect
                           placeholder="Select Employment Type"
                           options={[{ label: "Full-Time", value: "1" },{ label: "Part-Time", value: "2" }]}
                           name="employmentType"
                           value={employmentType}
                           className="form-control rounded-0"
                           handleChange={handleInput}
                           label={"Employment type *"}
                        />
                        {validator.current.message("employmentType", employmentType, "required")}
                     </div>
                  </div> */}
               {/*<div className="col-6">
                     <div className="form-group">
                        <NormalSelect
                           placeholder="Enter or search for team name"
                           options={[{ label: "Testing", value: "Testing" }]}
                           name="team"
                           value={team}
                           className="form-control rounded-0"
                           handleChange={handleInput}
                           label={"Team"}
                        />
                        {validator.current.message("team", team, "required")}
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="form-group">
                        <NormalInput
                           placeholder="Type Name"
                           name="teamManager"
                           value={teamManager}
                           className="form-control rounded-0"
                           onChange={handleInput}
                           label={"Team Manager"}
                        />
                        {validator.current.message("teamManager", teamManager, "required")}
                     </div>
                  </div> */}
                  {/* <div className="col-6">
                     <div className="form-group m-0 ">
                        <label className="fs-17 text-black">Job Function *</label>
                        <Select
                           isMulti
                           options={jobFunctionCategoriesArray}
                           className="basic-multi-select form-control-multiselect"
                           value={jobFunctions}
                           name="jobFunctions"
                           onChange={(value) => { handleMultiSelect(value, "jobFunctions") }}
                        />
                        {validator.current.message("jobFunctions", jobFunctions, "required")}
                     </div>
                  </div> */}
		 {/*<div className="col-6">
                     <p>Biometric Authentication</p>
                     <div className="d-flex align-items-center">
                        <NormalRadio
                           name="bioAuth"
                           className="form-control border-0 rounded-0"
                           onChange={handleInput}
                           label={'Enable'}
                           value={"true"}
                           checked={bioAuth === "true"}
                        />
                        <NormalRadio
                           className="form-control border-0 rounded-0"
                           onChange={handleInput}
                           label={'Disable'}
                           name="bioAuth"
                           value={"false"}
                           checked={bioAuth === "false"}
                        />
                     </div>
                  </div> */}
		 {/*<div className="col-6">
                     <div className="d-flex">
                        <label className="mb-0">  Geo-Tagging* </label>
                        <NormalSwitch
                           checked={geoTag}
                           onChange={(e) =>
                              setFields({ ...formFields, geoTag: e.target.value })
                           }
                           className="mx-2"
                           disable={true}
                        />
                        <i className="mx-3">
                           {formFields.geoTag ? "Enabled" : "Disabled"}
                        </i>
                     </div>
                     <div className="form-group">
                        <NormalInput
                           placeholder="Geo-tag detected location displays here"
                           name="geoLocation"
                           value={geoLocation}
                           className="form-control rounded-0 mt-2"
                           onChange={handleInput}
                           disabled={true}
                        />
                     </div>
                  </div> */}
               {/* <div className="row justify-content-between mt-4">
                  <div className="col-6 row">
                     <div className="col-6 d-flex flex-column">
                        <NormalCheckbox
                           name="approveTimeSheet"
                           value={approveTimeSheet}
                           className="co-admin custom-checkbox"
                           checked={approveTimeSheet}
                           onChange={handleInput}

                           label={"Auto - Approve Timesheet"}
                        />
                        <span><i className="fs-12">Time sheet submitted by the staffs will be Auto-approved. You can Reject it later. </i></span>
                     </div>
                  </div>
                  <div className="col-6 row">
                     <div className="col-6 d-flex  flex-column">

                        <NormalCheckbox
                           name="approveWorkRequest"
                           value={approveWorkRequest}
                           className="co-admin custom-checkbox"
                           checked={approveWorkRequest}
                           onChange={handleInput}
                           label={"Auto - Approve Work Request"}
                        />
                        <span><i className="fs-12">Work request will be assigned to the staffs .w.r.t.their availability.</i></span>
                     </div>
                  </div>

                  <div className="col-6">
                     <div className="form-group m-0 mt-2">
                        <label className="fs-17 text-black">Biometric *</label>
                        <Select
                           isMulti
                           options={[{ value: 'Biometric 1', label: 'Biometric 1' }, { value: 'Biometric 2', label: 'Biometric 2' }]}
                           className="basic-multi-select form-control-multiselect"
                           value={biometric}
                           name="biometric"
                           onChange={(value) => { handleMultiSelect(value, "biometric") }}
                        />
                        {validator.current.message("biometric", biometric, "required")}
                     </div>
                  </div>
               </div> */}
               
               {/* <div className="col-4">
                  <NormalButton
                     label="PREVIOUS"
                     outline={false}
                     className="mb-2 btn fs-14 btn-block bg-gray disable"
                     disabled={true}
                  />
               </div>
               <div className="col-4">
                  <NormalButton
                     onClick={(e) => {action !== "Add" ?handleSaveValues("1"): handleSubmit(e)  }}
                     id="coAdmin"
                     label="NEXT"
                     outline={false}
                     className="mb-2 btn fs-14 btn-block bg-c10 text-c1 fw-bold"
                  />
               </div> */}
               
               {/* <div className="row mt-5">
                  <div className="col-8">
                     {!!payRate && !!payRate.jobRate && payRate.jobRate.map((jobRateObj, jobRateIndex)=><>
                        <div className="row mb-4">
                           <div className="col-5">
                           <label>Job Function <sup>*</sup></label>
                              <NormalSelect
                                 options={jobFunctionCategoriesArray}
                                 placeholder="Select Job Category"
                                 name="jobCategory"
                                 value={jobRateObj.jobCategory}
                                 className="form-control rounded-0"
                                 handleChange={e=>handleJobFunctionInputs(e, jobRateIndex)}
                              />
                              {validator.current.message("Job Category", jobRateObj.jobCategory, "required")}
                           </div>
                           <div className="col-5">
                           <label>Job Name <sup>*</sup></label>
                              <NormalSelect
                                 options={jobNameArray
                                          .filter(jobNameObj=>jobNameObj.category===jobRateObj.jobCategory)
                                          .filter(jobNameObj=>(payRate.jobRate
                                                .filter((innerJob, innerJobIndex)=>(innerJob.jobName===jobNameObj.value && innerJobIndex!==jobRateIndex && innerJob.jobCategory === jobNameObj.category))
                                          .length===0 ))
                                       }
                                 placeholder="Select Job Name"
                                 name="jobName"
                                 value={jobRateObj.jobName}
                                 className="form-control rounded-0"
                                 handleChange={e=>handleJobFunctionInputs(e, jobRateIndex)}
                              />
                              {validator.current.message("Job Name", jobRateObj.jobName, "required")}
                           </div>
                           <div className="col-1 d-flex justify-content-center align-items-center">
                              {payRate.jobRate.length>1 && <span
                                 className='cursor-pointer '
                                 onClick={()=>removePayRateByIndex(jobRateIndex)}
                              >X</span>}
                           </div>
                        </div>
                     </>)}
                  </div>
                  <div className="col-6 d-flex justify-content-end"></div>
               </div>

               <div className="row mt-5">
                  <div className="col-8">
                        <div className="row">
                           <div className="col-5"></div>
                           <div className="col-5"></div>
                           <div className="col-2 ">
                              <span onClick={addNewJobFunctionFields} className={jobNameArray.length<=payRate.jobRate.length?'no-drop':'cursor-pointer'}>+ Add New</span>
                           </div>
                        </div>
                  </div>
                  <div className="col-3 d-flex justify-content-end"></div>
               </div> */}
               <div className="row mt-5">
                  <div className="col-6"></div>
                  <div className="col-6 d-flex justify-content-end">
                     <div className="row w-100  justify-content-end">
                        <div className="">
                           <NormalButton
                              onClick={() => { handleSubmit() }}
                              loader={loadingKey === 'handle-submit'}
                              label="SAVE AND PROCEED"
                              outline={false}
                              className="mb-2 btn fs-13 btn-block fw-bold btn-dark-primary "
                           />
                        </div>
                     </div>
                  </div>
                  <div className='mt-3 marginalign'><i>*Notification mail will be sent to staff</i></div>
               </div>
            </>
            : <CommonPageLoader />}
            {showDeleteConfirmation && (
               <DeleteFileConfirmationModal
                  isOpen={true}
                  handleAction={handleDeleteConfirmation}
                  toggle={() => setShowDeleteConfirmation(false)}
               />
            )}

         {showChangeEmailModal &&
            <ChangeEmailModal
               details={formFields}
               toggle={() => setShowChangeEmailModal(!showChangeEmailModal)}
               isOpen={showChangeEmailModal}
               updateEmailField={updateEmailField}
               headerName={'Change Email'}
            />
         }
      </>

   )
}

const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         fileUploadOnMDB,
         deleteFileFromMDB,
         getStaffById,
         getAllJobFunctionCategoriesByAgency,
         getAllRegions,
         getAllRoll,
         getTimeZoneList,
         getAllJobFunction,
         getAgencyJobs
      },
      dispatch
   );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(EditProfile);
