import React , {useState} from 'react';
import { NormalButton, NormalModal } from 'component/common';

export default function RecurringConfirmationModal({handleAction,isOpen,toggle, loadingKey = '', loadingCheckKey = '', message = '',type}) {
    
    const [isChecked, setIsChecked] = useState(false);
    
    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            renderlabel="Review Alert!"
            headerClass="border-0 pt-1 px-1"
            toggle={toggle}
        >
             <>
                  <div className=" text-center mb-3 px-5">
                     {/* <p className="fs-20 mb-0">
                        Your work is <b>In-progress. </b>
                     </p> */}
                     <p className="h3-m mb-0">
                        {message ? message : 
                        <p style={{fontSize:"18px"}} ></p>}
                     </p>
                  </div>
                  <div className='text-center mb-2'>
                  <input type="checkbox"
                       id="acknowledge"
                       onChange={(e) => setIsChecked(e.target.checked)} />
                    <label htmlFor="acknowledge" style={{ marginLeft: '5px' }}>I understand and acknowledge the above</label>
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                     <NormalButton
                        onClick={()=>{
                            type?handleAction({type:type,value:false}):handleAction(false)
                        }}
                        id="cancel"
                        label="Cancel"
                        outline={false}
                        disabled ={loadingKey === loadingCheckKey}
                        // loader={isFormLoder}
                        className="pl-4 pr-4 mb-2 btn fs-13 btn border-primary btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                            type?handleAction({type:type,value:true}):handleAction(true)
                        }}
                        id="confirm"
                        label="Acknowledge"
                        loader={loadingKey === loadingCheckKey}
                        disabled={!isChecked || loadingKey === loadingCheckKey}
                        outline={false}
                        className="mb-2 ml-2 btn fs-13 btn btn-dark-primary  btn-block "
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}


