import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "./date.scss";
import iconImg from 'assets/svg/calender.svg';
import moment from "moment";
// import "react-datepicker/dist/react-datepicker.css";

export class NormalDate extends Component {

  openDatepicker = (e) => {
    this._calendar.setOpen(true);
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
  render() {
    let {
      className = "form-control w-100",
      placeholder = `${localStorage.getItem('dateFormat')}`,
      onChange,
      onClick,
      value = "",
      name,
      disabled = false,
      isReadOnly = false,
      showYearPicker = false,
      showFullMonthYearPickerStatus = false,
      showMonthYearPickerStatus = false,
      showYearDropdown = false,
      showMonthDropdown = false,
      maxdate = "false",
      mindate = null,
      timeInput = false,
      label = "",
      isIcon=false,
      isLeftIcon = false,
      showTimeSelect=false,
      timeIntervals=30,
      open,
      readOnly,
      id,
      setActiveNormalDateIndex,
    } = this.props;

    return (
      <>
      {
        !showTimeSelect?
      <div className="normal-date-input">
        {label !== "" ? (
          <div className="fs-12 text-black font-JosefinSans pb-2 mb-1 line-height-20 defaultfont">
            {label}
          </div>
        ) : (
          ""
        )}
        {!isReadOnly ? (
          <div className="position-relative normal-date-input" onClick={this.openDatepicker} >
            {
               isLeftIcon?
               <img className="left-calender-icon" src={iconImg} onClick={this.openDatepicker} />
               :''
            }
          <DatePicker
            id={id}
            open={open}
            readOnly={readOnly}
            ref={(c) => this._calendar = c}
            calendarIcon={true}
            timeInputLabel={timeInput && "Time:"}
            showTimeInput={timeInput}
            className={className}
            disabled={disabled}
            placeholderText={placeholder}
            selected={value}
            showYearPicker={showYearPicker}
            showMonthYearPicker={showMonthYearPickerStatus}
            showFullMonthYearPicker={showFullMonthYearPickerStatus}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dateFormat={`${localStorage.getItem('dateFormat')==="DD/MM/YYYY"?"dd/MM/yyyy":"MM/dd/yyyy"}`}
            // maxDate={maxdate && new Date()}
            minDate={mindate}
            autoComplete="off"
            onChange={(date,e) => { 
              if(!readOnly){
                let body = {};
  
                body = {
                  target: {
                    name: name,
                    value: date,
                  },
                };
              if(e?.target.value) { 
                const isValid = moment(e.target.value,localStorage.getItem('dateFormat'),true).isValid() 
                isValid && onChange(body);
              } 
              else {
                onChange(body);
              }
              // setTimeout(() => {
              //   this._calendar.setOpen(false);
                // if (typeof setActiveNormalDateIndex === 'function') {
                //   setActiveNormalDateIndex(null);
                // }
              // }, 100);
              }
            }}
            name={name}
          />
          {
            isIcon?
            <img className="calender-icon" src={iconImg} onClick={this.openDatepicker} />
            :''
          }
          </div>
        ) : (
          <p>{value}</p>
        )}
      </div>
      :
      <div className="normal-date-input">
        {label !== '' ?
          <div className="fs-12 text-black font-JosefinSans pb-2 mb-1 line-height-20 defaultfont">
            {label}
          </div>
          : ''}
        {!isReadOnly ?
          <DatePicker
            filterTime={this.filterPassedTime}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            showTimeSelectOnly
            showPopperArrow={false}
            // timeInputLabel={timeInput && "Time:"}
            // showTimeInput={timeInput}
            className={className}
            disabled={disabled}
            placeholderText={placeholder}
            selected={value}
            // showYearPicker={showYearPicker}
            // showMonthYearPicker={showMonthYearPickerStatus}
            // showFullMonthYearPicker={showFullMonthYearPickerStatus}
            // showMonthDropdown={showMonthDropdown}
            // showYearDropdown={showYearDropdown}
            dateFormat="h:mm aa"
            // maxDate={maxdate && new Date()}
            minDate={mindate}
            autoComplete="off"
            onChange={(date,e) => { 
              if(!readOnly){
              let body = {};

              body = {
                target: {
                  name: name,
                  value: date

                },
              }; 
              if(e?.target.value){ 
                const isValid = moment(e.target.value,'hh:mm A',true).isValid() 
                isValid && onChange(body);
              } 
              else { 
                onChange(body)
              }
             }
            }}
            name={name}
          />
          :
          <p>{value}</p>
        }

      </div>
    }
    </>
    );
  }
}
