import React,{useState,useEffect} from 'react';
import { withRouter, useLocation } from "react-router-dom";
import "./style.scss";
import Moment from "moment";
import {approveStaff,rejectStaff} from "action/workRequest";
import {TimeSheetMark } from 'component/workShedule/timeSheetMark/index';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import leftArrowIcon from 'assets/svg/left_arrow.svg';
import rightArrowIcon from 'assets/svg/right_arrow.svg';
import deleteIcon from 'assets/svg/delete.svg';
import profile from '../../../assets/svg/Ellipse.png';
import useMapDataParser from 'component/common/interactiveMap/useMapDataParser';
import InteractiveMap from 'component/common/interactiveMap';

function StaffDetails({setActiveStaff,workID,fetchWorkById, startDate, endDate, approveStaff,rejectStaff,data,sitePicture, clientLocLatLng}) {

    const [active, setActive] = useState(0);
    const [isFormLoder, setFormLoder] = useState(false);
    const { timeData: staffTimeData, staffName, staffLatLong, showMap, toggleMap } = useMapDataParser({ data: data?.staffWorkData?.[active] });
    const location = useLocation();

    const [activePointsFromStaffDetails, setActivePointsFromStaffDetails] = useState('');

    useEffect(() => {
        if (!!setActiveStaff) {
            setActiveStaff(staffWorkData[active])
        }
    }, []);
    const handleStaffDetais=(val)=>{
        console.log(active+val)
        setActive(active+val);
        if(!!setActiveStaff){
            setActiveStaff(staffWorkData[active+val])
        }
    }
    // // handle single staff approve by agency
    // const handleApproveStaff = async (id) => {
    //     let body = {
    //         workId: workID,
    //         staffId: id,
    //     }
    //     setFormLoder(true);
    //     await approveStaff(body).then((data) => {
    //         setFormLoder(false);
    //         fetchWorkById(workID);
    //         // setDefault();
    //         // handleReload();
    //     }).catch(() => {
    //         setFormLoder(false);
    //     });
    // }

    // // handle single staff reject by agency
    // const handleRejectStaff = async (id) => {
    //     let body = {
    //         workId: workID,
    //         staffId: id,
    //         remove:true
    //     }
    //     setFormLoder(true);
    //     await rejectStaff(body).then((data) => {
    //         setFormLoder(false);
    //         fetchWorkById(workID);
    //         // setDefault();
    //         // handleReload();
    //     }).catch(() => {
    //         setFormLoder(false);
    //     });
    // }
    let{staffWorkData,workId}=data;

    const getClientLoc = (longLat) => {
        let clientLoc = {lat: '', lng:''};
        if(!!longLat)
        {
          clientLoc.lat = longLat?.[1];
          clientLoc.lng = longLat?.[0]
        }
        return clientLoc;
      }
    
    // console.log(staffWorkData)
    return (
        <>
            {/* Map */}
            <InteractiveMap 
                timeData={staffTimeData} 
                staffName={staffName} 
                activePointsFromStaffDetails={activePointsFromStaffDetails} 
                staffLatLong={staffLatLong}
                open={showMap} 
                toggle={toggleMap} 
                sitePicture={!!sitePicture && sitePicture.split("|").length>1 ? sitePicture.split("|")[1] : ""} 
                clientLatLong = {getClientLoc(clientLocLatLng)} 
            />
            
            <div className="">
                <div className="row align-items-center justify-content-end">
                    <div className="col-4">
                        <div className="form-group">
                            <img src={leftArrowIcon} />
                            <span className={`navi-link sub-text green-href fs-12 ml-2 ${active === 0 ? 'no-drop':'cursor-pointer'}`} onClick={()=> active === 0 ? {}:handleStaffDetais(-1)}>Show Previous</span>
                            {/* <a className="sub-text green-href fs-12 ml-2" href="#" >Show Previous</a> */}
                        </div>    
                    </div>
                    <div className="col-4">
                        <div className="form-group text-center">
                            <p className="text-black fs-16 font-weight-bold"> Staff Shift Details</p>                        
                        </div>    
                    </div>
                    <div className="col-4">
                        <div className="form-group text-right">
                            {/* <a className="text-black fs-12 mr-2" href="#" > Show next</a>   */}
                            <span className={`navi-link sub-text green-href fs-12 mr-2 ${(active+1) === staffWorkData.length ? 'no-drop':'cursor-pointer'}`} onClick={()=>(active+1) === staffWorkData.length ? { }:handleStaffDetais(1)}> Show next</span>                       
                            <img src={rightArrowIcon} />
                        </div>    
                    </div>
                </div>
                { staffWorkData[active] ? (
                <div className='row align-items-center justify-content-center'>
	    	    {/*<div className="col-4">
                        <div className="form-group">
                            <p className="sub-text fs-16" >work Request ID</p>
                        </div>    
                    </div>
                    <div className="col-8">
                        <div className="form-group">
                            <p className="text-black fs-16 "> {workId}</p>                        
                        </div>    
                    </div>*/}

                    <div className='justify-content-center'>
                        <div className="justify-content-center ribbon highlight">
                            <span className="text-black fs-16 align-item-center padding-1" > {staffWorkData[active].staffName} </span>
                        </div>    
                    </div>
                   
                    {/* <div className="col-4">
                        <div className="form-group">
                            <p className="sub-text fs-16" >Work Hours</p>
                        </div>    
                    </div>
                    <div className="col-8">
                        <div className="form-group">
                            <p className="text-black fs-16 "> {Moment(staffWorkData[active].clockIn).format('MM/DD/YYYY hh:mm:ss a') } - {Moment(staffWorkData[active].clockOut).format('MM/DD/YYYY hh:mm:ss a') }</p>                        
                            </div>    
                    </div> */}
	    	    {/*<div className="col-4">
                        <div className="form-group">
                            <p className="sub-text fs-16" >Location</p>
                        </div>    
                    </div>
                    <div className="col-8">
                        <div className="form-group">
                            <p title={staffWorkData[active].location} className="text-black fs-16 mb-0"> {staffWorkData[active].location.split(',').slice(0,2).join(',')}</p>                        
                            <p className="btn-gray-clr fs-12 "> 15km away from client’s location </p>                         
                        </div>    
                    </div>*/}
                </div> ): ""}
            </div>
            {
                staffWorkData[active] ?
                (staffWorkData[active].status >= 3 || (staffWorkData[active].status === 0 && staffWorkData[active].clockIn))?
                    <TimeSheetMark data={staffWorkData[active]} toggleMap={toggleMap} startDate={startDate} endDate={endDate} setActivePointsFromStaffDetails={setActivePointsFromStaffDetails} />
                :<>
                    {/* <table className="w-100 mb-5 mt-3">
                        <thead>
                            <td>Name</td>
                            <td>ID</td>
                            <td>Location</td>   
                            <td>Action</td>
                        </thead>
                        <tbody>
                            {
                                !!staffWorkData[active] ?
                                    <>
                                        {
                                            // workStaffAssignment.staffWorkData.map((item, index) =>
                                                <tr>
                                                    <td className="fs-14">{ staffWorkData[active].staffName}</td>
                                <td className="fs-12">{ staffWorkData[active].staffId}</td>
                                                    <td className="fs-12 ">
                                                        <span title={ staffWorkData[active].location}>
                                                        { staffWorkData[active].location.split(',').slice(0,2).join(',')}
                                                        </span>
                                                        </td>
                                                    {
                                                        staffWorkData[active].status === 0 ?
                                                            <td className="fs-12">Work Canceled</td>
                                                            :
                                                            <>
                                                                { (staffWorkData[active].status === 2 && location.pathname!="/admin/timeSheet")?
                                                                    <>
                                                                        <td className="text-success fs-12 cursor-pointer text-decoration-underline" id="approve-button" onClick={() => handleApproveStaff( staffWorkData[active].staffId)}>APPROVE </td>
                                                                    </>
                                                                    :
                                                                    <td className="text-muted fs-12 no-drop" >{ staffWorkData[active].status === 1 ? 'APPROVE' :'APPROVED'} </td>

                                                                }
                                                                {location.pathname!="/admin/timeSheet"?
                                                                <td className="text-danger fs-12 cursor-pointer text-decoration-underline" id="reject-button" onClick={() => handleRejectStaff( staffWorkData[active].staffId)}>REJECT </td>
                                                                :
                                                                <td className="text-muted fs-12 no-drop" >REJECT</td>
                                                                }
                                                            </>
                                                    }
                                                </tr>
                                            // )
                                        }

                                    </>
                                    :
                                    <tr>
                                        <td colSpan="5" className="border-0">
                                            <p className="text-center fs-14">
                                                { staffWorkData[active].status === 0 ? 'Work Canceled' :  staffWorkData[active].status === 1 ? 'Need to accept by agency' : ''}
                                            </p>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table> */}
                </>
                : ""
            }
            
    </>

    )
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            approveStaff,rejectStaff
        },
        dispatch
    );
};

export default connect(
    mapDispatchToProps,
    mapDispatchToProps
)(withRouter(StaffDetails));


