import React, { useEffect, useState } from 'react';

const steps = [1, 5, 10, 30, 60, 300, 1800, 3600];

function GeoInterval({value, onChangeValue=()=>{}, disable=false}) {
    const [data, setData] = useState(steps[4]);

    useEffect(()=>{
        if(value){
            setData(value)
        }
    },[value])

    const handleChange = (event) => {
        let value = steps[event.target.value]
      setData(value);
      onChangeValue(value)
    };

    function convertToTimeString(seconds) {
        let timeString = '';
        if (seconds < 60) {
            timeString = seconds + 's';
        } else if (seconds < 3600) {
            timeString = Math.floor(seconds / 60) + 'm';
        } else {
            timeString = Math.floor(seconds / 3600) + 'h';
        }
    
        return timeString;
    }

    return (
        <div className="d-flex justify-content-center align-items-center" style={{gap:'10px'}}>
      <input
      className='geo-interval-slider'
        type="range"
        min={0}
        max={steps.length - 1}
        value={steps.indexOf(data)}
        onChange={handleChange}
        step={1}
        disabled={disable}
      />
        <span id="timer-value s2-r">{convertToTimeString(data)}</span>
        </div>
    );
}

export default GeoInterval;
