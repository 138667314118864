import React, { useEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react';

import classes from './interactive_map.module.scss'
import clockInCursor from '../../../assets/svg/map_cursors/clockIn.svg'
import clockOutCursor from '../../../assets/svg/map_cursors/clockOut.svg'
import pauseCursor from '../../../assets/svg/map_cursors/pause.svg'
import resumeCursor from '../../../assets/svg/map_cursors/resume.svg'
import defaultClientImage from "../../../assets/images/letter-c.png";
import moment from 'moment';
import { entryType } from './constants';
import WarningModal from './warningModal';

const AnyReactComponent = ({ text, time, active, onCursorClick}) =>(
    <div className={!!active ? classes.active_cursor : ''} onClick={onCursorClick}>
        <img
            src={
                text === entryType.CLOCK_IN ? clockInCursor :
                    text === entryType.CLOCK_OUT ? clockOutCursor :
                        text === entryType.PAUSED ? pauseCursor : resumeCursor
            }
            alt="cursor"
            className={classes.cursor_in_map}
        />
        <div className={classes.text_details}>
            {text}
            <br />
            {time}
        </div>
    </div>
);

// const StaffLoc = ({sitePicture}) =>(
//     <div>
//         <img
//             src={sitePicture}
//             alt="cursor"
//             className={classes.staff_loc_cursor}
//         />
//     </div>
// );

const ClientLoc = ({sitePicture}) =>(
    <div>
        <img
            src={sitePicture}
            alt="cursor"
            className={classes.staff_loc_cursor}
        />
    </div>
);

export default function Map({ mapData = [], staffLatLong,clientLocLatLong, activePoint = 0 , showLines=true, warningModal, setWarningModal, setActivePoint,sitePicture}) {

    const [pathArray, setPathArray] = useState([]);

    const mapp = useRef('');

    const handleGoogleMapApi = (google) => {
        if (mapData.length < 2) return;
        
        let pathArrayTemp = [];

        const lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 3,
        };

        let prev = 0;
        let solidLine = true;

        for (let current = 1; current < mapData.length; current++) {

            if (!mapData[prev]?.lat || !mapData[prev]?.lng || !mapData[current]?.lat || !mapData[current]?.lng) {
                solidLine = !solidLine;
                prev = current;
                continue;
            }

            if (solidLine) {
                pathArrayTemp.push(
                    new google.maps.Polyline({
                        path: [
                            { lat: Number(mapData[prev]?.lat), lng: Number(mapData[prev]?.lng) },
                            { lat: Number(mapData[current]?.lat), lng: Number(mapData[current]?.lng) }
                        ],
                        geodesic: false,
                        strokeColor: 'green',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                    })
                )
            } else {
                pathArrayTemp.push(
                    new google.maps.Polyline({
                        path: [
                            { lat: Number(mapData[prev]?.lat), lng: Number(mapData[prev]?.lng) },
                            { lat: Number(mapData[current]?.lat), lng: Number(mapData[current]?.lng) }
                        ],
                        geodesic: false,
                        strokeColor: 'red',
                        strokeOpacity: 0,
                        icons: [
                            {
                                icon: lineSymbol,
                                offset: "0%",
                                repeat: "20px",
                            },
                        ],
                        strokeWeight: 2,
                    })
                )
            }
            

            solidLine = !solidLine;
            prev = current;
            
        }

        setPathArray(pathArrayTemp);

        mapp.current = google.map
    };

    useEffect(() => {
        !!pathArray.length && mapp.current &&
            pathArray.forEach((el, _) =>
                !!showLines ? el?.setMap(mapp.current) :
                    el?.setMap(null));
    }, [showLines, pathArray, mapp.current]);

    return (
        <div style={{ width: '100%', height: '100%', position:'relative' }}>
            <WarningModal data={warningModal} setData={setWarningModal}/>
           <GoogleMapReact
                defaultCenter={{ lng: !!clientLocLatLong?.lng ? Number(clientLocLatLong?.lng) : 0, lat: !!clientLocLatLong?.lat ? Number(clientLocLatLong?.lat) :0}}
                // default center is staff loc
                // if active-item = "", then center => (if staff loc ? loc : 0) 
                // else active-item loc ? loc : 0
                center={{
                    lng: activePoint === ''
                        ? !!clientLocLatLong?.lng
                            ? Number(clientLocLatLong?.lng)
                            : 0 
                        : !!mapData[activePoint]?.lng
                            ? Number(mapData[activePoint]?.lng)
                            : 0,
                    lat: activePoint === ''
                        ? !!clientLocLatLong?.lat ? Number(clientLocLatLong?.lat)
                            : 0
                        : !!mapData[activePoint]?.lat
                            ? Number(mapData[activePoint]?.lat)
                            : 0
                }}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={handleGoogleMapApi}
            >
                {/* {!!staffLatLong?.lat && !!staffLatLong?.lng && (
                    <StaffLoc
                        sitePicture = {sitePicture ? sitePicture : defaultClientImage}
                        lat={Number(staffLatLong?.lat)}
                        lng={Number(staffLatLong?.lng)}
                    />
                )}   */}
                 {!!clientLocLatLong?.lat && !!clientLocLatLong?.lng && (
                    <ClientLoc
                        sitePicture = {sitePicture ? sitePicture : defaultClientImage}
                        lat={Number(clientLocLatLong?.lat)}
                        lng={Number(clientLocLatLong?.lng)}
                    />
                )}  
              
                {
                    !!mapData.length && mapData.map((point, index) => (
                        !!point?.lat && !!point?.lng &&
                        <AnyReactComponent
                            key={index}
                            onCursorClick={()=>setActivePoint(index)}
                            lat={Number(point?.lat)}
                            lng={Number(point?.lng)}
                            active={activePoint === index}
                            text={point?.label}
                            time={`${moment(point?.date).format(`${localStorage.getItem('dateFormat')}`)},  ${moment(point?.date).format('LTS')}`}
                        />
                    ))
                }
            </GoogleMapReact>
        </div>
    )
}
