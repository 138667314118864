import React, { useState } from 'react';
import { NormalSelect } from "component/common"
import "./style.scss"
import option from 'assets/svg/option.svg'
import Union from 'assets/svg/Union.svg'

export function FilterWorkList() {

    let jobLocation =[
        {
            label: 'Work category',
            label: 'Job function',
        }
    ]
    let action =[
        {
            label: 'Add work Request',
            label: 'Staff assignment',
        }
    ]
    const [filter,searchValue]  = useState('')
    return (
        <div className="row pl-3 pr-3">
                <div className="col-5">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <p className="mb-0 mr-2">
                                View by
                            </p>
                            <NormalSelect
                                placeholder="All Clients"
                                name="username"
                                value={filter}
                                className="form-control select-mini"
                                onChange={e=>{}}
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="mb-0 mr-2">
                                Show
                            </p>
                            <NormalSelect
                                placeholder="All "
                                name="username"
                                value={filter}
                                className="form-control select-mini"
                                onChange={e=>{}}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-7">
                    <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center mr-1">
                            <NormalSelect
                                placeholder="All Clients"
                                name="username"
                                value={jobLocation[1]}
                                options={jobLocation}
                                className="form-control select-mini"
                                onChange={e=>{}}
                            />
                        </div>
                        <div className="d-flex align-items-center  mr-1">
                            <NormalSelect
                                placeholder="Action"
                                name="username"
                                value={action[1]}
                                options={action}
                                className="form-control select-mini"
                                onChange={e=>{}}
                            />
                        </div>
                        <div className="d-flex align-items-center  mr-1">
                            <NormalSelect
                                placeholder="Week"
                                name="username"
                                value={action[1]}
                                options={action}
                                className="form-control select-mini"
                                onChange={e=>{}}
                            />
                        </div>
                        <div className="d-flex align-items-center  mr-1">
                            <img className=" ml-3 mr-2" src={option}/>
                        </div>
                        <div className="d-flex align-items-center  mr-1">
                            <img className=" ml-3 " src={Union}/>
                        </div>
                    </div>
                </div>
            </div>

    )
}


