import React, { useEffect, useState } from "react"
import {NavLink} from 'react-router-dom';

import {history} from '../../../service/helpers';
import "./style.scss"

const SidebarMobile = (props) => {

      const [sideBarData, setSideBarData] = useState({
        navLinks: [
            {
              to: '/admin/workSchedule',
              label: 'Work Schedule',
              classNames:"WSSizePlus1",
            },
            {
              to: '/admin/timeSheet',
              label: 'Time Sheet Review',
              classNames:"sideBarIcon1",
            },
            {
              to: '/admin/staffManagement',
              label: 'Staff',
              classNames:"sideBarIcon1",
            },
            {
              to: '/admin/clientOnboarding',
              label: 'Clients',
              classNames:"sideBarIcon1",
            },
          
            {
              to: '/admin/invoice',
              label: 'Invoices',
              classNames:"sideBarIcon1",
            },
            {
              to: '/admin/timeOff',
              label: 'Time Off',
              classNames:"sideBarIcon1",
            },
            {
              to: '/admin/report',
              label: 'Reports',
              classNames:"sideBarIcon1",
            },
            {
              to: '/admin/auditLogs',
              label: 'Audit Logs',
              classNames: "sideBarIcon1",
            },
            {
              to: '/admin/subscription',
              label: 'Subscription',
              classNames:"sideBarIcon1",
            },
            {
              to: '/admin/settings',
              label: 'Settings',
              classNames:"sideBarIcon1",
            }
          ],
        prev: '',
        next: '',
        active: '',
      });
  
    const activeTab = (index) => {
      setSideBarData({...sideBarData,next: index + 1,prev: index - 1,active: index})
    };

    useEffect(() => {
        let {location} = history;
        let {navLinks} = sideBarData;
        navLinks.forEach((item, index) => {
          if (item.to === location.pathname) {
            setSideBarData({...sideBarData,next: index + 1,prev: index - 1,active: index})
          }
        });
      },[])
      
        let {navLinks, active} = sideBarData;
        let {menuOpen, handleSidenav} = props;
        let {location} = history;
        const tab = (to,index) =>{
            if(to !== "/something"){
                activeTab(index)
            }
        }
        const closeMenu = () => {
            handleSidenav(false)
          }
    
        return (
            <>
            <div className="left=menu">
               <ul className={`menuNav ${menuOpen ? " showMenu" : "hidemenu"}`}>
               {navLinks.map(({to, label, classNames, id}, index) => (
                <li key={index}>
                    <NavLink
                       onClick={()=>{tab(to,index); closeMenu()}}
                       tabIndex={to === "/something" ? "-1" :""}
                       to={to}
                       className="nav-link"
                       exact
                    >
                    <div className="sidebar-menu">
                     {(active === index && location.pathname.indexOf(to)>-1) || 
                        (active!==undefined && location.pathname.indexOf(to)>-1) ? (
                          <span className={classNames}>{label}</span>
                        ) : (
                          <span id={id}>{label}</span>
                        )}
                     
                    </div>
                    </NavLink>
                
                </li>
               ))}
               </ul>
            </div>
            </>
        )
    }

export default SidebarMobile;