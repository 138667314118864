import React from 'react';

export function NormalToUSInput(number) {
  if (!number) return number;

  // Clear the inputs for characters other than numbers
  const phoneNumber = number.replace(/[^\d]/g, "");

  // Check the inputs for ensuring US format
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice( 3, 6)}-${phoneNumber.slice(6, 10)}`;
} 

export function NormalToUSOutput(number) {
  if (!number) return number;

  // Clear the inputs for characters other than numbers
  const phoneNumber = number.toString();

  // Check the inputs for ensuring US format
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength == 10) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice( 3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
}

export function USFormatToNormal(number) {

  // Clear the inputs for characters other than numbers
  const phoneNumber = number.replace(/[^\d]/g, "");

  // Check the inputs for ensuring US format
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength == 10) return phoneNumber;
}
