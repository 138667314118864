import { axiosInstance } from './utilities';
import { logout } from './utilities';

// var beURL = 'http://staffiqdev-be.qutrix.io';
//var beURL = 'http://be.staffatom.com';
var beURL = process.env.REACT_APP_BEURL;

//var bePort = '80'; // or use 84 as needed
var bePort = process.env.REACT_APP_BEPORT;

let isRefreshing = false;
let refreshSubscribers = [];

function handleCallbackApiCalls (){
   let token = localStorage.getItem("AuthToken")
   isRefreshing = false;
   refreshSubscribers.forEach((callback) =>{ 
    callback(token)
 });
   refreshSubscribers = [];
}
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if ((error.response.status === 401 ) && !originalRequest._retry) {
      if (isRefreshing) {
         return new Promise((resolve) => {
          refreshSubscribers.push((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      isRefreshing = true;
       
      axiosInstance.defaults.headers.common[
         "Authorization"
      ]=`Bearer ${localStorage.getItem("refreshToken")}`
       axiosInstance.post(`${getServiceUrl('auth')}v1/refreshToken?accessToken=Bearer ${localStorage.getItem('AuthToken')}`)
      .then((response) => {
        const { token, refreshToken } = response.data.data;
        localStorage.setItem('AuthToken', token);
        localStorage.setItem('refreshToken', refreshToken);

        handleCallbackApiCalls()
      })
      .catch((error) => {
        logout()
      })
   
      return new Promise((resolve) => {
         refreshSubscribers.push((accessToken) => {
           originalRequest.headers.Authorization = `Bearer ${accessToken}`;
           resolve(axiosInstance(originalRequest));
         });
       });
     
    }
    return Promise.reject(error);
  }
);


export var api = async function ({
   method = 'get',
   api,
   body,
   status = false,
   token = '',
   baseURL = 'normal',
   responseType = null
}) {
   return await new Promise((resolve, reject) => {
      // setting token
      axiosInstance.defaults.headers.common[
         "Authorization"
      ] = baseURL == 'agencyPayment'?`Basic cXV0cml4OnF3ZXJ0eUAxMjM=`: localStorage.getItem("AuthToken")
            ? `Bearer ${localStorage.getItem("AuthToken")}`
            : "";
       
            axiosInstance.defaults.responseType = responseType ? responseType : 'json'
       
      axiosInstance[method](`${getServiceUrl(baseURL)}${api}`, body ? body : "")
         .then((data) => {
            resolve(statusHelper(status, data));
         })
         .catch((error) => {
            try {
               if (error.response) {
                  reject(statusHelper(status, error.response));
               } else {
                  reject(error);
               }
            } catch (err) {
               console.log(err);
               reject(err);
            }
         });
   });
};

var statusHelper = (status, data) => {
   // if (data.status == 401 || data.status == 403) {
   //    logout();
   // }
   if (status) {
      return {
         status: data.status,
         ...data.data,
      };
   } else {
      return data.data;
   }
};

export let getServiceUrl = (baseURL) => {
   let finalURL = '';

   switch (baseURL) {
      case 'auth':
         finalURL = (beURL) + ':' + (bePort) + '83/';
         break;
      case 'agency':
         finalURL = (beURL) + ':' + (bePort) + '87/';
         break;
      case 'agencyPayment':
         finalURL = (beURL) + ':' + (bePort) + '87/';
         break;
      case 'staff':
         finalURL = (beURL) + ':' + (bePort) + '91/';
         break;
      case 'location':
         finalURL = (beURL) + ':' + (bePort) + '89/';
         break;
      case 'timeOff':
         finalURL = (beURL) + ':' + (bePort) + '87/';
         break;
      case 'client':
         finalURL = (beURL) + ':' + (bePort) + '85/';
         break;
      case 'CSV':
         finalURL = (beURL) + ':' + (bePort) + '87/';
         break;
      case 'notification':
         finalURL = (beURL) + ':' + (bePort) + '99/';
         break;
      case 'Agency':
         finalURL = (beURL) + ':' + (bePort) + '87/agency/';
         break;
      case 'work':
         finalURL = (beURL) + ':' + (bePort) + '90/';
         break;
      case 'workStatus':
         finalURL = (beURL) + ':' + (bePort) + '95/';
         break;
      case 'fileUpload':
         finalURL = (beURL) + ':' + (bePort) + '81/';
         break;
      case 'Logs':
         finalURL = (beURL) + ':' + (bePort) + '99/v1/';
         break;
      case 'Report':
         finalURL = (beURL) + ':' + (bePort) + '95/';
         break;
      case 'subscription':
         finalURL = (beURL) + ':' + (bePort) + '89/';
         break;
       case 'job':
         finalURL = (beURL) + ':' + (bePort) + '84/';
         break;
       case 'tax':
         finalURL = (beURL) + ':' + (bePort) + '84/';
         break;
      default:
         finalURL = (beURL) + ':' + (bePort) + '83/';
         break;
   }

   return finalURL;
};
