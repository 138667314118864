import { settingsApi } from '../service/apiVariables';

import { addQuery } from '../service/helperFunctions';


 

export const getSettingsById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, settingsApi.getSettingsById);
        api({ ...settingsApi.getSettingsById })
            .then(({ data }) => {
                dispatch({type: 'UPDATE_USER_DATA',payload: data,})
                
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getTimeZoneList = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, settingsApi.getTimeZoneList);
        api({ ...settingsApi.getTimeZoneList })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};
 
export const updateGeneralDetails = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateGeneralDetails, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateCompanyDetails = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateCompanyDetails, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateJobFunctions = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateJob, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateJob = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateJobFunction, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateSaveCard = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateCardDetail, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const removeCardDetail = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.removeCardDetail, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updatePermission = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updatePermission, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateNotification = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateNotification, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };


  export const changePasscode = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.changePassword);
      settingsApi.changePassword.query.emailId=encodeURIComponent(settingsApi.changePassword.query.emailId)
      api({ ...settingsApi.changePassword })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const verifyOTP = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.otpVerify);
      settingsApi.otpVerify.query.emailId=encodeURIComponent(settingsApi.otpVerify.query.emailId)
      api({ ...settingsApi.otpVerify })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updatePassCode = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updatePassCode, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateAgencyOverTime = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateAgencyOverTime, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateTimeOff = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateTimeOff, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  export const updateSubscription = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...settingsApi.updateSubscription, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
  
  export const getSubscriptionList = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, settingsApi.getSubscriptions);
        api({ ...settingsApi.getSubscriptions })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
}; 


export const deleteAgency = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, settingsApi.deleteAgency);
    api({ ...settingsApi.deleteAgency, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
}; 


export const downloadData = (query, responseType) => (dispatch, getState, { api, Toast }) => { 

  return new Promise((resolve, reject) => {
    addQuery(query, settingsApi.downloadData);
    api({ ...settingsApi.downloadData, responseType })
      .then(( data ) => { 
        
        resolve(data); 
        Toast({ type: "success" });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
}; 

export const getNewTasks = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.newTasks);
      api({ ...settingsApi.newTasks })
          .then(({ data }) => {
              resolve(data);
          })
          .catch(({ message }) => {
              reject(Toast({ type: 'error', message }));
          });
  });
};


export const updateTaxSettings = (body) => (dispatch, getState, { api, Toast }) => { 

  return new Promise((resolve, reject) => {
    api({ ...settingsApi.updateTaxSettings, body })
      .then(( data ) => { 
        
        resolve(data); 
        
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
    });
  };

  export const getTaxSettings = (query) => (dispatch, getState, { api, Toast }) => { 

    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.getTaxSettings);
      api({ ...settingsApi.getTaxSettings, query })
        .then(( data ) => { 
          
          resolve(data); 
          Toast({ type: "success" });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  }

  export const getTaxRegion = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, settingsApi.getTaxRegion);
      api({ ...settingsApi.getTaxRegion })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
   
export const getAccountingCodes = (query) => (dispatch, getState, { api, Toast }) => {

  return new Promise((resolve, reject) => {
    addQuery(query, settingsApi.getAccountingCodes);
    api({ ...settingsApi.getAccountingCodes})
      .then(( data ) => {
        resolve(data);
        Toast({ type: "success"});
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message}));
      });
  });
};

export const getTaxRates = (query) => (dispatch, getState, { api, Toast }) => {

  return new Promise((resolve, reject) => {
    addQuery(query, settingsApi.getTaxRates);
    api({ ...settingsApi.getTaxRates})
      .then(( data ) => {
        resolve(data);
        Toast({ type: "success"});
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message}));
      });
  });
};

export const addTaxRates = (body) => (dispatch, getState, {api, Toast}) => {
  
  return new Promise((resolve, reject) => {
    api({ ...settingsApi.addTaxRates, body})
      .then(({ message, data }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000});
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message}));
      });
  }); 
}

export const updateTaxRate = (body) => (dispatch, getState, {api, Toast}) => {
  
  return new Promise((resolve, reject) => {
    api({ ...settingsApi.updateTaxRate, body})
      .then(( { message, data }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000});
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message}));
      });
  }); 
}

export const deleteTaxRate = (query) => (dispatch, getState, {api, Toast}) => {
  
  return new Promise((resolve, reject) => {
    addQuery(query, settingsApi.deleteTaxRate);
    api({ ...settingsApi.deleteTaxRate})
      .then(({message, data }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000});
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message}));
      });
  }); 
}

export const addServiceItems = (body) => (dispatch, getState, { api, Toast }) => {

  return new Promise((resolve, reject) => {
    api({ ...settingsApi.addServiceItems, body})
    .then(({ message, data }) => {
      resolve(data);

      Toast({ type: "success", message, time: 5000 });
  })
  .catch(({ message }) => {
      reject(Toast({ type: "error", message }));
  });
  });
};

export const getServiceItems = (query) => (dispatch, getState, { api, Toast }) => {

  return new Promise((resolve, reject) => {
    addQuery(query, settingsApi.getServiceItems);
    api({ ...settingsApi.getServiceItems})
      .then(( data ) => {
        resolve(data);
        Toast({ type: "success"});
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message}));
      });
  });
};


export const updateServiceItems = (body) => (dispatch, getState, { api, Toast }) => {

  return new Promise((resolve, reject) => {
    api({ ...settingsApi.updateServiceItems, body})
    .then(({ message, data }) => {
      resolve(data);

      Toast({ type: "success", message, time: 5000 });
  })
  .catch(({ message }) => {
      reject(Toast({ type: "error", message }));
  });
  });
};

export const deleteServiceItems = (query) => (dispatch, getState, { api, Toast }) => {

  return new Promise((resolve, reject) => {
    addQuery( query, settingsApi.deleteServiceItems);
    api({ ...settingsApi.deleteServiceItems})
    .then(({ message, data }) => {
      resolve(data);

      Toast({ type: "success", message, time: 5000 });
  })
  .catch(({ message }) => {
      reject(Toast({ type: "error", message }));
  });
  });
};