import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import OtpInput from "react-otp-input";
import moment from "moment";
import { NormalButton, NormalModal, NormalInput, CommonPageLoader } from "component/common";
import {
  sendOtpToNewEmail,
  otpVerifyNewEmail
} from "action/AuthAct";

import ConfirmModal from "../ConfirmModal";

const ChangeEmailModal = (props)  => {

  const dispatchHook = useDispatch();

  const [OTP, setOTP] = useState("");
  const [changeScreen, setChangeScreen] = useState(false);
  const [, forceUpdate] = useState(null);
 
  const [newEmail, setNewEmail] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);

  const [timer, setTimer] = useState(0);
  const [loading,] = useState(false)
  const [sendOtpLoading, setSendOtpLoading] = useState(false)

  const [showModal, setShowModal] = useState(false);

  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        email: {
            message: "must be a valid  Email.",
            rule: (val, params, validator) => {
              return (
                validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
                validator.helpers.testRegex(
                  val,
                  /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
                )
              );
            },
            messageReplace: (message, params) =>
              message.replace("", this.helpers.toSentence(params)),
            required: true,
          },
        element: (message) => (
          <span className="error-message font-md">{message}</span>
        ),
      },
    })
  );
  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

    }
    if(timer === 0) {
        setOTP('')
        setShowModal(false)
    }
    return () => clearInterval(interval); 

  }, [timer]);

  const maskEmailAddress = (emailAddress) => {
    function mask(str) {
      var strLen = str.length;
      if (strLen > 4) {
        return (
          str.substr(0, 1) +
          str.substr(1, strLen - 1).replace(/\w/g, "*") +
          str.substr(-1, 1)
        );
      }
      return str.replace(/\w/g, "*");
    }
    return emailAddress.replace(
      /([\w.]+)@([\w.]+)(\.[\w.]+)/g,
      function (m, p1, p2, p3) {
        return mask(p1) + "@" + mask(p2) + p3;
      }
    );
  };

  const sendOtp = () => {
      let valid = validator.current.allValid();
      if (valid) {
        setSendOtpLoading(true)
          const payload = {
              emailId: props.details?.email,
              newEmailId: newEmail
          }

          dispatchHook(sendOtpToNewEmail(payload)).then((res) => {
              setTimer(119)
              setChangeScreen(true)
              setSendOtpLoading(true)
          })
              .catch((err) => {setSendOtpLoading(false);console.log(err)})
      }
      else {
          validator.current.showMessages();
          forceUpdate(true);
      }
  }

    const handleUpdateEmail = async () => {
        props.updateEmailField(newEmail);
        setDefault();
    }

  const handleOtp = () => {
    setOtpLoading(true)
    let payload = {
        emailId: props.details?.email,
        newEmailId: newEmail,
        otp: OTP
      };
      dispatchHook(otpVerifyNewEmail(payload)).then((res) => {
        setOTP("");
        setOtpLoading(false)
        handleUpdateEmail();
      })
      .catch((err) => {console.log(err);setOtpLoading(false)})
  }

    const handleModalAction = (action) => {
        if (action) {
            handleOtp();
        }
        else {
            setShowModal(false);
        }
    }

    const setDefault = () => {
        setChangeScreen(false);
        setOTP("");
        setNewEmail("");
        setTimer(0);
        setSendOtpLoading(false)
        props.toggle();
    }

  return (
    <>
      <NormalModal
        isOpen={props.isOpen}
        size="md"
        renderHeader={(e) => (
          <h6 className="fw-600">{props.headerName ? props.headerName : 'Migrate User'}</h6>
        )}
        headerClass="border-0 pt-4 px-4"
        toggle={setDefault}
      >
        {!loading ? !changeScreen ? (
          <>
            <div className="form-group col-12">
              <NormalInput
                placeholder="New email address"
                name="newEmail"
                type="email"
                value={newEmail}
                className="form-control rounded-0 new-pass-width"
                onChange={(e) => { setNewEmail(e.target.value); validator.current.showMessages(); }}
                label={"Enter New Email"}
              />
              {validator.current.message(
                                        "Contact Email",
                                        newEmail,
                                        "required|email"
                )}
                </div>
            <div className="pb-3 px-3 pt-4 d-flex justify-content-end">
              <NormalButton
                onClick={sendOtp}
                id="confirm"
                label="Send OTP"
                outline={false}
                loader={sendOtpLoading}
                className="mb-2 btn fs-14 btn-dark-primary rounded-0 btn-block"
              />
            </div>
          </>
        ) : (
          <div>
            <div className=" text-center">
              <p className="fs-14">An OTP has been sent to {maskEmailAddress(newEmail)}</p>
              <p className="fs-14">Enter OTP</p>
              <div className="d-flex justify-content-center">
                <OtpInput
                  isDisabled={timer < 1}
                  value={OTP}
                  onChange={setOTP}
                  numInputs={4}
                  isInputNum={true}
                  separator={<span>&nbsp;</span>}
                  inputStyle={"otp-space"}
                />
                {validator.current.message("OTP", setOTP, "required")}
              </div>
              

             <span>{timer > 0 ? moment("00:00:00.000", "hh:mm:ss.SSS").add(timer, "s").format("mm:ss") : ''}</span>
              

              <div className="d-flex w-100 resend justify-content-center mt-2 mb-4">
                   {timer === 0 ?
                   <p className="m-0 resend-otp cursor-pointer fs-16 mb-4 mt-2" onClick={(e) => { sendOtp();
                     }}>Re-send Verification Code</p> :
   
                   <p className="m-0 no-drop fs-16 mb-4 mt-2" ><u>Re-send Verification Code</u></p> }
                 </div>
            </div>
            <div className="d-flex justify-content-end">
              <NormalButton
                disabled={OTP.length < 4 || timer === 0 || otpLoading}
                onClick={() => setShowModal(true)}
                id="confirm"
                label="Submit"
                outline={false}
                className="mb-2 btn fs-12 btn-dark-primary rounded-0 btn-block"
              />
            </div>
          </div>
        ):<div className='common-page-loader-center'>
        <CommonPageLoader /> 
      </div>}
              <ConfirmModal
                  toggle={() => setShowModal(!showModal)}
                  isOpen={showModal}
                  handleAction={(e) => handleModalAction(e)}
                  message={'Existing email address will be updated with the new email address. Are you sure you want to proceed?'}
                  isLoading={otpLoading}
              />
      </NormalModal>
    </>
  );
}

export default ChangeEmailModal;