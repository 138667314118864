import React from 'react';
import { Modal,ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import './style.scss';
import refreshIcon from "../../../assets/svg/mdi_refresh_white.svg";
import warningIcon from "assets/svg/warning.svg"

export class NormalModal extends React.Component {


    render() {

        var {
            children,
            isOpen,
            toggle,
            className = '',
            renderlabel = '',
            verticalCenter = true,
            footerContent = '',
            headerClass = '',
            bodyClass = '',
            footerClass = '',
            toggleKey,
            loading = false,
            renderHeader = '',
            size='lg',
            onRefresh=false,
            centered = false
        } = this.props




        return (

            <Modal show={isOpen} onHide={toggle} className={`${verticalCenter && 'modal-dialog-centered'} ${className}`} size={size} centered={centered} >

                <Modal.Header
                    className={renderHeader ? headerClass : ''}
                    onHide={toggle}
                    closeButton
                >
                        <Modal.Title className={`${renderlabel !== "" ? 'adjust-render-label' : (renderHeader ? '' : 'waning-dialogue-container-title')}`} id="contained-modal-title-vcenter">

                    {renderHeader ? renderHeader() : <p className='warning-title'><img src={warningIcon} style={{marginBottom:"8px"}} /> { renderlabel!==""? renderlabel : "Alert!"}</p>}
                        </Modal.Title>
                        
                    {/* <span className="icon-close font-sm" onClick={e => toggle(toggleKey)} /> */}
                    {onRefresh && <img className='refreshIconInModel ml-3' src={refreshIcon} onClick={onRefresh}/>}
                </Modal.Header>
                <div className="position-relative">
                    <Modal.Body
                        className={` bg-transparent border-0 ${ bodyClass}`}
                    >
                        {children}
                    </Modal.Body>

                    {footerContent ? <Modal.Footer
                        className={footerClass}
                    >
                        {footerContent()}
                    </Modal.Footer> : ''}

                    {loading ? <div class="upload-loader">
                        <div class="position-relative w-100 h-100">
                            <div class="loader-circle"></div>
                        </div>
                    </div> : ''}

                </div>

            </Modal>

        )
    }
}

