const { UPDATE_CONNECTION_STATUS, UPDATE_NOTIFICATION_COUNT, UPDATE_NEW_WORK_REQUEST, UPDATE_CLIENT_INVITATIONS, UPDATE_CONNECTED_CLIENT, UPDATE_IMPORT_CLIENT, UPDATE_IMPORT_CLIENT_BILLRATE, UPDATE_CONNECTED_STAFF, UPDATE_CONNECTED_STAFF_PAYRATE, UPDATE_INVOICE_DATA, DELETE_INVOICE_DATA, UPDATE_PERMISSIONS } = require("service/actionType");



const initialState = {
    isConnected:false,
    notificationCount:false,
    workRequest:null,
    clientInvitations:null,
    client:null,
    importClient: null,
    importClientBillrate: null,
    staff:null,
    staffPayRate:null,
    invoice: null,
    deleteInvoice:null,
    permissions:null,
  };

  export function updateNewRecord(data){
    return {
        type: UPDATE_NEW_WORK_REQUEST,
        payload: data,
    }
}

  export default  (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CONNECTION_STATUS:
        return {
          ...state,
          isConnected: action.payload,
        };
        case UPDATE_NOTIFICATION_COUNT:
          return {
            ...state,
            notificationCount: action.payload,
          };
      case UPDATE_NEW_WORK_REQUEST:
        return {
          ...state,
          workRequest: action.payload,
        };
        case UPDATE_CLIENT_INVITATIONS:
          return {
            ...state,
            clientInvitations: action.payload,
          };
          case UPDATE_CONNECTED_CLIENT:
          return {
            ...state,
            client: action.payload,
          };
          case UPDATE_IMPORT_CLIENT:
          return {
            ...state,
            importClient: action.payload,
          }
          case UPDATE_IMPORT_CLIENT_BILLRATE:
          return {
            ...state,
            importClientBillrate: action.payload,
          }
          case UPDATE_CONNECTED_STAFF:
            return {
              ...state,
              staff: action.payload,
          }
          case UPDATE_CONNECTED_STAFF_PAYRATE:
            return {
              ...state,
              staffPayRate: action.payload,
          }
          case UPDATE_INVOICE_DATA:
            return {
              ...state,
              invoice: action.payload,
            }
          case DELETE_INVOICE_DATA:
            return {
              ...state,
              deleteInvoice: action.payload
            }
          case UPDATE_PERMISSIONS:
          return {
            ...state,
            permissions: action.payload,
          };
      default:
        return state;
    }
  };
  