
import { workRequest } from '../service/apiVariables';
import {  addQuery } from '../service/helperFunctions';



export const getAllWork = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
       addQuery(query, workRequest.getAllWork);
       api({ ...workRequest.getAllWork })
          .then(( data ) => {
             resolve(data);
          })
          .catch(({ message }) => {
             reject(Toast({ type: 'error', message }));
          });
    });
};

export const addWork = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.create, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const updateWork = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.update, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateStaffStatus = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.updateWRStaffStatus, body })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateWRStaffStatus = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.updateWRStaffStatus, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAllClient = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.getAllClient })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAllLocationByClient = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
     addQuery(query, workRequest.getAllWorkLocationClientId);
     api({ ...workRequest.getAllWorkLocationClientId })
        .then(( {data} ) => {
           resolve(data);
        })
        .catch(({ message }) => {
           reject(Toast({ type: 'error', message }));
        });
  });
};

export const getAllJobCategoriesByClient = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getJobCategoriesBYClient);
    api({ ...workRequest.getJobCategoriesBYClient, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getAllJobNamensByClient = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getJobNamesBYClient);
    api({ ...workRequest.getJobNamesBYClient, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getAllJobFunctionsByClient = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getJobFunctionsBYClient);
    api({ ...workRequest.getJobFunctionsBYClient, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getAllJobFunctionsByStaff = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getJobFunctionsBYstaff);
    api({ ...workRequest.getJobFunctionsBYstaff, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getAllLocationsByClient = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getAllLocationByClient);
    api({ ...workRequest.getAllLocationByClient, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAllStaff = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.getAllStaff })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAllAvailableStaff = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getAllAvailableStaff);
    api({ ...workRequest.getAllAvailableStaff, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getSingleWork = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getById);
    api({ ...workRequest.getById, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
// export const getAllWorkByStaff = (query) => (dispatch, getState, { api, Toast }) => {
//   return new Promise((resolve, reject) => {
//     addQuery(query, workRequest.getAllWorkStaff);
//     api({ ...workRequest.getAllWorkStaff, query })
//       .then(({ data,message }) => {
//         resolve(data); 
//         Toast({ type: "success", message });
//       })
//       .catch(({ message }) => {
//         reject(Toast({ type: "error", message }));
//       });
//   });
// };
export const getAllWorkByStaff = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.getAllWorkStaff, body })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// export const getAllWorkByClient = (query) => (dispatch, getState, { api, Toast }) => {
//   return new Promise((resolve, reject) => {
//     addQuery(query, workRequest.getAllWorkClient);
//     api({ ...workRequest.getAllWorkClient, query })
//       .then(({ data,message }) => {
//         resolve(data); 
//         Toast({ type: "success", message });
//       })
//       .catch(({ message }) => {
//         reject(Toast({ type: "error", message }));
//       });
//   });
// };
export const getAllWorkByClient = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.getAllWorkClient, body })
      .then(({ data,page, message }) => {
        resolve({data, page});
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const deleteWork = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.delete);
    api({ ...workRequest.delete, query })
      .then(({ data,message }) => {
        resolve(data); 
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const approveWork = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.approveWork, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const rejectWork = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.rejectWork, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const approveStaff = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.approveStaff, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const approveStaffByClient = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.approveStaffByClient, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const acceptOnBehalfOfStaff = (body) => (dispatch, getState, { api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.acceptOnBehalfOfStaff, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
}
export const workCompletedByAgency = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.completeWork, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const assignStaff = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.assignStaff, body })
      .then(({ data, message }) => {
        resolve(data,message);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const rejectStaff = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.rejectStaff, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const rejectStaffInterested = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...workRequest.rejectInterested, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// export const rejectStaffFromAssign = (body) => (dispatch, getState, { api, Toast }) => {
//   return new Promise((resolve, reject) => {
//     api({ ...workRequest.rejectInterested, body })
//       .then(({ data, message }) => {
//         resolve(data);
//         Toast({ type: "success", message });
//       })
//       .catch(({ message }) => {
//         reject(Toast({ type: "error", message }));
//       });
//   });
// };

export const addRecurringWorkRequests = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.addRecurringWorkRequest);
    api({ ...workRequest.addRecurringWorkRequest})
      .then((data) => {
        resolve(data);
        if(data.status !== 202)
        Toast({ type: "success", message:'Recurrence shifts are created.' });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateDefaultBillingRateFrequency = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
     addQuery(query, workRequest.updateDefaultBillingRateFrequency);
     api({ ...workRequest.updateDefaultBillingRateFrequency })
        .then(( data ) => {
           resolve(data);
        })
        .catch(({ message }) => {
           reject(Toast({ type: 'error', message }));
        });
  });
};

export const getWorkLocationPoints = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
     addQuery(query, workRequest.getWorkLocationPoints);
     api({ ...workRequest.getWorkLocationPoints })
        .then(( data ) => {
           resolve(data);
        })
        .catch(({ message }) => {
           reject(Toast({ type: 'error', message }));
        });
  });
};