import React from "react";
import Select from "react-select";

export default function SelectDropdown({ name = "", placeholder = "", disabled = false, options, value, onChange }) {
	return (
		<Select
			options={options}
			value={options.find((el) => el?.value?.toString() === value?.toString())}
            isDisabled={disabled}
			styles={{
				control: (provided, state) => ({
					...provided,
					padding: "0.2rem 0",
					paddingLeft: "10px",
					border: "1px solid #dddddd !important",
					borderRadius: "5px",
					backgroundColor: state.isDisabled ? "#f1f1f1" : "#fff",
				}),
				option: (provided, state) => ({
					...provided,
					backgroundColor: state.isSelected ? "#E0E7E9" : state.isFocused ? "#f4f6f8" : "white",
					color: "#555",
				}),
			}}
			onChange={(val) => (!!name ? onChange(name, val.value) : onChange(val.value))}
			placeholder={placeholder}
		/>
	);
}
