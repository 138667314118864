import React,{useEffect,useRef,useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { NormalModal, CommonPageLoader,NormalButton,NormalTextarea } from "component/common"
import "./style.scss";
import ReactStars from "react-rating-stars-component";
import Moment from "moment";
import { getSingleWork,approveWork,rejectWork,approveStaff,rejectStaff, updateStaffStatus, workCompletedByAgency, updateWork, addRecurringWorkRequests, approveStaffByClient, acceptOnBehalfOfStaff} from "action/workRequest";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import bagIcon from 'assets/svg/bag.svg';
import markerIcon from 'assets/svg/Vector.svg';
import { getClassNameForBackground, getClassNameForLeftBorder } from "service/utilities";
import { getServiceUrl } from 'service/api';
import { documentDownloadFromMDB } from 'service/apiVariables';
import ConfirmModel from '../ConfirmModel';
import RejectAccordian from 'component/common/RejectAccordian/RejectAccordian';
// import RejectAccordianStaff from 'component/common/RejectAccordianStaff/RejectAccordianStaff';
import RejectAccordianStaff from 'component/common/RejectAccordianStaffNew';
import RejectAccordianClient from 'component/common/RejectAccordianClient';
import axios from 'axios';
import editProfile from 'component/StaffManagement/editProfile';
import { Toast } from 'service/toast';

import calendarBlack from 'assets/svg/calendar-black.svg'
import clientLocationIcon from 'assets/svg/client-location-icon.svg'
import expandfeedback from "assets/svg/expand-feedback.svg";
import locationGreenIcon from 'assets/svg/location-green.svg'
import redFlagIcon from 'assets/svg/red-flag.svg'
import StarIcon from "assets/svg/Star.svg";
import timeIcon from 'assets/svg/time-icon.svg'
import EditIcon from 'assets/svg/new-edit-icon.svg'
import warningIcon from 'assets/svg/warning.svg';
import disabledEditIcon from 'assets/svg/disabled-edit-icon.svg'
import redBgIcon from 'assets/svg/red-background-icon.svg'
import greyBgIcon from 'assets/svg/grey-disabled-icon.svg'
import closeIcon from 'assets/svg/close-button.svg'
import upArrowIcon from 'assets/svg/up-arrow-white.svg'
import downArrowIcon from 'assets/svg/down-arrow-white.svg'
import dummyImage from 'assets/images/dummy-image.png'
import tickIcon from 'assets/svg/tick.svg';
import approveIcon from 'assets/svg/approve.svg';
import arrowDownIcon from "assets/svg/arrow-down.svg"
import newMessageIcon from "assets/svg/new-message-icon.svg"
import redOutlineIcon from "assets/svg/red-outline-thumbsdown-icon.svg"
import redSolidIcon from "assets/svg/red-solid-thumbsdown-icon.svg"
import greenSolidIcon from "assets/svg/green-solid-thumbsup-icon.svg"
import greenOutlineIcon from "assets/svg/green-outline-thumbsup-icon.svg"
import greyOutlineIcon from "assets/svg/grey-outline-thumbsup-icon.svg"
import EditDisableIcon from "assets/svg/edit-disable.svg"
import blueSolidIcon from "assets/svg/blue-solid-thumsup.svg"
import blueClockIcon from 'assets/svg/clock solid.svg';

import { getWorkStatusLabel } from 'pages/Admin/workSchedule';
import StaffDetailsNew from '../staffDetailsNew';
import ConfirmationModal from './ConfirmationModal';
import upArrowBlackIcon from 'assets/svg/up-arrow-black.svg'
import downArrowBlackIcon from 'assets/svg/down-arrow-black.svg'
import CustomLoader from 'component/common/CustomLoader';
import DisplayClientImage from './DisplayClientImage/index';
import RecurringModel from '../RecurringModel';
import recurrenceIcon from 'assets/svg/recurrenceIcon.svg'
import ClientImagePlaceholder from 'assets/svg/client-company-placeholder.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import WarningModal from 'component/common/WarningModel';
import EditTimeSheet from 'component/timeSheet/EditTimeSheet';

function WorkAssignment({
    getSingleWork,
    workAssign,
    setWorkAssign,
    approveWork,
    rejectWork,
    handleReload,
    approveStaff,
    rejectStaff,
    setAssignTimeSheet,
    setVisible,
    updateStaffStatus,
    workCompletedByAgency,
    updateWork,
    addRecurringWorkRequests,
    timeSheetView = false,
    showArchives,
    approveStaffByClient,
    acceptOnBehalfOfStaff
}) {

    const [edit, setEdit] = useState({});
    // const [clientId, setClientId] = useState('');
    const [workID, setWorkID] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isFormLoder, setFormLoder] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [reject, setReject] = useState([])
    const [rejectedStaff, setRejectedStaff] = useState({show:false, id:'', data:null})
    const [clientRejectedStaff, setClientRejectedStaff] = useState({show:false, id:'', data:null})
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [feedBackRatingShow, setFeedbackRatingShow] = useState(false)
    const [loadingKey, setLoadingKey] = useState('')
    const [recurring, setRecurring] = useState(false)
    const [proceed, setProceed] = useState({status:false, message:''})
    const [scroll, setScroll] = useState(false)

    const [ClientStaffApproval, setClientStaffApprove] = useState({showModal:false, staffId:''});
    const [AcceptOnBehalfOfStaff, setAcceptOnBehalfOfStaff] = useState({showModal:false, staffId:""});
    const [unassignStaff, setUnassignStaff] = useState({showModal:false, staffId:''});
   
    const [clientNotesBox, setClientNotesBox] = useState(false) 

    const [openConfirmModal,setOpenConfirmModal] = useState(false)
    const [showEditTimeSheet, setShowEditTimeSheet] = useState({ id: '',staffId:'', show: false });
    let{createdAt,workRequest,status,jobCategory,jobName,startDate,endDate,engagementType,priority,wrType,workStaffAssignment,staffNeeded,staffAccepted,location, clientName,notes,documents,longLat}=edit;

        
    
    useEffect(()=>{
      if(status === 3 && workStaffAssignment && workStaffAssignment?.staffWorkData){
       
      let staffStatus = workStaffAssignment.staffWorkData.some((staff)=> staff.status === 4)

      if(staffStatus && status === 3){
        setRecurring(true)
      }
    }
    
    },[status, workStaffAssignment])
    const hash = window.location.hash;
    useEffect(()=>{
      if (hash.includes('scroll-to-bottom')) {
      setScroll(true)
      } else {
        setScroll(false)
      }
    },[hash])
    useEffect(() => {
        if (!!workAssign.id) {
            fetchWorkById(workAssign.id);
            setWorkID(workAssign.id)
        }
    }, [workAssign]);

 // websocket implementation

 let workRequestData = useSelector((state)=> state.webSocketReducer.workRequest)

   useEffect(()=>{
    if(!!workRequestData){
      if (!!workAssign.id) {
        let workRequestId =  workRequestData?.id ? workRequestData?.id : workRequestData?._id
        if(workAssign.id === workRequestId){
          handleUpdateWorkRequest()
        }
      }
   
    }
    
   },[workRequestData])

   const handleUpdateWorkRequest = () => {
    setEdit(workRequestData)
   }

    // get work by workId
    const fetchWorkById = async (id) => {
        setLoading(true);

        const query = { 'id': id };
        await
            getSingleWork(query)
                .then((data) => {
                    setEdit(data)
                    // if(data.status>=3){
                    //     setWorkAssign({ value: false, id: '' });
                    //     setAssignTimeSheet({ value: true, id: id });
                    // }
                    // setWorkList(modifyData.length ? modifyData : []);
                    setLoading(false);
                })
                .catch((e) => setLoading(false));


    };
    const fetchFileDownloadURLById = async (id, filename='none.png', type="image/png") => {
        setLoading(true);
        
        if(filename.split(".").length>1){
            filename = filename.split(".")[0];
        }

        const link = document.createElement("a");
        link.target = "_blank";
        link.download = filename


        // await
        // fileDownloadFromMDB({ 'id': id })
        await axios
            .get(`${getServiceUrl(documentDownloadFromMDB.create.baseURL)}${documentDownloadFromMDB.create.api}${id}/`, {
                responseType: "blob",
                headers : {
                    Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
                }
            })
            .then((res) => {

                    link.href = URL.createObjectURL(
                    new Blob([res.data], { type: type })
                    );
                    link.click();
                
                setLoading(false);
            })
            .catch((e) => setLoading(false));
    };
    
    // handle  approve by agency
    const handleApproveWork= async()=>{
      if(edit.status != 1){
        return;
      }
      setLoadingKey('accept-work')
        let body = {
            workId: workAssign.id,
            clientId:edit.clientId,
        }
        setFormLoder(true);
        await approveWork(body).then((data) => {
            setFormLoder(false);
            setOpenConfirmModal(false)
            setDefault();
            setLoadingKey('')
       }).catch(() => {
           setFormLoder(false);
           setLoadingKey('')
       });
    }

    const handleAction = (action) => {
        if (action.type === 'accept-work') {
          action.value ? handleApproveWork() : setOpenConfirmModal(!openConfirmModal)}
        else if (action.type === 'cancel-work') 
        {action.value ? handleRejectWork() : setOpenModal(!openModal);}
        else if (action.type === 'client-staff-approve') 
        {action.value ? handleApproveStaffByClient() : setClientStaffApprove({showModal:!ClientStaffApproval?.showModal, staffId:''});}
        else if (action.type === 'agency-accept-for-staff')
          {action.value ? staffAcceptHandler() : setAcceptOnBehalfOfStaff({showModal:!AcceptOnBehalfOfStaff?.showModal, staffId:''});}
        else if (action.type === 'unassign-staff')
          {action.value ? handleUnassignStaff() : setUnassignStaff({showModal:!unassignStaff?.showModal, staffId:''});}
      };

    // handle  reject by agency
    const handleRejectWork= async()=>{
      if(loadingKey === '')
      setLoadingKey('cancel-work')
        let body = {
           clientId:edit?.clientId,
            workId: workAssign.id,
            reason:"Staff is not available"
        }
        setFormLoder(true);
        await rejectWork(body).then((data) => {
            setFormLoder(false);
            setOpenModal(false);
            setDefault();
            setLoadingKey('')
        }).catch(() => {
            setFormLoder(false);
            setLoadingKey('')
        });
    }

    const handleUpdateStatus = async ()=>{
      setLoadingKey('revert-cancel-work')
        const query = {
            workId : workAssign.id,
            status : "1",
            clientId: edit.clientId
        }
    
        setFormLoder(true);
        await updateStaffStatus(query).then((data) => {
        let a="success";
        if(a== "success"){
            Toast({ type: "success", message: "Reverted Work Request Cancellation..." });
            
        }
            setFormLoder(false);
            setDefault();
            setLoadingKey('')
        }).catch(()=>{
            setFormLoder(false)
            setLoadingKey('')
        })
    }

    // handle single staff approve by agency
    const handleApproveStaff = async (id) => {
      if(loadingKey === '')
      setLoadingKey(`approve-staff-${id}`)
        let body = {
            workId: workID,
            staffId: id,
            clientId:edit.clientId,
        }
        setFormLoder(true);
        await approveStaff(body).then((data) => {
            setFormLoder(false);
            // fetchWorkById(workAssign.id);
            // setDefault();
            // handleReload();
            setLoadingKey('')
        }).catch(() => {
            setFormLoder(false);
            setLoadingKey('')
        });
    }

    const handleApproveStaffByClient = async () => {
      setLoadingKey(`client-staff-approve`)
        let body = {
            workId: workID,
            staffId: ClientStaffApproval?.staffId,
            clientId:edit.clientId,
            agencyId: edit?.agencyId
        }
        await approveStaffByClient(body).then((data) => {
            setLoadingKey('')
            setClientStaffApprove({showModal:false, staffId:''})
        }).catch(() => {
            setLoadingKey('')
            setClientStaffApprove({showModal:false, staffId:''})
        });
    }

    const staffAcceptHandler = async () => {
       setLoadingKey(`agency-accept-for-staff`)
       let body= {
          workId: workID,
          staffId: AcceptOnBehalfOfStaff?.staffId,
          clientId: edit.clientId
       }
       await acceptOnBehalfOfStaff(body).then((data) => {
          setLoadingKey('');
          setAcceptOnBehalfOfStaff({showModal:false, staffId:''});
       }).catch(()=> {
          setLoadingKey('');
          setAcceptOnBehalfOfStaff({showModal:false, staffId:''})
       })
    }

    const handleUnassignStaff = async () => {
      setLoadingKey('unassign-staff')
      let body = {
        workId: workID,
        staffId: unassignStaff?.staffId,
        clientId: edit.clientId,
        remove: false
      }
      await rejectStaff(body).then((data) => {
        setLoadingKey('')
        setUnassignStaff({ showModal: false, staffId: '' })
      }).catch(() => {
        setLoadingKey('')
        setUnassignStaff({ showModal: false, staffId: '' })
      });
    }  

    // handle single staff reject by agency
    const handleRejectStaff = async (id) => {
      if(loadingKey === '')
      setLoadingKey(`reject-staff-${id}`);
        let body = {
            workId: workID,
            staffId: id,
            clientId:edit.clientId,
            remove:true
        }
        setFormLoder(true);
        await rejectStaff(body).then((data) => {
            setFormLoder(false);
            // fetchWorkById(workAssign.id);
            // setDefault();
            // handleReload();
            setLoadingKey('');
        }).catch(() => {
            setFormLoder(false);
            setLoadingKey('');
        });
    }
    // set Default values
    const setDefault = () => {
        setEdit({});
        setWorkAssign(false);
        setClientNotesBox(false)
        setRejectedStaff({show:false, id:'', data:null})
        setClientRejectedStaff({show:false, id:'', data:null})
        setShowConfirmationModal(false)
        setFeedbackRatingShow(false)
        window.location.hash = ""
        // handleReload();
    }
    // let { workStaffAssignment } = edit;
 
    useEffect(()=>{
        let rejectdata=[]
      for(let i=0;i<workStaffAssignment?.staffWorkData?.length;i++){
        let count=0;
        let rejectedreason = [];
        for(let a=0;a<workStaffAssignment?.rejectedStaff?.length;a++){
        if(workStaffAssignment?.staffWorkData[i].staffId===workStaffAssignment?.rejectedStaff[a].staffId){
          rejectedreason.push(workStaffAssignment.rejectedStaff[a])
          count++;
        }
        }
        if(rejectedreason?.length){
          rejectdata.push({value:true, reject:rejectedreason?.pop()})
        }
        else {
          rejectdata.push({value:false,reject:""})
        }
        if(workStaffAssignment?.staffWorkData[i].status > 3) {
          setRejectedStaff({show:false, id:'', data:null})
          setClientRejectedStaff({show:false, id:'', data:null})
        }
      }
      setReject(rejectdata)
    },[edit])

    const handleCompleted = async (id) => {

      if (edit?.status === 5) {
        // If status is 5, show the confirmation modal
        setShowConfirmationModal(true);
        return;
      }

      if(edit?.status<=4 || edit?.status >=7){
        return;
      }
      let body = {
          workId: workAssign.id,
          clientId:edit?.clientId,
      }
      setLoadingKey('approve-for-invoice')
      await workCompletedByAgency(body).then((data) => {
          setLoadingKey('')
          setDefault();
          // handleReload();
      }).catch(() => {
          setLoadingKey('')
      });
  }

  const handleConfirmationResponse = async (confirmed) => {
    // This function will be called from the ConfirmationModal component
    if (confirmed) {
      // If the user confirmed, proceed with the submission
      let body = {
        workId: workAssign.id,
        clientId: edit?.clientId,
      };
  
      setFormLoder(true);
      await workCompletedByAgency(body)
        .then((data) => {
          setFormLoder(false);
          setDefault();
        })
        .catch(() => {
          setFormLoder(false);
        });
    } else {
      // If the user canceled, hide the modal and do nothing
      setShowConfirmationModal(false);
    }
  };

  const handleCancelWorkRequest = () =>{
    if(edit.status>=3 || edit.status == 0 ){
      return;
    }
    setOpenModal(true)
    
  }

  const handleCloseRecurring = () => {
    setRecurring((prev)=>{
     return{
       ...prev,
        status:false
     }
    })
 }


    const handleHeaderContent = () =>{
      return (
        <div className=" d-flex justify-content-between">
        <h6 className="font-weight-bold "> Work Request </h6>
        </div>
      )
    }
    const handleAddRecurringWorkRequest = async(proceed=false) =>{
     
      
       let query = {
        agencyId:edit?.agencyId,
        workId:edit?._id,
        proceed:''
       }
       if(proceed){
        setLoadingKey('procedRecurringWorkRequest')
        query['proceed'] = true
      }else{
        setLoadingKey('recurringWorkRequest')
      }
        await addRecurringWorkRequests(query)
        .then((data)=>{
          if(data.status === 202){
            setProceed({status:true, message:data.message})
            return;
          }
          setProceed({status:false, message:''})
          setLoadingKey('')
          handleCloseRecurring()
          fetchWorkById(workAssign.id);
          setWorkID(workAssign.id)
        })
        .catch(()=>{
          setLoadingKey('')
        })
        
    }

   

    // const handleUpdateAndCreateRecurrenceShifts = async() => {
    //   let payload = edit;
    //   payload.id = edit?._id
    //   await updateWork(payload)
    //   .then(()=> {
    //     handleAddRecurringWorkRequest()
    //   })
    //   .catch((e)=>{
 
    //   })
    // }

     const handleUpdateRecurring = (data, createRecurrenceShifts = false, ) =>{
      if(createRecurrenceShifts){
        handleAddRecurringWorkRequest()
      }else{
        handleCloseRecurring()
      }
      
     }

    const handleFooterContentForTimeSheet = () =>{
      return (
        <div className={`wr-footer-box-time-sheet ${showArchives ? 'pointer-events-none':''}`}>
          <div className="frame-2180">
            {edit.recurringWRCreated && recurring && edit.repeat &&
          <NormalButton
            label='Recurrence Shifts Created'
            className="wr-footer-buttons approve-for-invoicing"
            disabled={true}
            /> }
          {(edit.status === 3 || edit.status === 4) && !edit.recurringWRCreated && recurring && edit.repeat && edit.workStaffAssignment?.staffWorkData && edit.workStaffAssignment?.staffWorkData.length?
         <NormalButton
            label='Review & Create Recurrence shifts'
            onClick={()=> setRecurring((prev)=>{
              return{
                    ...prev,
                status:true,
                view:false,
                     }
             })}
            className="wr-footer-buttons approve-for-invoicing"
            /> 
            : 
            !edit.recurringWRCreated &&
            <>
             <div className="client-approval-status s2-r">Client Approval Status</div> 
            {status >= 6?<span className=""><img src={approveIcon} width={25} /></span> : status > 6?<span className=""><img src={tickIcon} width={20} /></span>:<span className="disabled-tick"><img src={tickIcon} width={20} /> </span> }
            </> 
            }
             
 
             </div>

         {/* <div className={`frame-2273  ${edit?.status<=4 || edit?.status >=7 ? "disabled-action-button" : ''}` } onClick={handleCompleted} >   <div className="approve-for-invoicing">Approve for Invoicing</div> </div> */}
       
         <NormalButton
            label='Approve for Invoicing'
            onClick={handleCompleted}
            loader={loadingKey === 'approve-for-invoice'}
            className="wr-footer-buttons approve-for-invoicing"
            disabled={edit?.status<=4 || edit?.status >=7}
            />
         {showConfirmationModal && (
        <ConfirmationModal
          message="Client has not yet approved the timesheet. Are you sure you want to continue?"
          onConfirm={() => handleConfirmationResponse(true)}
          onCancel={() => handleConfirmationResponse(false)}
        />
      )}
        </div>
      )
    }
    const handleWrFooterContent = () =>{
           
      return (
        <div className={`wr-footer-box ${showArchives ? 'pointer-events-none':''}`}>
          <div className="frame-2180">
          {(edit.status === 1 || edit.status === 2) && !edit.recurringWRCreated && edit.repeat ? (
          <div className="button-container">
            <NormalButton
              label='Review & Create Recurrence shifts'
              className="wr-footer-buttons approve-for-invoicing"
              disabled={edit.status === 1 || edit.status === 2}
              title="Hover over this button for information"
            />
            {/* Additional information to show on hover */}
            <div className="hover-information">
            The recurrence shifts can be created solely when the staff status is seen as "pending-clock-in".
            </div>
          </div>
          ) : ""}

         </div>
        
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        {edit.status>=1? 
    
        <div className='' onClick={()=>{setOpenModal(true)}}>   <div className="cancel-work">Cancel Work</div> </div>
             : edit.status== 0 && edit.workStaffAssignment.staffWorkData?.length==0?
            
            // <div className='frame-2273 ' onClick={()=>{handleUpdateStatus()}}>    <div className="approve-for-invoicing">REVERT CANCEL WORK *</div> </div>
            <NormalButton
            label='REVERT CANCEL WORK *'
            onClick={()=>{handleUpdateStatus()}}
            loader={loadingKey === 'revert-cancel-work'}
            disabled={loadingKey === 'revert-cancel-work'}
            className="wr-footer-buttons approve-for-invoicing py-2"
            />
            :""}
            <div className="p-3"></div>
             
             {/* <div className={`frame-2273  ${edit.status != 1 ? "disabled-action-button" : ''}` } onClick={()=>{handleApproveWork()}}>   <div className="approve-for-invoicing">ACCEPT WORK</div> </div> */}
             <NormalButton
            label='Accept Work'
            onClick={()=>{edit.repeat?setOpenConfirmModal(true):handleApproveWork()}}
            loader={loadingKey === 'accept-work'}
            disabled={loadingKey === 'accept-work' || edit.status != 1}
            className="wr-footer-buttons approve-for-invoicing px-4 py-2"
            />
          </div>
    </div>
      )
    }

    const divRef = useRef(null);

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (divRef.current && event.keyCode === 38) {
          divRef.current.scrollTop -= 40;
        } else if (divRef.current && event.keyCode === 40) {
          divRef.current.scrollTop += 40;
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    const scrollToBottom = () => {
      if (divRef.current) {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
    };

    const isAnyStaffWorkInProgress = (staffData) => {
      if(staffData && staffData.length > 0) {
        const isInProgress = staffData.some((item) => item.clockIn)
        return isInProgress;
      }
      else {
        return false;
      }
    }

    const showEditTimeSheetIcon = (item, staffId) => {
      let enableEdit = item?.staffWorkData?.some((staff) => {
          return (
              staff.staffId === staffId &&
              ((Number(edit.status) >= 3 && Number(staff.status) >= 4 && Number(edit.status) <= 7) ||
              (Number(edit.status) == 0 && Number(staff.status) == 0) ||
              (Number(edit.status) == 0 && (Number(staff.status) == 4 || Number(staff.status) == 5)))
          );
      });
      
      return enableEdit;
  }

    
    return (
      <>
        <NormalModal
        toggle={setDefault}
        className={'modal-fill'}
        isOpen={workAssign.value}
        size="lg"
        onRefresh={()=>{
            fetchWorkById(workAssign.id);
            setWorkID(workAssign.id)
            setRejectedStaff({show:false, id:'', data:null})
            setClientRejectedStaff({show:false, id:'', data:null})
            window.location.hash = ""
        }}
        bodyClass={`add-staff-box modal-bg-transparent position-relative ${showArchives ? 'pointer-events-none':''}`}
        headerClass="border-0 pt-4 px-4"
        renderHeader={handleHeaderContent}
        footerContent={edit.status >= 3 && !timeSheetView === true ? handleFooterContentForTimeSheet: edit.status < 3 && !timeSheetView === true ? handleWrFooterContent : null}
          > 
 {isLoading  ?  <CommonPageLoader /> : <>
               <a href={`#scroll-to-${scroll ? 'top':'bottom'}`}>
                <div className='sticky-button-scroll cursor-pointer' tabIndex={0} id='scroll-down-button' >
                 <img src={arrowDownIcon} alt="arrow-down" style={{ transform: `rotate(${scroll ? 180 : 0}deg)` }}/>
                </div></a>





                <div className="wr-header-details">

  <div className="wr-header-id-box">
    <div className="wr-header-id">
      <div className="wr h2-sb">WR</div>
    </div>
    <div className="c-60693 num-sb">{`${!!workAssign.id && workAssign.id.substr(workAssign.id.length - 6)}`}</div>
  </div>
  <div className="wr-header-status-box">
      <div className={"mb-3 wr-color-status-box color-box "+(edit.status !==2 ? getClassNameForBackground(edit.status):edit.status==2 && workStaffAssignment.staffWorkData.length !== 0?getClassNameForBackground(2.1):getClassNameForBackground(2))}> </div>
         <div className={'wr-color-status-name h3-r'} id='wr-status-main-title'>{getWorkStatusLabel(edit.status, edit)} </div>
  </div>

  {(edit.status>=2 || edit.status == 0) && workStaffAssignment.staffWorkData.length !== 0 && !timeSheetView === true ? 
    <div className='no-drop r wr-edit-icon'  message='Edit Work Request'>
    <img src={EditDisableIcon} className='iconsax-linear-messageedit'/>
    </div>
: !timeSheetView === true && edit.status <= 2 ?
  <div className="wr-header-edit-icon" message='Edit Work Request' onClick={(e) => {
                 if (edit.status !== 0 && setVisible)
                 setWorkAssign({ value: false, id: workStaffAssignment.workId });
                 setVisible({ value: true, id: workStaffAssignment.workId });
                      }}>
    <div className="frame-2437">
    <img src={EditIcon} className='iconsax-linear-messageedit'/>
    </div>
  </div> : !timeSheetView === true ?
      <div className='no-drop wr-edit-icon'  message='Edit Work Request'>
      <img src={EditDisableIcon} className='iconsax-linear-messageedit'/>
      </div>
  : <div style={{width:'50px'}}></div> } 
</div>





           <div className='wr-details-container' ref={divRef} tabIndex={3}>
  
{/* WR client Details & Start & End Date */}


<div className="wr-client-start-end-details" id='scroll-to-top'>
  <div className="wr-client-job-details">
    <div className="wr-client-logo-details">
      <div className="wr-client-name-and-logo">
        <div className="wr-client-name-2450">
          <div className="client-site-image">
          { edit.sitePicture ? 
                        edit.sitePicture.includes('data:image/') ?
                        <img className="image-22" alt="site-picture" src={edit.sitePicture ? edit.sitePicture.split('|')[1] : ClientImagePlaceholder} />
                        : <DisplayClientImage entity="client" clientId={edit.clientId} imageId={edit.sitePicture ? edit.sitePicture : ''}/>
                      : 
                      <img className="image-22" alt="site-picture" src={ClientImagePlaceholder} />
                    }
          </div>
          <div className="frame-2326">
            <div className="frame-2200">
              <div className="client-name">Client Name</div>
            </div>
            <div className="frame-2201">
              <div
                className="national-institute-of-health-and-care-excellence-nice-hospital"
              >
                {edit.clientName}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wr-client-address cursor-pointer">
       
        <div className='client-location-hover' client-location={edit.location ? edit.location: ''}>
        <div className="rectangle-400"></div>
        <img src={clientLocationIcon} width={15} height={15} className='location-icon' />
        <div className=" s3-m ml-1">{edit.location ? `${edit.location.slice(0,7)}...` : ''}</div>
        </div>
      </div>
    </div>
    <div className="wr-job-category-details">
      <div className="job-category-details">
        <div className="frame-2496">
          <div className="category jos-12px-sb">Category</div>
        </div>
        <div className="frame-2171">
          <div className="nurse s3-m">{edit.jobCategory}</div>
        </div>
      </div>
      <div className="job-name-details">
        <div className="frame-2495">
          <div className="job-name jos-12px-sb">Job Name</div>
        </div>
        <div className="frame-21712">
          <div className={!edit.hasOwnProperty('jobName')? "select-jobname s3-m": "certified-nursing-assistant-cna s3-m"}>
          {!edit.hasOwnProperty('jobName')? <> Select job name <img style={{width:"23px"}} src={warningIcon}/></>: edit.jobName}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="wr-start-end-details">
    <div className="wr-priority-recurrence">
      <div className="wr-priority-detail">
        <div className="normal s3-r-8px">{edit.priority}</div>
      </div>
      <div className="wr-work-request-type">
        <div className="weekday s3-r-8px">{
                            edit.wrType == 1 ? "Weekday" :
                            edit.wrType == 2 ? "Weekend" :
                            edit.wrType == 3 ? "Holiday" :
                            edit.wrType == 4 ? "Contract" : "-"
                        }</div>
      </div>
      {edit?.repeat === true ?
      <div className="wr-recurrence cursor-pointer" onClick={()=> setRecurring({status:true, view:true})}>
        <div className="recurrence s3-r-8px">Recurrence</div>
        <div className="frame">
        <img src={recurrenceIcon} className='mb-5' width={20} alt='recurrence'/>

        </div>
      </div> : null }
      {!!workAssign.id && edit.masterWorkId ?
        <div className="" style={{minWidth:'100px'}} > 
        <a href={`${window.location.pathname}?workId=${edit.masterWorkId}`} target="_blank">
          <div className="wr-parent-id">Master WR {edit.masterWorkId.substr(edit.masterWorkId.length - 6)} </div>
          </a>
        </div>
      : null}
    </div>
    <div className="frame-2489">
      <div className="frame-1831">
        <div className="frame-1089">
          <div className="frame-1830">
            <div className="frame-1828">
              <div className="frame-1827">
                <div className="starts s3-r-8px">Starts</div>
              </div>
            </div>
          </div>
          <div className="frame-10892">
            <div className="ellipse-74"></div>
            <img src={locationGreenIcon} className='material-symbols-location-on' />
          </div>
        </div>
        <div className="frame-1829">
          <div className="frame-1828">
            <div className="frame-1827">
            <img src={calendarBlack} className='uil-calender2' />

              <div className="_27-02-2023 num-m">{Moment(edit.startDate).format(localStorage.getItem('dateFormat'))}</div>
            </div>
            <div className="frame-1826">
            <img src={timeIcon} className='ic-sharp-access-time2' />

              <div className="_03-15-00-pm s3-r">{Moment(edit.startDate).format('hh:mm:ss a')}</div>
            </div>
          </div>
        </div>
        <div className="line-77"></div>
      </div>
      <div className="frame-1881">
        <div className="frame-10893">
          <div className="frame-1830">
            <div className="frame-18282">
              <div className="frame-18272">
                <div className="ends s3-r-8px">Ends</div>
              </div>
            </div>
          </div>
          <div className="frame-1090">
            <div className="ellipse-742"></div>
            <img src={redFlagIcon} className='frame-2439' />
          </div>
        </div>
        <div className="frame-18292">
          <div className="frame-1828" style={{left:"4px"}}>
            <div className="frame-1827">
            <img src={calendarBlack} className='uil-calender2' />

              <div className="_27-02-2023 num-m">{Moment(edit.endDate).format(localStorage.getItem('dateFormat'))}</div>
            </div>
            <div className="frame-1826">
            <img src={timeIcon} className='ic-sharp-access-time2' />

              <div className="_03-15-00-pm2 s3-r">{Moment(edit.endDate).format('hh:mm:ss a')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='rate-frequency-container mb-2'>
          <div className='rate-frequency-text'>
            {(edit.rateFrequency == 1) ? "Per Hour" :
              edit.rateFrequency == 2 ? "Per Day" :
              edit.rateFrequency == 3 ? "Per Week" :
              "Per Month"}
            </div>
        </div>
  </div>
</div>

         {/* staff Details */}
         {!!workStaffAssignment && !!workStaffAssignment.staffWorkData&&            
         <div className="frame-2479">
  <div className="frame-2324">
    <div className="frame-2200">
      <div className="staff-assignment-details h2-sb">Staff Assignment</div>
    </div>

    <div className="frame-2201">
      <div className="frame-1878">
        <div className="group-1148">
          <div className="ellipse-75">{edit.staffNeeded}</div>

          {/* <div className="_1">{edit.staffNeeded}</div> */}
        </div>

        <div className="requested s2-r">Requested</div>
      </div>

      <div className="frame-1879">
        <div className="assigned s2-r">Assigned</div>

        <div className="group-1148">
          <div className="ellipse-752">{edit.staffAccepted ? edit.staffAccepted : 0}</div>
{/* 
          <div className="_12">{edit.staffAccepted ? edit.staffAccepted : 0}</div> */}
        </div>
      </div>
    </div>

    <div className="engagement-type-box">
       <div className="engagement-type s3-r-8px">Engagement Type:</div>
       <div className="engagement-type-value-box">
      <div className="contract s3-r-8px"> {
           edit.engagementType == 1 ? "Contract":
            edit.engagementType == 2 ? "Regular": "-"
           }</div>
     </div>
  </div>



  </div>

    
  {!!workStaffAssignment && !!workStaffAssignment.staffWorkData&& 
                <>
                <div className="w-100 mb-3 ">
                    <div className='d-flex justify-content-start'>
                      <div className='d-flex justify-content-start' >
                        <div className='staff-name-label s2-m'>Name</div>
                        {/* <td>ID</td> */}
                        {/* <td>Location</td> */}
                        <div className='staff-rating-label s2-m' ></div>
                        <div className='staff-status-label s2-m'>Staff Status</div>
                        </div>
                     {!timeSheetView === true &&   <div className='staff-action-label s2-m'>Action</div> }
                    </div>
                    <div>
                        {
                            !!workStaffAssignment && workStaffAssignment.staffWorkData.length ?
                                <>
                                    {
                                        workStaffAssignment.staffWorkData.map((item, index) =>{
                                       return (<>
                                            <div key={item.staffId} className='d-flex flex-column justify-content-start mt-3' style={{ maxHeight:"max-content"}} >
                                               <div className='d-flex justify-content-start align-items-center' id={`staff-row-${index+1}`}>
                                                <div className="fs-12 staff-name-label d-flex" >
                                                <Link to={`/Admin/staffManagement/StaffDetail/${item.staffId}?credentials=true`} target="_blank">
                                                    <span id={`staff-name-${index+1}`} className='staff-name-wr s2-r' >{item.staffName.length > 20 ? item.staffName.substring(0, 20) + "..." : item.staffName}</span>
                                                    </Link>
                                                    </div>
                                                    {(item.status > 0 && item.status <= 4 && !item.clockIn) && <div className="unassign-staff">
                                                 <OverlayTrigger
                                                   placement="top"
                                                   overlay={
                                                     <Tooltip>
                                                       Unassign staff
                                                     </Tooltip>
                                                   }
                                                 >
                                                   <span className="cursor-pointer" onClick={() => {
                                                     setUnassignStaff({ showModal: true, staffId: item.staffId })
                                                   }}>x</span>
                                                 </OverlayTrigger>
                                               </div>}

                                                <div className='fs-12 staff-rating-label'>
                                                <span className='staff-rating'>
                                                  {item.rating !== null && item.rating !== undefined ? (
                                                    <>
                                                      <img src={StarIcon} style={{ verticalAlign: 'baseline' }} />
                                                      <span style={{ verticalAlign: 'middle' }}>
                                                        {item.rating.toFixed(1)}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    "No Rating"
                                                  )}
                                                </span>
                                                </div>
					
                                               
                                               
                                                <div className="fs-12  staff-status-label d-flex align-items-center" id={`staff-status-${index+1}`}>
                                                   
                                                        {" "}
                                                        {item.status === 1 && reject.length>0 && reject[index].reject ==""
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#FF8080"}}>Yet to Accept
                                                        <OverlayTrigger
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip>
                                                              Click to accept on behalf of staff.
                                                            </Tooltip>
                                                        }
                                                        >
                                                         <img className='ml-1 cursor-pointer mb-2' src={blueSolidIcon} alt='blue-thumsup' onClick={()=> setAcceptOnBehalfOfStaff({showModal:true, staffId: item?.staffId})}/>
                                                        </OverlayTrigger>
                                                        </span>
                                                        :item.status === 1 && reject.length>0 && reject[index].reject !==""
                                                      
                                                        ? <div style={{color: "red"}} className='cursor-pointer text-uppercase' onClick={()=> setRejectedStaff({index:index, show:!rejectedStaff.show, id:item.id, data:reject.length>0 && reject[index].value==true ? reject[index].reject:""})}>Rejected by Staff  <img src={newMessageIcon} width={20} className='ml-3' /> </div>  
                                                        : item.status === 2
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#FB6873"}}>Yet to approve by Agency</span>
                                                        : item.status === 3 && reject.length>0 && reject[index].reject !==""
                                                       
                                                        ? <div style={{color: "red"}} className='cursor-pointer text-uppercase' onClick={()=> setClientRejectedStaff({index:index, show:!clientRejectedStaff.show, id:item.id, data:reject.length>0 && reject[index].value==true ? reject[index].reject:""})}>Rejected by Client  <img src={newMessageIcon} width={20} className='ml-2' /></div>
                                                        : item.status === 3
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#FB6873"}}>Yet to approve by Client
                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Click to approve on behalf of client.
                                                            </Tooltip>
                                                        }
                                                        ><img className='ml-1 cursor-pointer mb-2' src={blueSolidIcon} alt='blue-thumsup' onClick={() => setClientStaffApprove({showModal:true, staffId:item?.staffId})}/>
                                                        </OverlayTrigger>
                                                        </span>
                                                        : item.status === 4 &&
                                                        edit.status === 3
                                                        ? <span  className='pending-clock-in text-uppercase cta-3-m' style={{color: "#F3993E"}}>Pending ClockIn</span>
                                                        : item.status === 4 &&
                                                        edit.status === 3
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#008000"}}>Yet to approve by Client</span>
                                                        : item.status === 4 &&
                                                        edit.status === 4 &&
                                                        item.clockIn != null &&
                                                        item.pauseResumeData  && 
                                                        item.pauseResumeData[
                                                            item.pauseResumeData.length - 1
                                                        ].key === "pause"
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#34C7CA"}}>Paused</span>
                                                        : item.status === 4 &&
                                                        edit.status === 4 &&
                                                        item.clockIn != null
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#34C7CA"}}>In Progress</span>
                                                       : item.status === 4 &&
                                                        edit.status === 4 &&
                                                        !item.clockIn
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#0000FF"}}>Pending ClockIn</span>
                                                        : item.status === 5 &&
                                                        edit.status > 5 &&
                                                        item.clockOut != null
                                                        ? <span className='text-uppercase cta-3-m' style={{ color: "#FFD700"}}>Completed By Staff</span>
                                                        : item.status === 5 && edit.status >= 3
                                                        ? <span className='text-uppercase cta-3-m' style={{ color: "#FFD700"}}>Completed By Staff</span>
                                                        : item.status === 5 &&
                                                        edit.status === 4
                                                        ? <span className='text-uppercase cta-3-m' style={{ color: "#FFD700"}}>Completed By Staff</span>
                                                        : (item.status === 4 &&  edit.cancelByWork && item.clockIn !== null)
                                                         ? 
                                                        <span className='text-uppercase cta-3-m' style={{color: "#0000FF"}}>In Progress</span> 
                                                        : item.status === 4 && edit.status === 5 
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "rgb(52, 199, 202)"}}>Pending Timesheet Submission</span>
                                                        : item.status === 4
                                                        ? <span className='text-uppercase cta-3-m' style={{color: "#34C7CA"}}>In Progress</span>
                                                        : ""}
                                                    {/* </p> */}
                                                    {showEditTimeSheetIcon(edit?.workStaffAssignment, item.staffId) && <div className="">
                                                 <OverlayTrigger
                                                   placement="top"
                                                   overlay={
                                                     <Tooltip>
                                                     Edit Timesheet if required
                                                     </Tooltip>
                                                   }
                                                 >
                                                   <img className='ml-1 cursor-pointer' src={blueClockIcon} alt='blue-clock' width={15} height={15} onClick={() => setShowEditTimeSheet({ id: workID,staffId:item.staffId, show: true })}/>
                                                 </OverlayTrigger>
                                               </div>}
                                                </div>
                
                                                
                         { !timeSheetView === true ? 
                                                    (edit.status === 0 || edit.cancelByWork) ?
                                                        <div className="fs-12">Work Canceled</div>
                                                        :
                                                        <div className='d-flex justify-content-between align-items-center staff-action-label'>
                                                            {item.status === 2 ?
                                                                <>
                                                                    <div className="d-flex align-items-center text-success fs-12 text-decoration-underline cursor-pointer cta-3-m" id={`staff-approve-${index+1}`} onClick={() => handleApproveStaff(item.staffId)}>APPROVE <img src={greenOutlineIcon} width={15} className='ml-1'/> { loadingKey === `approve-staff-${item.staffId}` ? <CustomLoader/> : null} </div>
                                                                </>
                                                                :
                                                                <div className="text-muted fs-12 no-drop " id={`staff-approve-${index+1}`} >{item.status === 1 ? <span className='d-flex align-items-center  cta-3-m'>APPROVE <img src={greyOutlineIcon} width={15} className='' style={{marginTop:"-0.6rem", marginLeft:"0.75rem"}} /></span> : <span className='d-flex align-item-center'>APPROVED <img src={greenSolidIcon} width={15} className='ml-2' style={{marginTop:"-0.4rem"}} /> </span>} </div>

                                                            }
                                                            {item.status < 4 ?
                                                                <div className=" d-flex align-items-center text-danger fs-12 text-decoration-underline cursor-pointer cta-3-m" id={`staff-reject-${index+1}`} onClick={() => handleRejectStaff(item.staffId)}>REJECT <img src={redOutlineIcon} width={15} className='ml-1' /> { loadingKey === `reject-staff-${item.staffId}` ? <CustomLoader/> : null} </div>
                                                                :
                                                                <div className="fs-12 text-secondary no-drop  " id={`staff-reject-${index+1}`}> REJECT </div>
                                                            }
                                                        </div>
                                                 : null }
                                                </div>

                                             
                                               {rejectedStaff.show && rejectedStaff.index === index &&  <RejectAccordianStaff key={rejectedStaff.id} data={rejectedStaff.data} /> }
                                               {clientRejectedStaff.show && clientRejectedStaff.index === index &&   <RejectAccordianClient  key={clientRejectedStaff.id} data={clientRejectedStaff.data} /> }
                                           
                                            </div>
                                            
                                            </>)
                                       })
                                    }

                                </>
                                :
                                <div>
                                    <div 
                                      style=
                                        {{position: "relative", 
                                          right: edit.status === 0 ? "24px" : 
                                                 edit.status === 1 ? "12px" : 
                                                 edit.status === 2 ? "40px" : "41px"
                                        }} colSpan="5" className="border-0">
                                        <p style={{position: "relative", right: edit.status === 1 ? "12px" : edit.status === 0 ? "9px" : "-5px"}} className="text-center cta-3-m">
                                            {edit.status === 0 ? 'Work Canceled' : edit.status === 1 ? 'Need to accept by agency' : 'No staff assigned'}
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
            }


    {/* staff assignment details end */}
</div> }
    
            {
                !!workStaffAssignment && (edit.status >= 3 || (edit.status === 0 && isAnyStaffWorkInProgress(edit.workStaffAssignment?.staffWorkData))) &&
                <StaffDetailsNew data={workStaffAssignment} workID={edit.id} fetchWorkById={fetchWorkById} startDate={startDate} endDate={endDate} sitePicture={edit?.sitePicture} clientId={edit.clientId} clientLocLatLng={edit?.longLat} wrStatus={edit.status}/>
            }

     {/* client Notes */}
                 <div className={`client-notes-container ${!feedBackRatingShow && 'mb-5'}`}> 
                             
                           <div className="rectangle-325">

   <div className="frame-2344">
  <div className="frame-2343">
    <div className="frame-2342">
      <div className="frame-2340">
        <div className="frame-2339">
          <div className="client-notes s1-r">Client Notes</div>
        </div>
      </div>

      <div className="frame-2341">
        <div className="frame-23402">
          <div className="please-refer-the-staff-with-proper-experience s2-m">
          {edit.notes ? edit.notes : "N/A"}
          </div>
        </div>
      </div>
    </div>
     

     { clientNotesBox && <>

<div className="frame-2342">
<div className="frame-2340">
  <div className="frame-2339">
    <div className="client-notes s1-r">Attached Documents</div>
  </div>
</div>

<div className="frame-2341">
  <div className="frame-23402">
    <div className="please-refer-the-staff-with-proper-experience s2-m">
    {!edit.documents || edit.documents.length ===0 ? <label style={{fontSize: "13px"}} className='s2-m fs-13'>No Attachments</label> : null}
                        {edit.documents?.length===0?"":
                            edit.documents?.map((doc,i)=><a 
                                type='button'
                                className="s2-m document-links" 
                                onClick={()=>{
                                        if(doc.split("|").length>1)
                                            fetchFileDownloadURLById(doc.split("|")[0], doc.split("|")[1], doc.split("|")[2]);
                                    } 
                                }
                                target="_blank"> {doc.split("|").length>1 && (
                                    doc.split("|")[1].length>35 ? '...'+doc.split("|")[1].substring(doc.split("|")[1].length-35) : doc.split("|")[1]
                                )} ({i+1})
                            </a> )}   
    </div>
  </div>
</div>
</div>
 
<div className="frame-2342">
<div className="frame-2340">
  <div className="frame-2339">
    <div className="client-notes s1-r">Work Request Title</div>
  </div>
</div>

<div className="frame-2341">
  <div className="frame-23402">
    <div className="please-refer-the-staff-with-proper-experience s2-m">
    {edit.workRequest?edit.workRequest:"N/A"}
    </div>
  </div>
</div>
</div>

<div className="frame-2342">
<div className="frame-2340">
  <div className="frame-2339">
    <div className="client-notes s1-r">Created Date</div>
  </div>
</div>

<div className="frame-2341">
  <div className="frame-23402">
    <div className="please-refer-the-staff-with-proper-experience s2-m">
    {edit.createdAt?(Moment(edit.createdAt).format(`${localStorage.getItem('dateFormat')}`))+' - '+ (Moment(edit.createdAt).format("hh:mm:ss a")):"N/A"}
    </div>
  </div>
</div>
</div>

</>}



  </div>
</div>


                           <div className='d-flex justify-content-between align-items-center cursor-pointer' style={{minHeight:'2rem'}} onClick={()=> setClientNotesBox(!clientNotesBox)}>
                            <div className='additional-notes-title ml-3 cta-3-m'>Additional Details </div>
                            <div className='cta-3-m shrink-expand-title' >         
                             {!clientNotesBox && <>More <img src={downArrowIcon}/> </>}  
                             {clientNotesBox && <>Shrink <img src={upArrowIcon}/></>}  
                            </div>
                            </div>
                           </div>
                     
                </div> 

          {/* Staff and WR Rating/Feedback renders after client has submitted the timesheet */}
          {edit?.status > 5 && 
          <>
              {/* Staff Rating and Feedback details opener */}
              {!feedBackRatingShow && 
              <div className="frame-2325-2" >
                  <div className="feedback-details h2-sb">Staff Rating</div>

                  <div className="frame-2324">
                    <div className="line-76"></div>

                      <div className="ellipse-202"></div>
                      <img src={downArrowBlackIcon} className='iconsax-linear-arrowdown-2 cursor-pointer' onClick={()=> setFeedbackRatingShow(true)}/>
                    </div>
                </div> }
              
                {/* Staff Rating and Feedback Details */}
                {feedBackRatingShow && <>
                  <div className="client-feedback-s">Client Feedback</div>
                  <div className="line-76"></div>
                  <div className="feedback-details h2-sb">Staff Rating</div>

                  <div className='staff-rating-list'>
                  {edit?.workStaffAssignment?.staffWorkData?.map((staff) => (
                      <div className="frame-2247 my-2">
                      <div className="frame-2245">
                        <div className="frame-2244">
                          <div className="overall-wr s1-r">{staff.staffName}</div>
                        </div>
                        {staff.staffReviews?.rating > 0 ?
                          <div style={{position: "relative", top: "5px"}} className="_4-3-5">
                            <span>
                              <span className="_4-3-5-span">{staff.staffReviews?.rating}</span>
                              <span className="_4-3-5-span2">/ 5</span>
                            </span>
                          </div>
                            :<span style={{position: "relative", top: "3px"}} className="_4-3-5-span s3-r">Not Rated</span> }

                        <div className="frame-2018">
                          {/* Star Rating goes here*/}
                          <ReactStars 
                            size={25}
                            count={5}
                            color={"#E3E3E3"}
                            activeColor={"#FABB05"}
                            value= {staff.staffReviews?.rating}
                            edit={false}
                            isHalf={true}
                          />
                        </div>
                      </div>

                      <div className="frame-2246">
                        <div className="view-feedback s3-r">
                          {staff.staffReviews?.feedback 
                          ? staff.staffReviews?.feedback.length > 115 
                          ? `${staff.staffReviews?.feedback.slice(0, 115)}...`
                          : staff.staffReviews?.feedback
                          : "No Feedback"}
                        </div>
                        {
                            staff.staffReviews?.feedback
                             ? staff.staffReviews?.feedback.length > 115 ? 
                             <>
                             <img src={expandfeedback} style={{marginTop:"6px"}}/>
                             <div class="hover-feedback s3-r">
                              {staff.staffReviews?.feedback ? staff.staffReviews?.feedback: "No feedback"}
                             </div>
                             </>
                             :"":"" }
                      </div>
                    </div>
                  ))}
                  </div>

                <div className="feedback-details-opener">
                <div className="line-76"></div>   
                  <div className="ellipse-202"></div>
                      <img src={upArrowBlackIcon} className='iconsax-linear-arrowdown-2 cursor-pointer' onClick={()=> setFeedbackRatingShow(false)}/>
                </div>

                </>}

                {/* Overall Rating */}

                <div style={{position: "relative", bottom: !feedBackRatingShow && "70px"}} className="frame-2247">
                  <div className="frame-2245">
                    <div className="frame-2244">
                      <div className="overall-wr s1-r">Overall WR</div>
                    </div>
                    {edit?.workStaffAssignment?.starRating > 0 ?
                      <div style={{position: "relative", top: "5px"}} className="_4-3-5">
                        <span>
                          <span className="_4-3-5-span">{edit?.workStaffAssignment?.starRating}</span>
                          <span className="_4-3-5-span2">/ 5</span>
                        </span>
                      </div>
                        :<span style={{position: "relative", top: "3px"}} className="_4-3-5-span s3-r">Not Rated</span> }

                    <div className="frame-2018">
                      {/* Star Rating goes here*/}
                      <ReactStars 
                        size={25}
                        count={5}
                        color={"#E3E3E3"}
                        activeColor={"#FABB05"}
                        value= {edit?.workStaffAssignment?.starRating}
                        edit={false}
                        isHalf={true}
                      />
                    </div>
                  </div>

                  <div className="frame-2246">
                    <div className="view-feedback s3-r">
                      {edit?.workStaffAssignment?.feedBack
                       ? edit?.workStaffAssignment?.feedBack.length > 115 
                       ? `${edit?.workStaffAssignment?.feedBack.slice(0, 115)}...`
                       : edit?.workStaffAssignment?.feedBack
                       : "No Feedback"}
                    </div>
                    {
                       edit?.workStaffAssignment?.feedBack
                       ? edit?.workStaffAssignment?.feedBack.length > 115 ?
                       <>
                        <img src={expandfeedback} style={{marginTop:"6px"}}/>
                        <div className='hover-feedback s3-r'>
                          {edit?.workStaffAssignment?.feedBack ? edit?.workStaffAssignment?.feedBack : "No feedback"}
                        </div>
                       </>
                       :"":""
                    }
                  </div>
                </div>
              </>
            }

            {/* end Div */}
           <div id='scroll-to-bottom'></div>
           </div>
          {/* // footer */}

          { openModal ? <ConfirmModel
            toggle={() => setOpenModal(!openModal)}
            isOpen={openModal}
             handleAction={(e) => handleAction(e)}
             loadingCheckKey='cancel-work'
             loadingKey={loadingKey}
             message={(edit?.status === 1 || (edit?.status === 2 && edit.workStaffAssignment.staffWorkData?.length==0)) ? "Are you sure to cancel?"  : "This action cannot be reversed. Are you sure to cancel?"}
             type='cancel-work'
             />:null} 

{ openConfirmModal? <ConfirmModel
          toggle={() => setOpenConfirmModal(!openConfirmModal)}
          isOpen={openConfirmModal}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = 'accept-work'
          loadingKey={loadingKey}
          message='This is a recurring work request. Are you sure to accept?'
          type='accept-work'
        /> : null }

{ ClientStaffApproval?.showModal ? <ConfirmModel
          toggle={() => setClientStaffApprove({showModal :!ClientStaffApproval?.showModal, staffId:''})}
          isOpen={ClientStaffApproval?.showModal}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = 'client-staff-approve'
          loadingKey={loadingKey}
          message='You are approving on behalf of client. Are you sure?'
          type='client-staff-approve'
        /> : null }

{ AcceptOnBehalfOfStaff?.showModal ? <ConfirmModel
          toggle={() => setAcceptOnBehalfOfStaff({showModal :!AcceptOnBehalfOfStaff?.showModal, staffId:''})}
          isOpen={AcceptOnBehalfOfStaff?.showModal}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = 'agency-accept-for-staff'
          loadingKey={loadingKey}
          message='You are accepting on behalf of staff. Are you sure?'
          type='agency-accept-for-staff'
        /> : null }

           </>}

           { unassignStaff?.showModal ? <ConfirmModel
          toggle={() => setUnassignStaff({showModal :!unassignStaff?.showModal, staffId:''})}
          isOpen={unassignStaff?.showModal}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = 'unassign-staff'
          loadingKey={loadingKey}
          message='Are you sure you want to unassign the staff?'
          type='unassign-staff'
        /> : null } 

           {

           }

           <RecurringModel
              data={edit}
              disabled={true}
              viewWR={recurring.view}
              show={recurring.status}
              handleClose={handleCloseRecurring}
              repeat={edit?.repeat}
              repeatData={edit?.repeatData}
              handleUpdateRecurring={handleUpdateRecurring}
              workAssignmentNew={true}
              loadingKey={loadingKey}
              AlertModal={<>
                        {proceed.status &&
                             <WarningModal 
                                label ='Alert'
                                handleNo={() => setProceed({status:!proceed.status, message:''})}
                                handleYes={()=> handleAddRecurringWorkRequest(true)}
                                loading ={loadingKey === 'procedRecurringWorkRequest'}
                              >
                                    <div className='text-center my-2 ' style={{maxWidth:'500px'}}> {proceed.message.replace(/[\[\]]/g, ' ').split(',').map((mes)=> <> <br/><span className='h3-m'>{mes.toString()}</span> <br/></>)}<br/> <span className='proceed-message'> Are you sure to proceed to create recurrence shifts ? </span> </div>
                              </WarningModal> }
                         </>}
            />
          
          </NormalModal>
          <EditTimeSheet show={showEditTimeSheet.show} activeStaffId={showEditTimeSheet.staffId} closeModal={() => setShowEditTimeSheet({ id: '',staffId:'', show: false })} workRequestId={showEditTimeSheet.id} />

          </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSingleWork,
            approveWork,
            rejectWork,
            approveStaff,
            rejectStaff,
            updateStaffStatus,
            workCompletedByAgency,
            updateWork,
            addRecurringWorkRequests,
            approveStaffByClient,
            acceptOnBehalfOfStaff
        },
        dispatch
    );
};

export default connect(
    mapDispatchToProps,
    mapDispatchToProps
)(withRouter(WorkAssignment));
