import { modifySubscription } from 'action/SubscriptionAct';
import { generateQuery } from './helperFunctions';

export const authApi = {
   adminLogin: {
      api: 'v1/login',
      method: 'post',
      baseURL: 'auth',
   },
   adminLogOut: {
      url: 'v1/logOut',
      method: 'put',
      baseURL: 'auth',
      query: {
        auditId:'',
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   otpLogin: {
      api: 'v1/otpLogin',
      method: 'post',
      baseURL: 'auth',
   },
   forgetPassword: {
      api: 'v1/sendOtp',
      method: 'put',
      baseURL: 'auth',
   },
   resetPassword: {
      api: 'v1/resetPassword',
      method: 'post',
      baseURL: 'auth',
   },
   sendOtpToNewEmail: {
      url: "migrate/sendOtp",
      method: "put",
      baseURL: "auth",
      query: {
        emailId: null,
        newEmailId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      }
    },
    otpVerifyNewEmail: {
      url: "migrate/otpVerify",
      method: "put",
      baseURL: "auth",
      query: {
        emailId: null,
        newEmailId: null,
        otp: null
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      }
    }
};
export const agencyApi = {
   getUserById: {
      url: "getSingleAgency",
      method: "get",
      baseURL: "Agency",
      id: null,
      get api() {
        return this.url + "?id=" + this.id;
      },
      set addQuery({ key, payload }) {
         this[key] = payload;
      },
   },
   getRolls: {
      url: "v1/permission/getRoles",
      method: "get",
      baseURL: "agency",
      query: {
        
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   timeOffCode: {
      url: "timeOffCode/getAll",
      method: "get",
      baseURL: "agency",
      query: {
         agencyId:null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getPermissionMenu: {
      api: 'subscription/getPermissionsMenu',
      method: 'get',
      baseURL: 'subscription',
   },
   getUserLoginCount: {
      url: "v1/getLoggedUserCount",
      method: "get",
      baseURL: "job",
      query: {
         userMail:null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getAllSubscription: {
      api: 'subscription/getAllSubscription',
      method: 'get',
      baseURL: 'subscription',
   },
};
export const invoicesApi = {
   getAllinvoice: {
      url: "invoice/getAll",
      method: "get",
      baseURL: "work",
      query: {
         
         startDate: null,
         endDate: null,
         page: null,
         agencyId: null,
         size: null,
         clientName:'',
         sort: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   updateStatus: {
      url: "invoice/updatedStatus",
      method: "put",
      baseURL: "work",
      query: {
         id:null,
         status: null,
         paidDate: null,
         timeZone: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   postAddInvoice: {
      api: 'invoice/add',
      method: 'post',
      baseURL: 'work',
   },
   
   getInvoiceById: {
      url: "invoice/getById",
      method: "get",
      baseURL: "work",
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   putDeleteInvoice: {
      url: "invoice/delete",
      method: "delete",
      baseURL: "work",
      query: {
         invoiceId: null,
         // status: null,  //not needed in the new API
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   putArchiveInvoice: {
      url: "invoice/archive",
      method: "put",
      baseURL: "work",
      query: {
         id: null,
         archiveFlag: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   exportInvoice: {
      url: "invoice/getInvoiceSheet",
      method: "get",
      baseURL: "work",
      query: {
         id: null,
      },
      responseType:'blob',
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   postUpdateInvoice: {
      api: 'invoice/update',
      method: 'put',
      baseURL: 'work',
   },
}
export const clientApi = {
   getAllClient: {
      url: "client/getAllUser",
      method: "get",
      baseURL: "client",
      query: {
         page: null,
         location: "",
         size: null,
         name:''
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getCurrency: {
      api: 'subscription/getAllCurrency',
      method: 'get',
      baseURL: 'subscription',
   },
   getCSV: {
      url: "csvFileUpload/getCSVTemplate",
      method: "get",
      baseURL: "CSV",
      query: {
         page: null,
         location: null,
         size: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   postimportClients: {
      api: "import/clients",
      method: 'post',
      baseURL: 'fileUpload',
   },
   postAddClient: {
      api: 'client/signUp',
      method: 'post',
      baseURL: 'client',
   },
   postInvite: {
      api: 'client/inviteClient',
      method: 'post',
      baseURL: 'client',
   },
   postMultiClient:{
      api: 'client/multiClient',
      method: 'post',
      baseURL: 'client',
   },
   getClientById: {
      url: "client/getProfile",
      method: "get",
      baseURL: "client",
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   uploadClientBillingRates: {
      url: "import/billingRates",
      method: 'post',
      baseURL: "fileUpload",
      query: {
         clientId: null,
         type: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload}) {
         this.query[key] = payload;
      }
   },
   getPayrateById:{
      url: "client/getBillingRateByClientId",
      method: "get",
      baseURL: "client",
      query: {
         agencyId:null,
         clientId:null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getAllCurrency: {
      url: 'client/getAllCurrency',
      method: 'get',
      baseURL: 'client',
      query: {
         clientId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getClientByName: {
      url: "client/searchClientByName",
      method: "get",
      baseURL: "client",
      query: {
         name: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   putDeleteClient: {
      url: "client/deleteUserById",
      method: "put",
      baseURL: "client",
      query: {
         clientId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   postUpdateClient: {
      api: 'client/updateProfile',
      method: 'put',
      baseURL: 'client',
   },
   postUpdatePayrate: {
      api: 'client/updateBillingRate',
      method: 'put',
      baseURL: 'client',
   },
   deleteClientBillingRate: {
      url: "client/deleteBillingRate",
      method: "delete",
      baseURL: "client",
      query: {
         clientId: null,
         engagementType:null,
         jobFunctionId:null,
         wrType:null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getTaxSetting: {
      url: "tax/taxSetting",
      method: "get",
      baseURL: "job",
      query: {
        clientId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    updateTaxSetting: {
      url: "tax/taxSetting",
      method: "put",
      baseURL: "job",
      query: {
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    getTaxRegion: {
      url: "tax/region",
      method: "get",
      baseURL: "job",
      query: {
         code: ''
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
}
export const teamApi = {
   getAllUser: {
      url: "staff/getAll",
      method: "get",
      baseURL: "staff",
      query: {
         agencyId: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getAllUserList: {
      url: "staff/getAllUsers",
      method: "get",
      baseURL: "staff",
      query: {
         agencyId: null,
         page: null,
         role: null,
         size: null,
         status: null,

      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   generateBillingOrPayRateTemplate: {
      url: "export/generateBillingOrPayRateTemplate",
      method: "get",
      baseURL: "fileUpload",
      query: {
         staffOrClient: null,
         type: null,
      },
      responseType:'blob',
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },

   },
   postAddTeam: {
      api: 'team/add',
      method: 'post',
      baseURL: 'agency',
   },
   getTeamById: {
      url: "team/getById",
      method: "get",
      baseURL: "agency",
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getTeamHeader: {
      url: "staff/header",
      method: "get",
      baseURL: "staff",
      query: {
         agencyId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   putTeamEdit: {
      api: 'team/update',
      method: 'put',
      baseURL: 'agency',
   },
}

export const staffApi = {
   getAllStaff: {
      url: "staff/getAllByRole",
      method: "get",
      baseURL: "staff",
      query: {
         
         roleType: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   uploadAndOnboardStaffs: {
      api: "import/staffs",
      method: "post",
      baseURL: "fileUpload",
   },
   notifyAgain: {
      url: 'staff/notifyAgain',
      method: 'put',
      baseURL: 'staff',
      query: {
         emailId:null,
         resetPassword:null,
         staffName:null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   postAddStaff: {
      api: 'staff/add',
      method: 'post',
      baseURL: 'staff',
   },
   updateStaff: {
      api: 'staff/update',
      method: 'put',
      baseURL: 'staff',
   },
   uploadStaffPayRates: {
      url: "import/payRates",
      method: 'post',
      baseURL: "fileUpload",
      query: {
         staffId: null,
         type: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload}) {
         this.query[key] = payload;
      }

   },
   getStaffId: {
      url: "staff/getById",
      method: "get",
      baseURL: "staff",
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getRegions: {
      url: "subscription/getAllCountry",
      method: "get",
      baseURL: "subscription",
      query: {
         
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   deleteStaffFlag: {
      url: "staff/updateDeleteFlag",
      method: "put",
      baseURL: "staff",
      query: {
         id: null,
         flag: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },

   permanentDelete:{
      url: "staff/deleteStaff",
      method: "delete",
      baseURL: "staff",
      query: {
         staffId: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getPermissionAgency: {
      url: "v1/permission/getPermissionByType",
      method: "get",
      baseURL: "agency",
      query: {
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   updateGeneral: {
      api: 'staff/update',
      method: 'put',
      baseURL: "staff",
   },
   updatePreferences: {
      api: 'staff/updatePreferences',
      method: 'put',
      baseURL: "staff",
   },
   updateStaffPermission: {
      api: 'staff/updatePermission',
      method: 'put',
      baseURL: "staff",
   },
   updateStaffPayRate: {
      api: 'staff/updatePayRate',
      method: 'put',
      baseURL: "staff",
   },
   updateStaffCredentials: {
      api: 'staff/updateCredentials',
      method: 'put',
      baseURL: "staff",
   },
   postStaffInvite: {
      api: 'staff/inviteStaff',
      method: 'post',
      baseURL: 'agency',
   },

   getAllManagers: {
      api: 'staff/getAllManagers',
      method: 'get',
      baseURL: 'staff',
   },

   getAllRegions: {
      api: 'subscription/getAllCountry',
      method: 'get',
      baseURL: 'location',
   },

   addTeams: {
      api: 'team/add',
      method: 'post',
      baseURL: 'agency',
   },
   updateTeams: {
      api: 'team/update',
      method: 'put',
      baseURL: 'agency',
   },

   getJobFunctionCategoriesAgency: {
      url: "agency/getJobCategory",
      method: "get",
      baseURL: "agency",
      query: {
         agencyId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },

    getJobFunction: {
      url: "agency/getJobFunction",
      method: "get",
      baseURL: "agency",
      query: {
         agencyId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },

    
   staffChangePassword: {
      url: 'v1/sendOtp',
      method: 'put',
      baseURL: 'auth',
      query: {
         emailId: null,
       },
       get api() {
         return this.url + generateQuery(this.query);
       },
       set addQuery({ key, payload }) {
         this.query[key] = payload;
       },
   },
   staffOtpVerify: {
      url: 'v1/otpVerify',
      method: 'put',
      baseURL: 'auth',
      query: {
         emailId: null,
         otp: null,
       },
       get api() {
         return this.url + generateQuery(this.query);
       },
       set addQuery({ key, payload }) {
         this.query[key] = payload;
       },
   },
   staffUpdatePassword: {
      api: 'v1/resetPassword',
      method: 'post',
      baseURL: 'auth',
   },
   deleteStaffPayRate: {
      url: "staff/deletePayRate",
      method: "delete",
      baseURL: "staff",
      query: {
         staffId: null,
         engagementType:null,
         jobFunctionId:null,
         wrType:null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
}

export const timeOff = {
   getAll: {
      url: "timeOffCode/getAll",
      method: "get",
      baseURL: "timeOff",
      query: {
         agencyId: null,
         employmentType:[],
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getAllTimeOff: {
      url: "timeOff/getAllTimeOff",
      method: "get",
      baseURL: "agency",
      query: {
         page:null,
         size:null,
         status:null,
         startDate:null,
         endDate:null,
         agencyId:null,
         staffName:null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   create: {
      api: 'timeOffCode/add',
      method: 'post',
      baseURL: 'timeOff',
   },
   update: {
      api: 'timeOffCode/update',
      method: 'put',
      baseURL: 'timeOff',
   },
   updateStatus: {
      url: 'timeOff/updateStatus',
      method: 'put',
      baseURL: 'timeOff',
       query: {
         proceed: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   delete: {
      url: 'timeOffCode/delete',
      method: 'delete',
      baseURL: 'timeOff',
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getSingle: {
      url: 'timeOffCode/getById',
      method: 'get',
      baseURL: 'timeOff',
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
};

export const timeSheet = {
   getAll: {
      url: "timeSheet/getAllTimeSheet",
      method: "get",
      baseURL: "agency",
      query: {
         sort: null,
         action: null,
         startDate: null,
         endDate: null,
         page: null,
         size: null,
         agencyId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   postAddTimeSheet: {
      api: 'timeSheet/add',
      method: 'post',
      baseURL: 'agency',
   },
   editTimeSheet: {
      api: 'v1/timesheet/editTimeSheet',
      method: 'put',
      baseURL: 'workStatus',
   },
   editTimeSheetCheckIn: {
      api: 'v1/timesheet/editTimeSheetCheckIn',
      method: 'put',
      baseURL: 'workStatus',
   },
   editTimeSheetBreak: {
      api: 'v1/timesheet/editTimeSheetBreak',
      method: 'put',
      baseURL: 'workStatus',
   },
   postAddTimeOff: {
      api: 'timeOff/timeOffRequest',
      method: 'post',
      baseURL: 'agency',
   },
   getAllTimeSheet: {
      api: "v1/timesheet/getAllTimeSheet",
      method: "post",
      baseURL: "workStatus"
   },
   rejectTimeSheet: {
      api: "v1/timesheet/rejectTimeSheet",
      method: "put",
      baseURL: "workStatus"
   },
   approveTimeSheet: {
      api: "v1/timesheet/approveTimeSheet",
      method: "put",
      baseURL: "workStatus"
   },
   clientApproval: {
      api: "v1/workStatus/completeWorkByClient",
      method: "put",
      baseURL: "workStatus"
   },
  
};


export const notification = {
   get: {
      url: "notification/get",
      method: "get",
      baseURL: "notification",
      query: {
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   count: {
      url: "notification/notificationCount",
      method: "get",
      baseURL: "notification",
      query: {
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },

};
export const workRequest = {
   create: {
      api: 'client/addWorkRequest',
      method: 'post',
      baseURL: 'work',
   },
   update: {
      api: 'client/updateWorkRequest',
      method: 'put',
      baseURL: 'work',
   },
   getAllClient: {
      api: 'v1/workStatus/getAllClients',
      method: 'get',
      baseURL: 'workStatus',
   },
   getAllStaff: {
      api: 'v1/workStatus/getAllStaffs',
      method: 'get',
      baseURL: 'workStatus',
   },
   getAllWorkLocation: {
      api: 'v1/workStatus/getAllWorkLocation',
      method: 'get',
      baseURL: 'workStatus',
   },
   getAllWorkLocationClientId: {
      url: 'v1/workStatus/getAllWorkLocation',
      method: 'get',
      baseURL: 'workStatus',
      query: {
         clientId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getAllAvailableStaff: {
      url: 'v1/workStatus/getStaffsFromRange',
      method: 'get',
      baseURL: 'workStatus',
      query: {
         workId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getAllWork: {
      url: 'client/getAllWork',
      method: 'get',
      baseURL: 'work',
      query: {
         limit: 10,
         page: 1,
         clientId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   
   // getAllWorkStaff: {
   //    url: "v1/workStatus/getAllWorkByStaff",
   //    method: "get",
   //    baseURL: "workStatus",
   //    query: {
   //       staffId: null,
   //       status: null,
   //       startDate: null,
   //       endDate: null,
   //    },
   //    get api() {
   //      return this.url + generateQuery(this.query);
   //    },
   //    set addQuery({ key, payload }) {
   //      this.query[key] = payload;
   //    },
   //  },
    getAllWorkStaff: {
      api: "v1/workStatus/getAllWorkByStaff",
      method: "post",
      baseURL: "workStatus"
   },
   // getAllWorkClient: {
   //    url: "client/getAllWorkByClient",
   //    method: "get",
   //    baseURL: "work",
   //    query: {
   //       clientId: null,
   //       status: null,
   //       startDate: null,
   //       endDate: null,
   //    },
   //    get api() {
   //      return this.url + generateQuery(this.query);
   //    },
   //    set addQuery({ key, payload }) {
   //      this.query[key] = payload;
   //    },
   //  },
    getAllWorkClient: {
      api: "client/getAllWorkByClient",
      method: "post",
      baseURL: "work"
   },
   getById: {
      url: "client/getWorkRequestById",
      method: "get",
      baseURL: "work",
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   delete: {
      url: "client/deleteWork",
      method: "delete",
      baseURL: "work",
      query: {
         id: null,
         status: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
    },
   approveWork: {
      api: "v1/workStatus/acceptWorkByAgency",
      method: "put",
      baseURL: "workStatus"
   },
   acceptOnBehalfOfStaff: {
      api: "v1/workStatus/acceptWorkByStaff",
      method: "put",
      baseURL: "workStatus"
   },
   rejectWork: {
      api: "v1/workStatus/rejectWorkByAgency",
      method: "put",
      baseURL: "workStatus"
   },
   approveStaff: {
      api: "v1/workStatus/approveStaffByAgency",
      method: "put",
      baseURL: "workStatus"
   },
   approveStaffByClient: {
      api: "v1/workStatus/approveStaffByClient",
      method: "put",
      baseURL: "workStatus",
    },
   assignStaff: {
      api: "v1/workStatus/assignStaffByAgency",
      method: "put",
      baseURL: "workStatus"
   },
   rejectStaff: {
      api: "v1/workStatus/rejectStaffByAgency",
      method: "put",
      baseURL: "workStatus"
   },
   rejectInterested: {
      api: "v1/workStatus/rejectIamInterested",
      method: "put",
      baseURL: "workStatus"
   },
   addRecurringWorkRequest: {
      url: "client/addRecurringWorkRequest",
      method: "post",
      baseURL: "work",
      status:true,
      query: {
         agencyId: '',
         workId: '',
         proceed:'',
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
    },
   // rejectStaff: {
   //    api: "v1/workStatus/rejectStaffByAgency",
   //    method: "put",
   //    baseURL: "workStatus"
   // },
   completeWork: {
      api: "v1/workStatus/completeWorkByAgency",
      method: "put",
      baseURL: "workStatus"
   },
   getJobCategoriesBYClient: {
      url: "client/getAllJobCategoriesByClient",
      method: "get",
      baseURL: "client",
      query: {
         clientId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    getJobNamesBYClient: {
      url: "client/getAllJobNamesByClient",
      method: "get",
      baseURL: "client",
      query: {
         clientId: null,
         jobCategory: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
    },
   getJobFunctionsBYClient: {
      url: "client/getAllJobFunctionByClient",
      method: "get",
      baseURL: "client",
      query: {
         clientId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
   getJobFunctionsBYstaff: {
      url: "v1/workStatus/getAllJobFunction",
      method: "get",
      baseURL: "workStatus",
      query: {
         staffId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
   getAllLocationByClient: {
      url: "client/getAllLocationByClient",
      method: "get",
      baseURL: "client",
      query: {
         clientId: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    updateWRStaffStatus: {
      api: "v1/workStatus/updateWRStaffStatus",
      method: "put",
      baseURL: "workStatus",
   },
   updateDefaultBillingRateFrequency: {
      api: 'client/updateDefaultBillingRateFrequency',
      method: 'put',
      baseURL: 'work',
   },
   getWorkLocationPoints: {
      url: "v1/workStatus/getWorkLocationPoints",
      method: "get",
      baseURL: "workStatus",
      query: {
         agencyId: null,
         id:null
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
};
export const documentUpload = {
   create: {
      api: 'v1/storage/aws/uploadFile',
      method: 'post',
      baseURL: 'fileUpload',
   },
};
export const documentUploadOnMDB = {
   create: {
      api: 'v1/storage/mdb/uploadFile',
      method: 'post',
      baseURL: 'fileUpload',
   },
};

export const documentDownloadFromMDB = {
   create: {
      api: 'v1/storage/mdb/download/file/',
      method: 'get',
      baseURL: 'fileUpload',
      query: {
         'id': null,
      },
   },
};

export const downloadPayrollJournalFromMDB = {
   create: {
      api: 'payroll/journal/download/',
      method: 'get',
      baseURL: "Report",
      query: {
         'id': null,
      },
   },
};

export const reportLogs = {
   getAllLogs: {
      url: "getLogs",
      method: "get",
      baseURL: "Logs",
      query: {
         logType:null,
         page: null,
         filter: null,
         size: null,
         searchKey:null,
         startDate: "",
         endDate:"",
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getLogsCSV: {
      url: "logsCSV",
      method: "get",
      baseURL: "Logs",
      query: {
      //    page: null,
      //    filter: null,
      //    size: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   searchAudit: {
      url: "getLogs",
      method: "get",
      baseURL: "Logs",
      query: {
        page: 1,
        filter: 'all',
        size: 10,
        searchKey:null,
        startDate: "",
         endDate:"",
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
   getReport: {
      url: "report/getReport",
      method: "get",
      baseURL: "report",
      query: {
         page: null,
         size: null,
         sort: null,
         planName: null,
         startDate: null,
         endDate: null,
         region: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getReportHeader: {
      url: "report/header",
      method: "get",
      baseURL: "report",
      query: {
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   search: {
      url: "report/agencyDetailsByName?agencyName",
      method: "get",
      baseURL: "report",
      query: {
         name: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   searchReport: {
      url: "report/agencyDetailsByName",
      method: "get",
      baseURL: "report",
      query: {
         agencyName: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
}

export const settingsApi = {
   getSettingsById: {
      url: "agency/getSingleAgency",
      method: "get",
      baseURL: "agency",
      query: {
         id: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getTimeZoneList: {
      url: "subscription/getTimeZoneList",
      method: "get",
      baseURL: "subscription",
      query: {
         timezone: ["America", "Asia", "US", "Pacific"],
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getSubscriptions: {
      url: "subscription/getAllSubscriptionByAgency",
      method: "get",
      baseURL: "subscription",
      query: {
         // agencyId:null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   updateGeneralDetails: {
      api: 'agency/updateGeneral',
      method: 'put',
      baseURL: 'agency',
   },
   updateCompanyDetails: {
      api: 'agency/updateCompanyDetails',
      method: 'put',
      baseURL: 'agency',
   },
   updateJob: {
      api: 'agency/update-jobcategory',
      method: 'put',
      baseURL: 'agency',
   },
   updateJobFunction: {
      api: 'agency/updateJob',
      method: 'put',
      baseURL: 'agency',
   },
   updateCardDetail: {
      api: 'agency/saveCard',
      method: 'put',
      baseURL: 'agency',
   },
   updatePermission: {
      api: 'agency/updatePermission',
      method: 'put',
      baseURL: 'agency',
   },
   updateNotification: {
      api: 'agency/updateNotification',
      method: 'put',
      baseURL: 'agency',
   },
   updateAgencyOverTime: {
      api: 'agency/updateAgencyOverTime',
      method: 'put',
      baseURL: 'agency',
   },
   updateSubscription: {
      api: 'agency/updateSubscription',
      method: 'put',
      baseURL: 'agencyPayment',
   },
   updateTimeOff: {
      api: 'agency/updateTimeOff',
      method: 'put',
      baseURL: 'agency',
   },
   removeCardDetail: {
      api: 'agency/removeCard',
      method: 'delete',
      baseURL: 'agency',
   },
   changePassword: {
      url: 'v1/sendOtp',
      method: 'put',
      baseURL: 'auth',
      query: {
         emailId: null,
       },
       get api() {
         return this.url + generateQuery(this.query);
       },
       set addQuery({ key, payload }) {
         this.query[key] = payload;
       },
   },
   otpVerify: {
      url: 'v1/otpVerify',
      method: 'put',
      baseURL: 'auth',
      query: {
         emailId: null,
         otp: null,
       },
       get api() {
         return this.url + generateQuery(this.query);
       },
       set addQuery({ key, payload }) {
         this.query[key] = payload;
       },
   },
   updatePassCode: {
      api: 'v1/resetPassword',
      method: 'post',
      baseURL: 'auth',
   },
   deleteAgency: {
      url: "updateDeleteFlag",
      method: "delete",
      baseURL: "Agency",
      query: {
        id: null,
        deleteFlag: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    downloadData: {
      url: "account/backup",
      method: "get",
      baseURL: "job",
      query: {
         startDate:null,
        format: null,
        module: null,
        endDate:null
      },
      responseType:'',
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },

    newTasks: {
      url: "dashboard/newTasks",
      method: "get",
      baseURL: "work",
      query: {
         
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },

    getAccountingCodes:{
      url: "tax/accountingCode",
      method: "get",
      baseURL: "tax",
      query:{
         region: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
    },
    getTaxRates:{
      url: "tax/taxRate",
      method: "get",
      baseURL: "tax",
      query: {

      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
    },
    addTaxRates:{
      url: "tax/taxRate",
      method: "post",
      baseURL: "tax",
      query: {

      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },

    },

    updateTaxRate:{
      url: "tax/taxRate",
      method: "put",
      baseURL: "tax",
      query: {

      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },

    },

    deleteTaxRate:{
      url: "tax/taxRate",
      method: "delete",
      baseURL: "tax",
      query: {
         taxRateId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },

    },


    addServiceItems:{
      url: "tax/serviceItemSetting",
      method: "post",
      baseURL: "tax",
      query: {

      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
    },
    getServiceItems:{
      url: "tax/serviceItemSetting",
      method: "get",
      baseURL: "tax",
      query: {
         jobFunctionId:''
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },

    },
    updateServiceItems:{
      url:"tax/serviceItemSetting",
      method: "put",
      baseURL: "tax",
      query: {

      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },

    },
    deleteServiceItems:{
      url:"tax/serviceItemSetting",
      method:"delete",
      baseURL: "tax",
      query: {
         serviceItemId:null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
    },

    getTaxSettings: {
      url: "tax/taxSetting",
      method: "get",
      baseURL: "tax",
      query: {
         clientId: null
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   updateTaxSettings: {
      url: "tax/taxSetting",
      method: "put",
      baseURL: "tax",
      query: {
         
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getTaxRegion: {
      url: "tax/region",
      method: "get",
      baseURL: "job",
      query: {
         code: ''
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },

}

export const reportApi = {
   getMonthWise: {
      url: "agencyReport/getTotalHours",
      method: "get",
      baseURL: "Report",
      query: {
         type: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getReportHeader: {
      url: "agencyReport/header",
      method: "get",
      baseURL: "Report",
      get api() {
         return this.url;
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getReportUsers: {
      url: "agencyReport/getUsers",
      method: "get",
      baseURL: "Report",
      query: {
         type: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getReportWork: {
      url: "agencyReport/getWorks",
      method: "get",
      baseURL: "Report",
      query: {
         type: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   downloadTotalCSV: {
      url: "staffReport/totalWorksCSV",
      method: "get",
      baseURL: "Report",
      get api() {
        return this.url;
      },
      set addQuery({key, payload}) {
        this.query[key] = payload;
      },
   },
   downloadTotalPayrollJournalCSV: {
      url: "payroll/journal",
      method: "get",
      baseURL: "Report",
      query: {
         staffId: "",
         clientId: "",
         startDate: "",
         endDate: "",
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getUsersCSV: {
      url: "agencyReport/getUsersCSV",
      method: "get",
      baseURL: "Report",
      query: {
         startDate: "",
         endDate: "",
      },
      responseType:'blob',
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getWorksCSV: {
      url: "agencyReport/getWorksCSV",
      method: "get",
      baseURL: "Report",
      query: {
         type: 2,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getStaffBillableCSV: {
      url: "agencyReport/getStaffBillableCSV",
      method: "get",
      baseURL: "Report",
      query: {
         clientId: "",
         startDate: "",
         endDate: "",
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getReportSubscribers: {
      url: "agencyReport/getOverAllWorkRequest",
      method: "get",
      baseURL: "Report",
      get api() {
         return this.url;
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   }, 
   getReportBillable: {
      url: "agencyReport/getStaffBillable",
      method: "get",
      baseURL: "Report",
      query: {
         type: null,
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   getStaffRejectionStats: {
      url: "agencyReport/getStaffRejectionStats",
      method: "get",
      baseURL: "Report",
      query: {
         userType:7,
         startDate: "",
         endDate: "",
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },
   downloadStaffRejectionStatsReport: {
      url: "agencyReport/getStaffRejectionStatsReport",
      method: "get",
      baseURL: "Report",
      query: {
         userType:7,
         startDate: "",
         endDate: "",
      },
      get api() {
         return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
         this.query[key] = payload;
      },
   },

}

export const jobCategoriesApi = {
   getAllJobCategories: {
      url: "v1/getAllJobCategories",
      method: "get",
      baseURL: "job",
      query: {
        status:'active',
        jobFunctionStatus:'active',
        searchKey:'',
        page:1,
        size:10
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    updateJobCategory: {
      url: "agency/updateJob",
      method: "put",
      baseURL: "agency",
      get api() {
        return this.url;
      },
    },
    getJobFunctions: {
      url: "agency/getJobFunction",
      method: "get",
      baseURL: "agency",
      query: {
        agencyId:'',
        jobCategory:'',
        jobName:''
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    addJobCategory: {
      url: "v1/addJobCategory",
      method: "post",
      baseURL: "job",
      get api() {
        return this.url;
      },
      
    },
    getAgencyJobs: {
      url: "agency/getAgencyJobs",
      method: "get",
      baseURL: "agency",
      query: {
       id:''
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    addJobFunctionById: {
      url: "v1/addJobFunctionById",
      method: "post",
      baseURL: "job",
      query: {
        id:'',
        name:'',
        purpose:'',
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    unsubscribejobFunctionsById: {
      url: "unsubscribeJob",
      method: "put",
      baseURL: "Agency",
      query: {
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
    jobFunctionNotifyUsers: {
      url: "v1/notifyUsers",
      method: "post",
      baseURL: "job",
      query: {
        category:'',
        functionName:'',
        jobMasterId:'',
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
}

export const clientConnectApi = {
   acceptInviteFromClient: {
      url: "connect/acceptInviteFromClient",
      method: "put",
      baseURL: "client",
      query: {
        clientId:'',
        message:'',
        status:null,
        email:'',
        
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },

    getClientInvitations: {
      url: "connect/getClientInvitations",
      method: "get",
      baseURL: "client",
      query: {
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },

    notifyUsers: {
      url: "connect/notifyUsers",
      method: "post",
      baseURL: "client",
      query: {
        clientId:'',
        clientName:'',
        message:'',
        status:null,
        email:'' 
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    },
}

export const fileUploadOnMDB = {
   create: {
      url: 'v1/storage/mdb/uploadFile',
      method: 'post',
      baseURL: 'fileUpload',
      query: {
       entity:'admin',
       clientId:null,
      },
     get api() {
       return this.url + generateQuery(this.query);
     },
     set addQuery({ key, payload }) {
       this.query[key] = payload;
     },
   },
 };
 
 export const fileDownloadFromMDB = {
   getFile: {
      url: 'v1/storage/mdb/file',
      method: 'get',
      baseURL: 'fileUpload',
      query: {
         fileId:'',
         entity:'',
         clientId: '',
         agencyId:''
      },
      get api() {
       return this.url + generateQuery(this.query);
     },
     set addQuery({ key, payload }) {
       this.query[key] = payload;
     },
   },
   deleteFile: {
      url: 'v1/storage/mdb/deleteFile',
      method: 'delete',
      baseURL: 'fileUpload',
      query: {
         fileId:'',
         entity:'',
      },
      get api() {
       return this.url + generateQuery(this.query);
     },
     set addQuery({ key, payload }) {
       this.query[key] = payload;
     },
   },
 };

 export const stripeSubscriptionApi = {
 
   checkout: {
     url: "stripe/subscription/checkoutSession",
     method: "post",
     baseURL: "subscription",
     query: {
       email:'',
       currency:'',
       planName:'',
       quantity :0,
       unitAmount:0
     },
     get api() {
       return this.url + generateQuery(this.query);
     },
     set addQuery({ key, payload }) {
       this.query[key] = payload;
     },
   }
 }

 export const razorPaySubscriptionApi = {
   checkout: {
     url: "razorpay/subscription/checkoutSession",
     method: "post",
     baseURL: "subscription",
     query: {
       currency:'',
       unitAmount:0
     },
     get api() {
       return this.url + generateQuery(this.query);
     },
     set addQuery({ key, payload }) {
       this.query[key] = payload;
     },
   },
 }

 export const subscriptionApi = {
 modifySubscription: {
   api: "agency/modifySubscription",
   method: "put",
   baseURL: "agency",
},
getPlanById: {
   url: "subscription/getById",
   method: "get",
   baseURL: "subscription",
   query: {
     id: null
   },
   get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
 },
getBillingType: {
   url: "subscription/getBillingType",
   method: "get",
   baseURL: "subscription",
   query: {
     type:2
   },
   get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
 },
 paymentCheckOutSession: {
   url: "payment/checkoutSession",
   method: "post",
   baseURL: "subscription",
   query: {
      paymentReq:''
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
},
paymentTransactions: {
   url: "payment/getAllTransactions",
   method: "get",
   baseURL: "subscription",
   query: {
     startDate:'',
     endDate:'',
     paymentStatus:'',
     userType:null,
     page:1,
     size:10,
   },
   get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
 },
 gettransactionLogsCSV: {
   url: "payment/transactionLogsCSV",
   method: "get",
   baseURL: "subscription",
   query: {
      userId: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
 },
 pauseResumePlan: {
   url: "agency/pauseResumePlan",
   method: "put",
   baseURL: "agency",
   query: {
     flag:null,
     id: null
   },
   get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
 },
 }