import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { InputPrice, NormalModal, NormalInput, NormalSelect, Marker, NormalTextarea, DragFileUpload, CommonPageLoader } from "component/common"
import "./style.scss";
import Moment from "moment";
import Select from 'react-select';
import GoogleMapReact from "google-map-react";
import SimpleReactValidator from 'simple-react-validator';
import { getSingleWork, approveStaffByClient, rejectStaff, getAllAvailableStaff, assignStaff, rejectStaffInterested ,} from "action/workRequest";
import { getSettingsById } from 'action/SettingsAct';
import { fileUpload } from "action/fileUpload";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NormalButton } from 'component/common';
import { DistanceMatrix } from 'component/common/DistanceMatrix/distanceMatrix';
import createHTMLMapMarker from 'component/common/htmlMapMarker';
import { Toast } from "service/toast";
import { getClassNameForBackground, getClassNameForLeftBorder } from "service/utilities";
import { getDistanceFromLatLonInKm } from "service/milesAway";
import markerIcon from 'assets/svg/Vector.svg';
import calendarimg from 'assets/svg/calendar-5.svg';
import fileUploadIcon from 'assets/svg/upload.svg';
import noDataFound from  "assets/svg/no-data-found.svg"
import clientMarker from 'assets/svg/client-marker.svg';
import nav_user from 'assets/svg/user.svg';
import StarIcon from "assets/svg/Star.svg";
import StarNoRating from "assets/svg/Star_no_rating.svg";
import staff from "assets/svg/blueMultiStaff.svg"
import Vectorclose from 'assets/svg/Vectorclose.svg';
import no_results from "assets/svg/no_results.svg"
import defaultClientImage from "assets/images/letter-c.png";
import DisplayFile from 'component/workShedule/addWorkRequest/DisplayFile';
import { getFileFromMDB } from 'action/fileHandler';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DeleteFileConfirmationModal } from 'component/common/DeleteFileConfirmationModal'


function AssignWorkSchedule({ getSingleWork,approveStaffByClient, setAction, workAssign, setWorkAssign, handleReload, fileUpload, assignStaff, rejectStaffInterested, getSettingsById, getAllAvailableStaff,rejectStaff, getFileFromMDB }) {
    const [edit, setEdit] = useState({});
    const [clientId, setClientId] = useState('');
    const [workID, setWorkID] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isFormLoder, setFormLoder] = useState(false);
    const [file, setFile] = useState('');
    const [isUpload, setIsUpload] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [staffListMap, setStaffListMap] = useState([]);
    const [selectStaff, setSelectStaff] = useState('');
    const [showBy, setshowBy] = useState('Geo location');
    const [selectStaffList, setSelectStaffList] = useState([]);
    const [isValidateupdate, setIsValidateupdate] = useState(false);
    const [infoWindowOpen, setInfoWindowOpen] = useState('');
    const [markerClick, setMarkerClick] = useState(true);
    const [mapActive, setMapActive] = useState(false);
    const [sitePicture,setSitePicture] = useState('');
    const [distanceUnit, setDistanceUnit] = useState("");
    const [loadingKey, setLoadingKey] = useState("");
    const [onlineImage, setOnlineImage] = useState(null)
    const [staffImage,setStaffImage] = useState([])
    const [modalOpenClose,setModalOpenClose] = useState(workAssign.value)
    const [unassignStaff, setUnassignStaff] = useState(false)
    const [unassignConfirm, setUnassignConfirm] = useState(false)
    const [assignedStaff, setAssignedStaff] = useState({})
    const [assignedStaffIndex, setAssignedStaffIndex] = useState(-1)
    const [searchData, setSearchData] = useState([])
    const [searchVal, setSearchVal] = useState('')
    const [optLoading, setOptLoading] = useState(false)
    // set Validator
    const validator = useRef(new SimpleReactValidator({
        validators: {
            element: (message) => (
                <span className='error-message font-md'>{message}</span>
            )
        }
    }));
    const validatorUpdate = () => {
        setIsValidateupdate(!isValidateupdate)
    }
    useEffect(() => {
        if (!!workAssign.id) {
            fetchAllstaff(workAssign.id);
            fetchWorkById(workAssign.id);
            setWorkID(workAssign.id);
        }
        setMapActive(false);
        if(workAssign.value) {
            setModalOpenClose(true)
        }
    }, [workAssign]);
    // get work by workId
    const fetchWorkById = async (id) => {
        setLoading(true);

        const query = { 'id': id };
        await
            getSingleWork(query)
                .then((data) => {
                    setClientId(data.clientId);
                    fetchClientUserData(data.clientId);
                    setEdit(data)
                    setSitePicture(data.sitePicture);
                    if (!!data.workStaffAssignment) {
                        let staffWorkData = data.workStaffAssignment.staffWorkData;
                        if (staffWorkData.length) {
                            let ids = staffWorkData.map((item) => {
                                return item.staffId
                            })
                            setSelectStaffList(ids);
                        }else{
                            setSelectStaffList([]);
                        }
                    }

                    setLoading(false);
                })
                .catch((e) => setLoading(false));
    };
    const fetchAllstaff = async (id) => {
        setLoading(true);

        const query = { 'workId': id };
        await
            getAllAvailableStaff(query)
                .then((data) => {
                    setStaffListMap(data) 
                    data.forEach(async(item)=>{
                        if(!!item.profilePic){
                        await handlestaffImage(item.profilePic,item.id)}
                    })
                    let sortedData = data.sort((a, b) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    let newData = sortedData.map((item) => {
                        return {
                            label:
                                    <div className='d-flex'>
                                    <div className="d-flex justify-content-between">
                                        <span className='mr-5'>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip className='hover-content'>
                                                        <div>
                                                            {item.jobNames.map((jobName, index) => {
                                                                return <div key={index}>{jobName}</div>
                                                            })}
                                                            
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="d-flex " style={{width:"21.8rem"}}>
                                                    <div className='jobNames fs-14 text-44'>{item.jobNames.join(', ')}</div>
                                                    <div className='jobNamesCount'>{item.jobNames.length}</div>
                                                </div>
                                            </OverlayTrigger>
                                        </span>
                                        
                                        <span className='d-flex justify-content-between' style={{width:"29.2rem"}}>
                                            <span className=" fs-14 text-44">
                                            {item.name.length > 27 ? item.name.slice(0, 27) + "..." : item.name}
                                            {" "}
                                            </span>
                                            <span>
                                            {item.rating !== null && item.rating !== undefined ? (
                                                <span className="ml-2 agency-rating"><img src={StarIcon} style={{ verticalAlign: "baseline" }}/> {item.rating.toFixed(1)}</span>
                                            ) : (
                                                <span className="ml-2 no-rating"> <img src={StarNoRating} style={{ verticalAlign: 'baseline',marginRight:"5px" }}/>No Rating</span>
                                            )}
                                            </span>
                                        </span>
                                    </div>
                                    <span className="pr-2 fs-12 text-44" style={{marginLeft:"12%"}}>{item.status}</span>
                                    </div>,
                            customLabel: item.name,
                            value: item.id
                        }
                    })
                    setStaffList(newData)
                    setSearchData(newData)
                    setLoading(false);
                })
                .catch((e) => setLoading(false));
                setOptLoading(true)
    };
    //handle file upload
    const handleFileUpload = async (e) => {
        if (e) {
            setFile(e)
            setIsUpload(true);
            let formData = new FormData()
            formData.append('file', e[0]);
            await fileUpload(formData)
                .then((data) => {
                    setIsUpload(false);
                    let copy = [...documents];
                    copy[0] = data;
                    // setDocument(copy)
                    // setDefault();
                    // fetchAllData();
                })
                .catch(() => setIsUpload(false));
        }
    }
    // handle single staff approve by agency
    const handleAssignStaff = async (id, index) => {
        const formValid = validator.current.allValid();
        let body = {
            workId: workID,
            staffId: id,
            clientId:clientId,
        }
        setFormLoder(true);
        if (formValid) {
            await assignStaff(body).then((data, message) => {
                setFormLoder(false);
                if (index + 1 === selectStaffList.length || index === -1){
                    // Toast({ type: "success", message: 'Successfully Staff is assigned for the work' });
                    setDefault();
                }
                // handleReload();
            }).catch(() => {
                setFormLoder(false);
            });
        } else {
            validator.current.showMessages();
            validatorUpdate();
        }
    }

    // handle Multiple staff approve by agency
    const handleAssignMultipleStaff =  (selectedStaffList) => {
        setLoadingKey('handle-submit');
        let count = selectedStaffList.length;
        const assignStaff = () =>{
            if(count){
                handleAssignStaff(selectedStaffList[(count - 1)])
                .then(()=>{
                    count = count - 1;        
                    assignStaff()
                })
                .catch(()=>{
                    count = count - 1;
                    assignStaff()
                })
            }
            if(count==0){
                fetchWorkById(workAssign.id);
                fetchAllstaff(workAssign.id);
                setLoadingKey('')
            }
        }
        assignStaff()
        // setLoading(true);
        // selectedStaffList.forEach((item,index)=>{
        //     setTimeout(()=> {
        //         handleAssignStaff(item,index);
        //     },200*index)
        // });
        // setLoading(false);
    }

    // handle single staff approve by agency
  const handleApproveStaff = async (id) => {
    let body = {
      workId: workID,
      staffId: id,
      clientId : clientId
    };
    setFormLoder(true);
    await approveStaffByClient(body)
      .then((data) => {
        setFormLoder(false);
        // setDefault();
        // handleReload();
      })
      .catch(() => {
        setFormLoder(false);
      });
  };
    // handle single staff approve by agency
    const handleAssignStaffFromMap = async (id) => {
        let body = {
            workId: workID,
            staffId: id,
        }
        setFormLoder(true);
        await assignStaff(body).then((data) => {
            setFormLoder(false);
            fetchWorkById(workAssign.id);
            fetchAllstaff(workAssign.id);
            // handleReload();
        }).catch(() => {
            setFormLoder(false);
        });
    }

    // handle single staff reject
    const handleRejectStaffFromMap = async (id) => {
        if(workStaffAssignment.staffWorkData.findIndex(ar=>ar.staffId ===id) === -1) {
            handleRejectIAmInterested(id)
        } else {
            handleRejectStaff(id)
        }
    }
    const handleRejectIAmInterested = async (id) => {
        let body = {
            workId: workID,
            staffId: id,
        }
        setFormLoder(true);
        await rejectStaffInterested(body).then((data) => {
            setFormLoder(false);
            fetchWorkById(workAssign.id)
            fetchAllstaff(workAssign.id);
            // handleReload();
        }).catch(() => {
            setFormLoder(false);
        });
    }

    // handle single staff reject
    const handleRejectStaff = async (id) => {
        let body = {
            workId: workID,
            staffId: id,
            clientId:edit.clientId,
            remove:false
        }
        setFormLoder(true);
        await rejectStaff(body).then((data) => {
            setFormLoder(false);
            fetchWorkById(workAssign.id)
            fetchAllstaff(workAssign.id);
            // handleReload();
        }).catch(() => {
            setFormLoder(false);
        });
    }

    const handleAllAssignStaff = () => {
       let selectedStaffs = [];
        selectStaffList.forEach((item, index) =>{
            let{workStaffAssignment}=edit;
            let status = -1;
            // debugger
            if(workStaffAssignment.staffWorkData.length>0){
                if(workStaffAssignment.staffWorkData.findIndex(ar=>ar.staffId === item) !== -1){
                    status = workStaffAssignment.staffWorkData[workStaffAssignment.staffWorkData.findIndex(ar=>ar.staffId ===item)].status >0 ? 1:-1;
                }
            }
            // status = workStaffAssignment.staffWorkData.length===0 ? -1:
            // workStaffAssignment.staffWorkData.findIndex(ar=>ar.staffId != item) === -1? 1:
            // workStaffAssignment.staffWorkData[workStaffAssignment.staffWorkData.findIndex(ar=>ar.staffId ===item)].status >0? -1:1;
            if (status === -1) selectedStaffs.push(item);
        });
        if(selectedStaffs.length > 0)
        handleAssignMultipleStaff(selectedStaffs);
        else
        setModalOpenClose(false)
    }

    const fetchClientUserData = async (data) => {
        let userData = JSON.parse(localStorage.getItem('userData'));
        let agencyId = userData?.agencyId ? userData?.agencyId : userData?.id?userData?.id:"";
        setLoading(true);
        const query = {
          id:agencyId
        };
        await getSettingsById(query).then((data) => {
           setDistanceUnit(data.distanceUnit);
           
        })
           .catch((e) => console.log(e));
        setLoading(false);
     };


    // set Default values
    const setDefault = () => {
        validator.current.hideMessages();
        setEdit({});
        setSelectStaffList([]);
        setSelectStaff('')
        setWorkAssign(false);
        setAction(0)
        setTimeout(() => {setAction(1)},100)
        // handleReload();
    }
    const renderMarkers = (map, maps) => {
        let markers = [];
        // return(
        staffListMap.map((item) => {
            let staffLatLng;
            if (item.longLat !== null) {

                staffLatLng = {
                    lat: item.longLat[1],
                    lng: item.longLat[0]
                }
            }
            let contentString = '<div id="content">' +
                '<div id="siteNotice">' +
                "</div>" +
                '<h1 id="firstHeading" class="firstHeading">Uluru</h1>' +
                '<div id="bodyContent">' +
                "<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large " +
                "sandstone rock formation in the southern part of the " +
                "Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) " +
                "south west of the nearest large town, Alice Springs; 450&#160;km " +
                "(280&#160;mi) by road. Kata Tjuta and Uluru are the two major " +
                "features of the Uluru - Kata Tjuta National Park. Uluru is " +
                "sacred to the Pitjantjatjara and Yankunytjatjara, the " +
                "Aboriginal people of the area. It has many springs, waterholes, " +
                "rock caves and ancient paintings. Uluru is listed as a World " +
                "Heritage Site.</p>" +
                '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
                "https://en.wikipedia.org/w/index.php?title=Uluru</a> " +
                "(last visited June 22, 2009).</p>" +
                "</div>" +
                "</div>";
            const infowindow = new maps.InfoWindow({
                content: contentString,
            });
            let marker = createHTMLMapMarker({
                latlng: staffLatLng,
                map: map,
                html: `<div id="custom-marker-staff"><div id="inner-circle"> <img id="staff-pic" src=${!!item.profilePic ? item.profilePic : nav_user}></div></div>`
            });
            marker.addListener("click", () => {
                // infowindow.open(map, marker);
                alert("Partyin Partyin Yeah!");
            });
            //     let marker = new maps.Marker({
            //         position: staffLatLng,
            //         map,
            //         title: item.firstname,
            //         icon:custom_icon
            //    });
            markers.push(marker);
            return marker;
        })
        // )
        return markers;
    }
    const handleToggleOpen = (index) => {

        setInfoWindowOpen(index === infoWindowOpen ? -1:index);
    }
    const onChildClick = (key, childProps) => {

        // const markerId = childProps.marker.get('id');
        // console.log(key, childProps)
        // const index = this.props.markers.findIndex(m => m.get('id') === markerId);
        // if (this.props.onChildClick) {
        //   this.props.onChildClick(index);
        // }
    }
    let { createdAt, workRequest, status, jobName, jobCategory, startDate, endDate, staffNeeded, clientName, location, notes, documents, longLat ,workStaffAssignment } = edit;
    const parentStatus = status;
    const colourStyles = {
        control: styles => ({ ...styles, height: 57, backgroundColor: '#f9f9f9' }),

    };
    let userData = JSON.parse(localStorage.getItem('userData'));
    let agencyId = userData?.agencyId ? userData?.agencyId : userData?.id?userData?.id:"";

    const handleImageDownload = async (sitePicture) => {
        setLoading(true);
        let imageData;
        let fileId = sitePicture.split("/")[1];
        await getFileFromMDB({ entity: "client", clientId: clientId }, fileId)
          .then((response) => {
            imageData = `data:${response.contentType};base64,${response?.fileContent.data}`;
            setLoading(false);
            setOnlineImage(imageData.toString());
            return imageData;
          })
          .catch((error) => {
            setLoading(false);
            setOnlineImage(null);
            console.log("Error fetching image:", error);
          });
      }; 

      const handlestaffImage =  async (sitePicture,staffId) => {
        setLoading(true)

        let imageData;
        let fileId = sitePicture.split("/")[1];
        fileId && await getFileFromMDB({ entity: "staff", agencyId: agencyId}, fileId)
          .then((response) => {
            imageData = `data:${response.contentType};base64,${response?.fileContent.data}`;           
            setStaffImage((prevStaffImage)=>[...prevStaffImage,{id:staffId,onlineImage:imageData.toString()}])
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log("Error fetching image:", error);
          }); 
      }; 

      useEffect(()=>{
        if(sitePicture) {
          handleImageDownload(sitePicture)
        } else {
          setOnlineImage(null)
        }
      },[sitePicture])
      
      const handleUnassignStaff = (item, index) => {
        
            let staffPressent = -1;
            if(!!workStaffAssignment.staffWorkData){
                staffPressent=workStaffAssignment.staffWorkData.findIndex((ar)=>ar.staffId === item);
            }   
            if(staffPressent !== -1){
                handleRejectStaff(item);
            }
            let copy = [...selectStaffList];
            copy.splice(index, 1)
            setSelectStaffList(copy);
            setSelectStaff('');
            setUnassignStaff(false)
        
        
      }

    const handleCustomFilter = (newValue) => {
        return true
    }
    const handleInputChange = (newValue) =>{
        if(newValue){
            let result = staffList.filter((item) => item.customLabel?.toLowerCase().includes(newValue.toLowerCase()))
            setSearchData(result)
        }
        else{
            setSearchData(staffList)
        }
    }

    const handleCancelAction = (action) => {
        if(action){
            setUnassignConfirm(true)
            handleUnassignStaff(assignedStaff, assignedStaffIndex)
        }
        else{
            setUnassignStaff(false)
        }
    }

    return (
        <NormalModal
            toggle={setDefault}
            className={'modal-fill'}
            isOpen={modalOpenClose}
            size="xl"
            renderHeader={e =>
                <div className=" d-flex justify-content-between">
                   <h6 className=" ml-2 mb-0">Assign WR Id - {!!workID&&workID.substr(workID.length - 6)}</h6>
                </div>
            }
            bodyClass='add-staff-box'
            headerClass="border-0 "
        >
            {
                !isLoading ?
                    <div>
                    <div className="row align-items-start">
                        <div className="col-5">
                            <div className="row align-items-center mb-3 ">
                                <div className="col-6 mb-3">
                                    <p className="sub-text text-black assign-wr-label">Job Function</p>
                                    <p className="disable-text mb-0">{jobCategory} - {jobName}</p>
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-between mb-3">
                                    <div className="form-group mb-0">
                                    <span className={"color-box "+(status !==2 ?getClassNameForBackground(status):status == 2 && workStaffAssignment.staffWorkData.length !==0?getClassNameForBackground(2.1):getClassNameForBackground(2) )}></span>
                                        <span className={`req-status text-black fs-16`}>                                            
                                            { 
                                                status === 0 ? 'CANCELED': 
                                                status === 1 ? 'NEW REQUEST': 
                                                status === 2 && workStaffAssignment.staffWorkData.length !==0 ? 'VIEW STAFF RESPONSE. PLEASE CONFIRM': 
                                                status === 2 ? 'PENDING STAFF ASSIGNMENT':
                                                status === 3 ? 'PENDING STAFF CLOCK-IN': 
                                                status === 4 ? 'STAFF IS WORKING': 
                                                status === 5 ? 'PENDING TIMESHEET APPROVAL BY CLIENT':
                                                status === 6 ? 'PLEASE REVIEW STAFF TIMESHEET': 
                                                status === 7 ? 'TIMESHEET APPROVED. CREATE INVOICE NOW!': 
                                                status === 8 ? 'INVOICE CREATED':
                                                status === 9 ? 'INVOICE PAID':
                                                status === 10 ? 'NOT FILLED':''
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <p className="sub-text assign-wr-label">Client Name</p>
                                    <p className="disable-text mb-0">{clientName}</p>
                                </div>

                                <div className="col-12 mb-3">
                                    <p className="sub-text assign-wr-label">Client Site Location</p>
                                    <p className="disable-text ">{location}
                                        <img src={markerIcon} className="float-right" />
                                    </p>
                                </div>

                                {/* <div className="col-12 d-flex align-items-center"> */}
                                <div className="col-6 form-group ">
                                    <p className="sub-text text-black assign-wr-label">Start Date</p>
                                    {/* <NormalDate
                                        placeholder="MM/DD/YYY"
                                        name="username"
                                        value={!!startDate ? new Date(startDate): new Date()}
                                        className="form-control rounded-0"
                                        // onChange={this.handleChange}
                                        label={""}
                                    /> */}
                                    <p className="disable-text ">{Moment(new Date(startDate)).format(localStorage.getItem('dateFormat'))}
                                        <img src={calendarimg} className="float-right" />
                                    </p>

                                </div>
                                <div className="col-6 form-group ">
                                    <p className="sub-text text-black assign-wr-label">End Date</p>
                                    {/* <NormalDate
                                        placeholder="MM/DD/YYY"
                                        name="username"
                                        value={!!endDate ? new Date(endDate) : new Date()}
                                        className="form-control rounded-0"
                                        // onChange={this.handleChange}
                                        label={""}
                                    /> */}
                                    <p className="disable-text ">{Moment(new Date(endDate)).format(localStorage.getItem('dateFormat'))}
                                        <img src={calendarimg} className="float-right" />
                                    </p>

                                </div>
                            </div>

                            {/* <div className="col-12">
                                <div className="form-group">
                                    <p className="sub-heading fs-12">Weekly on monday, Tuesday,wednesday,thursday,friday 
                                        until Oct 14,2020</p>
                                    
                                </div>    
                            </div> */}

                            {/* </div> */}
                            <div className="row align-items-center mb-3 ">
                                <div className="col-6 pr-1">
                                    <div className="d-flex align-items-center">

                                        <div className="form-group mr-1 mb-1">
                                            <p className="sub-text text-black assign-wr-label">Start Time</p>
                                            {/* <NormalSelect
                                            placeholder="Weekly"
                                            name="clentName"
                                            value={Moment(new Date(startDate)).format('hh:mm a')}
                                            className="form-control rounded-0"
                                            onChange={()=>{}}
                                            disabled={true}
                                            label={''}
                                        /> */}
                                            <p className="disable-text mb-0">{Moment(new Date(startDate)).format('hh:mm a')}</p>

                                        </div>
                                        <div className="form-group ml-1 mb-1">
                                            <p className="sub-text text-black assign-wr-label">End Time</p>
                                            {/* <NormalSelect
                                            placeholder="Weekly"
                                            name="clentName"
                                            value={Moment(new Date(endDate)).format('hh:mm a')}
                                            className="form-control rounded-0"
                                            onChange={()=>{}}
                                            disabled={true}
                                            label={''}
                                        /> */}
                                            <p className="disable-text mb-0">{Moment(new Date(endDate)).format('hh:mm a')}</p>

                                        </div>
                                    </div>
                                    {/* <p className="sub-text-gray font-italic fs-14">Start and end time for start date</p>  */}
                                </div>
                                <div className="col-6">
                                    <div className="col-12">
                                        <p className="sub-text text-black assign-wr-label"> No. Of Workers Requested</p>
                                    </div>
                                    <div className="col-12">
                                        <p className="disable-text mb-0">{staffNeeded}</p>
                                    </div> 
                                </div>
                                <div className="col-6">
                                    {/* <div className="row align-items-center">
                                    <div className="col-12">
                                        <p className="sub-text text-black fs-14">Time Zone</p>
                                    </div>
                                    <div className="col-12">
                                        <p className="disable-text mb-0">10:30 am</p>
                                    </div> 
                                </div> */}
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        {/* <p className="sub-heading fs-12">Weekly on monday, Tuesday,wednesday,thursday,friday 
                                        until Oct 14,2020</p> */}

                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-1 ">
		    		{/*<div className="col-6">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <p className="sub-text text-black fs-14">No.of staff requested</p>
                                    </div>
                                    <div className="col-12">
                                        <p className="disable-text mb-0">{staffNeeded}</p>
                                    </div> 
                                </div>
                            	</div> */} 
                                {/* <div className="col-6">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <p className="sub-text text-black fs-14">Rate (optional)</p>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                 
                                        

                                    <div className="col-7 form-group mb-0 pr-1">
                                        <NormalInput
                                            placeholder="100"
                                            name="monthlyAmount"
                                            value={staffNeeded}
                                            className="form-control mr-2 small-input-box rounded-0"
                                            onChange={()=>{ }}
                                        />
                                    </div> 
                                    <div className="col-5 form-group  mb-0 pl-1">
                                        <NormalSelect
                                            options={[{ label: "", value: "" },{ label: "USD", value: "USD" },{ label: "INR", value: "INR" }]}
                                            placeholder="USD"
                                            name="clentName"
                                            value={"USD"}
                                            className="form-control rounded-0"
                                            onChange={()=>{}}
                                            label={''}
                                        />
                                        
                                    </div>  
                                </div>
                            </div> */}
                            </div>
                            <div className="row justify-content-end ">
                                <div className="col-6">
                                    <div className="form-group">
                                        {/* <NormalCheckbox
                                        name="isKeepMe"
                                        checked={false}
                                        label="Visible to staff"
                                        id="isKeepMe"
                                        onChange={()=>{}}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center ">
                                {/* <div className="col-12">
                                    <div className="form-group">
                                        <p className="sub-text text-black assign-wr-label">Assign Worker</p> */}
                                        {/* <div className="customStaffContainer">
                                        {
                                            selectStaffList.length && staffList.length ?
                                                <>
                                                    {
                                                        selectStaffList.map((item, index) => (

                                                            <p key={index} className="sub-text text-black fs-14 selectStaff">{staffList[staffList.findIndex((ar) => ar.value === item)].customLabel}
                                                            {
                                                                !!workStaffAssignment?.staffWorkData &&
                                                                workStaffAssignment?.staffWorkData?.filter(staff=>staff.staffId===item).length>0 && 
                                                                workStaffAssignment?.staffWorkData?.filter(staff=>staff.staffId===item)[0].status >= 4 &&
                                                                !!workStaffAssignment?.staffWorkData?.filter(staff=>staff.staffId===item)[0].clockIn
                                                                ?
                                                                <span className="cursor-pointer"></span>
                                                                :
                                                                <span className="cursor-pointer" onClick={() => {
                                                                    let staffPressent = -1;
                                                                    if(!!workStaffAssignment.staffWorkData){
                                                                        staffPressent=workStaffAssignment.staffWorkData.findIndex((ar)=>ar.staffId === item);
                                                                    }   
                                                                    if(staffPressent !== -1){
                                                                        handleRejectStaff(item);
                                                                    }
                                                                    let copy = [...selectStaffList];
                                                                    copy.splice(index, 1)
                                                                    setSelectStaffList(copy);
                                                                    setSelectStaff('');
                                                                    
                                                                }}>x</span>
                                                            }
                                                            </p>

                                                        ))
                                                    }
                                                </>
                                                : ''

                                        }
                                        </div> */}
                                        {/* <Select
                                            // isMulti
                                            closeMenuOnSelect={false}
                                            styles={colourStyles}
                                            placeholder="Select Staff"
                                            options={staffList}
                                            className="basic-multi-select form-control-multiselect"
                                            value={selectStaff}
                                            name="clentName"
                                            // isDisabled={Number(staffNeeded) === selectStaffList.length || status >4}
                                            isDisabled={status >4}
                                            onChange={(e) => {
                                                // console.log(e)
                                                setSelectStaff(e.value);
                                                let data = [...selectStaffList];
                                                if (!data.includes(e.value)) {
                                                    data.push(e.value);
                                                    setSelectStaffList(data);
                                                } else {
                                                    Toast({ type: "error", message: "This staff is already assigned. Please select another staff" });
                                                }
                                            }}
                                        /> */}
                                        {/* <NormalSelect
                                            placeholder="Select Staff"
                                            name="clentName"
                                            options={staffList}
                                            value={selectStaff}
                                            className="form-control rounded-0"
                                            handleChange={(e)=>{
                                                console.log(e)
                                                setSelectStaff(e.target.value);
                                                let data =[...selectStaffList];
                                                data.push(e.target.value)
                                                setSelectStaffList(data)
                                            }}
                                            disabled={Number(staffNeeded) === selectStaffList.length || status !== 2}
                                            label={''}
                                            multiLabel={true}
                                        /> */}
                                        {/* {validator.current.message("Employee", selectStaffList, "required")}
                                    </div> */}
                                        {/* {status > 4 ?
                                        <>
                                            <p className="text-success fs-12 cursor-pointer" onClick={() => handleApproveStaff(staffId)}>Approve </p>
                                        </>
                                        :
                                        <p className="text-success fs-12" >Approved </p>

                                    } */}
                                        {/* {
                                        !!selectStaff ?
                                        <>
                                            <p className="text-success fs-12 cursor-pointer mr-2" onClick={() => handleApproveStaff()}>Approve </p>
                                            <p className="text-danger fs-12 cursor-pointer ml-2" onClick={() => handleRejectStaff()}>Reject </p>
                                        </>
                                        :''
                                    } */}
                                {/* </div> */}
                                {/* <div className="col-12 mb-3">
                                    <p className="sub-text assign-wr-label"> Client Requested On</p>
                                    <p className="disable-text "> {Moment(createdAt).format(localStorage.getItem('dateFormat'))} {Moment(createdAt).format('hh:mm:ss a')} </p> */}
		    			            {/* <span className="ml-2">{Moment(startDate).format('hh:mm a')} - {Moment(endDate).format('hh:mm a')}</span> */}
                                {/* </div> */}
                                {/* <div className="col-12 mb-3">
                                    <p className="sub-text text-black fs-14">Client Address</p>
                                    <NormalTextarea
                                        placeholder=""
                                        name="username"
                                        rows={3}
                                        value={location}
                                        className="form-control rounded-0"
                                        // onChange={this.handleChange}
                                        label={""}
                                    />
                                </div> */}
                                {/* <div className="col-12 d-flex align-items-center justify-content-between">
                                    <p className="sub-text text-black assign-wr-label">Work Title </p>
                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-between mb-3">
                                    <p className="disable-text mb-0 work-title-msg">{ workRequest? workRequest : "Not Provided" }</p>
                                </div> */}
                                {/* <div className="col-12 ">
                                    <div className="form-group">
                                        <p className="sub-text assign-wr-label">Client Note</p>
                                        <NormalInput
                                            placeholder=" "
                                            name="note"
                                            value={ notes? notes : "Not Provided" }
                                            disabled={true}
                                            className="form-control rounded-0"
                                            // onChange={this.handleChange}
                                            label={""}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-12">
                                    <p className="sub-text assign-wr-label">Client Document</p>
                                    <div className="form-group">
                                        <div className="file-upload">
                                            <DragFileUpload
                                                disabled={true}
                                                className={`rounded-0 file-uploader ${documents?.length === 0 ? "gray-bg" : ""}`}
                                                // handleFileUpload={handleFileUpload}
                                                handleFileUpload={() => { }}
                                                grayBg={documents?.length === 0}
                                            >
                                                <div className="p-3 icon">
                                                   
                                                    {
                                                        !!documents && documents.length > 0 ?
                                                            <>
                                                                {
                                                                    // documents.map((item) =>  { return (<img className="p-2 doc-upload-img" src={item.split("|").length>1 && item.split("|")[1]} alt={item.split("|").length>1 && item.split("|")[0]} />) })
                                                                    <DisplayFile file={documents} agencyId={agencyId}/>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                            <img style={{width: "250px", height: "250px"}} src={noDataFound} />
                                                            
                                                            </>
                                                    }
                                                </div>
                                            </DragFileUpload>

                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-12 d-flex align-items-center mt-3 justify-content-end "> */}
                                    {/* <NormalButton
                                        onClick={()=>{}}
                                        id="Save AS Draft"
                                        label="SAVE AS DRAFT"
                                        outline={false}
                                        // loader={isFormLoder}
                                        className=" btn fs-14 btn-dark-primary-outline btn-block  "
                                    /> */}
                                    {/* <div className="p-3"></div> */}
                                    {/* <NormalButton
                                        onClick={() => { handleAllAssignStaff() }}
                                        id="Assign"
                                        label="Submit"
                                        outline={false}
                                        disabled={status>4 || !selectStaffList.length}
                                        loader={loadingKey === 'handle-submit'}
                                        //disabled={Number(staffNeeded) !== selectStaffList.length|| status>4}
                                        // loader={isFormLoder}
                                        className=" btn fs-14 btn-dark-primary btn-block  "
                                    /> */}

                                {/* </div> */}
                            </div>


                        </div>
                        <div className="col-7 pr-0">
                            <div className="row ">
		    		 {/*<div className="col-8 d-flex justify-content-end align-items-center">
                                    <div className="form-group ">
                                        <p>Show by</p>

                                    </div>
                                </div>
                                <div className="col-4 mb-3">
                                    <div className="form-group pr-5">
                                        <NormalSelect
                                            placeholder="Select"
                                            name="showBy"
                                            value={showBy}
                                            className="form-control "
                                            options={[{ label: "Geo location", value: "Geo location" }, { label: "Availability", value: "Availability" }]}
                                            handleChange={(e) => {
                                                setshowBy(e.target.value)
                                            }}
                                        />

                                    </div>
                                </div> */}
                                <div className="col-12 pr-0">
                                    <div onClick={() => setMapActive(true)}>
                                        <div style={{ pointerEvents: mapActive ? "auto" : "none" }}>
                                    {
                                        !!longLat ?
                                            <div style={{ height: "643px", width: "100%" }}>

                                                <GoogleMapReact
                                                    // bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
                                                    defaultCenter={{
                                                        lat: longLat[1],
                                                        lng: longLat[0]
                                                    }}
                                                    defaultZoom={5}
                                                // onChildClick={()=>{
                                                //     setInfoWindowOpen(!infoWindowOpen)
                                                // }}
                                                // onChildClick={()=>{
                                                //     onChildClick()
                                                // }}
                                                // onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                                                >
                                                    {/* <AnyReactComponent
                                                        lat={59.955413}
                                                        lng={30.337844}
                                                        text="My Marker"
                                                    /> */}

                                                    {/* <StaffMarker
                                                lat={latLong[0]}
                                                lng={latLong[1]}
                                                text="My Marker"
                                            /> */}
                                                    <Marker
                                                        lat={longLat[1]}
                                                        lng={longLat[0]}
                                                        name={clientName}
                                                        color=""
                                                        isInfo={false}
                                                        children={
                                                       
                                                            // <img height="40px" width="40px" src={!!sitePicture && sitePicture.split("|").length>1 ? sitePicture.split("|")[1]:defaultClientImage} />
                                                            <img height="40px" width="40px" src={onlineImage ? onlineImage : defaultClientImage}/>
                                                        }
                                                    />

                                                    {
                                                        staffListMap.map((item, index) => {
                                                            let staffLatLng;
                                                           
                                                            let { status, name, location, awayFrom } = item;
                                                            if (item.longLat !== null) {
                                                                staffLatLng = {
                                                                    lat: item.longLat[1],
                                                                    lng: item.longLat[0]
                                                                }
                                                            }
                                                            return (
                                                                <Marker
                                                                    key={item.id}
                                                                    index={item.id}
                                                                    lat={staffLatLng.lat}
                                                                    lng={staffLatLng.lng}
                                                                    name={name}
                                                                    color=""
                                                                    onClick={() => {
                                                                        handleToggleOpen(item.id)
                                                                    }}
                                                                    infoWindowOpen={infoWindowOpen}
                                                                    children={
                                                                        <div className={`${status == 'Available' ? 'green' : status == 'Shown interest' ? 'blue' : status == 'Already booked' ? 'red' : ''}`} id="custom-marker-staff">
                                                                            <div id="inner-circle"> 
                                                                       <img id='staff-pic' src={!!item.profilePic &&  item.profilePic.split("/").length>1 ? staffImage.length>0&&staffImage?.filter((staff)=>staff?.id === item.id)[0]?.onlineImage : nav_user}/>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    infoWindowContent={
                                                                        // <div className="info-outline">
                                                                        //     {
                                                                            
                                                                        status == 'Leave' ?
                                                                            <div className="info-outline-small d-flex justify-content-center">
                                                                                <div className="">
                                                                                    <span className="text-red fs-12">
                                                                                        Leave
                                                                                </span>
                                                                                </div>
                                                                                <span className="rectang"></span>
                                                                            </div>
                                                                            :
                                                                        status == 'Already Booked' ?
                                                                            <div className="info-outline-small d-flex justify-content-center">
                                                                                <div className="">
                                                                                    <span className="text-red fs-12">
                                                                                        Already booked
                                                                                </span>
                                                                                </div>
                                                                                <span className="rectang"></span>
                                                                            </div>
                                                                           :
                                                                            <div className="info-outline">
                                                                                <div className="d-flex justify-content-between mb-2">
                                                                                    <div className={`status-info ${status === 'Available' ? 'green' : ''}`}>
                                                                                        <span className="text-c2 fs-12">
                                                                                            {selectStaffList.length == 0 ? status : !selectStaffList.filter((id)=>{return id == item.id}).length ? status : status != 'Available' ? status : 'Assigned'}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="close-info cursor-pointer" onClick={() => {
                                                                                        let val = 'sdf';
                                                                                        handleToggleOpen(item.id)
                                                                                    }}>
                                                                                        <img src={Vectorclose} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <p>Name</p>
                                                                                    </div>
                                                                                    <div className="col-8">
                                                                                        <p className="text-black">{name}</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <p>Location</p>
                                                                                    </div>
                                                                                    <div className="col-8">
                                                                                        <p className="text-black">{location}</p>
                                                                                        {/* <p className="mile"><i>{distanctravelledinkm(longLat[1],longLat[0],staffLatLng.lat,staffLatLng.lng)} Miles Away from client</i></p> */}
                                                                                        <p className="mile"><i> <DistanceMatrix destination={longLat[1]} destination2={longLat[0]} origin={staffLatLng.lat} origin2={staffLatLng.lng} unit={distanceUnit}/></i></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <p>Overtime</p>
                                                                                    </div>
                                                                                    <div className="col-8">
                                                                                        <p className="text-black">Not exceeded</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    {
                                                                                        status === 'Available' ?
                                                                                            <div className="col-4">
                                                                                                
                                                                                                 <p className={`text-decoration-underline ${parentStatus<4 && selectStaffList.length == 0 ? 'mile' : !selectStaffList.filter((id)=>{return id == item.id}).length ? 'mile' : 'grey'} ${parentStatus<4 && selectStaffList.length == 0 ? 'cursor-pointer' : !selectStaffList.filter((id)=>{return id == item.id}).length ? 'cursor-pointer':'no-drop'}`} onClick={() => {
                                                                                                    (parentStatus<4 && selectStaffList.length == 0 ? true : !selectStaffList.filter((id)=>{return id == item.id}).length) && handleAssignStaffFromMap(item.id)
                                                                                                 }}>
                                                                                                     Assign
                                                                                </p>
                                                                                </div>
                                                                                            :
                                                                                            
                                                                                            <>
                                                                                              { status!== 'Available' && status!== 'Shown Interested'?
                                                                                                <div className="col-3 text-muted">
                                                                                                    <p className='text-muted'
                                                                                                    //  onClick={() => {
                                                                                                    //     handleAssignStaffFromMap(item.id)
                                                                                                    // }}
                                                                                                    >
                                                                                                        Assign
                                                                                                    </p>
                                                                                                </div>:""}
                                                                                                {/* <div className="col-3">
                                                                                                    <p className={`text-decoration-underline mile ${parentStatus>4 ? 'no-drop':'cursor-pointer'}`} onClick={() => {
                                                                                                      handleApproveStaff(item.id)  
                                                                                                    }}>
                                                                                                        Approve
                                                                                </p>
                                                                                                </div> */}
                                                                                                {status === 'Shown Interested' ? 
                                                                                                 <>
                                                                                                <div className="col-3">
                                                                                                <p className={`text-decoration-underline mile ${parentStatus>4 ? 'no-drop':'cursor-pointer'}`}
                                                                                                 onClick={() => {
                                                                                                    handleAssignStaffFromMap(item.id)
                                                                                                }}
                                                                                                >
                                                                                                    Assign
                                                                                                </p>
                                                                                                </div>
                                                                                                <div className="col-3">
                                                                                                    <p className={`text-decoration-underline reject ${parentStatus>4 ? 'no-drop':'cursor-pointer'}`} onClick={() => {
                                                                                                        handleRejectStaffFromMap(item.id)
                                                                                                    }}>Reject</p>
                                                                                                </div> </> : null}
                                                                                                {/* <div className="col-3">
                                                                                                    <p className={`text-decoration-underline text-c2 ${parentStatus>4 ? 'no-drop':'cursor-pointer'}`}>Hold *</p> 
                                                                                                </div> */}
                                                                                                
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                                <span className="rectang"></span>
                                                                            </div>
                                                                    }
                                                                //     </div>
                                                                // }
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {/* <InfoWindow open={infoWindowOpen} >
                                                <div>
                                                    <h1>Marker Info Placeholder</h1>
                                                </div>
                                            </InfoWindow> */}
                                                </GoogleMapReact>
                                            </div>
                                            : ''
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className ="col-12 staffIcon"src={staff} alt='staff' width={38} height={38} />
                    <div className='col-12 d-flex justify-content-center align-items-center'>
                        <div className='assigned-worker-container'>
                            <p className="sub-text text-black assign-wr-label">Assigned Worker</p>
                            <div className='col-12 assigned-worker mb-3'>
                                <div className="customStaffContainer">
                                    {
                                        selectStaffList.length && staffList.length ?
                                            <>
                                                {
                                                    selectStaffList.map((item, index) => (

                                                        <p key={index} className="sub-text text-black fs-14 selectStaff">{staffList[staffList.findIndex((ar) => ar.value === item)].customLabel.length > 30 ? staffList[staffList.findIndex((ar) => ar.value === item)].customLabel.slice(0, 30) + "..." : staffList[staffList.findIndex((ar) => ar.value === item)].customLabel}
                                                        {
                                                            !!workStaffAssignment?.staffWorkData &&
                                                            workStaffAssignment?.staffWorkData?.filter(staff=>staff.staffId===item).length>0 && 
                                                            workStaffAssignment?.staffWorkData?.filter(staff=>staff.staffId===item)[0].status >= 4 &&
                                                            !!workStaffAssignment?.staffWorkData?.filter(staff=>staff.staffId===item)[0].clockIn
                                                            ?
                                                            <span className="cursor-pointer"></span>
                                                            :
                                                            <span className="cursor-pointer" onClick={() => {
                                                                setUnassignStaff(true)
                                                                setAssignedStaff(item)
                                                                setAssignedStaffIndex(index)
                                                                
                                
                                                                
                                                            }}>x</span>
                                                        }
                                                        </p>

                                                    ))
                                                }
                                            </>
                                            : 'No staff assigned'

                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='d-flex justify-content-center mb-3'>
                            <div className="col-12">
                                <div className="form-group">
                                    <p className="sub-text text-black assign-wr-label">Assign Worker</p>
                                    <Select
                                        // isMulti
                                        closeMenuOnSelect={false}
                                        styles={colourStyles}
                                        placeholder="Select or Search Staff"
                                        options={!optLoading ? [{ label: <CommonPageLoader />, value: '' }] : searchData.length > 0 ? searchData : [{label: <div className='assign-staff-no-staff'> <img className='no-results-ws' src={no_results} alt="no-results" /> </div>, value:""}]}
                                        className="basic-multi-select form-control-multiselect"
                                        value={selectStaff}
                                        name="clientName"
                                        // isDisabled={Number(staffNeeded) === selectStaffList.length || status >4}
                                        isDisabled={status >4}
                                        onInputChange={(newValue) => handleInputChange(newValue)}
                                        filterOption = {handleCustomFilter}
                                        // isSearchable= {false}
                                        onChange={(e) => {
                                            // console.log(e)
                                            setSelectStaff(e.value);
                                            let data = [...selectStaffList];
                                            if (!data.includes(e.value)) {
                                                data.push(e.value);
                                                setSelectStaffList(data);
                                            } else {
                                                Toast({ type: "error", message: "This staff is already assigned. Please select another staff" });
                                            }
                                        }}
                                    />
                                    {validator.current.message("Employee", selectStaffList, "required")}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-3 '>
                            <div className='col-8' style={{marginLeft:"-0.625rem"}}>
                                <div className='d-flex mb-3'>
                                    <div className="col-4">
                                        <p className="sub-text assign-wr-label"> WR Requested On</p>
                                        <p className="disable-text "> {Moment(createdAt).format(localStorage.getItem('dateFormat'))} {Moment(createdAt).format('hh:mm:ss a')} </p>
                                    </div>
                                    <div className='col-8'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="sub-text text-black assign-wr-label">Work Title </p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <p className="disable-text mb-0 work-title-msg">{ workRequest? workRequest : "Not Provided" }</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-12 ">
                                        <div className="form-group">
                                            <p className="sub-text assign-wr-label">Client Note</p>
                                            <NormalInput
                                                placeholder=" "
                                                name="note"
                                                value={ notes? notes : "Not Provided" }
                                                disabled={true}
                                                className="form-control rounded-0"
                                                // onChange={this.handleChange}
                                                label={""}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4' style={{marginRight:"-0.8rem"}}>
                                <div className="col-12">
                                    <p className="sub-text assign-wr-label">Client Document</p>
                                    <div className="form-group">
                                        <div className="file-upload">
                                            <DragFileUpload
                                                disabled={true}
                                                className={`rounded-0 file-uploader ${documents?.length === 0 ? "gray-bg" : ""}`}
                                                // handleFileUpload={handleFileUpload}
                                                handleFileUpload={() => { }}
                                                grayBg={documents?.length === 0}
                                            >
                                                <div className="p-3 icon">
                                                    
                                                    {
                                                        !!documents && documents.length > 0 ?
                                                            <>
                                                                {
                                                                    // documents.map((item) =>  { return (<img className="p-2 doc-upload-img" src={item.split("|").length>1 && item.split("|")[1]} alt={item.split("|").length>1 && item.split("|")[0]} />) })
                                                                    <DisplayFile file={documents} agencyId={agencyId}/>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                            <img style={{width: "250px", height: "250px"}} src={noDataFound} />
                                                            {/* <p className="p-2">Drag and drop the files <br />or<br /> browse</p> */}
                                                            
                                                            </>
                                                    }
                                                </div>
                                            </DragFileUpload>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mb-3 mr-3">
                            
                            <NormalButton
                                onClick={() => { handleAllAssignStaff() }}
                                id="Assign"
                                label="Submit"
                                outline={false}
                                disabled={status>4 || !selectStaffList.length}
                                loader={loadingKey === 'handle-submit'}
                                //disabled={Number(staffNeeded) !== selectStaffList.length|| status>4}
                                // loader={isFormLoder}
                                className=" btn fs-14 btn-dark-primary btn-block  "
                            />

                        </div>
                    </div>
                    {
                        
                    unassignStaff && <DeleteFileConfirmationModal toggle={() => setUnassignStaff(!unassignStaff)}
                    isOpen={unassignStaff}
                    handleAction={(e) => handleCancelAction(e)}
                    message = "Are you sure you want to unassign staff?"
                    customText={"permanently"}
                    />
                
                    }
                    </div>
                    :
                    <CommonPageLoader />
            }

        </NormalModal>

    )
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSingleWork,approveStaffByClient, fileUpload, assignStaff, rejectStaff, getAllAvailableStaff, getSettingsById, rejectStaffInterested, getFileFromMDB
        },
        dispatch
    );
};

export default connect(
    mapDispatchToProps,
    mapDispatchToProps
)(withRouter(AssignWorkSchedule));

const StaffMarker = props => {
    return <div className="SuperAwesomePin"></div>
}
