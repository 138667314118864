import React, { Component } from "react";
import {
   amountFormat,
   numberFormat,
   currencyFormat,
} from "service/helperFunctions";
import infoIcon from "assets/svg/info.svg";
import "./style.scss"

export class InputPrice extends Component {
   render() {
      let {
         className = "",
         placeholder = "",
         onChange,
         value = "",
         name,
         disabled = false,
         type = "text",
         iconname = "",
         max = "",
         min = "",
         caps = false,
         format = "",
         label = "",
      } = this.props;

      return (
         <>
            {iconname !== "" ? (
               <span className={`${iconname} input-icon`}></span>
            ) : (
                  ""
               )}
            {label !== '' ?
                <div className="fs-17 text-black font-JosefinSans pb-2 mb-1 line-height-20">
                    {label} 
                </div>
                : ''}   
            <form autocomplete="off">
               <input
                  className={`custom-input-price form-control  ${className} ${
                     iconname !== "" ? "pl-5 pr-5" : "pl-3"
                     }`}
                  name={name}
                  type={type}
                  disabled={disabled}
                  value={value}
                  minLength={min}
                  maxLength={max}
                  placeholder={placeholder}
                  autoComplete={'off'}
                  onChange={(e) => {
                     let body = {};
                     let tempVal = caps
                        ? e.target.value.toUpperCase()
                        : e.target.value;
                     if (format === "amount") {
                        tempVal = parseFloat(amountFormat(tempVal));
                     } else if (format === "number") {
                        tempVal = numberFormat(tempVal);
                     }

                     body = {
                        target: {
                           name: e.target.name,
                           value: tempVal,
                        },
                     };

                     onChange(body);
                  }}
                  onBlur={(e) => {
                     let body = {};
                     let tempVal = caps
                        ? e.target.value.toUpperCase()
                        : e.target.value;
                     if (format === "currency") {
                        tempVal = parseFloat(tempVal);
                     }
                     body = {
                        target: {
                           name: e.target.name,
                           value: tempVal,
                        },
                     };

                     onChange(body);
                  }}
               />
            </form>
         </>
      );
   }
}
