

export const clientManagementSteps = [
    // {
    //     target:'h4',
    //     content:'Client Module',
    //     placement:'auto',
    //     disableBeacon: true,
    // },
    {
        target:'#client-invites',
        content:'Check your client invitations here',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'#onboard-client',
        content:'Here you can onboard new client',
        placement:'auto',
    },
    {
        target:'.input-group',
        content:'Here you can search client from your existing client connections',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        target:'table > tbody > tr:nth-child(1)',
        content:'Check your client basic details here',
        placement:'auto',
    },
    {
        target:'table > tbody > tr:nth-child(1) > td:nth-child(6) > div > #edit-client-prof',
        content:'Edit client details by clicking here',
        placement:'auto',
        spotlightPadding:3,
    },
    {
        target:'table > tbody > tr:nth-child(1) > td:nth-child(6) > div > #unsubscribe-client-prof',
        content:'You can unsubscribe your client by clicking here',
        placement:'auto',
        spotlightPadding:3,
    },

]

export const clientManagementAddSteps = [
    {
        target:'.nav-tabs > a:nth-child(1)',
        content:'You can create a client account by entering basic information in this tab.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(2)',
        content:'Update your client billing rates here.',
        placement:'auto',
        disableBeacon: true,
    },
    {
        target:'.nav-tabs > a:nth-child(3)',
        content:'Update your client tax details here.',
        placement:'auto',
        disableBeacon: true,
    }
]