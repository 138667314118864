import { addQuery } from "service/helperFunctions";
import { clientConnectApi} from "../service/apiVariables";

export const acceptClientInvite = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, clientConnectApi.acceptInviteFromClient);
    api({ ...clientConnectApi.acceptInviteFromClient })
      .then(({ data, message }) => {
        Toast({ type: "success", message })
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getClientInvitations = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, clientConnectApi.getClientInvitations);
    api({ ...clientConnectApi.getClientInvitations })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const notifyUsers = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, clientConnectApi.notifyUsers);
    api({ ...clientConnectApi.notifyUsers })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
