export const timeSheetReviewSteps = [
    {
        content:'Use these powerful filters that will help you easily customise your search experience and discover the perfect data for you.',
        target:'#time-sheet-filters',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        content:'Click here to switch to calendar view.',
        target:'.calender_img',
        placement:'auto',
        spotlightPadding:5,
    },
    {
        target:'.refreshIcon',
        content:'Click here to reload all work requests.', 
        
    },
    {
        target:'.time-sheet-record',
        content:'You can review the time sheet details of work request here.',
        placement:'auto',
        disableBeacon: true,
        
    },
    {
        content:'View time sheet details by clicking here.',
        target:'#time-sheet-view > div > span',
        placement:'auto',
        
    },
    {
        content:'You can edit time sheet details by clicking here.',
        target:'#time-sheet-edit > div > span',
        placement:'auto',
        
    },
]

export const timeSheetCalendarSteps = [
    {
        target:'.time-period',
        content:'Click here to view work request details  ',
        disableBeacon: true,
        disableScrolling: true,
    },
]