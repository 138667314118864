import React from 'react';
import { NormalButton, NormalModal } from 'component/common';

export function DeleteFileConfirmationModal({handleAction,isOpen,toggle,message}) {
    
    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            headerClass="border-0 pt-4 px-4"
            toggle={toggle}
        >
             <>
                  <div className=" text-center mb-5 px-5">
                     <p className="h3-m mb-0">
                     {message ? message :  "Are you sure you want to delete it ?"}
                     </p>
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                     <NormalButton
                        onClick={()=>{
                            handleAction(false)
                        }}
                        id="no"
                        label="No"
                        outline={false}
                        // loader={isFormLoder}
                        className="mb-2 btn fs-16 btn btn-dark-primary  btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                            handleAction(true)
                        }}
                        id="confirm"
                        label="Confirm"
                        outline={true}
                        // loader={isFormLoder}
                        className="mb-2 ml-2 btn fs-16 btn-dark-outline  btn-block "
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}